import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_MERCHANT_BY_BIN } from '../../GraphQL/Queries';
import {Button, Col, Form, FormFeedback, Input, Label, Row} from "reactstrap";
import { UpdatePushNotify } from '../../schemas';
import {useFormik} from "formik";
import { UPDATE_PUSH_PARAMETERS } from '../../GraphQL/Mutations';
import {toast} from "react-toastify";
import InputMask from "react-input-mask";
import {useNavigate} from "react-router-dom";
import Loader from '../UI/Loader/Loader';

const EditPushDetails = ({bin}) => {
    const [clicked, setClicked] = useState(false);
    const [merchant, setMerchant] = useState({
        bin: bin,
        kaspiNotification: false,
        kaspiPhone: ''
    });
    const [updatePushParameters] = useMutation(UPDATE_PUSH_PARAMETERS, { 
        context: { clientName: 'billing' },
        refetchQueries: [{query: GET_MERCHANT_BY_BIN, variables: {bin}, context: { clientName: 'billing' }}]
    });
    const navigate = useNavigate();

    const {loading, error, data} = useQuery(GET_MERCHANT_BY_BIN, {
        variables: {bin},
        context: { clientName: 'billing' }
    });

    const formik = useFormik({
        initialValues: merchant,
        enableReinitialize: true,
        validationSchema: UpdatePushNotify,
        onSubmit: (values) => {
            console.log('submit')
            setClicked(true);

            let variables = {
                merchantModel: {
                    bin: bin,
                    kaspiNotification: values.kaspiNotification,
                    kaspiPhone: values.kaspiPhone
                }
            }
            
            toast.promise(
                updatePushParameters({variables: variables}), {
                    pending: 'В обработке',
                    success: 'Параметры пуша обновлены',
                    error: 'Ошибка'
                }
            )
                .then(result => {
                    // navigate('/merchants')
                })
                .catch(err => {
                    setClicked(false);
                })
        }
    });

    useEffect(() => {
        if (data && data.merchantFindByBin){
            setMerchant({
                ...merchant,
                kaspiNotification: data.merchantFindByBin.kaspiNotification,
                kaspiPhone: data.merchantFindByBin.kaspiPhone
            })
        }
    }, [data]);

    if (loading) return <Loader/>
    return (
        <Form onSubmit={formik.handleSubmit}>
            <div className={'create_invoice_wrapper'}>
                <h3>Редактирование параметров пуша</h3>
                <Row className='row_form'>
                    <Col xs={4}><Label for="guid">БИН</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="bin"
                            id="bin"
                            disabled
                            value={bin}
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={12}>
                        <Label for="kaspiNotification" className="activeTerminalLabel">Уведомлять в Каспи?</Label>
                        <Input type="checkbox" name="kaspiNotification" id="kaspiNotification" checked={formik.values.kaspiNotification === true} onChange={() => formik.setFieldValue('kaspiNotification', !formik.values.kaspiNotification)}/>
                    </Col>
                </Row>
                {formik.values.kaspiNotification === true
                    ? <Row className='row_form'>
                        <Col xs={4}><Label for="helpDeskPhone">Номер для получения уведомлении</Label></Col>
                        <Col xs={8}>
                            <Input
                                type="tel"
                                mask="9999999999"
                                maskChar="_"
                                name="kaspiPhone"
                                id="kaspiPhone"
                                invalid={formik.touched.kaspiPhone && Boolean(formik.errors.kaspiPhone)}
                                placeholder={'+7(XXX)XXX-XX-XX'}
                                onChange={formik.handleChange}
                                tag={InputMask}
                                value={formik.values.kaspiPhone}
                            />
                            <FormFeedback>
                                {formik.touched.kaspiPhone && formik.errors.kaspiPhone}
                            </FormFeedback>
                        </Col>
                    </Row>
                    : null
                }
                <Button color={'primary'} type={'submit'} className="filter_button" disabled={clicked}>Сохранить</Button>
            </div>
        </Form>
    )
}

export default EditPushDetails;