import React, {useEffect, useState} from 'react';
import {Button} from "reactstrap";
import {useNavigate, Link} from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {useQuery} from "@apollo/client";
import {GET_SERVICE_PROFILES} from "../../GraphQL/Queries";
import Loader from "../../components/UI/Loader/Loader";
import {DataTable} from "../../components/DataTable/DataTable";

const ServiceProfiles = () => {
    const navigate = useNavigate();
    const [filter, setFilter] = useState({
        sortBy: 'id'
    });

    const { loading, error, data } = useQuery(GET_SERVICE_PROFILES, { variables: {
            sortBy: filter.sortBy
        }}
    );

    const sortByHandler = (e) => {
        setFilter({
            ...filter,
            sortBy: e.target.id
        })
    };

    const [serviceProfiles, setServiceProfiles] = useState([]);

    useEffect(() => {
        if (data) {
            console.log(data)
            setServiceProfiles(data.servicesProfiles)
        }
    }, [data, filter]);

    const columns = [
        { title: '#', id: '#' },
        { title: 'Id', id: 'id' },
        { title: 'Дата создания', id: 'creationDate'},
        { title: 'Дата изменения', id: 'changeDate'},
        { title: 'Наименование', id: 'name'},
    ];

    const rowsItem = [
        { title: '#' },
        { title: 'id' },
        { title: 'creationDate' },
        { title: 'changeDate' },
        { title: 'name' },
    ];

    if (loading) return <Loader/>

    return (
        <div>
            <div className="breadcrumb_wrapper">
                <Breadcrumb>
                    <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Профили услуг</BreadcrumbItem>
                </Breadcrumb>
                <Button
                    color="dark"
                    className="add_btn"
                    onClick={() => navigate('/service_profiles/new')}
                >
                    + Добавить
                </Button>
            </div>
            <DataTable
                hideColumns={[]}
                columns={columns}
                rows={serviceProfiles}
                rowsItem={rowsItem}
                link={'service_profiles'}
                sortBy={filter.sortBy}
                isPaginated={false}
                sortByHandler={(e) => sortByHandler(e)}
                hideIcons={true}
            />
        </div>
    );
};

export default ServiceProfiles;
