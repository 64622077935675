import React, {useState} from 'react';
import QrComponent from "../../QrComponent/QrComponent";
import {Modal, Button, Col, Label, Input, Row, Form} from 'reactstrap';
import {Link} from "react-router-dom";
import CreateInvoice from "../../CreateInvoice/CreateInvoice";
import TopUpBonus from "../../TopUpBonus/TopUpBonus";
import PushNotification from "../../PushNotification/PushNotification";
import EditPushDetails from '../../EditPushDetails/EditPushDetails';
import RefundKaspi from "../../RefundKaspi/RefundKaspi";
import RefundChargeOff from "../../RefundChargeOff/RefundChargeOff";
import CompletionRefund from "../../CompletionRefund/CompletionRefund";
import RefundBonus from "../../RefundBonus/RefundBonus";
import TerminalActivation from "../../TerminalActivity/TerminalActivation";
import DeactivateModul from "../../DeactivateModul/DeactivateModul";

const TableIcons = ({activationId, showDeactivate, showEditPhone, invoiceId, cancelInvoiceHandler, showCancelInvoice, bin, showInvoiceCreater, showDuplicate, id, terminalTypes, showQr, type, guid, address, merchName, showSettings, sendPushNotify, invoiceTypesData, transactionType, transactionAmount, refundedAmount, billingTerminal, terminalActivity}) => {
    const [openQr, setOpenQr] = useState(false);
    const [openInvoiceCreater, setOpenInvoiceCreater] = useState(false);
    const [openCancelInvoice, setOpenCancelInvoice] = useState(false);
    const [openTopUpBonus, setOpenTopUpBonus] = useState(false);
    const [openPushNotification, setOpenPushNotification] = useState(false);
    const [openEditPhone, setOpenEditPhone] = useState(false);
    const [openRefundKaspi, setOpenRefundKaspi] = useState(false);
    const [finishRefundKaspi, setFinishRefundKaspi] = useState(false);
    const [openChargeOff, setOpenChargeOff] = useState(false);
    const [openBonus, setOpenBonus] = useState(false);
    const [comment, setComment] = useState('');
    const [activeTerminal, setActiveTerminal] = useState(false);
    const [deactivateTerminal, setDeactivateTerminal] = useState(false);

    const commentHandler = (e) => {
        console.log(e.target)
        setComment(e.target.value)
    };

    return (
        <div className="Table_icons" style={{width: 135}}>
            <img
                src={"./../cancel.png"} alt="cancel"
                style={{
                    width: 30,
                    cursor: 'pointer',
                    display: showCancelInvoice ? 'inline-block' : 'none'
                }}
                onClick={() => setOpenCancelInvoice(true)}
            />
            <img
                src="invoice.png" alt="invoice"
                style={{
                    width: 30,
                    cursor: 'pointer',
                    display: showInvoiceCreater ? 'inline-block' : 'none'
                }}
                onClick={() => setOpenInvoiceCreater(true)}
            />
            <img
                src="gift_card.png" alt="invoice"
                style={{
                    width: 30,
                    cursor: 'pointer',
                    display: showInvoiceCreater ? 'inline-block' : 'none',
                    marginLeft: 5
                }}
                onClick={() => setOpenTopUpBonus(true)}
            />

            <img
                src="./../push.png" alt="push"
                style={{
                    width: 30,
                    cursor: 'pointer',
                    display: showCancelInvoice ? 'inline-block' : 'none',
                    marginLeft: 5
                }}
                onClick={() => setOpenPushNotification(true)}
            />

            <img
                src="edit_phone.png" alt="edit"
                style={{
                    width: 30,
                    cursor: 'pointer',
                    display: showEditPhone ? 'inline-block' : 'none',
                    marginLeft: 5
                }}
                onClick={() => setOpenEditPhone(true)}
            />

            {/*<a href={`http://kaspi-qr.multibank.kz/?editable=false&guid=${guid}&merchantName=${merchName}&address=${address}`} rel="noopener noreferrer" target={'_blank'}>*/}
            {/*    <img*/}
            {/*        src="https://img.icons8.com/wired/64/000000/qr-code.png" alt="qr"*/}
            {/*        width={30}*/}
            {/*        onClick={(e) => {*/}
            {/*            navigate('/terminals')*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</a>*/}

            <img
                src="qr-icon.png" alt="qr"
                style={{
                    width: 30,
                    cursor: 'pointer',
                    display: showQr ? 'inline-block' : 'none'
                }}
                onClick={() => setOpenQr(true)}
            />

            <img
                src="./../kaspi-refund.png" alt="kaspi-refund"
                style={{
                    width: 30,
                    cursor: 'pointer',
                    display: transactionType === 'Kaspi' ? 'inline-block' : 'none'
                }}
                title="Создать возврат по транзакции Kaspi"
                onClick={() => setOpenRefundKaspi(true)}
            />

            <img
                src="./../finish-refund.png" alt="finish-refund"
                style={{
                    width: 30,
                    cursor: 'pointer',
                    display: transactionType === 'Kaspi' ? 'inline-block' : 'none'
                }}
                title="Завершить возврат по транзакции Kaspi"
                onClick={() => setFinishRefundKaspi(true)}
            />

            <img
                src="./../charge-off-kaspi.png" alt="charge-off-refund"
                style={{
                    width: 30,
                    cursor: 'pointer',
                    display: transactionType === 'ChargeOff' ? 'inline-block' : 'none'
                }}
                title="Возврат по операции списания Charge Off"
                onClick={() => setOpenChargeOff(true)}
            />

            <img
                src="./../bonus.png" alt="bonus-refund"
                style={{
                    width: 30,
                    cursor: 'pointer',
                    display: transactionType === 'Bonus' ? 'inline-block' : 'none'
                }}
                title="Возврат по операции списания Bonus"
                onClick={() => setOpenBonus(true)}
            />

            <img
                src="./../activate.png" alt="activate-terminal"
                style={{
                    width: 30,
                    cursor: 'pointer',
                    display: billingTerminal && !terminalActivity ? "inline-block" : "none"
                }}
                title="Активация терминала"
                onClick={() => setActiveTerminal(true)}
            />

            <img
                src="./../deactivate.png" alt="deactivate-terminal"
                style={{
                    width: 30,
                    cursor: 'pointer',
                    display: showDeactivate ? "inline-block" : "none"
                }}
                title="Деактивация терминала"
                onClick={() => setDeactivateTerminal(true)}
            />

            <Link
                to={`/terminals/duplicate/${id}`}

                style={{
                    color: "inherit",
                    textDecoration: "none",
                    display: showDuplicate ? 'inline-block' : 'none',
                    marginLeft: 5,
                }}
            >
                <img
                    src={'duplicate.png'}
                    alt="duplicate"
                    width='30'
                />
            </Link>

            <Link
                to={`/controller_settings/${guid}`}
                style={{
                    color: "inherit",
                    textDecoration: "none",
                    display: showSettings ? 'inline-block' : 'none',
                    marginLeft: 5,
                }}
            >
                <img
                    src="https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/000000/external-settings-interface-kiranshastry-lineal-kiranshastry.png"
                    alt="settings"
                    width='30'
                />
            </Link>

            <Link
                to={`/logs/${guid}`}
                style={{
                    color: "inherit",
                    textDecoration: "none",
                    display: showSettings ? 'inline-block' : 'none',
                    marginLeft: 5,
                }}
            >
                <img
                    src="https://img.icons8.com/ios/50/000000/event-log.png"
                    alt="logs"
                    width='30'
                />
            </Link>

            <Modal
                isOpen={openQr}
                centered={true}
                fullscreen="xl"
                size="xl"
                style={{width: 1020}}
                toggle={() => setOpenQr(!openQr)}
            >
                <QrComponent
                    type={type}
                    value={guid}
                    terminalTypes={terminalTypes}
                    addrres={address}
                    merchName={merchName}
                    guid={guid}
                />
            </Modal>

            <Modal
                isOpen={openInvoiceCreater}
                centered={true}
                fullscreen="lg"
                size="lg"
                style={{width: 1020}}
                toggle={() => setOpenInvoiceCreater(!openInvoiceCreater)}
            >
                <CreateInvoice bin={bin} invoiceTypesData={invoiceTypesData}/>
            </Modal>

            <Modal
                isOpen={openCancelInvoice}
                centered={true}
                fullscreen="md"
                size="md"
                style={{width: 1020}}
                toggle={() => setOpenCancelInvoice(!openCancelInvoice)}
            >
                <div className={'create_invoice_wrapper'}>
                    <h4>Укажите причину отмены </h4>
                    <Form onSubmit={() => {
                        setOpenCancelInvoice(false)
                        cancelInvoiceHandler(id, comment)
                    }}>
                        <Row className={'create_invoice_row'}>
                            {/*<Col><Label htmlFor={'comment'}>Укажите причину отмены </Label></Col>*/}
                            <Col>
                                <Input
                                    type={'textarea'}
                                    id={'comment'}
                                    name={'comment'}
                                    value={comment}
                                    onChange={commentHandler}
                                    required={true}
                                />
                            </Col>
                        </Row>
                        <div className={'invoice_cancel_wrapper'}>
                            <Button color={'primary'} className="invoice_cancel_btn" type={'submit'}>Да</Button>
                            <Button className="invoice_cancel_btn" onClick={() => {
                                setOpenCancelInvoice(false)
                            }}>Нет</Button>
                        </div>
                    </Form>
                </div>
            </Modal>

            <Modal
                isOpen={openPushNotification}
                centered={true}
                fullscreen="sm"
                size="sm"
                style={{width: 1020}}
                toggle={() => setOpenPushNotification(!openPushNotification)}
            >
                <PushNotification
                    invoiceId={invoiceId}
                    closeModal={() => setOpenPushNotification(false)}
                />
            </Modal>

            <Modal
                isOpen={openTopUpBonus}
                centered={true}
                fullscreen="lg"
                size="lg"
                style={{width: 1020}}
                toggle={() => setOpenTopUpBonus(!openTopUpBonus)}
            >
                <TopUpBonus bin={bin}/>
            </Modal>

            <Modal
                isOpen={openEditPhone}
                centered={true}
                fullscreen="lg"
                size="lg"
                style={{width: 1020}}
                toggle={() => setOpenEditPhone(!openEditPhone)}
            >
                <EditPushDetails bin={bin}/>
            </Modal>

            <Modal
                isOpen={openRefundKaspi}
                centered={true}
                fullscreen="lg"
                size="lg"
                style={{width: 1020}}
                toggle={() => setOpenRefundKaspi(!openRefundKaspi)}
            >
                <RefundKaspi id={id} transactionAmount={transactionAmount} refundedAmount={refundedAmount}/>
            </Modal>

            <Modal
                isOpen={finishRefundKaspi}
                centered={true}
                fullscreen="lg"
                size="lg"
                style={{width: 1020}}
                toggle={() => setFinishRefundKaspi(!finishRefundKaspi)}
            >
               <CompletionRefund id={id} />
            </Modal>

            <Modal
                isOpen={openChargeOff}
                centered={true}
                fullscreen="lg"
                size="lg"
                style={{width: 1020}}
                toggle={() => setOpenChargeOff(!openChargeOff)}
            >
                <RefundChargeOff id={id} transactionType={transactionType} transactionAmount={transactionAmount}/>
            </Modal>

            <Modal
                isOpen={openBonus}
                centered={true}
                fullscreen="lg"
                size="lg"
                style={{width: 1020}}
                toggle={() => setOpenBonus(!openBonus)}
            >
                <RefundBonus id={id}/>
            </Modal>

            <Modal
                isOpen={activeTerminal}
                centered={true}
                fullscreen="lg"
                size="lg"
                style={{width: 1020}}
                toggle={() => setActiveTerminal(!activeTerminal)}
            >
                <TerminalActivation terminalActivity={terminalActivity} guid={guid} />
            </Modal>

            <Modal
                isOpen={deactivateTerminal}
                centered={true}
                fullscreen="lg"
                size="lg"
                style={{width: 1020}}
                toggle={() => setDeactivateTerminal(!deactivateTerminal)}
            >
                <DeactivateModul activationId={activationId} guid={guid} handler={() => setDeactivateTerminal(!deactivateTerminal)}/>
            </Modal>
        </div>
    )
};

export default TableIcons;
