import {Routes, Route, Navigate} from "react-router-dom";
import Main from "./containers/Main/Main";
import Merchants from "./containers/Merchants/Merchants";
import MerchantNew from "./containers/Merchants/MerchantNew";
import Terminals from "./containers/Terminals/Terminals";
import TerminalNew from "./containers/Terminals/TerminalNew";
import Services from "./containers/Services/Services";
import ServiceNew from "./containers/Services/ServiceNew";
import ServiceProfiles from "./containers/ServiceProfiles/ServiceProfiles";
import ServiceProfileNew from "./containers/ServiceProfiles/ServiceProfileNew";
import Login from "./containers/Login/Login";
import MerchantEdit from "./containers/Merchants/MerchantEdit";
import ServiceEdit from "./containers/Services/ServiceEdit";
import ServiceProfileEdit from "./containers/ServiceProfiles/ServiceProfileEdit";
import TerminalEdit from "./containers/Terminals/TerminalEdit";
import Payments from "./containers/Reports/Payments";
import Invoices from "./containers/Reports/Invoices";
import Refunds from "./containers/Reports/Refunds";
import TerminalTypes from './containers/TerminalTypes/TerminalTypes';
import TerminalTypeNew from "./containers/TerminalTypes/TerminalTypeNew";
import TerminalTypeEdit from "./containers/TerminalTypes/TerminalTypeEdit";
import ControllerSetings from "./containers/ControllerSettings/ControllerSettings";
import Files from "./containers/Files/Files";
import DeviceLogs from "./containers/DeviceLogs/DeviceLogs";
import TerminalDuplicate from "./containers/Terminals/TerminalDuplicate";
import Tariffs from "./containers/Billing/Tariffs/Tariffs";
import TariffNew from "./containers/Billing/Tariffs/TariffNew";
import BillingInvoices from "./containers/Billing/Invoices/Invoices";
import BillingTransactions from './containers/Billing/Transactions/Transactions';
import BillingTerminalActivation from './containers/Billing/TeriminalActivationReport/TerminalActivationReport'
import TariffEdit from "./containers/Billing/Tariffs/TariffEdit";
import BillingNewTerminal from "./containers/Billing/Terminals/BillingNewTerminal";
import TerminalEditTabs from "./components/TerminalEditTabs/TerminalEditTabs";
import Accounts from "./containers/Billing/Accounts/Accounts";
import MassUpdate from "./containers/Files/MassUpdate";
import BillingTerminals from "./containers/Billing/Terminals/BillingTerminals";
import CreateInvoice from "./containers/Billing/Invoices/CreateInvoice";

export const Router = ({auth}) => {
    
    const RequiredAuth = ({children, auth}) => {
        return auth === true
            ? children
            : <Navigate to="/login" replace />
    };
    
    const ProtectedLogin = ({children, auth}) => {
        return auth === true
            ? <Navigate to="/" replace />
            : children
    };
    
    return (
        <div>
            <Routes>
                <Route path="/" element={<RequiredAuth auth={!!auth}><Main/></RequiredAuth>}/>

                <Route path="/login" element={<ProtectedLogin auth={!!auth}><Login/></ProtectedLogin>}/>
                {/*        */}
                <Route path="/merchants" element={<RequiredAuth auth={!!auth}><Merchants/></RequiredAuth>}/>
                <Route path="/merchants/new" element={<RequiredAuth auth={!!auth}><MerchantNew/></RequiredAuth>}/>
                <Route path="/merchants/:id" element={<RequiredAuth auth={!!auth}><MerchantEdit/></RequiredAuth>}/>
                
                <Route path="/terminals" element={<RequiredAuth auth={!!auth}><Terminals/></RequiredAuth>}/>
                <Route path="/terminals/new" element={<RequiredAuth auth={!!auth}><TerminalNew/></RequiredAuth>}/>
                <Route path="/terminals/:id" element={<RequiredAuth auth={!!auth}><TerminalEditTabs/></RequiredAuth>}/>
                <Route path="/terminals/duplicate/:id" element={<RequiredAuth auth={!!auth}><TerminalDuplicate/></RequiredAuth>}/>
                
                <Route path="/terminal_types" element={<RequiredAuth auth={!!auth}><TerminalTypes/></RequiredAuth>}/>
                <Route path="/terminal_types/new" element={<RequiredAuth auth={!!auth}><TerminalTypeNew/></RequiredAuth>}/>
                <Route path="/terminal_types/:id" element={<RequiredAuth auth={!!auth}><TerminalTypeEdit/></RequiredAuth>}/>
                
                <Route path="/services" element={<RequiredAuth auth={!!auth}><Services/></RequiredAuth>}/>
                <Route path="/services/new" element={<RequiredAuth auth={!!auth}><ServiceNew/></RequiredAuth>}/>
                <Route path="/services/:id" element={<RequiredAuth auth={!!auth}><ServiceEdit/></RequiredAuth>}/>
                
                <Route path="/service_profiles" element={<RequiredAuth auth={!!auth}><ServiceProfiles/></RequiredAuth>}/>
                <Route path="/service_profiles/new" element={<RequiredAuth auth={!!auth}><ServiceProfileNew/></RequiredAuth>}/>
                <Route path="/service_profiles/:id" element={<RequiredAuth auth={!!auth}><ServiceProfileEdit/></RequiredAuth>}/>
                
                <Route path="/payments" element={<RequiredAuth auth={!!auth}><Payments/></RequiredAuth>}/>
                <Route path="/invoices" element={<RequiredAuth auth={!!auth}><Invoices/></RequiredAuth>}/>
                <Route path="/refunds" element={<RequiredAuth auth={!!auth}><Refunds/></RequiredAuth>  }/>
                
                <Route path="/controller_settings/:guid" element={<RequiredAuth auth={!!auth}><ControllerSetings/></RequiredAuth>  }/>
                <Route path="/files" element={<RequiredAuth auth={!!auth}><Files/></RequiredAuth>  }/>
                <Route path={"/files/mass_update"} element={<RequiredAuth auth={!!auth}><MassUpdate/></RequiredAuth>}/>
                <Route path="/logs/:guid" element={<RequiredAuth auth={!!auth}><DeviceLogs/></RequiredAuth>  }/>
                
                <Route path="/billing/tariffs" element={<RequiredAuth auth={!!auth}><Tariffs/></RequiredAuth>} />
                <Route path="/billing/tariffs/new" element={<RequiredAuth auth={!!auth}><TariffNew/></RequiredAuth>} />
                {/*<Route path="/billing/tariffs/:id" element={<RequiredAuth auth={!!auth}><TariffEdit/></RequiredAuth>} />*/}

                <Route path="/billing/invoices" element={<RequiredAuth auth={!!auth}><BillingInvoices/></RequiredAuth>} />
                <Route path="/billing/invoices/create" element={<RequiredAuth auth={!!auth}><CreateInvoice/></RequiredAuth>} />

                <Route path="/billing/transactions" element={<RequiredAuth auth={!!auth}><BillingTransactions/></RequiredAuth>} />
                <Route path="/billing/terminalActivation" element={<RequiredAuth auth={!!auth}><BillingTerminalActivation/></RequiredAuth>} />
                <Route path="/billing/terminal" element={<RequiredAuth auth={!!auth}><BillingNewTerminal/></RequiredAuth>} />
                <Route path="/billing/accounts" element={<RequiredAuth auth={!!auth}><Accounts/></RequiredAuth>} />
                <Route path="/billing/terminals" element={<RequiredAuth auth={!!auth}><BillingTerminals/></RequiredAuth>} />

                <Route path="*" element={<div>Не верный роут</div>}/>
            </Routes>
        </div>
    );
};