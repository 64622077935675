import React, {useState} from "react";
import {Modal, ModalHeader} from "reactstrap";
import {Link} from "react-router-dom";
import {useLazyQuery} from "@apollo/client";
import {GET_INVOICE_PRODUCTS} from "../../GraphQL/Queries";
import Loader from "../UI/Loader/Loader";

const InvoiceTerminals = ({terminals, invoiceId}) => {
    const [showModal, setShowModal] = useState(false);
    const [getInvoiceProducts, { loading, error, data }] = useLazyQuery(GET_INVOICE_PRODUCTS, { context: { clientName: 'billing' } });
    
    const getProducts = () => {
        setShowModal(!showModal);
        getInvoiceProducts({variables: { invoiceId: invoiceId}})
    };
    
    return (
        <>
            <span onClick={getProducts} className='paymentValue'>Список</span>
            <Modal
                isOpen={showModal}
                centered
                fullscreen="xl"
                size="lg"
                toggle={() => setShowModal(!showModal)}
            >
                <ModalHeader toggle={() => setShowModal(!showModal)}>
                    Список терминалов 
                </ModalHeader>
                {loading
                    ? <Loader/>
                    : data?.invoiceProducts.map((item, i) => {
                    let terminal = terminals.find((terminal) => terminal.guid === item.guid);
                    return <div key={item.guid} style={{padding: 10}}>
                        {i + 1}. <Link to={`/terminals/${terminal.id}`} className="table_links">{terminal.guid}</Link> - БИН {item.bin}    
                    </div>
                })}
            </Modal>
            
        </>
    )
};

export default InvoiceTerminals;