import React, {useState} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {NOT_COMPLETED_REFUNDS} from "../../GraphQL/Queries";
import {useFormik} from "formik";
import {CompletionRefundSchema} from "../../schemas";
import {toast} from "react-toastify";
import './CompletionRefund.css';
import {COMPLETE_REFUND} from "../../GraphQL/Mutations";

const CompletionRefund = ({id}) => {
    const [clicked, setClicked] = useState(false);

    const {data} = useQuery(NOT_COMPLETED_REFUNDS, {
        variables: {
            transactionId: id
        }, context: {clientName: 'billing'}
    });

    const [completeRefund] = useMutation(COMPLETE_REFUND, {context: {clientName: 'billing'}});

    const formik = useFormik({
        initialValues: {
            option: ''
        },
        validationSchema: CompletionRefundSchema,
        onSubmit: (values) => {
            setClicked(true);
            toast.promise(
                completeRefund({
                    variables: {
                        refundId: values.option
                    }
                }), {
                    pending: 'В обработке',
                    success: 'Возврат завершен',
                    error: {
                        render({data}){
                            return <span>{data.message}</span>
                        }
                    }
                }
            )
                .catch(err => {
                    setClicked(false);
                })
        }
    });

    return (
        <form className="refund_completion_form"
              onSubmit={formik.handleSubmit}
        >
            <h3 className="refund_completion_title">Завершение возврата Kaspi</h3>
            <div className={'refund_completion_form_item'}>
                <label className="refund_completion_form_label" htmlFor={'sum'}>Выбрать возврат</label>
                <select
                    className="refund_completion_form_select"
                    value={formik.values.option}
                    onChange={formik.handleChange}
                    name="option"
                >
                    <option value={''}>Выберите возврат</option>
                    {data?.notCompleted.map(item => {
                        console.log(item)
                        return <option key={item.id} value={item.id}>Сумма: {item.amount} / Создан: {item.createdDate.slice(0, 10)} / Номер: {item.txn_id}</option>
                    })}
                </select>
            </div>
            <button type={'submit'} className="refund_completion_button" disabled={clicked}>Завершить</button>
        </form>
    );
};

export default CompletionRefund;