import {makeAutoObservable} from "mobx";

let date = new Date().toISOString();

class BillingFilter {
    accountsFilter = {
        bin: '',
        amountFrom: '',
        amountTo: '',
        size: 25
    };

    invoicesFilter = {
        dateFrom: `${date.slice(0, 11)}00:00`,
        dateTo: `${date.slice(0, 11)}23:59`,
        bin: '',
        status: '',
        type: '',
        size: 25
    };
    
    transactionsFilter = {
        dateFrom: `${date.slice(0, 11)}00:00`,
        dateTo: `${date.slice(0, 11)}23:59`,
        billingInvoiceId: '',
        // accountId: '',
        amountFrom: '',
        amountTo: '',
        bin: '',
        type: '',
        guid: '',
        size: 25
    };
    
    tariffsFilter = {
        productType: ''
    };
    
    terminalsFilter = {
        activatedDateFrom: '',
        activatedDateTo: '',
        active: '',
        tariffId: '',
        bin: '',
        guid: '',
        trialDateTo: '',
        trialDateFrom: '',
        size: 25
    }

    activationsFilter = {
        dateFrom: `${date.slice(0, 11)}00:00`,
        dateTo: `${date.slice(0, 11)}23:59`,
        bin: null,
        status: null,
        guid: null,
        size: 25
    }

    constructor() {
        makeAutoObservable(this);
    };

    setTerminalsFilter(name, value) {
        this.terminalsFilter = {
            ...this.terminalsFilter, 
            [name]: value
        }
    };
    
    setInvoicesFilter(name, value){
        this.invoicesFilter = {
            ...this.invoicesFilter,
            [name]: value
        }
    };

    setTransactionsFilter(name, value){
        this.transactionsFilter = {
            ...this.transactionsFilter,
            [name]: value
        }
    };

    setAccountsFilter(name, value){
        this.accountsFilter = {
            ...this.accountsFilter,
            [name]: value
        }
    };

    setActivationsFilter(name, value){
        console.log(value)
        this.activationsFilter = {
            ...this.activationsFilter,
            [name]: value ? value : null
        }
    };

    cleanTerminalsFilter = () => {
        this.terminalsFilter = {
            activatedDateFrom: '',
            activatedDateTo: '',
            active: '',
            tariffId: '',
            bin: '',
            guid: '',
            trialDateTo: '',
            trialDateFrom: '',
            size: 25
        }
    };
    
    cleanInvoicesFilter = () => {
        this.invoicesFilter = {
            dateFrom: `${date.slice(0, 11)}00:00`,
            dateTo: `${date.slice(0, 11)}23:59`,
            bin: '',
            status: '',
            type: '',
            size: 25
        }
    };
    
    cleanTransactionsFilter = () => {
        this.transactionsFilter = {
            dateFrom: `${date.slice(0, 11)}00:00`,
            dateTo: `${date.slice(0, 11)}23:59`,
            billingInvoiceId: '',
            // accountId: '',
            amountFrom: '',
            amountTo: '',
            bin: '',
            type: '',
            size: 25,
            guid: ''
        };
    }

    cleanAccountFilter = () => {
        this.accountsFilter = {
            bin: '',
            amountFrom: '',
            amountTo: '',
            size: 25
        }
    };
    
    setTariffsFilter = (name, value) => {
        this.tariffsFilter = {
            ...this.tariffsFilter,
            [name]: value
        }
    };

    cleanTariffsFilter = () => {
        this.tariffsFilter = {
            productType: ''
        }
    };

    cleanActivationsFilter = () => {
        this.activationsFilter = {
            dateFrom: `${date.slice(0, 11)}00:00`,
            dateTo: `${date.slice(0, 11)}23:59`,
            bin: null,
            status: null,
            guid: null,
            size: 25
        }
    };
}

export default new BillingFilter();