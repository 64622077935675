import React from 'react';
import {Input} from "reactstrap";

const ReportDatesFilter = ({dateFrom, dateTo, dateFromChange, dateToChange}) => {
    return (
        <div className="date_wrap">
            <Input
                className={'filter_item'}
                type="datetime-local"
                id="dateFrom"
                name="dateFrom"
                value={dateFrom}
                onChange={(e) => {
                    dateFromChange(e)
                }}
            />
            <Input
                className={'filter_item'}
                type="datetime-local"
                id="dateTo"
                name="dateTo"
                // defaultValue={dateTo ? dateTo  : `${date.slice(0, 11)}23:59`}
                value={dateTo}
                onChange={(e) => {
                    dateToChange(e)
                }}
            />
        </div>
    );
};

export default ReportDatesFilter;