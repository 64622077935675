import React, {useState} from 'react';
import {Col, Input, Label, Row} from "reactstrap";
import {useFormik} from "formik";
import {ActivateTerminal} from "../../schemas";
import {toast} from "react-toastify";
import {useMutation} from "@apollo/client";
import {ACTIVATE_PRODUCT} from "../../GraphQL/Mutations";
import "./TerminalActivty.css";

const TerminalActivation = ({terminalActivity, guid}) => {
    const [clicked, setClicked] = useState(false);

    const [activateProduct] = useMutation(ACTIVATE_PRODUCT, {context: {clientName: 'billing'}});

    const formik = useFormik({
        initialValues: {
            isActivated: terminalActivity
        },
        onSubmit: () => {
            setClicked(true);
            toast.promise(
                activateProduct({
                    variables: {
                        guid: guid
                    }
                }), {
                    pending: 'В обработке',
                    success: {
                        render(data) {
                            return <span>{data.data.data.activateProduct.message}</span>
                        }
                    }
                    // 'Терминал успешно активирован'
                    ,
                    error: {

                        render({data}) {
                            return <span>{data.message}</span>
                        }
                    }
                }
            )
                .catch(err => {
                    setClicked(false);
                })
        },
        validationSchema: ActivateTerminal,
    });

    return (
        <form className={"TerminalActivation"} onSubmit={formik.handleSubmit}>
            <h3>Активация терминала</h3>
            <Row className='row_form'>
                <Col xs={12}>
                    <Label for="activateTerminal" className="activeTerminalLabel">Активировать терминал</Label>
                    <Input type="checkbox" name="isActivated" id="activateTerminal"
                           checked={formik.values.isActivated}
                        // disabled={isBlocked}
                           onChange={(e) => formik.handleChange(e)}
                    />
                </Col>
            </Row>
            <button type={'submit'} className="transaction_refund_button" disabled={clicked}>Отправить</button>
        </form>
    );
};

export default TerminalActivation;