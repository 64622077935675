import React, { useEffect, useState } from 'react';
import {Button, Form, Input} from "reactstrap";
import {useNavigate, Link, useSearchParams} from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {useQuery} from "@apollo/client";
import {
    FILTER_TERMINALS,
    GET_MERCHANTS,
    GET_SERVICE_PROFILES,
    GET_TERMINAL_TYPES,
    GET_TERMINALS
} from "../../GraphQL/Queries";
import Loader from "../../components/UI/Loader/Loader";
import {DataTable} from "../../components/DataTable/DataTable";
import CustomAutocomplete from "../../components/CustomAutocomplete/CustomAutocomplete";
import Filter from "../../store/Filter";

const Terminals = () => {
    const navigate = useNavigate();
    const [filter, setFilter] = useState({
        page: 1,
        size: 25,
        sortBy: 'id',
        sortOrder: 'asc'
    });

    const [searchParams, setSearchParams] = useSearchParams();
    
    const [ pageLoading, setPageLoading ] = useState(false);

    const { loading, error, data, refetch } = useQuery(FILTER_TERMINALS, { variables: {
            page: 0,
            size: filter.size,
            sortBy: 'id',
            sortOrder: 'asc'
        }}
    );

    const allTerminals = useQuery(GET_TERMINALS, { variables: {
            sortBy: "id"
        }}
    );
    
    const merchants = useQuery(GET_MERCHANTS, { variables: {
            sortBy: "id",
            sortOrder: 'asc'
        }}
    );
    
    const serviceProfile = useQuery(GET_SERVICE_PROFILES, { variables: {
            sortBy: "id"
        }}
    );

    const terminalTypes = useQuery(GET_TERMINAL_TYPES, {
        variables: {
            sortBy: "id",
            sortOrder: "asc"
        }
    });

    const handlePageChange = (pageNumber) => {
        setPageLoading(true);
   
        setFilter({
            ...filter,
            page: pageNumber
        });

        refetch({
            filter: {
                name: Filter.terminalsFilter.name || null,
                guid: Filter.terminalsFilter.guid || null,
                merchantId: Filter.terminalsFilter.merchantId || null,
                servicesProfileId: Filter.terminalsFilter.servicesProfileId || null,
                paymentType: Filter.terminalsFilter.paymentType || null,
                active: Filter.terminalsFilter.active === 'Да' ? true : Filter.terminalsFilter.active === 'Нет' ? false : null,
                typeId: Filter.terminalsFilter.typeId || null, 
                useCredit: Filter.terminalsFilter.useCredit === 'Да' ? true : Filter.terminalsFilter.useCredit === 'Нет' ? false : null,
                useAMQP: Filter.terminalsFilter.useAMQP === 'Да' ? true : Filter.terminalsFilter.useAMQP === 'Нет' ? false : null
            },
            page: pageNumber - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const sortByHandler = (e) => {
        setPageLoading(true);
        
        if (filter.sortBy === e.target.id) {
            setFilter({
                ...filter,
                sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
            });
        } else {
            setFilter({
                ...filter,
                sortBy: e.target.id
            });
        }

        refetch({
            filter: {
                name: Filter.terminalsFilter.name || null,
                guid: Filter.terminalsFilter.guid || null,
                merchantId: Filter.terminalsFilter.merchantId || null,
                servicesProfileId: Filter.terminalsFilter.servicesProfileId || null,
                paymentType: Filter.terminalsFilter.paymentType || null,
                active: Filter.terminalsFilter.active === 'Да' ? true : Filter.terminalsFilter.active === 'Нет' ? false : null,
                typeId: Filter.terminalsFilter.typeId || null,
                useCredit: Filter.terminalsFilter.useCredit === 'Да' ? true : Filter.terminalsFilter.useCredit === 'Нет' ? false : null,
                useAMQP: Filter.terminalsFilter.useAMQP === 'Да' ? true : Filter.terminalsFilter.useAMQP === 'Нет' ? false : null
            },
            page: filter.page - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const pageSizeHandler = (e) => {
        setPageLoading(true);
        
        setFilter({
            ...filter,
            size: parseInt(e.target.value),
            page: 1
        });

        Filter.setTerminalsFilter('size', parseInt(e.target.value));

        setSearchParams({...Filter.terminalsFilter, size: parseInt(e.target.value)});

        refetch({
            filter: {
                name: Filter.terminalsFilter.name || null,
                guid: Filter.terminalsFilter.guid || null,
                merchantId: Filter.terminalsFilter.merchantId || null,
                servicesProfileId: Filter.terminalsFilter.servicesProfileId || null,
                paymentType: Filter.terminalsFilter.paymentType || null,
                active: Filter.terminalsFilter.active === 'Да' ? true : Filter.terminalsFilter.active === 'Нет' ? false : null,
                typeId: Filter.terminalsFilter.typeId || null,
                useCredit: Filter.terminalsFilter.useCredit === 'Да' ? true : Filter.terminalsFilter.useCredit === 'Нет' ? false : null,
                useAMQP: Filter.terminalsFilter.useAMQP === 'Да' ? true : Filter.terminalsFilter.useAMQP === 'Нет' ? false : null
            },
            page: 0,
            size: parseInt(e.target.value),
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const [terminals, setTerminals] = useState([]);
    const [totalItems, setTotalItems] = useState();

    useEffect(() => {
        if (data) {
            setTerminals(data.terminalsPage?.data)
            setTotalItems(data.terminalsPage?.total)
        }
    }, [data]);

    let name = searchParams.get("name");
    let guid = searchParams.get("guid");
    let merchantId = searchParams.get("merchantId");
    let servicesProfileId = searchParams.get("servicesProfileId");
    let paymentType = searchParams.get("paymentType");
    let active = searchParams.get("active");
    let typeId = searchParams.get("typeId");
    let useCredit = searchParams.get("useCredit");
    let useAMQP = searchParams.get("useAMQP");
    let size = searchParams.get("size");


    useEffect(() => {
        
        if (name || guid || merchantId || servicesProfileId || paymentType || active || typeId || useCredit || useAMQP || size){
            if (name) Filter.setTerminalsFilter('name', name);
            if (guid) Filter.setTerminalsFilter('guid', guid);
            if (merchantId) Filter.setTerminalsFilter('merchantId', merchantId);
            if (servicesProfileId) Filter.setTerminalsFilter('servicesProfileId', servicesProfileId);
            if (paymentType) Filter.setTerminalsFilter('paymentType', paymentType);
            if (active) Filter.setTerminalsFilter('active', active);
            if (typeId) Filter.setTerminalsFilter('typeId', typeId);
            if (useCredit) Filter.setTerminalsFilter('useCredit', useCredit);
            if (useAMQP) Filter.setTerminalsFilter('useAMQP', useAMQP);
            if (size) {
                Filter.setTerminalsFilter('size', size);
                setFilter({...filter, size});
            }

            refetch({
                filter: {
                    name: name ? name : null,
                    guid: guid ? guid : null,
                    merchantId: merchantId ? merchantId : null,
                    servicesProfileId: servicesProfileId ? servicesProfileId : null,
                    paymentType: paymentType ? paymentType : null,
                    active: active ? active === 'Да' : null,
                    typeId: typeId ? typeId : null,
                    useCredit: useCredit ? useCredit === 'Да': null,
                    useAMQP: useAMQP ? useAMQP === 'Да': null,
                },
                page: 0,
                size: parseInt(filter.size),
                sortBy: filter.sortBy,
                sortOrder: filter.sortOrder
            })
        } else {
            Filter.setTerminalsFilter('size', 25);
            Filter.setTerminalsFilter('name', '');
            Filter.setTerminalsFilter('guid', '');
            Filter.setTerminalsFilter('merchantId', '');
            Filter.setTerminalsFilter('servicesProfileId', '');
            Filter.setTerminalsFilter('paymentType', '');
            Filter.setTerminalsFilter('active', '');
            Filter.setTerminalsFilter('typeId', '');
            Filter.setTerminalsFilter('useCredit', '');
            Filter.setTerminalsFilter('useAMQP', '')
        }
    }, [filter.size, Filter.terminalsFilter.size]);
    
    const columns = [
        { title: '#', id: '#' },
        { title: 'Id', id: 'id' },
        { title: 'Guid', id: 'guid' },
        { title: 'Дата создания', id: 'creationDate'},
        { title: 'Дата изменения', id: 'changeDate'},
        { title: 'Наим-е', id: 'name'},
        { title: 'Тип', id: 'typeId' },
        { title: 'Адрес', id: 'address' },
        { title: 'Шаг', id: 'step' },
        { title: 'Мерчант', id: 'merchantId' },
        { title: 'Профиль услуги', id: 'servicesProfileId' },
        { title: 'Тип оплаты', id: 'paymentType' },
        { title: 'Актив', id: 'active' },
        { title: 'Номер сим-карты', id: 'modemPhoneNumber' },
        { title: 'IMEI', id: 'imei'},
        { title: 'Исп. кредит', id: 'useCredit' },
        { title: 'Сумма кредита', id: 'creditAmount' },
        { title: 'Новый протокол', id: 'useAMQP'},
        { title: 'Действия', id: 'actions'},
    ];

    const rowsItem = [
        { title: '#' },
        { title: 'id' },
        { title: 'guid' },
        { title: 'creationDate' },
        { title: 'changeDate' },
        { title: 'name' },
        { title: 'typeId' },
        { title: 'address'},
        { title: 'step' },
        { title: 'merchantId' },
        { title: 'servicesProfileId' },
        { title: 'paymentType' },
        { title: 'active' },
        { title: 'modemPhoneNumber' },
        { title: 'imei' },
        { title: 'useCredit' },
        { title: 'creditAmount' },
        { title: 'useAMQP' }
    ];

    if (loading || allTerminals.loading || merchants.loading || serviceProfile.loading || terminalTypes.loading) return <Loader/>
    
    return (
        <div>
            <div className="breadcrumb_wrapper">
                <Breadcrumb>
                    <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Терминалы</BreadcrumbItem>
                </Breadcrumb>
                <div>
                    <Button 
                        color="success"
                        className="add_btn"
                        onClick={() => navigate('/files')}
                        style={{marginRight: 15}}
                    >
                        Файлы
                    </Button>
                    <Button 
                        color="dark"
                        className="add_btn"
                        onClick={() => navigate('/terminals/new')}
                    >
                        + Добавить 
                    </Button>
                </div>
            </div>
            <hr/>
            <Form onSubmit={(e) => {
                e.preventDefault();
                setPageLoading(true);
                setFilter({
                    ...filter,
                    page: 1
                })
                    refetch({
                        filter: {
                            name: Filter.terminalsFilter.name || null,
                            guid: Filter.terminalsFilter.guid || null,
                            merchantId: Filter.terminalsFilter.merchantId || null,
                            servicesProfileId: Filter.terminalsFilter.servicesProfileId || null,
                            paymentType: Filter.terminalsFilter.paymentType || null,
                            active: Filter.terminalsFilter.active === 'Да' ? true : Filter.terminalsFilter.active === 'Нет' ? false : null,
                            typeId: Filter.terminalsFilter.typeId || null,
                            useCredit: Filter.terminalsFilter.useCredit === 'Да' ? true : Filter.terminalsFilter.useCredit === 'Нет' ? false : null,
                            useAMQP: Filter.terminalsFilter.useAMQP === 'Да' ? true : Filter.terminalsFilter.useAMQP === 'Нет' ? false : null
                        },
                        page: 0,
                        size: filter.size,
                        sortBy: filter.sortBy
                    })
                        .then(() => {
                            setPageLoading(false);
                        })
            }}>
                <div className={'filter_wrapper'}>
                    <div className={'filter_item'}>
                        <CustomAutocomplete
                            list={allTerminals.data ? allTerminals.data.terminals : []}
                            name={'terminal'}
                            setValue={(name, item) => {
                                if (item){
                                    setSearchParams({ 
                                        ...Filter.terminalsFilter, 
                                        guid: Filter.terminalsFilter.guid || '',
                                        merchantId: Filter.terminalsFilter.merchantId || '',
                                        servicesProfileId: Filter.terminalsFilter.servicesProfileId || '',
                                        typeId: Filter.terminalsFilter.typeId || '',
                                        name: item.name
                                    })
                                    Filter.terminalsFilter.name = item.name
                                } else {
                                    setSearchParams({ 
                                        ...Filter.terminalsFilter, 
                                        guid: Filter.terminalsFilter.guid || '',
                                        merchantId: Filter.terminalsFilter.merchantId || '',
                                        servicesProfileId: Filter.terminalsFilter.servicesProfileId || '',
                                        typeId: Filter.terminalsFilter.typeId || '',
                                        name: ''
                                    })
                                    Filter.terminalsFilter.name = null
                                }
                            }}
                            placeholder="Наименование терминала"
                            searchBy={["name"]}
                            resultStringKeyName='name'
                            getValueBy={'name'}
                            edit={true}
                            value={Filter.terminalsFilter.name ? Filter.terminalsFilter.name : null}
                        />
                    </div>
                    <div className={'filter_item'}>
                        <CustomAutocomplete
                            list={allTerminals.data ? allTerminals.data.terminals : []}
                            name={'guid'}
                            setValue={(name, item) => {
                                if (item){
                                    setSearchParams({ 
                                        ...Filter.terminalsFilter, 
                                        name: Filter.terminalsFilter.name || '',
                                        merchantId: Filter.terminalsFilter.merchantId || '',
                                        servicesProfileId: Filter.terminalsFilter.servicesProfileId || '',
                                        typeId: Filter.terminalsFilter.typeId || '',
                                        guid: item.guid
                                    })
                                    Filter.terminalsFilter.guid = item.guid
                                } else {
                                    setSearchParams({ 
                                        ...Filter.terminalsFilter, 
                                        name: Filter.terminalsFilter.name || '',
                                        merchantId: Filter.terminalsFilter.merchantId || '',
                                        servicesProfileId: Filter.terminalsFilter.servicesProfileId || '',
                                        typeId: Filter.terminalsFilter.typeId || '',
                                        guid: ''
                                    })
                                    Filter.terminalsFilter.guid = null
                                }
                            }}
                            placeholder="GUID терминала"
                            searchBy={["guid"]}
                            resultStringKeyName='guid'
                            getValueBy={'guid'}
                            edit={true}
                            value={Filter.terminalsFilter.guid ? Filter.terminalsFilter.guid : null}
                        />
                    </div>
                    <div className={'filter_item'}>
                        <CustomAutocomplete
                            list={terminalTypes.data ? terminalTypes.data.terminalTypes : []}
                            placeholder="Тип терминала"
                            name="typeId"
                            id="typeId"
                            setValue={(name, item) => {
                                if (item){
                                    setSearchParams({
                                        ...Filter.terminalsFilter,
                                        name: Filter.terminalsFilter.name || '',
                                        guid: Filter.terminalsFilter.guid || '',
                                        servicesProfileId: Filter.terminalsFilter.servicesProfileId || '',
                                        merchantId: Filter.terminalsFilter.merchantId || '',
                                        typeId: item.id
                                    })
                                    Filter.terminalsFilter.typeId = item.id
                                } else {
                                    setSearchParams({
                                        ...Filter.terminalsFilter,
                                        name: Filter.terminalsFilter.name || '',
                                        guid: Filter.terminalsFilter.guid || '',
                                        servicesProfileId: Filter.terminalsFilter.servicesProfileId || '',
                                        merchantId: Filter.terminalsFilter.merchantId || '',
                                        typeId: ''
                                    })
                                    Filter.terminalsFilter.typeId = null
                                }
                            }}
                            searchBy={['name']}
                            resultStringKeyName='name'
                            getValueBy={'id'}
                            edit={true}
                            value={Filter.terminalsFilter.typeId ? Filter.terminalsFilter.typeId : null}
                        />
                    </div>
                    <div className={'filter_item'}>
                        <CustomAutocomplete
                            list={merchants.data?.merchants}
                            name={'merchantId'}
                            setValue={(name, item) => {
                                if (item){
                                    setSearchParams({ 
                                        ...Filter.terminalsFilter, 
                                        name: Filter.terminalsFilter.name || '',
                                        guid: Filter.terminalsFilter.guid || '',
                                        servicesProfileId: Filter.terminalsFilter.servicesProfileId || '',
                                        typeId: Filter.terminalsFilter.typeId || '',
                                        merchantId: item.id
                                    })
                                    Filter.terminalsFilter.merchantId = item.id
                                } else {
                                    setSearchParams({ 
                                        ...Filter.terminalsFilter, 
                                        name: Filter.terminalsFilter.name || '',
                                        guid: Filter.terminalsFilter.guid || '',
                                        servicesProfileId: Filter.terminalsFilter.servicesProfileId || '',
                                        typeId: Filter.terminalsFilter.typeId || '',
                                        merchantId: ''
                                    })
                                    Filter.terminalsFilter.merchantId = null
                                }
                            }}
                            placeholder="Наименование / Бин мерчанта"
                            searchBy={["name", "bin"]}
                            resultStringKeyName='name'
                            getValueBy={'id'}
                            edit={true}
                            value={Filter.terminalsFilter.merchantId || null}
                        />
                    </div>
                    <div className={'filter_item'}>
                        <CustomAutocomplete
                            list={serviceProfile.data ? serviceProfile.data.servicesProfiles : []}
                            name={'servicesProfileId'}
                            setValue={(name, item) => {
                                if (item){
                                    setSearchParams({ 
                                        ...Filter.terminalsFilter, 
                                        name: Filter.terminalsFilter.name || '',
                                        guid: Filter.terminalsFilter.guid || '',
                                        merchantId: Filter.terminalsFilter.merchantId || '',
                                        typeId: Filter.terminalsFilter.typeId || '',
                                        servicesProfileId: item.id
                                    })
                                    Filter.terminalsFilter.servicesProfileId = item.id
                                } else {
                                    setSearchParams({ 
                                        ...Filter.terminalsFilter, 
                                        name: Filter.terminalsFilter.name || '',
                                        guid: Filter.terminalsFilter.guid || '',
                                        merchantId: Filter.terminalsFilter.merchantId || '',
                                        typeId: Filter.terminalsFilter.typeId || '',
                                        servicesProfileId: ''
                                    })
                                    Filter.terminalsFilter.servicesProfileId = null
                                }
                            }}
                            placeholder="Наименование профиля улсуг"
                            searchBy={["name"]}
                            resultStringKeyName='name'
                            getValueBy={'id'}
                            edit={true}
                            value={Filter.terminalsFilter.servicesProfileId || null}
                        />
                    </div>
                    <Input
                        className={'filter_item'}
                        type='select'
                        id="paymentType"
                        value={Filter.terminalsFilter.paymentType || ''}
                        name='paymentType'
                        onChange={(e) => {
                            setSearchParams({ 
                                ...Filter.terminalsFilter, 
                                name: Filter.terminalsFilter.name || '',
                                guid: Filter.terminalsFilter.guid || '',
                                merchantId: Filter.terminalsFilter.merchantId || '',
                                servicesProfileId: Filter.terminalsFilter.servicesProfileId || '',
                                typeId: Filter.terminalsFilter.typeId || '',
                                paymentType: e.target.value
                            })
                            Filter.terminalsFilter.paymentType = e.target.value
                        }}
                        style={{color: '#747678'}}
                    >
                        <option value={''}>Выберите тип оплаты</option>
                        <option>INVOICE</option>
                        <option>SERVICE</option>
                    </Input>
                    <Input
                        className={'filter_item'}
                        type='select'
                        id="active"
                        value={Filter.terminalsFilter.active || ''}
                        name='active'
                        onChange={(e) => {
                            setSearchParams({ 
                                ...Filter.terminalsFilter, 
                                name: Filter.terminalsFilter.name || '',
                                guid: Filter.terminalsFilter.guid || '',
                                merchantId: Filter.terminalsFilter.merchantId || '',
                                servicesProfileId: Filter.terminalsFilter.servicesProfileId || '',
                                typeId: Filter.terminalsFilter.typeId || '',
                                active: e.target.value
                            })
                            Filter.terminalsFilter.active = e.target.value
                        }}
                        style={{color: '#747678'}}
                    >
                        <option value={''}>Активный</option>
                        <option>Да</option>
                        <option>Нет</option>
                    </Input>
                    <Input
                        className={'filter_item'}
                        type='select'
                        id="useCredit"
                        value={Filter.terminalsFilter.useCredit || ''}
                        name='useCredit'
                        onChange={(e) => {
                            setSearchParams({
                                ...Filter.terminalsFilter,
                                name: Filter.terminalsFilter.name || '',
                                guid: Filter.terminalsFilter.guid || '',
                                merchantId: Filter.terminalsFilter.merchantId || '',
                                servicesProfileId: Filter.terminalsFilter.servicesProfileId || '',
                                typeId: Filter.terminalsFilter.typeId || '',
                                useCredit: e.target.value
                            })
                            Filter.terminalsFilter.useCredit = e.target.value
                        }}
                        style={{color: '#747678'}}
                    >
                        <option value={''}>Исп. кредит</option>
                        <option>Да</option>
                        <option>Нет</option>
                    </Input>
                    <Input
                        className={'filter_item'}
                        type='select'
                        id="useAMQP"
                        value={Filter.terminalsFilter.useAMQP || ''}
                        name='useAMQP'
                        onChange={(e) => {
                            setSearchParams({
                                ...Filter.terminalsFilter,
                                name: Filter.terminalsFilter.name || '',
                                guid: Filter.terminalsFilter.guid || '',
                                merchantId: Filter.terminalsFilter.merchantId || '',
                                servicesProfileId: Filter.terminalsFilter.servicesProfileId || '',
                                typeId: Filter.terminalsFilter.typeId || '',
                                useAMQP: e.target.value
                            })
                            Filter.terminalsFilter.useAMQP = e.target.value
                        }}
                        style={{color: '#747678'}}
                    >
                        <option value={''}>Новый протокол</option>
                        <option>Да</option>
                        <option>Нет</option>
                    </Input>
                    <div className={'buttons_wrapper'}>
                        <Button color={'primary'} type={'submit'} className="filter_button" style={{width: 100, marginRight: 20}} >Поиск</Button>
                        <Button color={'danger'} style={{width: 100}} className="filter_button" onClick={() => {
                            setPageLoading(true);
                            setSearchParams('');
                            Filter.cleanTerminalsFilter();
                            setFilter({
                                ...filter,
                                size: 25
                            })
                            refetch({
                                filter: {
                                    name: null,
                                    guid: null,
                                    merchantId: null,
                                    servicesProfileId: null,
                                    paymentType:  null,
                                    active: null,
                                    typeId: null,
                                    useCredit: null,
                                    useAMQP: null
                                },
                                page: filter.page - 1,
                                size: filter.size,
                                sortBy: filter.sortBy
                            })
                                .then(() => {
                                    setPageLoading(false);
                                })
                        }}>Сбросить</Button>
                    </div>
                </div>
            </Form>
            <hr/>
            <DataTable
                hideColumns={['#', 'canceledAmount', 'address', 'modemPhoneNumber', 'imei', 'changeDate', 'merchantId', 'typeId', 'servicesProfileId', 'active', 'step', 'paymentType', 'useCredit', 'creditAmount', 'useAMQP']}
                columns={columns}
                rows={terminals}
                rowsItem={rowsItem}
                merchants={merchants.data ? merchants.data.merchants : []}
                serviceProfiles={serviceProfile.data ? serviceProfile.data.servicesProfiles : []}
                terminalTypes={terminalTypes.data ? terminalTypes.data.terminalTypes : []}
                link={'terminals'}
                sortBy={filter.sortBy}
                sortByHandler={(e) => sortByHandler(e)}
                itemsCountPerPage={filter.size}
                sortOrder={filter.sortOrder}
                pageSizeHandler={(e) => pageSizeHandler(e)}
                totalItemsCount={totalItems}
                activePage={filter.page}
                showQr={true}
                showDuplicate={true}
                showSettings={true}
                handlePageChange={(pageNumber) => handlePageChange(pageNumber)}
                isPaginated={true}
            />
            {pageLoading
                ? <Loader/>
                : null
            }
        </div>
    );
};

export default Terminals;