import React, { useEffect, useState } from 'react';
import Loader from '../../components/UI/Loader/Loader';
import {DataTable} from "../../components/DataTable/DataTable";
import { Breadcrumb, BreadcrumbItem, Button, Modal, ModalBody, ModalHeader, Label, Input, FormGroup } from 'reactstrap';
import {Link, useNavigate} from "react-router-dom";
import ControllerSettingsStore from '../../store/ControllerSettingsStore';

const Files = () => {
    const navigate = useNavigate();
    const [file, setFile] = useState({
        file: '',
        description: ''
    });
    const [clicked, setClicked] = useState(false);
    const [files, setFiles] = useState([]);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [error, setError] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const getFiles = () => {
        setLoadingFiles(true);
        ControllerSettingsStore.getFiles()
            .then(data => {
                setFiles(data);
                setLoadingFiles(false);
            })
            .catch(err => {
                setError(true);
                setLoadingFiles(false);
            })
    };

    useEffect(() => {
        getFiles();
    }, []);

    const changeFileHandler = e => {
        console.log(e.target.files);
        setFile({
            ...file,
            file: e.target.files[0]
        });
    };

    const changeInputHandler = (e) => {
        setFile({
            ...file,
            [e.target.name]: e.target.value
        })
    }

    const uploadFile = (e) => {
        setClicked(true);
        e.preventDefault();
        ControllerSettingsStore.uploadFile(file.file, file.description)
            .then(data => {
                setShowModal(false);
                getFiles();
            })
            .catch(err => {
                setClicked(false);
            })
    };

    if (error) return <div>Лена, тебе сюда нельзя</div>
    
    if (loadingFiles) return <Loader/>

    const columns = [
        { title: '#', id: '#' },
        { title: 'Id', id: 'id' },
        { title: 'Дата создания', id: 'datecreate'},
        { title: 'Описание', id: 'description'},
        { title: 'Файл', id: 'file'},
        { title: 'Fw', id: 'fw'},
        { title: 'Действия', id: 'actions'}
    ];

    const rowsItem = [
        { title: '#' },
        { title: 'id' },
        { title: 'datecreate' },
        { title: 'description' },
        { title: 'file' },
        { title: 'fw'}
    ];

    return (
        <div>
            <div className="breadcrumb_wrapper">
                <Breadcrumb>
                    <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to='/terminals' className="breadcrumb_link">Терминалы</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Файлы для обновлении</BreadcrumbItem>
                </Breadcrumb>
                <div>
                    <Button
                        color="success"
                        className="add_btn"
                        onClick={() => navigate('/files/mass_update')}
                        style={{marginRight: 15}}
                    >
                        Обновить 
                    </Button>
                    <Button
                        color="dark"
                        className="add_btn"
                        onClick={() => setShowModal(true)}
                    >
                        + Добавить
                    </Button>
                </div>
                
            </div>
            <DataTable
                deleteAction={true}
                deleteHandler={(items) => {
                    console.log(items);
                    ControllerSettingsStore.deleteFiles(items)
                        .then(response => {
                            getFiles()
                        })
                }}
                hideColumns={['file']}
                columns={columns}
                rows={files}
                rowsItem={rowsItem}
                link={''}
                isPaginated={false}
                sortByHandler={(e) => console.log(e)}
                hideIcons={true}
                showCheckBoxes={true}
            />
            <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
                <ModalHeader toggle={() => setShowModal(false)}>Добавление файла</ModalHeader>
                <ModalBody>
                    <Input
                        id='file'
                        type='file'
                        style={{margin: '20px 0'}}
                        onChange={changeFileHandler}
                    />
                    <FormGroup>
                        <Label for="description">Описание</Label>
                        <Input type="textarea" name="description" id="description" onChange={changeInputHandler}/>
                    </FormGroup>
                    <Button color='primary' className="add_btn mr" onClick={uploadFile} disabled={clicked}>Загрузить</Button>
                </ModalBody>
            </Modal>
        </div>
    )
};

export default Files;