import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {GET_PRODUCT, GET_TARIFFS} from "../../../GraphQL/Queries";
import Loader from "../../../components/UI/Loader/Loader";
import {useFormik} from "formik";
import {CreateProductSchema} from "../../../schemas";
import {toast} from "react-toastify";
import {CREATE_PRODUCT} from "../../../GraphQL/Mutations";
import {Button, Col, Form, FormFeedback, Input, Label, Row} from "reactstrap";
import CustomAutocomplete from "../../../components/CustomAutocomplete/CustomAutocomplete";
import Filter from "../../../store/Filter";

const BillingEditTerminal = ({merchants}) => {
    const [clicked, setClicked] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    let guid = searchParams.get("guid");
    let bin = searchParams.get("bin");

    
    const { loading, error, data } = useQuery(GET_PRODUCT, { variables: { guid }, context:  { clientName: 'billing' }});
    
    const tariffs = useQuery(GET_TARIFFS, { context: { clientName: 'billing' }});
    
    const [product, setProduct] = useState({
        guid: guid,
        createdDate: '',
        activatedDate: '',
        tariffId: '',
        bin: bin,
        trialDateTo: '',
        trialDateComment: '',
        active: null,
        description: ''
    });
    
    useEffect(() => {
        if (data && data.productByGuid) {
            if (data.productByGuid) {
                console.log(data.productByGuid)
                setProduct({
                    ...product,
                    guid: data.productByGuid.guid,
                    createdDate: data.productByGuid.createdDate,
                    activatedDate: data.productByGuid.activatedDate,
                    tariffId: data.productByGuid.tariffId,
                    bin: data.productByGuid.bin,
                    trialDateTo: data.productByGuid.trialDateTo ? (data.productByGuid.trialDateTo).slice(0, 10) : '',
                    trialDateComment: data.productByGuid.trialDateComment,
                    active: data.productByGuid.active,
                    description: data.productByGuid.description
                })
            } else {
                console.log('null')
                setProduct({
                    ...product,
                    guid: guid,
                    createdDate: null,
                    activatedDate: null,
                    tariffId: '',
                    bin: bin,
                    trialDateTo: '',
                    trialDateComment: '',
                    active: null,
                    description: null
                })
            }
        } 
    }, [data]);

    const [createProduct] = useMutation(CREATE_PRODUCT, {
        context: { clientname: 'billing'},
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_PRODUCT, variables: { guid }, context:  { clientName: 'billing' }}]
    });
    
    const formik = useFormik({
        initialValues: product,
        enableReinitialize: true,
        validationSchema: CreateProductSchema,
        onSubmit: (values) => {
            console.log('submit')
            setClicked(true);

            let variables = {
                productDTO: {
                    guid: guid,
                    bin: bin,
                    tariffId: parseInt(values.tariffId),
                    notify: values.notify,
                    phone: values.phone,
                    trialDateTo: new Date(values.trialDateTo),
                    trialDateComment: values.trialDateComment
                }
                
            }
            
            toast.promise(
                createProduct({variables: variables, context: { clientName: 'billing' }}), {
                    pending: 'В обработке',
                    success: 'Терминал заведен в системе Биллинг',
                    error: 'Ошибка'
                }
            )
                .then(result => {
                    navigate(`/terminals?name=${Filter.terminalsFilter.name}&guid=${Filter.terminalsFilter.guid}&merchantId=${Filter.terminalsFilter.merchantId}&servicesProfileId=${Filter.terminalsFilter.servicesProfileId}&paymentType=${Filter.terminalsFilter.paymentType}&active=${Filter.terminalsFilter.active}&typeId=${Filter.terminalsFilter.typeId}&useCredit=${Filter.terminalsFilter.useCredit}`);
                })
                .catch(err => {
                    setClicked(false);
                })
        }
    });

    const autocompleteChangeHandler = (name, id) => {
        console.log(name);
        console.log(id);
        formik.values[name] = id;
    };

    const navigate = useNavigate();
    
    const getFormatedDate = (unformatedDate) => {
        if (unformatedDate === null) return 'N/A'
        let date = new Date(unformatedDate);
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, 0);
        let day = (date.getDate()).toString().padStart(2, 0);
        let hours = (date.getHours()).toString().padStart(2, 0);
        let minutes = (date.getMinutes()).toString().padStart(2, 0);
        let seconds = (date.getSeconds()).toString().padStart(2, 0);
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    }
    
    const getMerchantInfo = (bin) => {
        if (bin) {
            let merchant = merchants.find(item => (item.bin).toString() === bin);
            return `${merchant.name} (${merchant.bin})`
        }
    }
    
    if (loading || tariffs.loading) return <Loader/>
    
    console.log(formik)
    return (
        <div>
            <Form onSubmit={formik.handleSubmit}>
                <Row className='row_form'>
                    <Col xs={4}><Label for="guid">GUID</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="guid"
                            id="guid"
                            onChange={formik.handleChange}
                            disabled
                            value={guid}
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="guid">Дата создания</Label></Col> 
                    <Col xs={8}>
                        <Input
                            type="text"
                            onChange={formik.handleChange}
                            disabled
                            value={formik.values.createdDate ? getFormatedDate(formik.values.createdDate) : 'N/A'}
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="guid">Дата активации</Label></Col> 
                    <Col xs={8}>
                        <Input
                            type="text"
                            onChange={formik.handleChange}
                            disabled
                            value={formik.values.activatedDate ? getFormatedDate(formik.values.activatedDate) : 'N/A'}
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="trialDateTo">Дата тестового периода</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="date"
                            name="trialDateTo"
                            id="trialDateTo"
                            onChange={formik.handleChange}
                            value={formik.values.trialDateTo}
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="trialDateComment">Причина тестового периода</Label></Col>
                    <Col xs={8}>
                        <Input
                            type='textarea'
                            id={'trialDateComment'}
                            disabled={!formik.values.trialDateTo}
                            name={"trialDateComment"}
                            onChange={formik.handleChange}
                            value={formik.values.trialDateComment}
                            invalid={formik.touched.trialDateComment && Boolean(formik.errors.trialDateComment)}
                        />
                        <FormFeedback>
                            {formik.touched.trialDateComment && formik.errors.trialDateComment}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="bin">Мерчант</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            onChange={formik.handleChange}
                            disabled
                            value={getMerchantInfo(bin)}
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="tariffId">Тариф</Label></Col>
                    <Col xs={8}>
                        <CustomAutocomplete
                            list={tariffs.data?.tariffsByProduct}
                            placeholder="Выберите тариф"
                            name="tariffId"
                            id="tariffId"
                            setValue={(name, item) => autocompleteChangeHandler(name, item.id)}
                            searchBy={['name']}
                            getValueBy={'id'}
                            resultStringKeyName='name'
                            invalid={formik.touched.tariffId && Boolean(formik.errors.tariffId)}
                            formFeedback={formik.touched.tariffId && formik.errors.tariffId}
                            // disabled={isBlocked}
                            edit={true}
                            value={formik.values.tariffId || null}
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="bin">Бин</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            onChange={formik.handleChange}
                            disabled
                            value={bin}
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="bin">Активный</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            onChange={formik.handleChange}
                            disabled
                            value={formik.values.active === null ? 'N/A' : formik.values.active ? 'Да' : 'Нет'}
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="bin">Комментарий неактивности</Label></Col>
                    <Col xs={8}>
                        <Input
                            type='textarea'
                            onChange={formik.handleChange}
                            disabled
                            value={formik.values.description}
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={8}>
                        <Button type="submit" color="primary" className="crud_button mr" disabled={clicked}>
                            Сохранить
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default BillingEditTerminal;