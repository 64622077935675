import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import TerminalEdit from "../../containers/Terminals/TerminalEdit";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {Link} from "react-router-dom";
import './TerminalEditTabs.css';
import BillingEditTerminal from "../../containers/Billing/Terminals/BillingEditTerminal";
import {useQuery} from "@apollo/client";
import {GET_MERCHANTS} from "../../GraphQL/Queries";
import Loader from "../UI/Loader/Loader";

const TerminalEditTabs = () => {
    const merchants = useQuery(GET_MERCHANTS, { variables: {
            sortBy: "id",
            sortOrder: 'asc'
        }}
    );

    if (merchants.loading) return <Loader/>
    return (
        <div>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to='/terminals' className="breadcrumb_link">Терминалы</Link></BreadcrumbItem>
                <BreadcrumbItem active>Редактирование</BreadcrumbItem>
            </Breadcrumb>

            <h4 className="main_title page_title">Редактирование терминала</h4>

            <Tabs>
                <TabList>
                    <Tab selectedClassName={"selected_tab_list"}><p>Каспи админ</p></Tab>
                    <Tab selectedClassName={"selected_tab_list"}><p>Биллинг</p></Tab>
                </TabList>

                <TabPanel>
                    <TerminalEdit merchants={merchants.data?.merchants}/>
                </TabPanel>
                <TabPanel>
                    <BillingEditTerminal merchants={merchants.data?.merchants}/>
                </TabPanel>
            </Tabs>
        </div>
        
    );
};

export default TerminalEditTabs;