import React from 'react';
import Toolbar from '../components/UI/Toolbar/Toolbar';
import { Router } from '../routes';
import { ToastContainer } from 'react-toastify';
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
import {observer} from "mobx-react-lite";
import User from "./../store/User";

const App = observer(() => {
  return (
      <div>
        <Toolbar
            username={User.user.username}
            logout={() => User.logout()}
        />
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            theme="dark"
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{width: 400}}
        />
        <div className={'custom_container'}>
          <Router auth={User.user.signInUserSession ? !!User.user.signInUserSession.idToken.jwtToken : null}/>
        </div>
      </div>
  );
});

export default App;