import React, {useEffect, useState} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {GET_TARIFFS} from "../../../GraphQL/Queries";
import {Col, FormFeedback, Input, Label, Row, Form, Button} from "reactstrap";
import {useNavigate, useSearchParams} from "react-router-dom";
import CustomAutocomplete from "../../../components/CustomAutocomplete/CustomAutocomplete";
import {useFormik} from "formik";
import {CreateProductSchema} from "../../../schemas";
import {toast} from "react-toastify";
import Loader from "../../../components/UI/Loader/Loader";
import {CREATE_PRODUCT} from "../../../GraphQL/Mutations";

const BillingNewTerminal = () => {
    const [clicked, setClicked] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    
    const { loading, error, data, refetch } = useQuery(GET_TARIFFS, { context: { clientName: 'billing' }});
    const [product, setProduct] = useState({});
    
    const autocompleteChangeHandler = (name, id) => {
        console.log(name);
        console.log(id)
        // setProduct({
        //     ...product, [name]: id
        // });
        formik.values[name] = id;
    };
    
    useEffect(() => {
        if (data) {
            setProduct({
                ...product,
                guid: searchParams.get('guid'),
                bin: searchParams.get('bin'),
                tariffId: null,
                trialDateTo: '',
                trialDateComment: '',
            });
        }
    }, [data]);
    
    const formik = useFormik({
        initialValues: product,
        enableReinitialize: true,
        validationSchema: CreateProductSchema,
        onSubmit: (values) => {
            console.log('submit')
            setClicked(true);

            let variables = {
                productDTO: {
                    guid: values.guid,
                    bin: values.bin,
                    tariffId: parseInt(values.tariffId),
                    trialDateTo: new Date(values.trialDateTo),
                    trialDateComment: values.trialDateComment
                }    
            }
            
            toast.promise(
                createProduct({variables: variables, context: { clientName: 'billing' }}), {
                    pending: 'В обработке',
                    success: 'Терминал заведен в системе Биллинг',
                    error: 'Ошибка'
                }
            )
                .then(result => {
                    navigate('/terminals')
                })
                .catch(err => {
                    setClicked(false);
                })
        }
    });

    const [createProduct] = useMutation(CREATE_PRODUCT, {
        context: { clientname: 'billing'}
    });
    
    const navigate = useNavigate();
    
    if (loading) return <Loader/>
    console.log(formik)
    return (
        <div>
            <h3 className="main_title">Создание продукта</h3>
            <Form onSubmit={formik.handleSubmit}>
                <Row className='row_form'>
                    <Col xs={4}><Label for="guid">GUID</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="guid"
                            id="guid"
                            invalid={formik.touched.guid && Boolean(formik.errors.guid)}
                            onChange={formik.handleChange}
                            disabled
                            value={formik.values.guid}
                        />
                        <FormFeedback>
                            {formik.touched.guid && formik.errors.guid}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="bin">БИН</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="bin"
                            id="bin"
                            invalid={formik.touched.bin && Boolean(formik.errors.bin)}
                            onChange={formik.handleChange}
                            disabled
                            value={formik.values.bin}
                        />
                        <FormFeedback>
                            {formik.touched.bin && formik.errors.bin}
                        </FormFeedback>
                    </Col>
                </Row>    
                <Row className='row_form'>
                    <Col xs={4}><Label for="tariffId">Тариф</Label></Col>
                    <Col xs={8}>
                        <CustomAutocomplete
                            list={data?.tariffsByProduct}
                            placeholder="Выберите тариф"
                            name="tariffId"
                            id="tariffId"
                            setValue={(name, item) => autocompleteChangeHandler(name, item.id)}
                            searchBy={['name']}
                            resultStringKeyName='name'
                            invalid={formik.touched.tariffId && Boolean(formik.errors.tariffId)}
                            formFeedback={formik.touched.tariffId && formik.errors.tariffId}
                        />
                    </Col>
                </Row>
                <Row className={'row_form'}>
                    <Col xs={4}><Label for={'trialDateTo'}>Дата тестового периода</Label></Col>
                    <Col xs={8}>
                        <Input 
                            type="date" 
                            name="trialDateTo" 
                            id="trialDateTo" 
                            placeholder="Дата тестового периода" 
                            onChange={formik.handleChange}
                            value={formik.values.trialDateTo}
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="trialDateComment">Причина тестового периода</Label></Col>
                    <Col xs={8}>
                        <Input
                            type='textarea'
                            id={'trialDateComment'}
                            disabled={!formik.values.trialDateTo}
                            name={"trialDateComment"}
                            onChange={formik.handleChange}
                            value={formik.values.trialDateComment}
                            invalid={formik.touched.trialDateComment && Boolean(formik.errors.trialDateComment)}
                        />
                        <FormFeedback>
                            {formik.touched.trialDateComment && formik.errors.trialDateComment}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={8}>
                        <Button type="submit" color="primary" className="crud_button mr" disabled={clicked}>
                            Сохранить
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default BillingNewTerminal;