import React, { useState } from 'react';
import FirstLoginForm  from '../../components/LoginForm/FirstLoginForm';
import User from '../../store/User';
import {observer} from "mobx-react-lite";
import {toast} from "react-toastify";
import LoginForm from '../../components/LoginForm/LoginForm';
import Loader from '../../components/UI/Loader/Loader'
import './Login.css';

const Login = observer(() => {
    const [user, setUser] = useState({
        login: '',
        password: '',
        newPassword: ''
    });

    const changeHandler = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    };

    const submitHandler = (e) => {
        e.preventDefault();
        User.login(user.login, user.password)
    };

    const submitFirstLoginHandler = (e) => {
        e.preventDefault();
        User.completeNewPassword(user.password)
    };

    const inputBlurHandler = () => {
        let res = user.login.search("_");
        if (res === -1) {
            let value = user.login.replace(/[^\w]/g, '');
            console.log(value)
            setUser({
                ...user,
                login: value.substring(0)
            })
        }   else return toast.error("Не верный формат ввода");
    };

    return(
        <div className={'login'}>
            {User.challengeName === 'NEW_PASSWORD_REQUIRED'
                ? <FirstLoginForm
                    submitHandler={(e) => submitFirstLoginHandler(e)}
                    changeHandler={(e) => changeHandler(e)}
                />
                : <LoginForm
                    login={user.login}
                    submitHandler={(e) => submitHandler(e)}
                    changeHandler={(e) => changeHandler(e)}
                    blurHandler={() => inputBlurHandler()}
                />
            }
            <div className={'error_warning'}>{User.error}</div>
            {User.loading ? <Loader/>: null}
        </div>
    )
});

export default Login;