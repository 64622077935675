export const resolveOperationsTitle = (field) => {
    switch (field) {
        case 'id':
            return 'ID';
        case 'externalId':
            return 'Внешний ID';
        case 'creationDate':
            return 'Дата создания';
        case 'changeDate':
            return 'Дата изменения';
        case 'status':
            return 'Статус';
        case 'amount':
            return 'Сумма';
        case 'clientId':
            return 'ID клиента';
        case 'clientDate':
            return 'Время клиента';
        case 'merchantId':
            return 'Мерчант';
        case 'serviceId':
            return 'Услуга';
        case 'terminalId':
            return 'Терминал';
        case 'type':
            return 'Тип';
        case 'invoiceId':
            return 'ID инвойса';
        case 'nameRus':
            return 'Наименование (рус)';
        case 'nameKaz':
            return 'Наименование (каз)';
        case 'descriptionRus':
            return 'Описание (рус)';
        case 'descriptionKaz':
            return 'Описание (каз)';
        case 'paymentId':
            return 'ID платежа';
        case 'canceledAmount':
            return 'Отмененная сумма';
        case 'creditAmount':
            return 'Сумма кредита';
        case 'paymentSystemType':
            return 'Платежная система';
        default:
            return field;
    }
}

