import React, {useEffect, useState} from 'react';
import {Link, useSearchParams} from "react-router-dom";
import Loader from "../../../components/UI/Loader/Loader";
import {Breadcrumb, BreadcrumbItem, Button, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {DataTable} from "../../../components/DataTable/DataTable";
import TotalInvoicesTable from "../../../components/TotalsTables/TotalInvoicesTable";
import {useMutation, useQuery} from "@apollo/client";
import {GET_INVOICES, GET_INVOICE_STATUSES, GET_MERCHANTS, GET_TERMINALS, GET_INVOICE_TYPES} from "../../../GraphQL/Queries";
import {CANCEL_INVOICE, KASPI_BANK_PUSH_NOTIFY_FILTERED} from "../../../GraphQL/Mutations";
import {toast} from "react-toastify";
import ReportDatesFilter from "../../../components/ReportDatesFilter/ReportDatesFilter";
import Legenda from "../../../components/UI/Legenda/Legenda";
import BillingFilter from "../../../store/BillingFilter";
import CustomAutocomplete from "../../../components/CustomAutocomplete/CustomAutocomplete";
import ConfirmNotifyInvoices from '../../../components/ConfirmNotifyInvoices/ConfirmNotifyInvoices';

const Invoices = () => {
    const [pageLoading, setPageLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    let date = new Date().toISOString();

    let [searchParams, setSearchParams] = useSearchParams();

    const [filter, setFilter] = useState({
        page: 1,
        size: 25,
        sortBy: 'id',
        sortOrder: 'desc'
    });

    const [cancelInvoice] = useMutation(CANCEL_INVOICE, {
        awaitRefetchQueries: true,
        refetchQueries: [{
            query: GET_INVOICES, variables: {
                page: 0, size: 25, sortBy: 'id', sortOrder: 'desc', filter: {
                    dateFrom: BillingFilter.invoicesFilter.dateFrom,
                    dateTo: BillingFilter.invoicesFilter.dateTo,
                    bin: BillingFilter.invoicesFilter.bin || null,
                    status: BillingFilter.invoicesFilter.status || null,
                    type: BillingFilter.invoicesFilter.type || null
                }
            }, context: {clientName: 'billing'}
        }],
    });

    const {loading, error, data, refetch} = useQuery(GET_INVOICES, {
            variables: {
                filter: {
                    dateFrom: `${date.slice(0, 11)}00:00`,
                    dateTo: `${date.slice(0, 11)}23:59`,
                },
                page: 0,
                size: filter.size,
                sortBy: 'id',
                sortOrder: 'desc'
            }, context: {clientName: 'billing'}
        }
    );

    const merchants = useQuery(GET_MERCHANTS, {
            variables: {
                sortBy: "id",
                sortOrder: "asc"
            }
        }
    );

    const statuses = useQuery(GET_INVOICE_STATUSES, {context: {clientName: 'billing'}});

    const terminals = useQuery(GET_TERMINALS, {
            variables: {
                sortBy: "id"
            }
        }
    );

    const {loading: invoiceTypesLoading, data: invoiceTypesData} = useQuery(GET_INVOICE_TYPES, {
        context: {clientName: 'billing'}
    });

    const [getKaspiBankPushNotifyFiltered, {
        data: kaspiBankPushNotifyFilteredData,
        loading: kspiBankPushNotifyFilteredLoading,
        error: kaspiBankPushNotifyFilteredError
    }] = useMutation(KASPI_BANK_PUSH_NOTIFY_FILTERED);


    const sortByHandler = (e) => {
        setPageLoading(true);

        if (filter.sortBy === e.target.id) {
            setFilter({
                ...filter,
                sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
            });
        } else {
            setFilter({
                ...filter,
                sortBy: e.target.id
            });
        }

        refetch({
            filter: {
                dateFrom: BillingFilter.invoicesFilter.dateFrom,
                dateTo: BillingFilter.invoicesFilter.dateTo,
                bin: BillingFilter.invoicesFilter.bin || null,
                status: BillingFilter.invoicesFilter.status || null,
                type: BillingFilter.invoicesFilter.type || null,
            },
            page: filter.page - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const pageSizeHandler = (e) => {
        setPageLoading(true);

        setFilter({
            ...filter,
            size: parseInt(e.target.value),
            page: 1
        });
        BillingFilter.setInvoicesFilter('size', parseInt(e.target.value));
        setSearchParams({...BillingFilter.invoicesFilter, size: parseInt(e.target.value)});
        refetch({
            filter: {
                dateFrom: BillingFilter.invoicesFilter.dateFrom,
                dateTo: BillingFilter.invoicesFilter.dateTo,
                bin: BillingFilter.invoicesFilter.bin || null,
                status: BillingFilter.invoicesFilter.status || null,
                type: BillingFilter.invoicesFilter.type || null,
            },
            page: 0,
            size: parseInt(e.target.value),
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const handlePageChange = (pageNumber) => {
        setPageLoading(true);

        console.log(`active page is ${pageNumber}`);
        setFilter({
            ...filter,
            page: pageNumber
        });

        refetch({
            filter: {
                dateFrom: BillingFilter.invoicesFilter.dateFrom,
                dateTo: BillingFilter.invoicesFilter.dateTo,
                bin: BillingFilter.invoicesFilter.bin || null,
                status: BillingFilter.invoicesFilter.status || null,
                type: BillingFilter.invoicesFilter.type || null,
            },
            page: pageNumber - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const [invoices, setInvoices] = useState([]);

    useEffect(() => {
        if (data) {
            setInvoices(data.invoicesPageable.data)
        }
    }, [data, filter]);

    let dateFrom = searchParams.get("dateFrom");
    let dateTo = searchParams.get("dateTo");
    let status = searchParams.get("status");
    let bin = searchParams.get("bin");
    let type = searchParams.get("type");
    let size = searchParams.get("size");

    useEffect(() => {
        BillingFilter.cleanInvoicesFilter();

        if (dateFrom || dateTo || bin || status || type || size) {
            if (dateFrom) BillingFilter.setInvoicesFilter('dateFrom', dateFrom)
            if (dateTo) BillingFilter.setInvoicesFilter('dateTo', dateTo);
            if (bin) BillingFilter.setInvoicesFilter('bin', bin);
            if (status) BillingFilter.setInvoicesFilter('status', status);
            if (type) BillingFilter.setInvoicesFilter('type', type);
            if (size) {
                BillingFilter.setInvoicesFilter('size', size);
                setFilter({...filter, size});
            }

            refetch({
                filter: {
                    dateFrom: BillingFilter.invoicesFilter.dateFrom,
                    dateTo: BillingFilter.invoicesFilter.dateTo,
                    bin: BillingFilter.invoicesFilter.bin || null,
                    status: BillingFilter.invoicesFilter.status || null,
                    type: BillingFilter.invoicesFilter.type || null
                },
                page: 0,
                size: filter.size,
                sortBy: filter.sortBy
            })
                .then(() => {
                    setPageLoading(false);
                })
        } else {
            BillingFilter.setInvoicesFilter('dateFrom', `${date.slice(0, 11)}00:00`);
            BillingFilter.setInvoicesFilter('dateTo', `${date.slice(0, 11)}23:59`);
            BillingFilter.setInvoicesFilter('bin', '');
            BillingFilter.setInvoicesFilter('status', '');
            BillingFilter.setInvoicesFilter('type', '');
            BillingFilter.setInvoicesFilter('size', 25);
        }
    }, [filter.size, BillingFilter.invoicesFilter.size]);

    const columns = [
        {title: '#', id: '#'},
        {title: 'Id', id: 'id'},
        {title: 'Дата создания', id: 'createdDate'},
        {title: 'Дата изменения', id: 'changedDate'},
        {title: 'Сумма', id: 'amount'},
        {title: 'БИН', id: 'bin'},
        {title: 'Мерчант', id: 'merchantName'},
        {title: 'Статус', id: 'status'},
        {title: 'Номер', id: 'phone'},
        {title: 'Оплаченная сумма', id: 'utilizedAmount'},
        {title: 'Тип', id: 'type'},
        {title: 'Терминалы', id: 'guids'},
        {title: 'Посл. уведомление', id: 'lastSentDate'},
        {title: 'Коммент.', id: 'comment'},
        {title: 'Действия', id: 'actions'},
    ];

    const rowsItem = [
        {title: '#',},
        {title: 'id'},
        {title: 'createdDate'},
        {title: 'changedDate'},
        {title: 'amount'},
        {title: 'bin'},
        {title: 'merchantName'},
        {title: 'status'},
        {title: 'phone'},
        {title: 'utilizedAmount'},
        {title: 'type'},
        {title: 'guids'},
        {title: 'lastSentDate'},
        {title: 'comment'}
    ];

    if (loading || pageLoading || statuses.loading || merchants.loading || invoiceTypesLoading) return <Loader/>


    return (
        <div>
            <div className="breadcrumb_wrapper">
                <Breadcrumb>
                    <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Отчет по инвойсам (биллинг)</BreadcrumbItem>
                </Breadcrumb>
                <Button
                    onClick={() => setShowModal(!showModal)}
                    color="dark"
                    className="add_btn send_notifications"
                    title="Отправить уведомления по всем неоплаченным инвойсам"
                >
                    Отправить уведомления
                </Button>
            </div>
            <hr/>
            <Form onSubmit={e => {
                e.preventDefault();
                setPageLoading(true);
                setSearchParams({
                    dateFrom: BillingFilter.invoicesFilter.dateFrom,
                    dateTo: BillingFilter.invoicesFilter.dateTo,
                    bin: BillingFilter.invoicesFilter.bin ? BillingFilter.invoicesFilter.bin : '',
                    status: BillingFilter.invoicesFilter.status ? BillingFilter.invoicesFilter.status : '',
                    type: BillingFilter.invoicesFilter.type ? BillingFilter.invoicesFilter.type : '',
                    size: BillingFilter.invoicesFilter.size ? BillingFilter.invoicesFilter.size : 25
                });
                setFilter({
                    ...filter,
                    page: 1
                });
                refetch({
                    filter: {
                        dateFrom: BillingFilter.invoicesFilter.dateFrom,
                        dateTo: BillingFilter.invoicesFilter.dateTo,
                        bin: BillingFilter.invoicesFilter.bin || null,
                        status: BillingFilter.invoicesFilter.status || null,
                        type: BillingFilter.invoicesFilter.type || null
                    },
                    page: 0,
                    size: filter.size,
                    sortBy: filter.sortBy
                })
                    .then(() => {
                        setPageLoading(false);
                    })
            }}>
                <div className={'filter_wrapper'}>
                    <ReportDatesFilter
                        dateFrom={BillingFilter.invoicesFilter.dateFrom}
                        dateTo={BillingFilter.invoicesFilter.dateTo}
                        dateFromChange={(e) => {
                            setSearchParams({...BillingFilter.invoicesFilter, dateFrom: e.target.value})
                            BillingFilter.invoicesFilter.dateFrom = e.target.value
                        }}
                        dateToChange={(e) => {
                            setSearchParams({...BillingFilter.invoicesFilter, dateTo: e.target.value})
                            BillingFilter.invoicesFilter.dateTo = e.target.value
                        }}
                    />
                    {/*<Input*/}
                    {/*    className={'filter_item'}*/}
                    {/*    type="text"*/}
                    {/*    id="bin"*/}
                    {/*    name="bin"*/}
                    {/*    placeholder='Бин'*/}
                    {/*    value={BillingFilter.invoicesFilter.bin}*/}
                    {/*    onChange={(e) => {*/}
                    {/*        setSearchParams({ ...BillingFilter.invoicesFilter, bin: e.target.value})*/}
                    {/*        BillingFilter.invoicesFilter.bin = e.target.value*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <div className={'filter_item'}>
                        <CustomAutocomplete
                            list={merchants.data?.merchants}
                            name={'bin'}
                            setValue={(name, item) => {
                                if (item) {
                                    setSearchParams({...BillingFilter.invoicesFilter, bin: item.bin})
                                    BillingFilter.invoicesFilter.bin = item.bin
                                } else {
                                    setSearchParams({...BillingFilter.invoicesFilter, bin: ''})
                                    BillingFilter.invoicesFilter.bin = null
                                }
                            }}
                            placeholder="Наименование / Бин мерчанта"
                            searchBy={["name", "bin"]}
                            resultStringKeyName='name'
                            getValueBy={'bin'}
                            edit={true}
                            value={BillingFilter.invoicesFilter.bin ? BillingFilter.invoicesFilter.bin : null}
                        />
                    </div>
                    <Input
                        className={'filter_item'}
                        type='select'
                        id="status"
                        value={BillingFilter.invoicesFilter.status}
                        name='status'
                        onChange={(e) => {
                            setSearchParams({...BillingFilter.invoicesFilter, status: e.target.value})
                            BillingFilter.invoicesFilter.status = e.target.value
                        }}
                        style={{color: '#747678'}}
                    >
                        <option value={''}>Выберите статус</option>
                        {/* <option>NEW</option>
                            <option>PAID</option>
                            <option>CANCELED</option>
                            <option>PAST_DUE</option> */}
                        {
                            statuses.data?.InvoiceStatuses?.map(stat => {
                                return <option key={stat.status} value={stat.status}>
                                    {stat.russian}
                                </option>
                            })
                        }
                    </Input>
                    <Input
                        className={'filter_item'}
                        type='select'
                        id="type"
                        value={BillingFilter.invoicesFilter.type}
                        name='type'
                        onChange={(e) => {
                            setSearchParams({...BillingFilter.invoicesFilter, type: e.target.value})
                            BillingFilter.invoicesFilter.type = e.target.value
                        }}
                        style={{color: '#747678'}}
                    >
                        <option value={''}>Выберите тип</option>
                        {invoiceTypesData.invoiceTypes.map(item => {
                            return (
                                <option
                                    value={item.type}
                                    label={item.russian}
                                    key={item.type}
                                >{item.type}</option>
                            )
                        })}
                    </Input>
                    <div className={'buttons_wrapper'}>
                        <Button color={'primary'} type={'submit'} className="filter_button"
                                style={{width: 100, marginRight: 20}}>Поиск</Button>
                        <Button color={'danger'} style={{width: 100}} className="filter_button" onClick={() => {
                            setPageLoading(true);
                            setSearchParams({
                                dateFrom: `${date.slice(0, 11)}00:00`,
                                dateTo: `${date.slice(0, 11)}23:59`
                            });
                            BillingFilter.cleanInvoicesFilter();
                            setFilter({
                                ...filter,
                                size: 25
                            })
                            refetch({
                                filter: {
                                    dateFrom: `${date.slice(0, 11)}00:00`,
                                    dateTo: `${date.slice(0, 11)}23:59`,
                                    bin: null,
                                    status: null
                                },
                                page: filter.page - 1,
                                size: filter.size,
                                sortBy: filter.sortBy
                            })
                                .then(() => {
                                    setPageLoading(false);
                                })
                        }}>Сбросить</Button>
                    </div>
                </div>
            </Form>
            <br/>
            <hr/>

            <Legenda
                // data={[{ title: 'NEW', color: '#dee2e6'}, { title: 'PAID', color: '#a7c8d580'}, { title: 'CANCELED', color: '#ef121285' }, { title: 'PAST_DUE', color: '#ffd34f'}]}
                data={statuses.data.InvoiceStatuses}
                title={'Статусы платежей'}
            />

            <DataTable
                notStripped={true}
                hideColumns={['changedDate', 'comment']}
                columns={columns}
                rows={invoices}
                rowsItem={rowsItem}
                merchants={merchants.data ? merchants.data.merchants : []}
                billingInvoiceStatuses={statuses.data?.InvoiceStatuses}
                terminals={terminals.data ? terminals.data.terminals : []}
                sortBy={filter.sortBy}
                sortByHandler={(e) => sortByHandler(e)}
                itemsCountPerPage={filter.size}
                sortOrder={filter.sortOrder}
                pageSizeHandler={(e) => pageSizeHandler(e)}
                totalItemsCount={data?.invoicesPageable?.total}
                activePage={filter.page}
                handlePageChange={(pageNumber) => handlePageChange(pageNumber)}
                isPaginated={true}
                showCancelInvoice={true}
                cancelInvoiceHandler={(id, comment) => {
                    toast.promise(
                        cancelInvoice({variables: {invoiceId: id, comment: comment}, context: {clientName: 'billing'}}), {
                            pending: 'В обработке',
                            success: 'Инвойс отменен',
                            error: 'Ошибка'
                        }
                    )
                        .then(result => {
                            console.log(result)
                        })

                }}
            />
            <TotalInvoicesTable
                totalAmount={data?.invoicesPageable?.totalAmount}
            />
            <Modal
                isOpen={showModal}
                centered={true}
                fullscreen="xl"
                size="lg"
                toggle={() => setShowModal(!showModal)}
            >
                <ModalHeader toggle={() => setShowModal(!showModal)}>Подтверждение</ModalHeader>
                <ModalBody>
                    <ConfirmNotifyInvoices
                        dateFrom={BillingFilter.invoicesFilter.dateFrom}
                        dateTo={BillingFilter.invoicesFilter.dateTo}
                        bin={BillingFilter.invoicesFilter.bin || null}
                        status={BillingFilter.invoicesFilter.status || null}
                        type={BillingFilter.invoicesFilter.type || null}
                        statuses={statuses.data.InvoiceStatuses}
                        types={invoiceTypesData.invoiceTypes}
                        invoicesCount={invoices.length}
                        cancel={() => setShowModal(!showModal)}
                        notify={() => {
                            setShowModal(false)
                            toast.promise(
                                getKaspiBankPushNotifyFiltered({
                                    variables: {
                                        filter: {
                                            dateFrom: BillingFilter.invoicesFilter.dateFrom,
                                            dateTo: BillingFilter.invoicesFilter.dateTo,
                                            bin: BillingFilter.invoicesFilter.bin || null,
                                            status: BillingFilter.invoicesFilter.status || null,
                                            type: BillingFilter.invoicesFilter.type || null}
                                    },
                                    context: {clientName: 'billing'}
                                }), {
                                    pending: 'В обработке',
                                    success: {
                                        render ({data}) {
                                            return data.data.kaspiBankPushNotifyFiltered.message
                                        }
                                    },
                                    error: 'Ошибка'
                                })
                                .then(result => {
                                    console.log(result)
                                })
                        }}
                    />
                </ModalBody>
            </Modal>
        </div>
    );
};

export default Invoices;