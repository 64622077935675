import React from 'react';

const FirstLoginForm = ({submitHandler, changeHandler}) => (
    <form onSubmit={(e) => submitHandler(e)}>
        <div className='login_wrapper'>

            <div className={'login_item_wrapper'}>
               <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="login_item"
                    placeholder="Новый пароль"
                    onChange={(e) => changeHandler(e)}
                />
            </div>
            <button
                type="submit"
                className="login_button"
            >
                Вход
            </button>
        </div>
    </form>
);

export default FirstLoginForm;
