import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {GET_TERMINAL_TYPES} from "../../GraphQL/Queries";
import Loader from "../../components/UI/Loader/Loader";
import {Breadcrumb, BreadcrumbItem, Button} from "reactstrap";
import {DataTable} from "../../components/DataTable/DataTable";

const TerminalTypes = () => {
    
    const navigate = useNavigate();
    const [filter, setFilter] = useState({
        sortBy: 'id',
        sortOrder: "asc"
    });
    
    const { loading, error, data } = useQuery(GET_TERMINAL_TYPES, { variables: {
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        }}
    );
    
    const [terminalTypes, setTerminalTypes] = useState([]);

    useEffect(() => {
        if (data) {
            setTerminalTypes(data.terminalTypes)
        }
    }, [data, filter]);
    
    const sortByHandler = (e) => {
        if (filter.sortBy === e.target.id) {
            setFilter({
                ...filter,
                sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
            });
        } else {
            setFilter({
                ...filter,
                sortBy: e.target.id
            });
        }  
    };

    const columns = [
        { title: '#', id: '#' },
        { title: 'Id', id: 'id' },
        { title: 'Наименование', id: 'name'},
        { title: 'QR', id: 'kaspiQrURL'}
    ];

    const rowsItem = [
        { title: '#' },
        { title: 'id' },
        { title: 'name' },
        { title: 'kaspiQrURL'}
    ];

    if (loading) return <Loader/>
    
    return (
        <div>
            <div className="breadcrumb_wrapper">
                <Breadcrumb>
                    <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Типы терминалов</BreadcrumbItem>
                </Breadcrumb>
                <Button
                    color="dark"
                    className="add_btn"
                    onClick={() => navigate('/terminal_types/new')}
                >
                    + Добавить
                </Button>
            </div>
            <DataTable
                hideColumns={[]}
                columns={columns}
                rows={terminalTypes}
                rowsItem={rowsItem}
                link={'terminal_types'}
                sortBy={filter.sortBy}
                sortOrder={filter.sortOrder}
                isPaginated={false}
                sortByHandler={(e) => sortByHandler(e)}
                hideIcons={true}
            />
        </div>
    );
};

export default TerminalTypes;