import React from 'react';
import {Table} from "reactstrap";

const TotalInvoicesTable = ({totalAmount}) => {
    return (
        <Table borderless style={{width: 300}}>
            <tbody>
            <tr>
                <td>
                    Общая сумма
                </td>
                <td>
                    {totalAmount ? totalAmount.toLocaleString('ru') : ''}
                </td>
            </tr>
            </tbody>
        </Table>
    );
};

export default TotalInvoicesTable;