import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';

const ConfirmNotifyInvoices = ({dateFrom, dateTo, bin, status, type, statuses, types, invoicesCount, cancel, notify}) => {
    const [confirm, setConfirm] = useState(false);

    return (
        <div style={{padding: 10}}>
            <Row>
                <Col xs={3}>
                    <div><b>Период:</b></div>
                </Col>
                <Col xs={9}>
                    <div> c {new Date(dateFrom).toLocaleString()} по {new Date(dateTo).toLocaleString()}</div>
                </Col>
            </Row>
            <Row style={{display: bin ? 'flex' : 'none', marginTop: 15}}>
                <Col xs={3}>
                    <div><b>Бин:</b></div>
                </Col>
                <Col xs={9}>
                    <div>{bin}</div>
                </Col>
            </Row>
            <Row style={{display: status ? 'flex' : 'none', marginTop: 15}}>
                <Col xs={3}>
                    <div><b>Статус:</b></div>
                </Col>
                <Col xs={9}>
                    <div>{status ? (statuses.find(item => item.status === status)).russian : null}</div>
                </Col>
            </Row>
            <Row style={{display: type ? 'flex' : 'none', marginTop: 15}}>
                <Col xs={3}>
                    <div><b>Тип:</b></div>
                </Col>
                <Col xs={9}>
                    <div>{type ? (types.find(item => item.type === type)).russian : null}</div>
                </Col>
            </Row>
            <Row style={{ marginTop: 15}}>
                <Col xs={3}>
                    <div><b>Кол-во инвойсов:</b></div>
                </Col>
                <Col xs={8}>
                    <div>{invoicesCount}</div>
                </Col>
            </Row>
            <hr/>
            {confirm
                ? <>
                    <Row>
                        <Col xs={12}>
                            <b>Вы точно подтверждаете отправку уведомлении?</b>
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>    
                        <Col xs={3}>
                            <Button
                                onClick={() => notify()}
                                color="success"
                                className="add_btn"
                                title="Да"
                            >
                                Да, точно
                            </Button>
                        </Col>
                        <Col xs={3}>
                            <Button
                                onClick={() => cancel()}
                                color="danger"
                                className="add_btn"
                                title="Нет"
                            >
                                Нет
                            </Button>
                        </Col>
                    </Row>
                </>
                : <Row>
                    <Col xs={6}>Вы подтверждаете отправку уведомлении?</Col>
                    <Col xs={3}>
                        <Button
                            onClick={() => setConfirm(true)}
                            color="success"
                            className="add_btn"
                            title="Да"
                        >
                            Да
                        </Button>
                    </Col>
                    <Col xs={3}>
                        <Button
                            onClick={() => cancel()}
                            color="danger"
                            className="add_btn"
                            title="Нет"
                        >
                            Нет
                        </Button>
                    </Col>
                </Row>
            }
        </div>
    )
};

export default ConfirmNotifyInvoices;