import React from 'react';
import Switch from "react-switch";

const ButtonSwitcher = ({id, checked, changeHandler, disabled}) => {
    return (
        <div style={{display: 'flex', alignContent: 'center'}}>
            <Switch 
                id={id}
                onChange={changeHandler} 
                checked={checked} 
                disabled={disabled}
            />
            <span style={{marginLeft: 10}}>{id}</span>
        </div>
    );
};

export default ButtonSwitcher;