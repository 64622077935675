import {makeAutoObservable} from "mobx";
import { toast } from "react-toastify";
import axios from '../axios-api';
import config from "../config";

class ControllerSettingsStore {

    constructor() {
        makeAutoObservable(this);
    };

    getFiles = () => {
        return axios.get(`${config.controllerURL}/getfw`)
            .then(result => {
                return result.data
            })
            .catch(err => {
                return err;
            })
    }

    deleteFiles = (ids) => {
        return axios.delete(`${config.controllerURL}/deletefw`, {data: {ids}})
            .then(response => {
                console.log(response.data);
                if (response.data.not_deleted_ids.length === 0) toast('Файлы успешно удалены');
                else {
                    let string = '';
                    response.data.not_deleted_ids.map(item => {
                        string += (`Файл ${item} не удален.\n`)
                        return 
                    })
                    toast.warning(string);
                }
                return response.data;
            })
            .catch(err => {
                toast.error('Ошибка')
                return err;
            })
    }

    uploadFile = (file, description) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('description', description);

        return toast.promise(
            axios.post(`${config.controllerURL}/upfile`, formData, {
                headers: {
                    "Content-type": "multipart/form-data",
                }
            }), {
                pending: 'В обработке',
                success: 'Файл успешно загружен',
                error: 'Ошибка'
        })
            .then(result => {
                return result.data;
            })
            .catch(err => {
                return err;
            })
    };

    getControllerSettings = (guid) => {
        return axios.get(`${config.controllerURL}/infocontroller/${guid}`)
            .then(result => {
                return result.data;
            })
            .catch(err => {
                return err;
            })
    };

    updateControllerSettings = (settings) => {
        return toast.promise(
            axios.post(`${config.controllerURL}/update/${settings.id}`, {
            addAmount: parseInt(settings.addAmount),
            alwaysInsert: settings.alwaysInsert,
            changedate: settings.changedate,
            createdate: settings.createdate,
            dbgLevel: parseInt(settings.dbgLevel), 
            fwHash: settings.fwHash, 
            coinsList: parseInt(settings.coinsList),
            desc: settings.desc,
            file: settings.file,
            fw: settings.fw,
            guid: settings.guid,
            id: parseInt(settings.id),
            idSetings: parseInt(settings.idSetings),
            invert: settings.invert,
            itemName: settings.itemName,
            itemNumber: settings.itemNumber,
            mdbVMCnoACK: settings.mdbVMCnoACK,
            log: settings.log,
            period: parseInt(settings.period),
            secondAddress: settings.secondAddress,
            step: typeof(settings.step) === 'string' ? Number((settings.step).replace(',', '.')) : settings.step,
            test: settings.test,
            timezone: parseInt(settings.timezone),
            width: parseInt(settings.width),
            work_mode: settings.work_mode,
            creditRequest: settings.creditRequest, 
            setupInReset: settings.setupInReset,
            disabledJustReset: settings.disabledJustReset,
            pinMDB: settings.pinMDB,
            dbgMask: parseInt(settings.dbgMask),
            exchange: parseInt(settings.exchange), 
            exchangePulse: settings.exchangePulse,
            denyBegin: settings.denyBegin, 
            amount: settings.amount,
            cancelSession: settings.cancelSession, 
            description: settings.description,
            billScale: settings.billScale,
            bills: settings.bills,
            coinScale: settings.coinScale,
            coins: settings.coins, 
            vctrl: settings.vctrl,
            logInterval: parseInt(settings.logInterval), 
            wasRstAfterRst: settings.wasRstAfterRst,
            justRstAfterRst: settings.justRstAfterRst,
            revalueLimitDeny: settings.revalueLimitDeny,
            trace: settings.trace,
            enabled: settings.enabled
        }),{
                pending: 'В обработке',
                success: 'Настройки успешно изменены',
                error: 'Ошибка'
        })
            .then((result) => {
                return result.data;
            })
            .catch(err => {
                return err;
            })
    };
    
    getTerminalsByFw = (data) => {
        this.loading = true;
        return axios.post(`${config.controllerURL}/hosts`, data)
            .then(response => {
                this.loading = false;
                return response.data;
            })
            .catch(err => {
                this.loading = false
            })
    };
    
    massUpdate = (data) => {
        toast.promise(
            axios.post(`${config.controllerURL}/update`, data), {
                pending: 'В обработке',
                success: 'Обновление успешно назначено',
                error: 'Ошибка'
            })
                .then(response => console.log(response.data))
                .catch(err => console.log(err))
    }
    
}

export default new ControllerSettingsStore();