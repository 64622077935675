import React, {useState} from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Modal, ModalHeader, Table } from 'reactstrap';
import { GET_PAYMENT, GET_TERMINALS, GET_MERCHANTS, GET_SERVICES } from '../../GraphQL/Queries';
import { resolvePaymentValue } from '../../services/utils/resolvePaymentValue';
import { resolveOperationsTitle } from '../../services/utils/resolveOperationsTitle';
import Loader from "../UI/Loader/Loader";

const PaymentDetails = ({id}) => {
    const [showModal, setShowModal] = useState(false);

    const allTerminals = useQuery(GET_TERMINALS, { variables: {
            sortBy: "id"
        }}
    );

    const merchants = useQuery(GET_MERCHANTS, { variables: {
            sortBy: "id",
            sortOrder: 'asc'
        }}
    );

    const services = useQuery(GET_SERVICES, { variables: {
            sortBy: "id",
            sortOrder: 'asc'
        }}
    );

    const [getPayment, { loading, error, data }] = useLazyQuery(GET_PAYMENT);

    const getInfo = () => {
        setShowModal(!showModal);
        getPayment({variables: { id: id}})
    }
    
    return (
        <>
            <span onClick={getInfo} className='paymentValue'>{id}</span>
            <Modal
                isOpen={showModal}
                centered
                fullscreen="xl"
                size="lg"
                toggle={() => setShowModal(!showModal)}
            >
                <ModalHeader toggle={() => setShowModal(!showModal)}>
                    Детали платежа
                </ModalHeader>
                {loading
                    ? <Loader />
                    : <Table striped>
                        <tbody>
                        { data
                            ? Object.keys(data.payment).map(field => {
                                const item = data.payment[field];
                                if (field !== '__typename') {
                                    return <tr key={field}>
                                        <td>
                                            {resolveOperationsTitle(field)}:
                                        </td>
                                        <td>
                                            {resolvePaymentValue(field, item, allTerminals.data?.terminals, merchants.data?.merchants, services.data?.services)}
                                        </td>
                                    </tr>
                                } else return
                            })
                            : null
                        }
                        </tbody>
                    </Table>
                }
            </Modal>
        </>
    )
};

export default PaymentDetails;