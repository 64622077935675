import React from 'react';
import RowCount from './RowCount/RowCount';
import Pagination from "react-js-pagination";
import './Pagination.css';

const CustomPagination = ({pageSizeHandler, activePage, rowsLength, totalItemsCount, itemsCountPerPage, handlePageChange}) => {
    return (
        <div className="Pagination">
            <div className="pagination_wrap">
                <RowCount 
                    pageSizeHandler={(e) => pageSizeHandler(e)} itemsCountPerPage={itemsCountPerPage}
                    values={[10, 25, 50, 100]}
                />
                <p>
                    {`${(activePage - 1) * itemsCountPerPage + 1}-${(activePage - 1) * itemsCountPerPage + rowsLength} из ${totalItemsCount}`}
                </p>
                <Pagination
                    activePage={activePage}
                    itemsCountPerPage={parseInt(itemsCountPerPage)}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={3}
                    onChange={(pageNumber) => handlePageChange(pageNumber)}
                />
            </div>
        </div>
    );
};

export default CustomPagination;