import React, {useEffect, useState} from 'react';
import {useQuery} from "@apollo/client";
import {ACTIVATIONS_PAGEABLE, GET_MERCHANTS, GET_TERMINALS} from "../../../GraphQL/Queries";
import {Link, useSearchParams} from "react-router-dom";
import Loader from "../../../components/UI/Loader/Loader";
import {Breadcrumb, BreadcrumbItem, Button, Form, Input} from "reactstrap";
import ReportDatesFilter from "../../../components/ReportDatesFilter/ReportDatesFilter";
import CustomAutocomplete from "../../../components/CustomAutocomplete/CustomAutocomplete";
import {DataTable} from "../../../components/DataTable/DataTable";
import BillingFilter from "../../../store/BillingFilter";

const TerminalActivationReport = () => {
    let date = new Date().toISOString();

    const [pageLoading, setPageLoading] = useState(false);
    const [filter, setFilter] = useState({
        page: 1,
        size: 25,
        sortBy: 'id',
        sortOrder: 'asc',
    });
    
    let [searchParams, setSearchParams] = useSearchParams();
    
    const [totalItems, setTotalItems] = useState();
    const [terminalActivations, setTerminalActivations] = useState([]);

    const merchants = useQuery(GET_MERCHANTS, {
            variables: {
                sortBy: "id",
                sortOrder: "asc"
            }
        }
    );
    const terminals = useQuery(GET_TERMINALS, { variables: { sortBy: "id" }});
    const {loading, data, refetch} = useQuery(ACTIVATIONS_PAGEABLE, {
        context: {clientName: 'billing'},
        variables: {
            filter: {
                dateFrom: `${date.slice(0, 11)}00:00`,
                dateTo: `${date.slice(0, 11)}23:59`,
                bin: null,
                status: null,
                guid: null
            },
            page: 0,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        }
    });
    
    const sortByHandler = (e) => {
        setPageLoading(true);

        if (filter.sortBy === e.target.id) {
            setFilter({
                ...filter,
                sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
            });
        } else {
            setFilter({
                ...filter,
                sortBy: e.target.id
            });
        }

        refetch({
            filter: {
                dateFrom: BillingFilter.activationsFilter.dateFrom,
                dateTo: BillingFilter.activationsFilter.dateTo,
                bin: BillingFilter.activationsFilter.bin,
                status: BillingFilter.activationsFilter.status,
                guid: BillingFilter.activationsFilter.guid
            },
            page: filter.page - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
        })
            .then(() => setPageLoading(false));
    };

    const handlePageChange = (pageNumber) => {
        setPageLoading(true);

        setFilter({
            ...filter,
            page: pageNumber
        });

        refetch({
            filter: {
                dateFrom: BillingFilter.activationsFilter.dateFrom,
                dateTo: BillingFilter.activationsFilter.dateTo,
                bin: BillingFilter.activationsFilter.bin,
                status: BillingFilter.activationsFilter.status,
                guid: BillingFilter.activationsFilter.guid
            },
            page: pageNumber- 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => setPageLoading(false));
    };

    const pageSizeHandler = (e) => {
        setPageLoading(true);

        setFilter({
            ...filter,
            size: parseInt(e.target.value),
            page: 1
        });

        BillingFilter.setActivationsFilter('size', parseInt(e.target.value));
        setSearchParams({
            dateFrom: BillingFilter.activationsFilter.dateFrom,
            dateTo: BillingFilter.activationsFilter.dateTo,
            bin: BillingFilter.activationsFilter.bin ? BillingFilter.activationsFilter.bin : '',
            status: BillingFilter.activationsFilter.status ? BillingFilter.activationsFilter.status : '',
            guid: BillingFilter.activationsFilter.guid ? BillingFilter.activationsFilter.guid : '',
            size: BillingFilter.activationsFilter.size ? BillingFilter.activationsFilter.size : e.target.value
        });
        
        refetch({
            filter: {
                dateFrom: BillingFilter.activationsFilter.dateFrom,
                dateTo: BillingFilter.activationsFilter.dateTo,
                bin: BillingFilter.activationsFilter.bin,
                status: BillingFilter.activationsFilter.status,
                guid: BillingFilter.activationsFilter.guid
            },
            page: 0,
            size: e.target.value,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => setPageLoading(false));
    };

    useEffect(() => {
        if (data){
            setTerminalActivations(data.activationsPageable.data);
            setTotalItems(data.activationsPageable.total)
        }
    }, [data, filter]);
    
    let dateFrom = searchParams.get("dateFrom");
    let dateTo = searchParams.get("dateTo");
    let bin = searchParams.get("bin");
    let status = searchParams.get("status");
    let guid = searchParams.get("guid");
    let size = searchParams.get("size");

    useEffect(() => {
        BillingFilter.cleanActivationsFilter();
        
        if (dateFrom || dateTo || bin || bin || status || guid || size) {
            if (dateFrom) BillingFilter.setActivationsFilter('dateFrom', dateFrom);
            if (dateTo) BillingFilter.setActivationsFilter('dateTo', dateTo);
            if (bin) BillingFilter.setActivationsFilter('bin', bin);
            if (status) BillingFilter.setActivationsFilter('status', status);
            if (guid) BillingFilter.setActivationsFilter('guid', guid);
            if (size) {
                BillingFilter.setActivationsFilter('size', size);
                setFilter({...filter, size});
            }

            refetch({
                filter: {
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                    bin: bin ? bin : null,
                    status: status ? status : null,
                    guid: guid ? guid : null
                },
                page: 0,
                size: filter.size,
                sortBy: filter.sortBy,
                sortOrder: filter.sortOrder
            })
                .then(() => {
                    setPageLoading(false);
                })
        } else {
            BillingFilter.setActivationsFilter('dateFrom', `${date.slice(0, 11)}00:00`);
            BillingFilter.setActivationsFilter('dateTo', `${date.slice(0, 11)}23:59`);
            BillingFilter.setActivationsFilter('bin', null);
            BillingFilter.setActivationsFilter('status', null);
            BillingFilter.setActivationsFilter('guid', null);
            BillingFilter.setActivationsFilter('size', 25);
        }
    }, [filter.size]);

    const columns = [
        {title: '#', id: '#'},
        {title: 'Id', id: 'id'},
        {title: 'Дата создания', id: 'createdDate'},
        {title: 'Сумма', id: 'amount'},
        {title: 'БИН', id: 'bin'},
        {title: 'Статус', id: 'status'},
        {title: 'GUID', id: 'guid'},
        {title: 'Транзакции', id: 'transactions'},
        { title: 'Действия', id: 'actions'},
    ];

    const rowsItem = [
        {title: '#',},
        {title: 'id'},
        {title: 'createdDate'},
        {title: 'amount'},
        {title: 'bin'},
        {title: 'status'},
        {title: 'guid'},
        {title: 'transactions'},
    ];

    if (loading || pageLoading) return <Loader/>

    return (
        <div className={"TerminalActivationReport"}>
            <div className="breadcrumb_wrapper">
                <Breadcrumb>
                    <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Отчет по активации терминалов (биллинг)</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <hr/>
            <Form onSubmit={e => {
                e.preventDefault();
                setPageLoading(true);
                
                setSearchParams({
                    dateFrom: BillingFilter.activationsFilter.dateFrom,
                    dateTo: BillingFilter.activationsFilter.dateTo,
                    bin: BillingFilter.activationsFilter.bin ? BillingFilter.activationsFilter.bin : '',
                    status: BillingFilter.activationsFilter.status ? BillingFilter.activationsFilter.status : '',
                    guid: BillingFilter.activationsFilter.guid ? BillingFilter.activationsFilter.guid : '',
                    size: BillingFilter.activationsFilter.size ? BillingFilter.activationsFilter.size : 25
                });
                
                setFilter({
                    ...filter,
                    page: 1
                });
                
                refetch({
                    filter: {
                        dateFrom: BillingFilter.activationsFilter.dateFrom,
                        dateTo: BillingFilter.activationsFilter.dateTo,
                        bin: BillingFilter.activationsFilter.bin,
                        status: BillingFilter.activationsFilter.status,
                        guid: BillingFilter.activationsFilter.guid
                    },
                    page: 0,
                    size: filter.size,
                    sortBy: filter.sortBy,
                    sortOrder: filter.sortOrder
                })
                    .then(() => setPageLoading(false));
            }}>
                <div className={'filter_wrapper'}>
                    <ReportDatesFilter
                        dateFrom={BillingFilter.activationsFilter.dateFrom}
                        dateTo={BillingFilter.activationsFilter.dateTo}
                        dateFromChange={(e) => {
                            setSearchParams({...BillingFilter.activationsFilter, dateFrom: e.target.value})
                            BillingFilter.setActivationsFilter('dateFrom', e.target.value );
                        }}
                        dateToChange={(e) => {
                            setSearchParams({...BillingFilter.activationsFilter, dateTo: e.target.value})
                            BillingFilter.setActivationsFilter('dateTo', e.target.value );
                        }}
                    />
                    {/*<Input*/}
                    {/*    className={'filter_item'}*/}
                    {/*    type="text"*/}
                    {/*    id="bin"*/}
                    {/*    name="bin"*/}
                    {/*    placeholder='Бин'*/}
                    {/*    value={BillingFilter.activationsFilter.bin}*/}
                    {/*    onChange={(e) => {*/}
                    {/*        setSearchParams({...BillingFilter.activationsFilter, bin: e.target.value})*/}
                    {/*        BillingFilter.setActivationsFilter('bin', e.target.value );*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <div className={'filter_item'}>
                        <CustomAutocomplete
                            list={merchants.data?.merchants}
                            name={'bin'}
                            setValue={(name, item) => {
                                if (item) {
                                    setSearchParams({...BillingFilter.activationsFilter, bin: item.bin})
                                    BillingFilter.activationsFilter.bin = item.bin
                                } else {
                                    setSearchParams({...BillingFilter.activationsFilter, bin: ''})
                                    BillingFilter.activationsFilter.bin = null
                                }
                            }}
                            placeholder="Наим-ие / Бин мерчанта"
                            searchBy={["name", "bin"]}
                            resultStringKeyName='name'
                            getValueBy={'bin'}
                            edit={true}
                            value={BillingFilter.activationsFilter.bin ? BillingFilter.activationsFilter.bin : null}
                        />
                    </div>
                    <Input
                        className={'filter_item'}
                        type='select'
                        id="status"
                        value={BillingFilter.activationsFilter.status}
                        name='status'
                        onChange={(e) => {
                            setSearchParams({...BillingFilter.activationsFilter, status: e.target.value})
                            BillingFilter.setActivationsFilter('status', e.target.value );  
                        }}
                        style={{color: '#747678'}}
                    >
                        <option value={''}>Выберите статус</option>
                        <option>NEW</option>
                        <option>IN_PROCESSING</option>
                        <option>COMPLETED</option>
                        <option>CANCELED</option>
                    </Input>
                    <div className={'filter_item'}>
                        <CustomAutocomplete
                            list={terminals.data?.terminals}
                            name={'guid'}
                            setValue={(name, item) => {
                                if (item) {
                                    setSearchParams({...BillingFilter.activationsFilter, guid: item.guid});
                                    BillingFilter.setActivationsFilter('guid', item.guid);
                                }
                                else {
                                    setSearchParams({...BillingFilter.activationsFilter, guid: ''});
                                    BillingFilter.setActivationsFilter('guid', null);
                                }
                            }}
                            placeholder="GUID терминала"
                            searchBy={["guid"]}
                            resultStringKeyName='guid'
                            getValueBy={'guid'}
                            edit={true}
                            value={BillingFilter.activationsFilter.guid}
                        />
                    </div>
                    <div className={'buttons_wrapper'}>
                        <Button color={'primary'} type={'submit'} className="filter_button"
                                style={{width: 100, marginRight: 20}}>Поиск</Button>
                        <Button color={'danger'} style={{width: 100}} className="filter_button" onClick={() => {
                            setPageLoading(true);
                            setSearchParams({
                                dateFrom: `${date.slice(0, 11)}00:00`,
                                dateTo: `${date.slice(0, 11)}23:59`
                            });
                            BillingFilter.cleanActivationsFilter();
                            setFilter({
                                ...filter,
                                size: 25
                            })
                            refetch({
                                filter: {
                                    dateFrom: `${date.slice(0, 11)}00:00`,
                                    dateTo: `${date.slice(0, 11)}23:59`,
                                    bin: null,
                                    status: null,
                                    guid: null
                                },
                                page: 0,
                                size: filter.size,
                                sortBy: filter.sortBy
                            })
                                .then(() => {
                                    setPageLoading(false);
                                })
                        }}>Сбросить</Button>
                    </div>
                </div>
            </Form>
            <br/>
            <hr/>

            <DataTable
                 notStripped={true}
                 hideColumns={[]}
                 columns={columns}
                 rows={terminalActivations}
                 rowsItem={rowsItem}
                 merchants={[]}
                 terminals={terminals.data ? terminals.data.terminals : []}
                 sortBy={filter.sortBy}
                 sortByHandler={(e) => sortByHandler(e)}
                 itemsCountPerPage={filter.size}
                 sortOrder={filter.sortOrder}
                 pageSizeHandler={(e) => pageSizeHandler(e)}
                 totalItemsCount={totalItems || 0}
                 activePage={filter.page}
                 handlePageChange={(pageNumber) => handlePageChange(pageNumber)}
                 isPaginated={true}
                 showCancelInvoice={true}
                 showDeactivate={true}
            />
        </div>
    );
};

export default TerminalActivationReport;