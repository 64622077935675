import React from 'react';
import QRCode from 'qrcode.react';
import {Button} from "reactstrap";
// import kaspiImg from '../../assets/kaspi.svg';

const QrComponent = ({terminalTypes, type, value, addrres, merchName, guid}) => {
    
    const getKaspiUrlForQr = (type) => {
        let currentTerminalType = terminalTypes.find(item => parseInt(item.id) === parseInt(type));
        return currentTerminalType.kaspiQrURL + value;
    }
    
    const downloadQRCode = () => {
        console.log('1');
        const canvas = document.getElementById("qr");
        console.log(canvas);
        let ctx = canvas.getContext("2d");
        console.log(ctx);
        ctx.font = "1px serif";
        ctx.fontWeight = 100;
        ctx.fillText(value, 1, 38.75);
        ctx.fillText(addrres, 1, 39.75);
        ctx.fillText(merchName, 1, 40.75);
        console.log('2');
        const pngUrl = canvas
            .toDataURL("image/jpg", 1.0)
            .replace("image/jpg", "image/octet-stream");
        console.log('3');
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        console.log(pngUrl);
        downloadLink.download = `${value}.jpg`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    
    return (
        <div style={{ height: 1080, textAlign: 'center'}}>
            <span style={{fontWeight: 600}}>GUID - {value}</span>
            <QRCode
                id={'qr'}
                value={getKaspiUrlForQr(type)}
                size={1000}
                // level={'H'}
                includeMargin={true}
                imageSettings={{
                    // src: 'https://s3.eu-central-1.amazonaws.com/kaspi-admin.multibankonline.net/images/kaspi.svg',
                    // src: kaspiImg,
                    src: 'kaspi.svg',
                    width: 150,
                    height: 150,
                    excavate: true
                }}
                style={{border: '10px solid white'}}
            />
            <div className={'qr_btns_wrapper'}>
                <Button color={'success'} type="button" onClick={downloadQRCode}>Сохранить</Button>
                <a href={`http://kaspi-qr.multibank.kz/?editable=false&guid=${guid}&merchantName=${merchName}&address=${addrres}`} rel="noopener noreferrer" target={'_blank'}>
                    <Button color={'primary'} type="button">Генерация PDF</Button>
                </a>
            </div>
        </div>
    );
};
    
export default QrComponent;