import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {GET_SERVICE, GET_SERVICES} from "../../GraphQL/Queries";
import Loader from "../../components/UI/Loader/Loader";
import {Breadcrumb, BreadcrumbItem, Button, Col, Form, FormFeedback, Input, Label, Row} from "reactstrap";
import {UPDATE_SERVICE} from "../../GraphQL/Mutations";
import {toast} from "react-toastify";
import {useFormik} from "formik";
import {CreateServiceSchema} from "../../schemas";
import Filter from "../../store/Filter";

const ServiceEdit = () => {
    const [clicked, setClicked] = useState(false);

    const params = useParams();
    const [updateService] = useMutation(UPDATE_SERVICE, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_SERVICES, variables: { sortBy: 'id'}}],
    });
    const navigate = useNavigate();
    const [service, setService] = useState({});
    const [isBlocked, setIsBlocked] = useState(true);

    const { loading, error, data } = useQuery(GET_SERVICE, { variables: {
            id: params.id
        }}
    );

    const formik = useFormik({
        initialValues: service,
        enableReinitialize: true,
        validationSchema: CreateServiceSchema,
        onSubmit: (values) => {
            setClicked(true);

            toast.promise(
                updateService({variables: {
                        service: {
                            id: service.id,
                            nameRus: values.nameRus,
                            nameKaz: values.nameKaz,
                            amount: values.amount,
                            descriptionRus: values.descriptionRus,
                            descriptionKaz: values.descriptionKaz
                        }
                    }}), {
                    pending: 'В обработке',
                    success: 'Сервис успешно обновлен',
                    error: 'Ошибка'
                }
            )
                .then(result => {
                    navigate(`/services?name=${Filter.servicesFilter.name}&size=${Filter.servicesFilter.size}`);
                })
                .catch(err => {
                    setClicked(false);
                })
        }
    });

    useEffect(() => {
        if (data && data.service) {
            setService(data.service);
        }
    },[data]);

    if (loading) return <Loader/>

    return (
        <div>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to='/services' className="breadcrumb_link">Услуги</Link></BreadcrumbItem>
                <BreadcrumbItem active>{data.service.nameRus}</BreadcrumbItem>
            </Breadcrumb>

            <h3 className="main_title">Редактирование сервиса</h3>

            <Form onSubmit={formik.handleSubmit}>
                <Row className='row_form'>
                    <Col xs={4}><Label for="name">ID</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            value={service.id}
                            disabled
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="name">Дата создания</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            value={service.creationDate ? (service.creationDate).slice(0, 19) : new Date(service.creationDate).toLocaleString()}
                            disabled
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="name">Дата изменения</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            value={service.changeDate ? (service.changeDate).slice(0, 19) : new Date(service.changeDate).toLocaleString()}
                            disabled
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="nameRus">Наименование (рус)</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="nameRus"
                            id="nameRus"
                            disabled={isBlocked}
                            value={formik.values.nameRus}
                            onChange={formik.handleChange}
                            invalid={formik.touched.nameRus && Boolean(formik.errors.nameRus)}
                        />
                        <FormFeedback>
                            {formik.touched.nameRus && formik.errors.nameRus}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="nameKaz">Наименование (каз)</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="nameKaz"
                            id="nameKaz"
                            disabled={isBlocked}
                            value={formik.values.nameKaz}
                            onChange={formik.handleChange}
                            invalid={formik.touched.nameKaz && Boolean(formik.errors.nameKaz)}
                        />
                        <FormFeedback>
                            {formik.touched.nameKaz && formik.errors.nameKaz}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="descriptionRus">Описание (рус)</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="descriptionRus"
                            id="descriptionRus"
                            disabled={isBlocked}
                            value={formik.values.descriptionRus}
                            onChange={formik.handleChange}
                            invalid={formik.touched.descriptionRus && Boolean(formik.errors.descriptionRus)}
                        />
                        <FormFeedback>
                            {formik.touched.descriptionRus && formik.errors.descriptionRus}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="descriptionKaz">Описание (каз)</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="descriptionKaz"
                            id="descriptionKaz"
                            disabled={isBlocked}
                            value={formik.values.descriptionKaz}
                            onChange={formik.handleChange}
                            invalid={formik.touched.descriptionKaz && Boolean(formik.errors.descriptionKaz)}
                        />
                        <FormFeedback>
                            {formik.touched.descriptionKaz && formik.errors.descriptionKaz}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="amount">Сумма</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="amount"
                            id="amount"
                            disabled={isBlocked}
                            value={formik.values.amount}
                            onChange={formik.handleChange}
                            invalid={formik.touched.amount && Boolean(formik.errors.amount)}
                        />
                        <FormFeedback>
                            {formik.touched.amount && formik.errors.amount}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}>
                        <Button color='dark' className={'crud_button'} onClick={() => navigate(-1)}>Назад</Button>
                    </Col>
                        {isBlocked
                            ? <Col xs={4}><Button
                                color="success"
                                className="crud_button"
                                onClick={() => setIsBlocked(false)}>
                                Редактировать
                            </Button></Col>
                            : <>
                                <Col xs={4}><Button
                                    type="button"
                                    color="danger"
                                    className="crud_button"
                                    onClick={() => {
                                        setIsBlocked(true);
                                        setService(data.service);
                                    }}
                                >
                                    Отмена
                                </Button></Col>
                                <Col xs={4}><Button type="submit" color="primary" className="crud_button mr" disabled={clicked}>
                                    Сохранить
                                </Button></Col>
                            </>
                        }
                </Row>
            </Form>
        </div>
    );
};

export default ServiceEdit;
