import React, {useEffect, useState} from 'react';
import {Button, Form} from "reactstrap";
import {useNavigate, Link, useSearchParams} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {useQuery} from "@apollo/client";
import {FILTER_SERVICES, GET_SERVICES} from "../../GraphQL/Queries";
import Loader from "../../components/UI/Loader/Loader";
import {DataTable} from "../../components/DataTable/DataTable";
import CustomAutocomplete from "../../components/CustomAutocomplete/CustomAutocomplete";
import Filter from "../../store/Filter";

const Services = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [filter, setFilter] = useState({
        page: 1,
        size: 25,
        sortBy: 'id',
        sortOrder: 'asc'
    });

    const [pageLoading, setPageLoading] = useState(false);

    const {loading, error, data, refetch} = useQuery(FILTER_SERVICES, {
            variables: {
                page: 0,
                size: filter.size,
                sortBy: 'id',
                sortOrder: 'asc'
            }
        }
    );

    const allServices = useQuery(GET_SERVICES, {
            variables: {
                sortBy: 'id'
            }
        }
    );

    const handlePageChange = (pageNumber) => {
        setPageLoading(true);
        setFilter({
            ...filter,
            page: pageNumber
        });

        refetch({
            filter: {
                name: Filter.servicesFilter.name ? Filter.servicesFilter.name : null,
            },
            page: pageNumber - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const sortByHandler = (e) => {
        setPageLoading(true);
        if (filter.sortBy === e.target.id) {
            setFilter({
                ...filter,
                sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
            });
        } else {
            setFilter({
                ...filter,
                sortBy: e.target.id
            });
        }

        refetch({
            filter: {
                name: Filter.servicesFilter.name ? Filter.servicesFilter.name : null,
            },
            page: filter.page - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const pageSizeHandler = (e) => {
        setPageLoading(true);
        setFilter({
            ...filter,
            size: parseInt(e.target.value),
            page: 1
        });
        Filter.setServicesFilter('size', parseInt(e.target.value));
        setSearchParams({...Filter.servicesFilter, size: parseInt(e.target.value)});
        refetch({
            filter: {
                name: Filter.servicesFilter.name ? Filter.servicesFilter.name : null,
            },
            page: 0,
            size: parseInt(e.target.value),
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => {
                setPageLoading(false)
            })
    };

    const [services, setServices] = useState([]);
    const [totalItems, setTotalItems] = useState();

    useEffect(() => {
        if (data) {
            setServices(data.servicesPage.data)
            setTotalItems(data.servicesPage.total)
        }
    }, [data]);

    let name = searchParams.get("name");
    let size = searchParams.get("size");

    useEffect(() => {
        if (name || size) {
            if (name) Filter.setServicesFilter('name', name)
            if (size) {
                Filter.setServicesFilter('size', size);
                setFilter({...filter, size});
            }

            refetch({
                filter: {
                    name: name || null,
                },
                page: 0,
                size: filter.size,
                // size: Filter.servicesFilter.size,
                sortBy: filter.sortBy,
                sortOrder: filter.sortOrder
            })
        } else {
            Filter.setServicesFilter('size', 25);
            Filter.setServicesFilter('name', '');
        }
    }, [Filter.servicesFilter.size, filter.size]);

    const columns = [
        {title: '#', id: '#'},
        {title: 'Id', id: 'id'},
        {title: 'Дата создания', id: 'creationDate'},
        {title: 'Дата изменения', id: 'changeDate'},
        {title: 'Наименование (рус)', id: 'nameRus'},
        {title: 'Наименование (каз)', id: 'nameKaz'},
        {title: 'Сумма (тг)', id: 'amount'},
        {title: 'Описание (рус)', id: 'descriptionRus'},
        {title: 'Описание (каз)', id: 'descriptionKaz'}
    ];

    const rowsItem = [
        {title: '#'},
        {title: 'id'},
        {title: 'creationDate'},
        {title: 'changeDate'},
        {title: 'nameRus'},
        {title: 'nameKaz'},
        {title: 'amount'},
        {title: 'descriptionRus'},
        {title: 'descriptionKaz'},
    ];

    if (loading || allServices.loading) return <Loader/>

    return (
        <div>
            <div className="breadcrumb_wrapper">
                <Breadcrumb>
                    <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Услуги</BreadcrumbItem>
                </Breadcrumb>
                <Button
                    color="dark"
                    className="add_btn"
                    onClick={() => navigate('/services/new')}
                >
                    + Добавить
                </Button>
            </div>
            <hr/>
            <Form onSubmit={(e) => {
                e.preventDefault();
                setPageLoading(true);
                setFilter({
                    ...filter,
                    page: 1,
                })
                refetch({
                    filter: {
                        name: Filter.servicesFilter.name ? Filter.servicesFilter.name : null,
                    },
                    page: 0,
                    size: filter.size,
                    sortBy: filter.sortBy,
                    sortOrder: filter.sortOrder
                })
                    .then(() => {
                        setPageLoading(false);
                    })
            }}>
                <div className={'filter_wrapper'}>
                    <div className={'filter_item'}>
                        <CustomAutocomplete
                            list={allServices.data?.services}
                            name={'service'}
                            setValue={(name, item) => {
                                if (item) {
                                    setSearchParams({...Filter.servicesFilter, name: item.nameRus})
                                    Filter.servicesFilter.name = item.nameRus
                                } else {
                                    setSearchParams({...Filter.servicesFilter, name: ''})
                                    Filter.servicesFilter.name = null
                                }
                            }}
                            placeholder="Наименование / Бин мерчанта"
                            searchBy={["nameRus"]}
                            resultStringKeyName='nameRus'
                            getValueBy={'nameRus'}
                            edit={true}
                            value={Filter.servicesFilter.name || null}
                        />
                    </div>
                    <div className={'filter_item'}>
                        <Button color={'primary'} type={'submit'} className="filter_button"
                                style={{width: 100, marginRight: 20}}>Поиск</Button>
                        <Button color={'danger'} style={{width: 100}} className="filter_button" onClick={() => {
                            setPageLoading(true);
                            setSearchParams('');
                            Filter.cleanServicesFilter();
                            setFilter({
                                ...filter,
                                size: 25
                            })
                            refetch({
                                filter: {
                                    name: null,
                                },
                                page: filter.page - 1,
                                size: filter.size,
                                sortBy: filter.sortBy
                            })
                                .then(() => {
                                    setPageLoading(false)
                                })
                        }}>Сбросить</Button>
                    </div>
                </div>
            </Form>
            <hr/>
            <DataTable
                hideColumns={['changeDate', 'nameKaz', 'descriptionRus', 'descriptionKaz']}
                columns={columns}
                rows={services}
                rowsItem={rowsItem}
                link={'services'}
                sortBy={filter.sortBy}
                sortByHandler={(e) => sortByHandler(e)}
                itemsCountPerPage={filter.size}
                sortOrder={filter.sortOrder}
                pageSizeHandler={(e) => pageSizeHandler(e)}
                totalItemsCount={totalItems}
                activePage={filter.page}
                handlePageChange={(pageNumber) => handlePageChange(pageNumber)}
                isPaginated={true}
                hideIcons={true}
            />
            {pageLoading
                ? <Loader/>
                : null
            }
        </div>
    );
};

export default Services;
