import React, {useState} from 'react'
import {Table, Button, Modal, Row, Label, Col, Input, Form} from 'reactstrap';
import CustomPagination from '../UI/Pagination/CustomPagination';
import './DataTable.css';
import {resolveRowValue} from '../../services/utils/resolveRowValue';
import TableIcons from "../UI/TableIcons/TableIcons";
import {v4 as uuidv4} from 'uuid';
import {toast} from "react-toastify";
import Switch from "react-switch";
import {getStatusColor} from "../../services/utils/getStatusColor";

export const DataTable = ({
                              showDeactivate,
                              toggleAllActive,
                              updateGuids,
                              updateCheckboxHandler,
                              updateAllCheckboxHandler,
                              updateAction,
                              updateHandler,
                              deleteAction,
                              deleteHandler,
                              showCheckBoxes,
                              billingInvoiceStatuses,
                              showEditPhone,
                              paymentSystems,
                              cancelInvoiceHandler,
                              showCancelInvoice,
                              showInvoiceCreater,
                              showDuplicate,
                              notStripped,
                              hideColumns,
                              showSettings,
                              terminalTypes,
                              hideIcons,
                              showQr,
                              refundHandler,
                              refundBtn,
                              columns,
                              rows,
                              rowsItem,
                              terminals,
                              services,
                              totalItemsCount,
                              activePage,
                              handlePageChange,
                              sortBy,
                              sortOrder,
                              sortByHandler,
                              pageSizeHandler,
                              itemsCountPerPage,
                              isPaginated,
                              link,
                              merchants,
                              serviceProfiles,
                              invoiceTypesData,
                              showRefundKaspi,
                              billing,
    biilingTerminal
                          }) => {
    const [showModal, setShowModal] = useState(false);
    const [hide, setHide] = useState(true);
    const [refundInfo, setRefundInfo] = useState({
        amount: 0,
        externalId: '',
        requestId: ''
    });

    const [fileIds, setFileIds] = useState([]);

    const toggleFileId = (id) => {
        let ids = [...fileIds];
        let index = ids.indexOf(id);
        if (index === -1) ids.push(id);
        else ids.splice(index, 1);
        setFileIds(ids)
    }

    const getInvoiceStatusColor = (status) => {
        let stat = billingInvoiceStatuses.find((statuses) => statuses.status === status);
        return stat.color;
    };

    const [maxAmount, setMaxAmount] = useState(0);

    return (
        <div>
            <div className='top_wrap' style={{flexDirection: hideColumns.length > 0 ? 'row' : 'row-reverse'}}>
                {hideColumns.length > 0
                    ? <Switch
                        onChange={() => setHide(!hide)}
                        checked={hide}
                        className="react-switch"
                    />
                    : null
                }
                {deleteAction
                    ? <Button color={'danger'} disabled={fileIds.length === 0}
                              onClick={() => deleteHandler(fileIds)}>Удалить</Button>
                    : null
                }
                {updateHandler
                    ? <Button color={"dark"} disabled={updateGuids.length === 0}
                              onClick={() => updateHandler()}>Назначить</Button>
                    : null
                }
            </div>
            <Table striped={!notStripped} className="Data_table" size={'sm'}>
                <thead>
                <tr>
                    {
                        columns.map(title => {
                            if (hide && hideColumns.includes(title.id)) return
                            return <th key={title.title} id={title.id}
                                       className={title.id === sortBy ? 'tableHead tableItem' : 'tableItem'}
                                       onClick={(e) => sortByHandler(e)}>
                                {title.title} {title.id === 'actions' && updateAction ? <Input
                                type="checkbox"
                                id={'all'}
                                onChange={() => updateAllCheckboxHandler()}
                                checked={toggleAllActive}
                            /> : null}
                                {sortOrder
                                    ? title.id === sortBy ? (sortOrder === 'asc' ? '↓' : '↑') : null
                                    : null
                                }
                            </th>
                        })
                    }
                </tr>
                </thead>
                <tbody>
                {
                    rows.map((row, i) => {
                        return <tr key={row.id}
                                   style={{backgroundColor: billingInvoiceStatuses ? getInvoiceStatusColor(row.status) : getStatusColor(row.status)}}>
                            {
                                rowsItem.map(item => {
                                    if (hide && hideColumns.includes(item.title)) return
                                    return <td key={item.title} className={'tableItem'}>
                                        {resolveRowValue(row, i, item, terminals, services, link, merchants, serviceProfiles, terminalTypes, paymentSystems, billingInvoiceStatuses, billing)}
                                    </td>
                                })
                            }
                            {refundBtn
                                ? <td>
                                    <Button className={'refund_button'} color="secondary" onClick={() => {
                                        setRefundInfo({
                                            ...refundInfo,
                                            externalId: row.externalId,
                                            requestId: uuidv4(),
                                            amount: row.creditAmount
                                        });
                                        setMaxAmount(row.creditAmount);
                                        setShowModal(true)
                                    }
                                    }
                                    >
                                        Возврат
                                    </Button>
                                </td>
                                : null
                            }
                            {showCheckBoxes
                                ? <td>
                                    <Input
                                        type="checkbox"
                                        id={row.id}
                                        checked={updateAction ? updateGuids.includes(row.guid) : fileIds.includes(row.id)}
                                        onChange={updateAction ? () => updateCheckboxHandler(row.guid) : () => toggleFileId(row.id)}
                                    />
                                </td>
                                : null
                            }
                            {
                                hideIcons ?
                                    null :
                                    <td>
                                        <TableIcons
                                            showInvoiceCreater={showInvoiceCreater}
                                            showDuplicate={showDuplicate}
                                            id={row.id}
                                            bin={row.bin}
                                            showQr={showQr}
                                            showSettings={showSettings}
                                            terminalTypes={terminalTypes}
                                            type={row.typeId}
                                            transactionType={row.type}
                                            guid={row.guid}
                                            address={row.address}
                                            merchName={showQr ? merchants?.find((merch) => parseInt(merch.id) === parseInt(row.merchantId)).name : ''}
                                            showCancelInvoice={showCancelInvoice ? (row.status === "NEW" || row.status === "PAST_DUE" || row.status === "PARTIALLY_PAID") : false}
                                            showRefundKaspi={showRefundKaspi ? (row.type === "Kaspi") : false}
                                            cancelInvoiceHandler={(id, comment) => cancelInvoiceHandler(id, comment)}
                                            invoiceId={row.id}
                                            showEditPhone={showEditPhone}
                                            invoiceTypesData={invoiceTypesData}
                                            refunds={row.refunds}
                                            transactionAmount={row.amount}
                                            refundedAmount={row.refundedAmount}
                                            terminalActivity={row.active}
                                            billingTerminal={biilingTerminal}
                                            activationId={row.id}
                                            showDeactivate={showDeactivate}
                                        />
                                    </td>
                            }
                        </tr>
                    })
                }
                </tbody>
            </Table>
            {isPaginated
                ? <CustomPagination
                    totalItemsCount={totalItemsCount}
                    itemsCountPerPage={itemsCountPerPage}
                    activePage={activePage}
                    pageSizeHandler={(e) => pageSizeHandler(e)}
                    rowsLength={rows.length}
                    page={2}
                    changePage={() => console.log('cangePage handler')}
                    handlePageChange={(pageNumber) => handlePageChange(pageNumber)}
                />
                : null
            }
            {/*TODO  переделать refund*/}
            <Modal
                isOpen={showModal}
                centered={true}
                fullscreen="xl"
                size="lg"
                toggle={() => {
                    setShowModal(!showModal);
                    setRefundInfo({
                        amount: 0,
                        externalId: '',
                        requestId: ''
                    })
                }
                }
            >
                <Form onSubmit={(e) => {
                    e.preventDefault()

                    if (parseInt(refundInfo.amount) > parseInt(maxAmount)) {
                        toast('Сумма отмены не может быть больше суммы остатка или платежа.')
                    } else if (parseInt(refundInfo.amount) < 1) {
                        toast('Сумма отмены не может быть меньше 1.')
                    } else {
                        refundHandler(refundInfo.externalId, refundInfo.amount, refundInfo.requestId);
                    }
                }}>
                    <Row style={{height: 100, textAlign: 'center', padding: '30px 0'}}>
                        <Col xs={3}>
                            <Label style={{marginTop: 7}} for={'amount'}>Сумма возврата</Label>
                        </Col>
                        <Col xs={5}>
                            <Input
                                id={'amount'}
                                placeholder={"Введите сумму возврата"}
                                required={true}
                                value={refundInfo.amount}
                                onChange={(e) => setRefundInfo({...refundInfo, amount: e.target.value})}
                            />
                        </Col>
                        <Col xs={3}>
                            <Button className={'refund_button'} color={'danger'} type={'submit'}>
                                Возврат
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
};
