let devConfig = {
    identityPoolId: 'us-west-2:7f7f9718-ea85-4d35-aab9-2cec1b1a3fb6', 
    region: 'us-west-2',
    identityPoolRegion: 'us-west-2',
    userPoolId: 'us-west-2_GX1BMpJ3z',
    userPoolWebClientId: '1r3nh0iu646b93jmq7pj5p9ik2'
};

let prodConfig = {
    identityPoolId: 'eu-central-1:fe769607-39ba-4baf-8532-d89e6ee0cd4a',
    region: 'eu-central-1',
    identityPoolRegion: 'eu-central-1',
    userPoolId: 'eu-central-1_79oWA9fut',
    userPoolWebClientId: '4ejd97qcbelb0qfjlcq1in0i5o'
};

const awsconfig = {
    Auth: process.env.REACT_APP_NODE_ENV === "prod" ? prodConfig : devConfig
 };
export default awsconfig;
