import {makeAutoObservable} from "mobx";
import axios from '../axios-api';
import config from "../config";

class DeviceLogStore {

    constructor() {
        makeAutoObservable(this);
    };
    
    getLogs = (guid, startdate, enddate, limit, contains) => {
        return axios.post(`${config.logsURL}/logs/${guid}`, {
            startdate: startdate,
            enddate: enddate,
            limit: limit,
            contains: contains
        })
            .then(result => {
                return result.data
            })
            .catch(err => {
                return err
            })
    };
};

export default new DeviceLogStore();