import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

const Main = () => {
    return (
        <div>
            <Breadcrumb>
                <BreadcrumbItem active>
                    Главная
                </BreadcrumbItem>
            </Breadcrumb>
            Main page
        </div>
    );
};

export default Main;