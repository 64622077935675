import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useNavigate, Link} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem, Button, Col, Form, FormFeedback, Input, Label, Row} from 'reactstrap';
import {useMutation, useQuery} from "@apollo/client";
import {FILTER_MERCHANTS, GET_MERCHANT} from "../../GraphQL/Queries";
import Loader from "../../components/UI/Loader/Loader";
import {UPDATE_MERCHANT} from "../../GraphQL/Mutations";
import {toast} from "react-toastify";
import InputMask from 'react-input-mask';
import {useFormik} from "formik";
import {CreateMerchantSchema} from "../../schemas";
import Filter from "../../store/Filter";

const Merchant = () => {
    const [clicked, setClicked] = useState(false);

    const params = useParams();
    
    const [updateMerchant] = useMutation(UPDATE_MERCHANT, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: FILTER_MERCHANTS, variables: { sortBy: 'id', page: 1, size: 10, sortOrder: 'asc' }}],
    });
    
    const navigate = useNavigate();
    
    const [merchant, setMerchant] = useState({
        name: '',
        phone: '',
        bin: '',
        helpDeskPhone: ''
    });
    
    const [isBlocked, setIsBlocked] = useState(true);
    
    const { loading, error, data } = useQuery(GET_MERCHANT, { variables: {
            id: params.id
        }}
    );

    const formik = useFormik({
        initialValues: merchant,
        enableReinitialize: true,
        validationSchema: CreateMerchantSchema,
        onSubmit: (values) => {
            setClicked(true);

            toast.promise(
                updateMerchant({
                    variables: {
                        merchant: {
                            id: merchant.id,
                            name: values.name,
                            phone: values.phone,
                            bin: values.bin,
                            helpDeskPhone: values.helpDeskPhone
                        }
                    }}),
                {
                    pending: 'В обработке',
                    success: 'Мерчант успешно обновлен',
                    error: 'Ошибка'
                }
            )
                .then(result => {
                    
                    navigate(`/merchants?name=${Filter.merchantsFilter.name}&bin=${Filter.merchantsFilter.bin}&size=${Filter.merchantsFilter.size}`);
                })
                .catch(err => {
                    setClicked(false);
                })
        }
    });

    
    useEffect(() => {
        
        if (data && data.merchant) {

            setMerchant(data.merchant);
        }
    },[data]);
    
    if (loading) return <Loader/>
    
    return (
        <div>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to='/merchants' className="breadcrumb_link">Мерчанты</Link></BreadcrumbItem>
                <BreadcrumbItem active>{data.merchant.name}</BreadcrumbItem>
            </Breadcrumb>

            <h3 className="main_title">Редактирование мерчанта</h3>
            
            <Form onSubmit={formik.handleSubmit}>
                <Row className='row_form'>
                    <Col xs={4}><Label for="name">ID</Label></Col>
                    <Col xs={4}>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            value={merchant.id}
                            disabled
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="name">Дата создания</Label></Col>
                    <Col xs={6}>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            value={merchant.creationDate ? (merchant.creationDate).slice(0, 19) : new Date(merchant.creationDate).toLocaleString()}
                            disabled
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="name">Дата изменения</Label></Col>
                    <Col xs={6}>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            value={merchant.creationDate ? (merchant.creationDate).slice(0, 19) : new Date(merchant.changeDate).toLocaleString()}
                            disabled
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="name">Наименование</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            value={formik.values.name}
                            disabled={isBlocked}
                            onChange={formik.handleChange}
                            invalid={formik.touched.name && Boolean(formik.errors.name)}
                        />
                        <FormFeedback>
                            {formik.touched.name && formik.errors.name}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="phone">Номер</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="tel"
                            mask="+7(999)999-99-99"
                            maskChar="_"
                            name="phone"
                            id="phone"
                            pattern={'^(\\+\\d{1})\\(\\d{3}\\)\\d{3}[\\s.-]\\d{2}[\\s.-]\\d{2}$'}
                            title={'+7(XXX)XXX-XX-XX'}
                            disabled={isBlocked}
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            tag={InputMask}
                            invalid={formik.touched.phone && Boolean(formik.errors.phone)}
                        />
                    </Col>
                    <FormFeedback>
                        {formik.touched.phone && formik.errors.phone}
                    </FormFeedback>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="bin">БИН</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="bin"
                            id="bin"
                            disabled={true}
                            value={formik.values.bin}
                            onChange={formik.handleChange}
                            invalid={formik.touched.bin && Boolean(formik.errors.bin)}
                        />
                        <FormFeedback>
                            {formik.touched.bin && formik.errors.bin}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="helpDeskPhone">Номер тех. поддержки</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="tel"
                            mask="+7(999)999-99-99"
                            maskChar="_"
                            name="helpDeskPhone"
                            id="helpDeskPhone"
                            pattern={'^(\\+\\d{1})\\(\\d{3}\\)\\d{3}[\\s.-]\\d{2}[\\s.-]\\d{2}$'}
                            title={'+7(XXX)XXX-XX-XX'}
                            disabled={isBlocked}
                            value={formik.values.helpDeskPhone}
                            onChange={formik.handleChange}
                            tag={InputMask}
                            invalid={formik.touched.helpDeskPhone && Boolean(formik.errors.helpDeskPhone)}
                        />
                        <FormFeedback>
                            {formik.touched.helpDeskPhone && formik.errors.helpDeskPhone}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                        <Col xs={4}><Button color='dark' className={'crud_button'} onClick={() => navigate(-1)}>Назад</Button></Col>
                        {isBlocked 
                            ? <Col xs={4}><Button
                                color="success"
                                className={'crud_button'}
                                onClick={() => setIsBlocked(false)}
                              >
                                Редактировать
                            </Button></Col>
                            : <>
                                <Col xs={4}><Button type="button" color="danger" className={'crud_button'}  onClick={() => {
                                    setIsBlocked(true);
                                    setMerchant(data.merchant);
                                }}>
                                    Отмена
                                </Button></Col>
                                <Col xs={4}><Button type="submit" color="primary" className={'crud_button'}  disabled={clicked}>
                                    Сохранить
                                </Button></Col>
                            </>
                        }
                </Row>
            </Form>
        </div>
    );
};

export default Merchant;