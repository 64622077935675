import React, {useState} from 'react';
import {useNavigate, Link} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem, Form, Button, Col, FormFeedback, Input, Label, Row} from 'reactstrap';
import {useMutation} from "@apollo/client";
import {CREATE_SERVICE_PROFILE} from "../../GraphQL/Mutations";
import {GET_SERVICE_PROFILES} from "../../GraphQL/Queries";
import {useFormik} from "formik";
import {CreateServiceProfileSchema } from "../../schemas";
import {toast} from "react-toastify";

const ServiceProfileNew = () => {
    const [clicked, setClicked] = useState(false);

    const [createServicesProfile] = useMutation(CREATE_SERVICE_PROFILE, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_SERVICE_PROFILES, variables: { sortBy: 'id'}}],
    });
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: ''
        },
        validationSchema: CreateServiceProfileSchema,
        onSubmit: (values) => {
            setClicked(true);
            toast.promise(
                createServicesProfile({
                    variables: {
                        name: values.name
                    }
                }), {
                    pending: 'В обработке',
                    success: 'Профиль сервиса успешно создан',
                    error: 'Ошибка'
                }
            )
                .then(result => {
                    navigate('/service_profiles');
                })
        }
    });

    return (
        <div>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to='/service_profiles' className="breadcrumb_link">Профили услуг</Link></BreadcrumbItem>
                <BreadcrumbItem active>Добавить</BreadcrumbItem>
            </Breadcrumb>
            <h3 className="main_title">Создание профиля услуги</h3>
            <Form onSubmit={formik.handleSubmit}>
                <Row className='row_form'>
                    <Col xs={4}><Label for="name">Наименование</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            invalid={formik.touched.name && Boolean(formik.errors.name)}
                            onChange={formik.handleChange}
                        />
                        <FormFeedback>
                            {formik.touched.name && formik.errors.name}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={12}>
                        <Button type="submit" color="primary" className="crud_button mr" disabled={clicked}>
                            Сохранить
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default ServiceProfileNew;
