import React from 'react';
import './ServiceProfileServices.css';
import {Col, Label, Row} from "reactstrap";
import {Link} from "react-router-dom";

const ServiceProfileServices = ({services}) => {
    return (
        <Row className='Service_profile_services'>
            <Col xs={2}><Label for="name">Сервисы</Label></Col>
            <Col xs={10} className='services_wrapper'>
                {
                    services?.map(service => {
                        return <Link className='service_link' key={service.id} to={`/services/${service.id}`}>
                            {service.nameRus} {service.amount}
                        </Link>
                    })
                }
            </Col>
        </Row>
    );
};

export default ServiceProfileServices;
