import React, {useEffect, useState} from 'react';
import {useParams, useNavigate, Link} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem, Button, Col, Collapse, Form, Input, Label, Row} from "reactstrap";
import Loader from "../../components/UI/Loader/Loader";
import ButtonSwitcher from '../../components/UI/ButtonSwitcher/ButtonSwitcher';
import ControllerSettingsStore from '../../store/ControllerSettingsStore';
import BillsCoinsSettings from '../../components/BillsCoinsSettings/BillsCoinsSettings';
import ProgressBar from '../../components/ProgressBar/ProgressBar';

const ControllerSetings = () => {
    const [clicked, setClicked] = useState(false);
    const params = useParams();
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const [edit, setEdit] = useState(false);
    const [defSettings, setDefSettings] = useState({});
    const [settings, setSettings] = useState({
        addAmount: null,
        alwaysInsert: null,
        log: false,
        fwHash: null,
        fw_set: null,
        last_date: null,
        coinsList: null,
        changedate: null,
        createdate: null,
        dbgLevel: null,
        desc: null,
        file: null,
        fw: null,
        guid: null,
        id: null,
        idSetings: null,
        invert: true,
        itemName: null,
        itemNumber: false,
        mdbVMCnoACK: true,
        period: null,
        secondAddress: true,
        step: null,
        test: null,
        timezone: null,
        width: null,
        work_mode: null,
        creditRequest: false,
        setupInReset: false,
        disabledJustReset: false,
        pinMDB: false,
        exchange: false,
        dbgMask: null,
        exchangePulse: false,
        denyBegin: false,
        amount: null,
        cancelSession: false,
        description: null,
        vctrl: null,
        logInterval: null,
        wasRstAfterRst: null,
        justRstAfterRst: null,
        revalueLimitDeny: null,
        trace: null,
        enabled: null
    });
    
    const [loadingSettings, setLoadingSettings] = useState(false);
    const [files, setFiles] = useState([]);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [showAdditionalSettings, setShowAdditionalSettings] = useState(false);

    const handleChange = (e) => {
        setSettings({
            ...settings,
            [e.target.name]: e.target.value
        })
    };

    const blurBillsCoinsHandler = (title, value) => {
        setSettings({
            ...settings,
            [title]: value
        })
    };

    const handleFileChange = (e) => {
        let currentFile = files.find(item => (item.fw).toString() === (e.target.value).toString());
        console.log(currentFile)
        setSettings({
            ...settings,
            file: currentFile.file,
            fw: currentFile.fw,
            fwHash: currentFile.fwHash
        })
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        setClicked(true);

        ControllerSettingsStore.updateControllerSettings(settings)
            .then((result) => {
                console.log(result);
                navigate(-1);
            })
            .catch(err => {
                console.log(err);
                setClicked(false);
            })
    };
    
    const handleCheckboxChange = (name) => {
        console.log(name);
        console.log(settings[name]);
        setSettings({
            ...settings,
            [name]: settings[name] ? false : true
        })
    };
    
    useEffect(() => {
        setLoadingSettings(true);
        setLoadingFiles(true);
        ControllerSettingsStore.getControllerSettings(params.guid)
            .then((result) => {
                console.log(result.fw)
                setSettings(result);
                setDefSettings(result);
                setLoadingSettings(false);
            })
            .catch(err => {
                console.log(err)
                setError(true);
                setLoadingSettings(false);
            })
        
        ControllerSettingsStore.getFiles()
            .then((result) => {
                setFiles(result);
                setLoadingFiles(false);
            })
            .catch(err => {
                setError(true);
                setLoadingFiles(false);
            })
    }, []);
    
    if (error) return <div>Лена, тебе сюда нельзя</div>
    
    if (loadingSettings || loadingFiles) return <Loader/>
    
    return (
        <div>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to='/terminals' className="breadcrumb_link">Терминалы</Link></BreadcrumbItem>
                <BreadcrumbItem active>Настройки роутера</BreadcrumbItem>
            </Breadcrumb>
            <Form onSubmit={handleSubmit}>
                <Row style={{marginTop: 20}}>
                    <Col xs={4}><Label for={'guid'}>guid</Label></Col>
                    <Col xs={8}>
                        <Input
                            type={'text'}
                            id={'guid'}
                            name={'guid'}
                            value={settings.guid}
                            disabled
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col xs={4}><Label for={'fw_set'}>fw_set</Label></Col>
                    <Col xs={8}>
                        <Input
                            type={'text'}
                            id={'fw_set'}
                            name={'fw_set'}
                            value={settings.fw_set}
                            disabled
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col xs={4}><Label for={'last_date'}>signal</Label></Col>
                    <Col xs={8}>
                        <Input
                            type={'text'}
                            id={'signal'}
                            name={'signal'}
                            value={settings.signal}
                            disabled
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col xs={4}><Label for={'last_date'}>last_date</Label></Col>
                    <Col xs={8}>
                        <Input
                            type={'text'}
                            id={'last_date'}
                            name={'last_date'}
                            value={settings.last_date ? new Date(settings.last_date).toLocaleString() : settings.last_date}
                            disabled
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col xs={4}><Label for={'downloadProgress'}>downloadProgress</Label></Col>
                    <Col xs={3}>
                        <ProgressBar completed={settings.downloadProgress ? settings.downloadProgress : 0} bgColor="#ef6c00"/>
                    </Col>
                </Row>
                <Row style={{marginTop: 20, marginBottom: 20}}>
                    <Col xs={4}><Label for={'work_mode'}>work_mode</Label></Col>
                    <Col xs={8}>
                        <Input
                            type={'select'}
                            id={'work_mode'}
                            name={'work_mode'}
                            value={settings.work_mode}
                            onChange={handleChange}
                            disabled={!edit}
                        >
                            <option value={''}>Выберите work_mode</option>
                            <option value={'pulse'}>pulse</option>
                            <option value={'billvalidator'}>billvalidator</option>
                            <option value={'changer'}>changer</option>
                            <option value={'cashless'}>cashless</option>
                            <option value={'echo'}>echo</option>
                            <option value={'cashless+invoice'}>cashless+invoice</option>
                            <option value={'cashless+changer'}>cashless+changer</option>
                            <option value={'cashless+billvalidator'}>cashless+billvalidator</option>
                            <option value={'cci'}>cci</option>
                            <option value={'cci+invoice'}>cci+invoice</option>
                            <option value={'cctalk'}>cctalk</option>
                            <option value={'eu9'}>eu9</option>
                        </Input>
                    </Col>
                </Row>
                {settings.work_mode?.includes('billvalidator')
                    ?
                        <>
                            <Row style={{marginTop: 20}}>
                                <Col xs={4}><Label for={'billScale'}>billScale</Label></Col>
                                <Col xs={8}>
                                    <Input
                                        type={'text'}
                                        id={'billScale'}
                                        name={'billScale'}
                                        value={settings.billScale}
                                        onChange={handleChange}
                                        disabled={!edit}
                                    />
                                </Col>
                            </Row>
                            <BillsCoinsSettings
                                title={'bills'}
                                itemsList={settings.bills}
                                scale={settings.billScale}
                                disabled={!edit}
                                changeHandler={(title, value) => blurBillsCoinsHandler(title, value)}
                            />
                            <Row style={{marginTop: 20}}>
                                <Col xs={4} style={{marginTop: 5}}><Label for={'alwaysInsert'}>alwaysInsert</Label></Col>
                                <Col xs={8}>
                                    <ButtonSwitcher
                                        id={'alwaysInsert'}
                                        checked={settings.alwaysInsert}
                                        changeHandler={() => handleCheckboxChange('alwaysInsert')}
                                        disabled={!edit}
                                    />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 20}}>
                                <Col xs={4} style={{marginTop: 5}}><Label for={'wasRstAfterRst'}>wasRstAfterRst</Label></Col>
                                <Col xs={8}>
                                    <ButtonSwitcher
                                        id={'wasRstAfterRst'}
                                        checked={settings.wasRstAfterRst}
                                        changeHandler={() => handleCheckboxChange('wasRstAfterRst')}
                                        disabled={!edit}
                                    />
                                </Col>
                            </Row>
                        </>
                    : null
                }
                {settings.work_mode?.includes('pulse')
                    ? 
                        <>
                            <Row style={{marginTop: 20}}>
                                <Col xs={4}><Label for={'width'}>width</Label></Col>
                                <Col xs={8}>
                                    <Input
                                        type={'text'}
                                        id={'width'}
                                        name={'width'}
                                        value={settings.width}
                                        onChange={handleChange}
                                        disabled={!edit}
                                    />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 20}}>
                                <Col xs={4}><Label for={'step'}>step</Label></Col>
                                <Col xs={8}>
                                    <Input
                                        type={'number'}
                                        id={'step'}
                                        name={'step'}
                                        step={'any'}
                                        lang={'en'}
                                        value={settings.step}
                                        onChange={handleChange}
                                        onWheel={ event => event.currentTarget.blur() }
                                        disabled={!edit}
                                    />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 20}}>
                                <Col xs={4}><Label for={'period'}>period</Label></Col>
                                <Col xs={8}>
                                    <Input
                                        type={'text'}
                                        id={'period'}
                                        name={'period'}
                                        value={settings.period}
                                        onChange={handleChange}
                                        disabled={!edit}
                                    />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 20}}>
                                <Col xs={4} style={{marginTop: 5}}><Label for={'invert'}>invert</Label></Col>
                                <Col xs={8}>
                                    <ButtonSwitcher 
                                        id={'invert'}
                                        checked={settings.invert}
                                        changeHandler={() => handleCheckboxChange('invert')}
                                        disabled={!edit}
                                    />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 20}}>
                                <Col xs={4} style={{marginTop: 5}}><Label for={'pinMDB'}>pinMDB</Label></Col>
                                <Col xs={8}>
                                    <ButtonSwitcher
                                        id={'pinMDB'}
                                        checked={settings.pinMDB}
                                        changeHandler={() => handleCheckboxChange('pinMDB')}
                                        disabled={!edit}
                                    />
                                </Col>
                            </Row>
                        </>
                    : null
                }
                {settings.work_mode?.includes('cashless')
                    ? <>
                        <Row style={{marginTop: 20}}>
                            <Col xs={4}><Label for={'itemName'}>itemName</Label></Col>
                            <Col xs={8}>
                                <Input
                                    type={'text'}
                                    id={'itemName'}
                                    name={'itemName'}
                                    value={settings.itemName}
                                    onChange={handleChange}
                                    disabled={!edit}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20}}>
                            <Col xs={4}><Label for={'itemNumber'}>itemNumber</Label></Col>
                            <Col xs={8}>
                                <ButtonSwitcher
                                    id={'itemNumber'}
                                    checked={settings.itemNumber}
                                    changeHandler={() => handleCheckboxChange('itemNumber')}
                                    disabled={!edit}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20}}>
                            <Col xs={4}><Label for={'secondAddress'}>secondAddress</Label></Col>
                            <Col xs={8}>
                                <ButtonSwitcher
                                    id={'secondAddress'}
                                    checked={settings.secondAddress}
                                    changeHandler={() => handleCheckboxChange('secondAddress')}
                                    disabled={!edit}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20}}>
                            <Col xs={4}><Label for={'cancelSession'}>cancelSession</Label></Col>
                            <Col xs={8}>
                                <ButtonSwitcher
                                    id={'cancelSession'}
                                    checked={settings.cancelSession}
                                    changeHandler={() => handleCheckboxChange('cancelSession')}
                                    disabled={!edit}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20}}>
                            <Col xs={4}><Label for={'creditRequest'}>creditRequest</Label></Col>
                            <Col xs={8}>
                                <ButtonSwitcher
                                    id={'creditRequest'}
                                    checked={settings.creditRequest}
                                    changeHandler={() => handleCheckboxChange('creditRequest')}
                                    disabled={!edit}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20}}>
                            <Col xs={4}><Label for={'denyBegin'}>denyBegin</Label></Col>
                            <Col xs={8}>
                                <ButtonSwitcher
                                    id={'denyBegin'}
                                    checked={settings.denyBegin}
                                    changeHandler={() => handleCheckboxChange('denyBegin')}
                                    disabled={!edit}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20}}>
                            <Col xs={4}><Label for={'setupInReset'}>setupInReset</Label></Col>
                            <Col xs={8}>
                                <ButtonSwitcher
                                    id={'setupInReset'}
                                    checked={settings.setupInReset}
                                    changeHandler={() => handleCheckboxChange('setupInReset')}
                                    disabled={!edit}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20}}>
                            <Col xs={4}><Label for={'disabledJustReset'}>disabledJustReset</Label></Col>
                            <Col xs={8}>
                                <ButtonSwitcher
                                    id={'disabledJustReset'}
                                    checked={settings.disabledJustReset}
                                    changeHandler={() => handleCheckboxChange('disabledJustReset')}
                                    disabled={!edit}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20}}>
                            <Col xs={4}><Label for={'justRstAfterRst'}>justRstAfterRst</Label></Col>
                            <Col xs={8}>
                                <ButtonSwitcher
                                    id={'justRstAfterRst'}
                                    checked={settings.justRstAfterRst}
                                    changeHandler={() => handleCheckboxChange('justRstAfterRst')}
                                    disabled={!edit}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20}}>
                            <Col xs={4}><Label for={'revalueLimitDeny'}>revalueLimitDeny</Label></Col>
                            <Col xs={8}>
                                <ButtonSwitcher
                                    id={'revalueLimitDeny'}
                                    checked={settings.revalueLimitDeny}
                                    changeHandler={() => handleCheckboxChange('revalueLimitDeny')}
                                    disabled={!edit}
                                />
                            </Col>
                        </Row>
                    </>
                    : null
                }
                {settings.work_mode?.includes('cci' )
                    ? <Row style={{marginTop: 20}}>
                        <Col xs={4}><Label for={'amount'}>amount</Label></Col>
                        <Col xs={8}>
                            <Input
                                type={'text'}
                                id={'amount'}
                                name={'amount'}
                                value={settings.amount}
                                onChange={handleChange}
                                disabled={!edit}
                            />
                        </Col>
                    </Row>
                    : null
                }
                {settings.work_mode?.includes('cctalk')
                    ? <Row style={{marginTop: 20}}>
                        <Col xs={4}><Label for={'trace'}>trace</Label></Col>
                        <Col xs={8}>
                            <ButtonSwitcher
                                id={'trace'}
                                checked={settings.trace}
                                changeHandler={() => handleCheckboxChange('trace')}
                                disabled={!edit}
                            />
                        </Col>
                    </Row>
                    : null
                }
                {settings.work_mode?.includes('changer') 
                    ? <>
                        <Row style={{marginTop: 20}}>
                            <Col xs={4}><Label for={'alwaysInsert'}>alwaysInsert</Label></Col>
                            <Col xs={8}>
                                <ButtonSwitcher
                                    id={'alwaysInsert'}
                                    checked={settings.alwaysInsert}
                                    changeHandler={() => handleCheckboxChange('alwaysInsert')}
                                    disabled={!edit}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20}}>
                            <Col xs={4}><Label for={'addAmount'}>addAmount</Label></Col>
                            <Col xs={8}>
                                <Input
                                    type={'text'}
                                    id={'addAmount'}
                                    name={'addAmount'}
                                    value={settings.addAmount}
                                    onChange={handleChange}
                                    disabled={!edit}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20}}>
                            <Col xs={4}><Label for={'exchange'}>exchange</Label></Col>
                            <Col xs={8}>
                                <Input
                                    type={'text'}
                                    id={'exchange'}
                                    name={'exchange'}
                                    value={settings.exchange}
                                    onChange={handleChange}
                                    disabled={!edit}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20}}>
                            <Col xs={4} style={{marginTop: 5}}><Label for={'exchangePulse'}>exchangePulse</Label></Col>
                            <Col xs={8}>
                                <ButtonSwitcher
                                    id={'exchangePulse'}
                                    checked={settings.exchangePulse}
                                    changeHandler={() => handleCheckboxChange('exchangePulse')}
                                    disabled={!edit}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20}}>
                            <Col xs={4}><Label for={'coinScale'}>coinScale</Label></Col>
                            <Col xs={8}>
                                <Input
                                    type={'text'}
                                    id={'coinScale'}
                                    name={'coinScale'}
                                    value={settings.coinScale}
                                    onChange={handleChange}
                                    disabled={!edit}
                                />
                            </Col>
                        </Row>
                        <BillsCoinsSettings
                            title={'coins'}
                            itemsList={settings.coins}
                            scale={settings.coinScale}
                            disabled={!edit}
                            changeHandler={(title, value) => blurBillsCoinsHandler(title, value)}
                        />
                    </>
                    : null
                }
                
                <Row style={{marginTop: 20}}>
                    <Col xs={3}>
                        <Button color='warning' onClick={() => setShowAdditionalSettings(!showAdditionalSettings)}>Общие настройки</Button>
                    </Col>
                </Row>
                <Collapse isOpen={showAdditionalSettings}>
                    <Row style={{marginTop: 20}}>
                        <Col xs={4}><Label for={'createdate'}>createdate</Label></Col>
                        <Col xs={8}>
                            <Input
                                type={'text'}
                                id={'createdate'}
                                name={'createdate'}
                                value={settings.createdate}
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col xs={4}><Label for={'changedate'}>changedate</Label></Col>
                        <Col xs={8}>
                            <Input
                                type={'text'}
                                id={'changedate'}
                                name={'changedate'}
                                value={settings.changedate}
                                disabled
                            />
                        </Col>
                    </Row>
                    
                    <Row style={{marginTop: 20}}>
                        <Col xs={4}><Label for={'dbgLevel'}>dbgLevel</Label></Col>
                        <Col xs={8}>
                            <Input
                                type={'select'}
                                id={'dbgLevel'}
                                name={'dbgLevel'}
                                value={settings.dbgLevel}
                                onChange={handleChange}
                                disabled={!edit}
                            >
                                <option value={''}>Выберите dbgLevel</option>
                                <option value={0}>System</option>
                                <option value={1}>Error</option>
                                <option value={2}>Warning</option>
                                <option value={3}>Notice</option>
                                <option value={4}>Debug</option>
                                <option value={5}>Debug2</option>
                            </Input>
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col xs={4}><Label for={'desc'}>desc</Label></Col>
                        <Col xs={8}>
                            <Input
                                type={'text'}
                                id={'desc'}
                                name={'desc'}
                                value={settings.desc}
                                onChange={handleChange}
                                disabled={!edit}
                            />
                        </Col>
                    </Row>
                    {/* <Row style={{marginTop: 20}}>
                        <Col xs={4}><Label for={'file'}>file</Label></Col>
                        <Col xs={8}>
                            <Input
                                type={'select'}
                                id={'file'}
                                name={'file'}
                                value={settings.file}
                                onChange={handleFileChange}
                            >
                                <option value={''}>Выберите файл</option>
                                {files
                                    ? files.map(file => {
                                        return <option key={file.id} id={file.id}>{file.file}</option>
                                    })  
                                    : null
                                }
                            </Input>
                        </Col>
                    </Row> */}
                    <Row style={{marginTop: 20}}>
                        <Col xs={4}><Label for={'fw'}>fw</Label></Col>
                        <Col xs={8}>
                            <Input
                                type={'select'}
                                id={'fw'}
                                name={'fw'}
                                value={settings.fw}
                                onChange={handleFileChange}
                                disabled={!edit}
                            >
                                <option value={''}>Выберите версию</option>
                                {files
                                    ? files.map(file => {
                                        return <option key={file.id} id={file.fw} value={file.fw}>{file.fw} - {file.description}</option>
                                    })  
                                    : null
                                }
                            </Input>
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col xs={4}><Label for={'mdbVMCnoACK'}>mdbVMCnoACK</Label></Col>
                        <Col xs={8}>
                            <ButtonSwitcher
                                id={'mdbVMCnoACK'}
                                checked={settings.mdbVMCnoACK}
                                changeHandler={() => handleCheckboxChange('mdbVMCnoACK')}
                                disabled={!edit}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col xs={4}><Label for={'alwaysInsert'}>alwaysInsert</Label></Col>
                        <Col xs={8}>
                            <ButtonSwitcher
                                id={'alwaysInsert'}
                                checked={settings.alwaysInsert}
                                changeHandler={() => handleCheckboxChange('alwaysInsert')}
                                disabled={!edit}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col xs={4}><Label for={'log'}>log</Label></Col>
                        <Col xs={8}>
                            <ButtonSwitcher
                                id={'log'}
                                checked={settings.log}
                                changeHandler={() => handleCheckboxChange('log')}
                                disabled={!edit}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col xs={4}><Label for={'test'}>test</Label></Col>
                        <Col xs={8}>
                            <ButtonSwitcher
                                id={'test'}
                                checked={settings.test}
                                changeHandler={() => handleCheckboxChange('test')}
                                disabled={!edit}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col xs={4}><Label for={'timezone'}>timezone</Label></Col>
                        <Col xs={8}>
                            <Input
                                type={'text'}
                                id={'timezone'}
                                name={'timezone'}
                                value={settings.timezone}
                                onChange={handleChange}
                                disabled={!edit}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col xs={4}><Label for={'dbgMask'}>dbgMask</Label></Col>
                        <Col xs={8}>
                            <Input
                                type={'text'}
                                id={'dbgMask'}
                                name={'dbgMask'}
                                value={settings.dbgMask}
                                onChange={handleChange}
                                disabled={!edit}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col xs={4}><Label for={'vctrl'}>vctrl</Label></Col>
                        <Col xs={8}>
                            <ButtonSwitcher
                                id={'vctrl'}
                                checked={settings.vctrl}
                                changeHandler={() => handleCheckboxChange('vctrl')}
                                disabled={!edit}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col xs={4}><Label for={'logInterval'}>logInterval</Label></Col>
                        <Col xs={8}>
                            <Input
                                type={'text'}
                                id={'logInterval'}
                                name={'logInterval'}
                                value={settings.logInterval}
                                onChange={handleChange}
                                disabled={!edit}
                            />
                        </Col>
                    </Row>
                </Collapse>   
                <Row style={{marginTop: 20}}>
                    <Col xs={4}>
                        <Label for="description">description</Label>
                    </Col>
                    <Col xs={8}>
                        <Input type="textarea" name="description" id="description" value={settings.description} onChange={handleChange} disabled={!edit}/>
                    </Col>
                </Row>
                <Row style={{marginTop: 20}}>   
                    <Col xs={4}>
                        <Button type="button" color="dark" onClick={() => navigate(-1)} style={{width: '100%', maxWidth: 300, margin: '20px 0'}}>Назад</Button>
                    </Col>
                    
                    <Col xs={4}>
                        {edit
                            ? <Button type="button" color="danger" onClick={() => {
                                setEdit(false);
                                setSettings(defSettings);
                            }} style={{width: '100%', maxWidth: 300, margin: '20px 0'}}>Отмена</Button>
                            : <Button type="button" color="primary" onClick={() => setEdit(true)} style={{width: '100%', maxWidth: 300, margin: '20px 0'}}>Редактирование</Button>
                        }
                    </Col>
                    {edit
                        ? <Col xs={4}>
                            <Button type="submit" color="success" disabled={clicked} style={{width: '100%', maxWidth: 300, margin: '20px 0'}}>Сохранить</Button>
                        </Col>
                        : null
                    }
                </Row>
            </Form>
        </div>
    );
};


export default ControllerSetings;
