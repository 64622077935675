import React, { useState, useEffect } from 'react';
import {Link, useSearchParams} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem, Button, Form, Input} from "reactstrap";
import {DataTable} from "../../../components/DataTable/DataTable";
import { useQuery } from '@apollo/client';
import {GET_ACCOUNTS, GET_MERCHANTS} from '../../../GraphQL/Queries';
import Loader from '../../../components/UI/Loader/Loader';
import BillingFilter from '../../../store/BillingFilter';
import TotalInvoicesTable from '../../../components/TotalsTables/TotalInvoicesTable';
import CustomAutocomplete from "../../../components/CustomAutocomplete/CustomAutocomplete";

const Accounts = () => {
    const [ pageLoading, setPageLoading ] = useState(false);

    let [searchParams, setSearchParams] = useSearchParams();

    const [filter, setFilter] = useState({
        page: 1,
        size: 25,
        sortBy: 'id',
        sortOrder: 'desc'
    });

    const {loading, error, data, refetch} = useQuery(GET_ACCOUNTS, {variables: {
        page: 0,
        size: filter.size,
        sortBy: 'id',
        sortOrder: 'desc'
    }, context: { clientName: 'billing' }});

    const merchants = useQuery(GET_MERCHANTS, { variables: {
            sortBy: "id",
            sortOrder: "asc"
        }}
    );

    const sortByHandler = (e) => {
        setPageLoading(true);

        if (filter.sortBy === e.target.id) {
            setFilter({
                ...filter,
                sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
            });
        } else {
            setFilter({
                ...filter,
                sortBy: e.target.id
            });
        }

        refetch({
            accountFilter: {
                bin: BillingFilter.accountsFilter.bin || null,
                amountFrom: parseInt(BillingFilter.accountsFilter.amountFrom) || null,
                amountTo: parseInt(BillingFilter.accountsFilter.amountTo) || null,
            },
            page: filter.page - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const pageSizeHandler = (e) => {
        setPageLoading(true);

        setFilter({
            ...filter,
            size: parseInt(e.target.value),
            page: 1
        });
        BillingFilter.setAccountsFilter('size', parseInt(e.target.value));
        setSearchParams({...BillingFilter.accountsFilter, size: parseInt(e.target.value)});
        refetch({
            accountFilter: {
                bin: BillingFilter.accountsFilter.bin || null,
                amountFrom: parseInt(BillingFilter.accountsFilter.amountFrom) || null,
                amountTo: parseInt(BillingFilter.accountsFilter.amountTo) || null,
            },
            page: 0,
            size: parseInt(e.target.value),
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const handlePageChange = (pageNumber) => {
        setPageLoading(true);
        setFilter({
            ...filter,
            page: pageNumber
        });

        refetch({
            accountFilter: {
                bin: BillingFilter.accountsFilter.bin || null,
                amountFrom: parseInt(BillingFilter.accountsFilter.amountFrom) || null,
                amountTo: parseInt(BillingFilter.accountsFilter.amountTo) || null,
            },
            page: pageNumber - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const [accounts, setAccounts] = useState([]);
    const [totalItems, setTotalItems] = useState();
    
    useEffect(() => {
        if (data) {
            setAccounts(data.accountsPageable?.data)
            setTotalItems(data.accountsPageable?.total)
        }
    }, [data, filter]);

    let bin = searchParams.get("bin");
    let amountFrom = searchParams.get('amountFrom');
    let amountTo = searchParams.get('amountTo');
    let size = searchParams.get('size');

    useEffect(() => {
        BillingFilter.cleanAccountFilter();

        if (bin || amountFrom || amountTo || size) {
            if (bin) BillingFilter.setAccountsFilter('bin', bin);
            if (amountFrom) BillingFilter.setAccountsFilter('amountFrom', amountFrom);
            if (amountTo) BillingFilter.setAccountsFilter('amountTo', amountTo);
            if (size) {
                BillingFilter.setAccountsFilter('size', size);
                setFilter({...filter, size});
            }

            refetch({
                accountFilter: {
                    bin: BillingFilter.accountsFilter.bin || null,
                    amountFrom: parseInt(BillingFilter.accountsFilter.amountFrom) || null,
                    amountTo: parseInt(BillingFilter.accountsFilter.amountTo) || null,
                },
                page: 0,
                size: filter.size,
                sortBy: filter.sortBy
            })
                .then(() => {
                    setPageLoading(false);
                })
        } else {
            BillingFilter.setAccountsFilter('bin', '');
            BillingFilter.setAccountsFilter('amountFrom', '');
            BillingFilter.setAccountsFilter('amountTo', '');
            BillingFilter.setAccountsFilter('size', 25);
        }
    }, [filter.size, BillingFilter.accountsFilter.size]);

    const columns = [
        { title: '#', id: '#' },
        { title: 'Бин', id: 'bin' },
        { title: 'Мерчант', id: 'merchantName' },
        { title: 'Баланс', id: 'balance'},
    ];

    const rowsItem = [
        { title: '#', },
        { title: 'bin' },
        { title: 'merchantName' },
        { title: 'balance' },
    ];

    if (loading || pageLoading || merchants.loading) return <Loader/>
    
    return(
        <div>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem active>Отчет по балансам (биллинг)</BreadcrumbItem>
            </Breadcrumb>
            <hr/>
            <Form onSubmit={(e) => {
                e.preventDefault();
                setPageLoading(true);
                setSearchParams({
                    bin: BillingFilter.accountsFilter.bin ? BillingFilter.accountsFilter.bin : '',
                    amountFrom: BillingFilter.accountsFilter.amountFrom ? parseInt(BillingFilter.accountsFilter.amountFrom ): '',
                    amountTo: BillingFilter.accountsFilter.amountTo ? parseInt(BillingFilter.accountsFilter.amountTo) : '',
                    size: BillingFilter.accountsFilter.size ? BillingFilter.accountsFilter.size : 25
                })
                setFilter({
                    ...filter,
                    page: 1
                });
                refetch({
                    accountFilter: {
                        bin: BillingFilter.accountsFilter.bin || null,
                        amountFrom: parseInt(BillingFilter.accountsFilter.amountFrom) || null,
                        amountTo: parseInt(BillingFilter.accountsFilter.amountTo) || null,
                    },
                    page: 0,
                    size: filter.size,
                    sortBy: filter.sortBy
                })
                    .then(() => {
                        setPageLoading(false);
                    })
            }}>
                <div className={'filter_wrapper'}>
                    <div className={'filter_item'}>
                        <CustomAutocomplete
                            list={merchants.data?.merchants}
                            name={'bin'}
                            setValue={(name, item) => {
                                if (item) {
                                    setSearchParams({ ...BillingFilter.accountsFilter, bin: item.bin})
                                    BillingFilter.accountsFilter.bin = item.bin
                                } else {
                                    setSearchParams({...BillingFilter.accountsFilter, bin: ''})
                                    BillingFilter.accountsFilter.bin = null
                                }
                            }}
                            placeholder="Наименование / Бин мерчанта"
                            searchBy={["name", "bin"]}
                            resultStringKeyName='name'
                            getValueBy={'bin'}
                            edit={true}
                            value={BillingFilter.invoicesFilter.bin ? BillingFilter.invoicesFilter.bin : null}
                        />
                    </div>
                    <Input
                        className={'filter_item'}
                        type="number"
                        id="amountFrom"
                        name="amountFrom"
                        placeholder='Сумма с'
                        value={BillingFilter.accountsFilter.amountFrom}
                        onChange={(e) => {
                            setSearchParams({ ...BillingFilter.accountsFilter, bin: e.target.value})
                            BillingFilter.accountsFilter.amountFrom = e.target.value
                        }}
                    />
                    <Input
                        className={'filter_item'}
                        type="text"
                        id="amountTo"
                        name="amountTo"
                        placeholder='Сумма по'
                        value={BillingFilter.accountsFilter.amountTo}
                        onChange={(e) => {
                            setSearchParams({ ...BillingFilter.accountsFilter, bin: e.target.value})
                            BillingFilter.accountsFilter.amountTo = e.target.value
                        }}
                    />
                    <div className={'buttons_wrapper'}>
                            <Button color={'primary'} type={'submit'} className="filter_button" style={{width: 100, marginRight: 20}} >Поиск</Button>
                            <Button color={'danger'} style={{width: 100}} className="filter_button" onClick={() => {
                                setPageLoading(true);
                                setSearchParams('');
                                BillingFilter.cleanAccountFilter();
                                setFilter({
                                    ...filter,
                                    size: 25
                                })
                                refetch({
                                    accountFilter: {
                                        bin: null,
                                        amountFrom: null,
                                        amountTo: null
                                    },
                                    page: filter.page - 1,
                                    size: filter.size,
                                    sortBy: filter.sortBy
                                })
                                    .then(() => {
                                        setPageLoading(false);
                                    })
                            }}>Сбросить</Button>
                        </div>
                </div>
            </Form>
            <br/><hr/>
            <DataTable
                notStripped={true}
                hideColumns={[]}
                columns={columns}
                // rows={data?.accountsPageable?.data}
                rows={accounts}
                merchants={merchants.data ? merchants.data.merchants : []}
                rowsItem={rowsItem}
                sortBy={filter.sortBy}
                sortByHandler={(e) => sortByHandler(e)}
                itemsCountPerPage={filter.size}
                sortOrder={filter.sortOrder}
                pageSizeHandler={(e) => pageSizeHandler(e)}
                totalItemsCount={totalItems}
                activePage={filter.page}
                handlePageChange={(pageNumber) => handlePageChange(pageNumber)}
                isPaginated={true}
                hideIcons={true}
            />
            <TotalInvoicesTable
                totalAmount={data?.accountsPageable?.totalAmount}
            />
        </div>
    )
}

export default Accounts;