import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {
    FILTER_PAYMENTS,
    GET_MERCHANTS, 
    GET_SERVICES,
    GET_TERMINALS,
    PAYMENT_SYSTEMS
} from "../../GraphQL/Queries";
import Loader from "../../components/UI/Loader/Loader";
import {Breadcrumb, BreadcrumbItem, Button, Form, Input} from "reactstrap";
import {DataTable} from "../../components/DataTable/DataTable";
import {toast} from "react-toastify";
import axios from "axios";
import CustomAutocomplete from '../../components/CustomAutocomplete/CustomAutocomplete';
import config from "../../config";
import Filter from '../../store/Filter';
import { useSearchParams } from "react-router-dom";
import TotalPaymentsTable from '../../components/TotalsTables/TotalPaymentsTable';
import Legenda from "../../components/UI/Legenda/Legenda";
import ReportDatesFilter from "../../components/ReportDatesFilter/ReportDatesFilter";
import './Reports.css';

const Payments = () => {

    const [ pageLoading, setPageLoading ] = useState(false);

    let [searchParams, setSearchParams] = useSearchParams();
    
    const [filter, setFilter] = useState({
        page: 1,
        size: 25,
        sortBy: 'id',
        sortOrder: 'desc'
    });

    let date = new Date().toISOString();
    
    const { loading, error, data, refetch } = useQuery(FILTER_PAYMENTS, { variables: {
            filter: {
                dateFrom: `${date.slice(0, 11)}00:00+06:00`,
                dateTo: `${date.slice(0, 11)}23:59+06:00`,
            },
            page: 0,
            size: filter.size,
            sortBy: 'id',
            sortOrder: 'desc'
        }}
    );

    const allTerminals = useQuery(GET_TERMINALS, { variables: {
            sortBy: "id"
        }}
    );

    const paymentSystems = useQuery(PAYMENT_SYSTEMS, { variables: {
        sortBy: "id",
        sortOrder: "asc"
    }});

    const merchants = useQuery(GET_MERCHANTS, { variables: {
            sortBy: "id",
            sortOrder: "asc"
        }}
    );

    const services = useQuery(GET_SERVICES, { variables: {
            sortBy: "id"
        }}
    );

    const handlePageChange = (pageNumber) => {
        setPageLoading(true);
       
        setFilter({
            ...filter,
            page: pageNumber
        });

        refetch({
            filter: {
                dateFrom: Filter.paymentesFilter.dateFrom + '+06:00',
                dateTo: Filter.paymentesFilter.dateTo + '+06:00',
                externalId: Filter.paymentesFilter.externalId ? Filter.paymentesFilter.externalId : null,
                type: Filter.paymentesFilter.type ? Filter.paymentesFilter.type : null,
                status: Filter.paymentesFilter.status ? Filter.paymentesFilter.status : null,
                terminalId: Filter.paymentesFilter.terminalId ? Filter.paymentesFilter.terminalId : null,
                merchantId: Filter.paymentesFilter.merchantId ? Filter.paymentesFilter.merchantId : null,
                clientId: Filter.paymentesFilter.clientId ? Filter.paymentesFilter.clientId : null,
                serviceId: Filter.paymentesFilter.serviceId ? Filter.paymentesFilter.serviceId : null,
                invoiceId: Filter.paymentesFilter.invoiceId ? Filter.paymentesFilter.invoiceId : null,
                paymentSystemId: Filter.paymentesFilter.paymentSystemId ? Filter.paymentesFilter.paymentSystemId : null
            },
            page: pageNumber - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const sortByHandler = (e) => {
        setPageLoading(true);
        
        if (filter.sortBy === e.target.id) {
            setFilter({
                ...filter,
                sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
            });
        } else {
            setFilter({
                ...filter,
                sortBy: e.target.id
            });
        }

        refetch({
            filter: {
                dateFrom: Filter.paymentesFilter.dateFrom + '+06:00',
                dateTo: Filter.paymentesFilter.dateTo + '+06:00',
                externalId: Filter.paymentesFilter.externalId ? Filter.paymentesFilter.externalId : null,
                type: Filter.paymentesFilter.type ? Filter.paymentesFilter.type : null,
                status: Filter.paymentesFilter.status ? Filter.paymentesFilter.status : null,
                terminalId: Filter.paymentesFilter.terminalId ? Filter.paymentesFilter.terminalId : null,
                merchantId: Filter.paymentesFilter.merchantId ? Filter.paymentesFilter.merchantId : null,
                clientId: Filter.paymentesFilter.clientId ? Filter.paymentesFilter.clientId : null,
                serviceId: Filter.paymentesFilter.serviceId ? Filter.paymentesFilter.serviceId : null,
                invoiceId: Filter.paymentesFilter.invoiceId ? Filter.paymentesFilter.invoiceId : null,
                paymentSystemId: Filter.paymentesFilter.paymentSystemId ? Filter.paymentesFilter.paymentSystemId : null
            },
            page: filter.page - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const pageSizeHandler = (e) => {
        setPageLoading(true);
        
        setFilter({
            ...filter,
            size: parseInt(e.target.value),
            page: 1
        });
        Filter.setPaymentsFilter('size', parseInt(e.target.value));
        setSearchParams({...Filter.paymentesFilter, size: parseInt(e.target.value)});
        refetch({
            filter: {
                dateFrom: Filter.paymentesFilter.dateFrom + '+06:00',
                dateTo: Filter.paymentesFilter.dateTo + '+06:00',
                externalId: Filter.paymentesFilter.externalId ? Filter.paymentesFilter.externalId : null,
                type: Filter.paymentesFilter.type ? Filter.paymentesFilter.type : null,
                status: Filter.paymentesFilter.status ? Filter.paymentesFilter.status : null,
                terminalId: Filter.paymentesFilter.terminalId ? Filter.paymentesFilter.terminalId : null,
                merchantId: Filter.paymentesFilter.merchantId ? Filter.paymentesFilter.merchantId : null,
                clientId: Filter.paymentesFilter.clientId ? Filter.paymentesFilter.clientId : null,
                serviceId: Filter.paymentesFilter.serviceId ? Filter.paymentesFilter.serviceId : null,
                invoiceId: Filter.paymentesFilter.invoiceId ? Filter.paymentesFilter.invoiceId : null,
                paymentSystemId: Filter.paymentesFilter.paymentSystemId ? Filter.paymentesFilter.paymentSystemId : null
            },
            page: 0,
            size: parseInt(e.target.value),
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const [payments, setPayments] = useState([]);
    const [totalItems, setTotalItems] = useState();
    
    const refundHandler = (externalId, amount, requestId) => {
        const user = JSON.parse(localStorage.getItem('user'));
        
        toast.promise(
                axios.post(`${config.apiURL}/api/refund?externalId=${externalId}&amount=${amount}&requestId=${requestId}`, null,{
                    headers: {
                        authorization: user.signInUserSession ? `Bearer ${user.signInUserSession.idToken.jwtToken}` : ""
                    }
                })
            , {
                pending: 'В обработке',
                success: {
                    render({data}){
                        return data.data.message
                    }
                },
                error: 'Ошибка'
            }
        )
            .then(() => {
                refetch({
                    filter: {
                        dateFrom: Filter.paymentesFilter.dateFrom + '+06:00',
                        dateTo: Filter.paymentesFilter.dateTo + '+06:00',
                        externalId: Filter.paymentesFilter.externalId ? Filter.paymentesFilter.externalId : null,
                        type: Filter.paymentesFilter.type ? Filter.paymentesFilter.type : null,
                        status: Filter.paymentesFilter.status ? Filter.paymentesFilter.status : null,
                        terminalId: Filter.paymentesFilter.terminalId ? Filter.paymentesFilter.terminalId : null,
                        merchantId: Filter.paymentesFilter.merchantId ? Filter.paymentesFilter.merchantId : null,
                        clientId: Filter.paymentesFilter.clientId ? Filter.paymentesFilter.clientId : null,
                        serviceId: Filter.paymentesFilter.serviceId ? Filter.paymentesFilter.serviceId : null,
                        invoiceId: Filter.paymentesFilter.invoiceId ? Filter.paymentesFilter.invoiceId : null,
                        paymentSystemId: Filter.paymentesFilter.paymentSystemId ? Filter.paymentesFilter.paymentSystemId : null
                    },
                    page: filter.page - 1,
                    size: filter.size,
                    sortBy: filter.sortBy,
                    sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
                })
                    .then(() => {
                        setPageLoading(false);
                    })
            })
    }

    useEffect(() => {
        if (data) {
            setPayments(data.paymentsPage.data)
            setTotalItems(data.paymentsPage.total)
        }
    }, [data, filter]);

    let dateFrom = searchParams.get("dateFrom");
    let dateTo = searchParams.get("dateTo");
    let externalId = searchParams.get("externalId");
    let status = searchParams.get("status");
    let type = searchParams.get("type");
    let clientId = searchParams.get("clientId");
    let serviceId = searchParams.get("serviceId");
    let merchantId = searchParams.get("merchantId");
    let terminalId = searchParams.get("terminalId");
    let paymentSystemId = searchParams.get("paymentSystemId");
    let size = searchParams.get("size");

    useEffect(() => {
        Filter.cleanPaymentsFilter();
        if (dateFrom || dateTo || externalId || status || terminalId || type || clientId || serviceId || merchantId || paymentSystemId || size){
            if (dateFrom) Filter.setPaymentsFilter('dateFrom', dateFrom)
            if (dateTo) Filter.setPaymentsFilter('dateTo', dateTo);
            if (externalId) Filter.setPaymentsFilter('externalId', externalId);
            if (status) Filter.setPaymentsFilter('status', status)
            if (terminalId) Filter.setPaymentsFilter('terminalId', terminalId);
            if (type) Filter.setPaymentsFilter('type', type);
            if (clientId) Filter.setPaymentsFilter('clientId', clientId);
            if (serviceId) Filter.setPaymentsFilter('serviceId', serviceId);
            if (merchantId) Filter.setPaymentsFilter('merchantId', merchantId);
            if (paymentSystemId) Filter.setPaymentsFilter('paymentSystemId', paymentSystemId);
            if (size) {
                Filter.setPaymentsFilter('size', size);
                setFilter({...filter, size});
            }

                refetch({
                    filter: {
                        dateFrom: Filter.paymentesFilter.dateFrom + '+06:00',
                        dateTo: Filter.paymentesFilter.dateTo + '+06:00',
                        externalId: Filter.paymentesFilter.externalId ? Filter.paymentesFilter.externalId : null,
                        type: Filter.paymentesFilter.type ? Filter.paymentesFilter.type : null,
                        status: Filter.paymentesFilter.status ? Filter.paymentesFilter.status : null,
                        terminalId: Filter.paymentesFilter.terminalId ? Filter.paymentesFilter.terminalId : null,
                        merchantId: Filter.paymentesFilter.merchantId ? Filter.paymentesFilter.merchantId : null,
                        clientId: Filter.paymentesFilter.clientId ? Filter.paymentesFilter.clientId : null,
                        serviceId: Filter.paymentesFilter.serviceId ? Filter.paymentesFilter.serviceId : null,
                        invoiceId: Filter.paymentesFilter.invoiceId ? Filter.paymentesFilter.invoiceId : null,
                        paymentSystemId: Filter.paymentesFilter.paymentSystemId ? Filter.paymentesFilter.paymentSystemId : null
                    },
                    page: filter.page - 1,
                    size: filter.size,
                    sortBy: filter.sortBy,
                    sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
                })
                    .then(() => {
                        setPageLoading(false);
                    })    
        } else {
            Filter.setPaymentsFilter('dateFrom', `${date.slice(0, 11)}00:00`);
            Filter.setPaymentsFilter('dateTo', `${date.slice(0, 11)}23:59`);
            Filter.setPaymentsFilter('size', 25);
            Filter.setPaymentsFilter('externalID', '');
            Filter.setPaymentsFilter('status', '');
            Filter.setPaymentsFilter('terminalId', '');
            Filter.setPaymentsFilter('type', '');
            Filter.setPaymentsFilter('clientId', '');
            Filter.setPaymentsFilter('serviceId', '');
            Filter.setPaymentsFilter('merchantId', '');
            Filter.setPaymentsFilter('paymentSystemId', '');
        }
    }, [filter.size, Filter.paymentesFilter.size]);

    const columns = [
        { title: '#', id: '#' },
        { title: 'Id', id: 'id' },
        { title: 'Сумма', id: 'amount' },
        { title: 'Отмененная сумма', id: 'canceledAmount' },
        { title: 'Сумма кредита', id: 'creditAmount' },
        { title: 'Дата создания', id: 'creationDate'},
        { title: 'Дата изменения', id: 'changeDate'},
        { title: 'Внешний ID', id: 'externalId'},
        { title: 'Статус', id: 'status' },
        { title: 'ID клиента', id: 'clientId' },
        { title: 'Время клиента', id: 'clientDate' },
        { title: 'Мерчант', id: 'merchantId' },
        { title: 'Услуга', id: 'serviceId' },
        { title: 'Терминал', id: 'terminalId' },
        { title: 'Тип', id: 'type' },
        { title: 'ID инвойса', id: 'invoiceId' },
        { title: 'Платежная система', id: 'paymentSystemId'},
        { title: 'Действия', id: 'actions' }
    ];

    const rowsItem = [
        { title: '#' },
        { title: 'id' },
        { title: 'amount' },
        { title: 'canceledAmount' },
        { title: 'creditAmount' },
        { title: 'creationDate' },
        { title: 'changeDate' },
        { title: 'externalId' },
        { title: 'status' },
        { title: 'clientId'},
        { title: 'clientDate' },
        { title: 'merchantId' },
        { title: 'serviceId' },
        { title: 'terminalId' },
        { title: 'type' },
        { title: 'invoiceId' },
        { title: 'paymentSystemId' }
    ];

    if (loading || allTerminals.loading || merchants.loading || services.loading) return <Loader/>
    
    return(
        <div>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem active>Отчет по платежам</BreadcrumbItem>
            </Breadcrumb>
            <hr/>
            <Form onSubmit={(e) => {
                e.preventDefault();
                 setPageLoading(true);
                  
                 setFilter({
                     ...filter,
                     page: 1
                 });
                 refetch({
                     filter: {
                         dateFrom: Filter.paymentesFilter.dateFrom + '+06:00',
                         status: Filter.paymentesFilter.status ? Filter.paymentesFilter.status : null,
                         dateTo: Filter.paymentesFilter.dateTo + '+06:00',
                         externalId: Filter.paymentesFilter.externalId ? Filter.paymentesFilter.externalId : null,
                         type: Filter.paymentesFilter.type ? Filter.paymentesFilter.type : null,
                         terminalId: Filter.paymentesFilter.terminalId ? Filter.paymentesFilter.terminalId : null,
                         merchantId: Filter.paymentesFilter.merchantId ? Filter.paymentesFilter.merchantId : null,
                         clientId: Filter.paymentesFilter.clientId ? Filter.paymentesFilter.clientId : null,
                         serviceId: Filter.paymentesFilter.serviceId ? Filter.paymentesFilter.serviceId : null,
                         paymentSystemId: Filter.paymentesFilter.paymentSystemId ? Filter.paymentesFilter.paymentSystemId : null
                     },
                     page: 0,
                     size: filter.size,
                     sortBy: filter.sortBy
                 })
                     .then(() => {
                         setPageLoading(false);
                     })
            }}>
                <div className={'filter_wrapper'}>
                    <ReportDatesFilter 
                        dateFrom={Filter.paymentesFilter.dateFrom}
                        dateTo={Filter.paymentesFilter.dateTo}
                        dateFromChange={(e) => {
                            setSearchParams({
                                ...Filter.paymentesFilter,
                                terminalId: Filter.paymentesFilter.terminalId || '',
                                merchantId: Filter.paymentesFilter.merchantId || '',
                                serviceId: Filter.paymentesFilter.serviceId || '',
                                paymentSystemId: Filter.paymentesFilter.paymentSystemId || '',
                                dateFrom: e.target.value
                            })
                            Filter.paymentesFilter.dateFrom = e.target.value
                        }}
                        dateToChange={(e) => {
                            setSearchParams({
                                ...Filter.paymentesFilter,
                                terminalId: Filter.paymentesFilter.terminalId || '',
                                merchantId: Filter.paymentesFilter.merchantId || '',
                                serviceId: Filter.paymentesFilter.serviceId || '',
                                paymentSystemId: Filter.paymentesFilter.paymentSystemId || '',
                                dateTo: e.target.value
                            })
                            Filter.paymentesFilter.dateTo = e.target.value
                        }}
                    /> 
                    <Input
                        className={'filter_item'}
                        type="text"
                        id="externalId"
                        name="externalId"
                        placeholder='Внешний ID'
                        value={Filter.paymentesFilter.externalId}
                        onChange={(e) => {
                            setSearchParams({ 
                                ...Filter.paymentesFilter, 
                                terminalId: Filter.paymentesFilter.terminalId || '',
                                merchantId: Filter.paymentesFilter.merchantId || '',
                                serviceId: Filter.paymentesFilter.serviceId || '',
                                paymentSystemId: Filter.paymentesFilter.paymentSystemId || '',
                                externalId: e.target.value
                            })
                            Filter.paymentesFilter.externalId = e.target.value
                        }}
                    />
                    <Input
                        className={'filter_item'}
                        type='select'
                        id="type"
                        value={Filter.paymentesFilter.type}
                        name='type'
                        onChange={(e) => {
                            setSearchParams({ 
                                ...Filter.paymentesFilter, 
                                terminalId: Filter.paymentesFilter.terminalId || '',
                                merchantId: Filter.paymentesFilter.merchantId || '',
                                serviceId: Filter.paymentesFilter.serviceId || '',
                                paymentSystemId: Filter.paymentesFilter.paymentSystemId || '',
                                type: e.target.value
                            })
                            Filter.paymentesFilter.type = e.target.value
                        }}
                        style={{color: '#747678'}}
                    >
                        <option value={''}>Выберите тип оплаты</option>
                        <option>INVOICE</option>
                        <option>SERVICE</option>
                    </Input>
                    <Input
                        className={'filter_item'}
                        type='select'
                        id="status"
                        name='status'
                        value={Filter.paymentesFilter.status}
                        onChange={(e) => {
                            setSearchParams({ 
                                ...Filter.paymentesFilter,
                                terminalId: Filter.paymentesFilter.terminalId || '',
                                merchantId: Filter.paymentesFilter.merchantId || '',
                                serviceId: Filter.paymentesFilter.serviceId || '',
                                paymentSystemId: Filter.paymentesFilter.paymentSystemId || '',
                                status: e.target.value
                            })
                            Filter.paymentesFilter.status = e.target.value
                        }}
                        style={{color: '#747678'}}
                    >
                        <option value={''}>Выберите статус оплаты</option>
                        <option>NEW</option>
                        <option>PROCESS</option>
                        <option>CANCEL</option>
                        <option>FINISH</option>
                        <option>CANCELED</option>
                        <option>CANCELLING</option>
                    </Input>
                    <div className={'filter_item'}>
                        <CustomAutocomplete 
                            list={allTerminals.data ? allTerminals.data.terminals : []}
                            name={'terminalId'}
                            setValue={(name, item) => {
                                if (item){
                                    setSearchParams({ 
                                        ...Filter.paymentesFilter, 
                                        merchantId: Filter.paymentesFilter.merchantId || '',
                                        serviceId: Filter.paymentesFilter.serviceId || '',
                                        paymentSystemId: Filter.paymentesFilter.paymentSystemId || '',
                                        terminalId: item.id
                                    })
                                    Filter.paymentesFilter.terminalId = item.id
                                } else {
                                    setSearchParams({ 
                                        ...Filter.paymentesFilter, 
                                        merchantId: Filter.paymentesFilter.merchantId || '',
                                        serviceId: Filter.paymentesFilter.serviceId || '',
                                        paymentSystemId: Filter.paymentesFilter.paymentSystemId || '',
                                        terminalId: ''
                                    })
                                    Filter.paymentesFilter.terminalId = null
                                }
                            }}
                            placeholder="Наименование / GUID терминала"
                            searchBy={["name", "guid"]}
                            resultStringKeyName='guid'
                            getValueBy={'id'}
                            edit={true}
                            value={Filter.paymentesFilter.terminalId ? Filter.paymentesFilter.terminalId : null}
                        />
                    </div>
                    <div className={'filter_item'}>
                        <CustomAutocomplete 
                            list={merchants.data ? merchants.data.merchants : []}
                            name={'merchantId'}
                            setValue={(name, item) => {
                                if (item) {
                                    setSearchParams({ 
                                        ...Filter.paymentesFilter,
                                        terminalId: Filter.paymentesFilter.terminalId || '',
                                        serviceId: Filter.paymentesFilter.serviceId || '',
                                        paymentSystemId: Filter.paymentesFilter.paymentSystemId || '',
                                        merchantId: item.id
                                    })
                                    Filter.paymentesFilter.merchantId = item.id
                                } else {
                                    setSearchParams({ 
                                        ...Filter.paymentesFilter, 
                                        terminalId: Filter.paymentesFilter.terminalId || '',
                                        serviceId: Filter.paymentesFilter.serviceId || '',
                                        paymentSystemId: Filter.paymentesFilter.paymentSystemId || '',
                                        merchantId: ''
                                    })
                                    Filter.paymentesFilter.merchantId = null
                                }
                                
                            }}
                            placeholder="Наименование / Бин мерчанта"
                            searchBy={["name", "bin"]}
                            resultStringKeyName='name'
                            getValueBy={'id'}
                            edit={true}
                            value={Filter.paymentesFilter.merchantId ? Filter.paymentesFilter.merchantId : null}
                        />
                    </div>
                    <Input
                        className={'filter_item'}
                        type='text'
                        placeholder="Введите ID клиента"
                        value={Filter.paymentesFilter.clientId}
                        name='clientId'
                        onChange={(e) => {
                            setSearchParams({ 
                                ...Filter.paymentesFilter,
                                terminalId: Filter.paymentesFilter.terminalId || '',
                                merchantId: Filter.paymentesFilter.merchantId || '',
                                serviceId: Filter.paymentesFilter.serviceId || '', 
                                paymentSystemId: Filter.paymentesFilter.paymentSystemId || '',
                                clientId: e.target.value
                            })
                                Filter.paymentesFilter.clientId = e.target.value
                        }}
                    />
                    <div className={'filter_item'}>
                        <CustomAutocomplete 
                            list={services.data ? services.data.services : []}
                            name={'serviceId'}
                            setValue={(name, item) => {
                                if (item) {
                                    setSearchParams({ 
                                        ...Filter.paymentesFilter, 
                                        terminalId: Filter.paymentesFilter.terminalId || '',
                                        merchantId: Filter.paymentesFilter.merchantId || '',
                                        paymentSystemId: Filter.paymentesFilter.paymentSystemId || '',
                                        serviceId: item.id
                                    })
                                    Filter.paymentesFilter.serviceId = item.id
                                } else {
                                    setSearchParams({ 
                                        ...Filter.paymentesFilter, 
                                        terminalId: Filter.paymentesFilter.terminalId || '',
                                        merchantId: Filter.paymentesFilter.merchantId || '',
                                        paymentSystemId: Filter.paymentesFilter.paymentSystemId || '',
                                        serviceId: ''
                                    })
                                    Filter.paymentesFilter.serviceId = null
                                }
                            }}
                            placeholder="Наименование услуги"
                            searchBy={["nameRus"]}
                            resultStringKeyName='nameRus'
                            getValueBy={'id'}
                            edit={true}
                            value={Filter.paymentesFilter.serviceId ? Filter.paymentesFilter.serviceId : null}
                        />
                    </div>
                    <div className={'filter_item'}>
                        <CustomAutocomplete 
                            list={paymentSystems.data ? paymentSystems.data.paymentSystems : []}
                            name={'paymentSystemId'}
                            setValue={(name, item) => {
                                if (item) {
                                    setSearchParams({ 
                                        ...Filter.paymentesFilter, 
                                        terminalId: Filter.paymentesFilter.terminalId || '',
                                        merchantId: Filter.paymentesFilter.merchantId || '',
                                        serviceId: item.id
                                    })
                                    Filter.paymentesFilter.paymentSystemId = item.id
                                } else {
                                    setSearchParams({ 
                                        ...Filter.paymentesFilter, 
                                        terminalId: Filter.paymentesFilter.terminalId || '',
                                        merchantId: Filter.paymentesFilter.merchantId || '',
                                        serviceId: ''
                                    })
                                    Filter.paymentesFilter.paymentSystemId = null
                                }
                            }}
                            placeholder="Платежную систему"
                            searchBy={["name"]}
                            resultStringKeyName='name'
                            getValueBy={'id'}
                            edit={true}
                            value={Filter.paymentesFilter.paymentSystemId ? Filter.paymentesFilter.paymentSystemId : null}
                        />
                    </div>
                    {/* <Input
                        className={'filter_item'}
                        type='select'
                        id="paymentSystemId"
                        value={Filter.paymentesFilter.paymentSystemId}
                        name='paymentSystemId'
                        onChange={(e) => {
                            setSearchParams({
                                ...Filter.paymentesFilter,
                                terminalId: Filter.paymentesFilter.terminalId || '',
                                merchantId: Filter.paymentesFilter.merchantId || '',
                                serviceId: Filter.paymentesFilter.serviceId || '',
                                paymentSystemId: e.target.value
                            })
                            Filter.paymentesFilter.paymentSystemId = e.target.value
                        }}
                        style={{color: '#747678'}}
                    >
                        <option value={''}>Выберите платежную систему</option>
                        <option>KASPI</option>
                        <option>EASY_COFFEE</option>
                    </Input> */}
                    <div className={'buttons_wrapper'}>
                    <Button color={'primary'} type={'submit'} className="filter_button" style={{width: 100, marginRight: 20}} >Поиск</Button>
                    <Button color={'danger'} style={{width: 100}} className="filter_button" onClick={() => {
                        setPageLoading(true);
                        Filter.cleanPaymentsFilter();
                        setSearchParams({
                            dateFrom: `${date.slice(0, 11)}00:00`,
                            dateTo: `${date.slice(0, 11)}23:59`
                        });
                        setFilter({
                            ...filter,
                            size: 25
                        })
                        refetch({
                            filter: {
                                // dateFrom: `${date.slice(0, 11)}00:00+06:00`,
                                // dateTo: `${date.slice(0, 11)}23:59+06:00`,
                                dateFrom: `${(new Date().toISOString()).slice(0, 11)}00:00+06:00`,
                                dateTo: `${(new Date().toISOString()).slice(0, 11)}23:59+06:00`,
                                status: null,
                                externalId: null,
                                type: null,
                                terminalId: null,
                                merchantId:  null,
                                clientId: null,
                                serviceId:  null,
                                paymentSystemId: null
                            },
                            page: filter.page - 1,
                            size: filter.size,
                            sortBy: filter.sortBy
                        })
                            .then(() => {
                                setPageLoading(false);
                            })
                    }}>Сбросить</Button>
                </div>
                </div>
            </Form>
            <hr/>
            <Legenda
                data={[{ status: 'NEW', color: '#dee2e6'}, { status: 'PROCESS', color: '#f8ff9c' }, { status: 'CANCEL', color: '#ff00002b'}, { status: 'FINISH', color: '#c1ffd2'}, { status: 'CANCELED', color: '#ef121285' }, { status: 'CANCELLING', color: '#ff000052'}]}
                title={'Статусы платежей'}
            />
            <DataTable
                notStripped={true}
                hideColumns={['#', 'canceledAmount', 'status', 'clientDate', 'changeDate', 'merchantId', 'serviceId', 'type', 'terminalId', 'paymentSystemId']}
                columns={columns}
                rows={payments}
                rowsItem={rowsItem}
                merchants={merchants.data ? merchants.data.merchants : []}
                terminals={allTerminals.data ? allTerminals.data.terminals : []}
                services={services.data ? services.data.services : []}
                paymentSystems={paymentSystems.data ? paymentSystems.data.paymentSystems : []}
                link={'payments'}
                sortBy={filter.sortBy}
                sortByHandler={(e) => sortByHandler(e)}
                itemsCountPerPage={filter.size}
                sortOrder={filter.sortOrder}
                pageSizeHandler={(e) => pageSizeHandler(e)}
                totalItemsCount={totalItems}
                activePage={filter.page}
                handlePageChange={(pageNumber) => handlePageChange(pageNumber)}
                isPaginated={true}
                refundBtn={true}
                refundHandler={(externalId, amount, requestId) => refundHandler(externalId, amount, requestId)}
                hideIcons={true}
            />
            <TotalPaymentsTable
                totalAmount={data?.paymentsPage?.totalAmount}
                totalCanceledAmount={data?.paymentsPage?.totalCanceledAmount}
                totalCreditAmount={data?.paymentsPage?.totalCreditAmount}
            />
            {pageLoading
                ? <Loader/>
                : null
            }
        </div>
    )
};

export default Payments;