import React, {useState} from 'react';
import {useMutation} from "@apollo/client";
import {CREATE_BONUS_REFUND} from "../../GraphQL/Mutations";
import {useFormik} from "formik";
import {RefundBonusSchema} from "../../schemas";
import {toast} from "react-toastify";
import TransactionRefund from "../TransactionRefund/TransactionRefund";

const RefundBonus = ({id}) => {

    const [clicked, setClicked] = useState(false);

    const [createBonusRefund] = useMutation(CREATE_BONUS_REFUND, {context: {clientName: 'billing'}});

    const formik = useFormik({
        initialValues: {
            sum: '',
            comment: ''
        },
        validationSchema: RefundBonusSchema,
        onSubmit: (values) => {
            setClicked(true);
            console.log(formik.errors)
            toast.promise(
                createBonusRefund({
                    variables: {
                        cancellationDTO: {
                            transactionId: id,
                            amount: parseInt(values.sum),
                            comment: values.comment
                        }
                    }
                }), {
                    pending: 'В обработке',
                    success: 'Возврат по операции Bonus',
                    error: {
                        render({data}){
                            return <span>{data.message}</span>
                        }
                    }
                }
            )
                .catch(err => {
                    setClicked(false);
                })
        }
    });

    return (
        <div className="RefundBonus">
            <TransactionRefund allFormik={formik} title={"Возврат по операции Bonus"} clicked={clicked}/>
        </div>
    );
};

export default RefundBonus;