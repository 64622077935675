import React, {useState} from 'react';
import {useMutation} from "@apollo/client";
import {CREATE_CHARGE_OFF_REFUND} from "../../GraphQL/Mutations";
import {useFormik} from "formik";
import {RefundChargeOffSchema} from "../../schemas";
import {toast} from "react-toastify";
import TransactionRefund from "../TransactionRefund/TransactionRefund";

const RefundChargeOff = ({id, transactionType, transactionAmount}) => {
    const [clicked, setClicked] = useState(false);

    const [chargeOffRefund] = useMutation(CREATE_CHARGE_OFF_REFUND, {context: {clientName: 'billing'}});

    const formik = useFormik({
        initialValues: {
            sum: '',
            comment: ''
        },
        validationSchema: RefundChargeOffSchema,
        onSubmit: (values) => {
            setClicked(true);
            toast.promise(
                chargeOffRefund({
                    variables: {
                        cancellationDTO: {
                            transactionId: id,
                            amount: parseInt(values.sum),
                            comment: values.comment
                        }
                    }
                }), {
                    pending: 'В обработке',
                    success: 'Возврат по операции списания',
                    error: {
                        render({data}) {
                            return <span>{data.message}</span>
                        }
                    }
                }
            )
                .catch(err => {
                    setClicked(false);
                })
        }
    });

    console.log(transactionAmount)

    return (
        <div className="RefundChargeOff">
            <TransactionRefund
                allFormik={formik}
                title={"Возврат по операции списания"}
                transactionType={transactionType}
                clicked={clicked}
                transactionAmount={transactionAmount}
            />
        </div>
    );
};

export default RefundChargeOff;