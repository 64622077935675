import React, {useEffect, useState} from "react";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {GET_MERCHANTS, GET_PRODUCTS_BY_BIN} from "../../../GraphQL/Queries";
import {CREATE_ADVANCE_INVOICE} from "../../../GraphQL/Mutations";
import Loader from "../../../components/UI/Loader/Loader";
import {Breadcrumb, BreadcrumbItem, Button, Input, Label} from "reactstrap";
import {Link} from "react-router-dom";
import CustomAutocomplete from "../../../components/CustomAutocomplete/CustomAutocomplete";
import "./CreateInvoice.css";
import {toast} from "react-toastify";
import {DataTable} from "../../../components/DataTable/DataTable";

const CreateInvoice = () => {
    const [invoice, setInvoice] = useState({
        bin: null,
        numberOfMonths: 0
    });
    
    const {data, error, loading} = useQuery(GET_MERCHANTS, {variables: {sortBy: 'id', sortOrder: 'desc'}});
    const [getProducts, {data: productsData, loading: productsLoading, error: productsError}] = useLazyQuery(GET_PRODUCTS_BY_BIN, {context: { clientName: 'billing' }});
    const [createInvoice, {data: invoiceData, loading: invoiceLoading, error: invoiceError}] = useMutation(CREATE_ADVANCE_INVOICE, {context: { clientName: 'billing' }});

    const columns = [
        { title: '#', id: '#' },
        { title: 'Guid', id: 'guid' },
        { title: 'Дата создания', id: 'createdDate'},
        { title: 'Дата активации', id: 'activatedDate'},
        { title: 'Активный', id: 'active'},
        { title: 'Наим. тарифа', id: 'tariffName'},
        { title: 'Тест. период', id: 'trialDateTo'},
        { title: 'Причина тест. периода', id : 'trialDateComment'},
        { title: 'Дата создания инвойса', id: 'invoiceCreationDate'},
        { title: 'Действия', id: 'actions'},
    ];

    const rowsItem = [
        { title: '#' },
        { title: 'guid' },
        { title: 'createdDate' },
        { title: 'activatedDate' },
        { title: 'active' },
        { title: 'tariffName' },
        { title: 'trialDateTo'},
        { title: 'trialDateComment'},
        { title: 'invoiceCreationDate'}
    ];

    const [guids, setGuids] = useState([]);

    const [toggleAllActive, setToggleAllActive] = useState(false);

    const toggleGuid = (guid) => {
        let newguids = [...guids];
        let index = newguids.indexOf(guid);
        if (index === -1) newguids.push(guid);
        else newguids.splice(index, 1);
        setGuids(newguids)
    };

    const toggleAll = () => {
        setToggleAllActive(!toggleAllActive);
        if (!toggleAllActive) {
            setGuids(productsData?.productsByBin.map(terminal => terminal.guid))
        } else {
            setGuids([]);
        }
    };
    // useEffect(() => {
    //    
    // }, [productsData]);
    
    if (loading || productsLoading || invoiceLoading) return <Loader/>
    
    return (
        <>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem active>Создание инвойса (биллинг)</BreadcrumbItem>
            </Breadcrumb>
            <hr/>
            <div className={'invoice_wrapper'}>
                <Label>Выберите мерчанта: </Label>
                <div className={'filter_item'}>
                    <CustomAutocomplete
                        list={data? data.merchants : []}
                        name={'bin'}
                        setValue={(name, item) => {
                            if (item) {
                                setInvoice({...invoice, bin: item.bin});
                                getProducts({variables: { bin: item.bin }})
                            } else {
                                setInvoice({...invoice, bin: ''});
                            }
                        }}
                        placeholder="Наименование / Бин"
                        searchBy={["name", "bin"]}
                        resultStringKeyName='name'
                        getValueBy={'bin'}
                        edit={true}
                        value={invoice.bin}
                    />
                </div>
            </div>
            
            {productsData?.productsByBin
                ? <>
                    <DataTable
                        updateAction={true}
                        updateCheckboxHandler={(guid) => toggleGuid(guid)}
                        updateAllCheckboxHandler={() => toggleAll()}
                        toggleAllActive={toggleAllActive}
                        updateGuids={guids}
                        notStripped={false}
                        hideColumns={['#']}
                        columns={columns}
                        rows={productsData.productsByBin}
                        rowsItem={rowsItem}
                        sortByHandler={(e) => {}}
                        isPaginated={false}
                        showCancelInvoice={false}
                        showCheckBoxes={true}
                        hideIcons={true}
                    />
                    <div className={'invoice_wrapper'}>
                        <Label>Кол-во месяцев: </Label>
                        <div className={'filter_item'}>
                            <Input
                                placeholder={'Кол-во месяцев'}
                                name={'numberOfMonths'}
                                step="1"
                                min="1"
                                value={invoice.numberOfMonths}
                                type={"number"}
                                onChange={(e) => setInvoice({...invoice, numberOfMonths: e.target.value})}
                                onWheel={(e) => e.target.blur()}
                            />
                        </div>
                    </div>
                    <button 
                        onClick={() => {
                            toast.promise(
                                createInvoice(
                                    { variables: {
                                            model: {
                                                bin: invoice.bin,
                                                guids,
                                                numberOfMonths: invoice.numberOfMonths
                                            }
                                        }}
                                ), {
                                    pending: 'В обработке',
                                    success: 'Инвойс создан',
                                    error: {
                                        render({data}){
                                            return <span>{data.message}</span>
                                        }
                                    }
                                }
                            )
                        }} 
                        className="refund_completion_button" 
                        disabled={guids.length === 0 || invoice.numberOfMonths < 1}
                    >
                        Создать
                    </button>
                </>
                : null
            }
        </>
    )
};

export default CreateInvoice;