import React, {useState} from 'react';
import {Button, Col, Form, FormFeedback, Input, Label, Row} from "reactstrap";
import {useMutation} from "@apollo/client";
import {TOP_UP_BONUS} from "../../GraphQL/Mutations";
import {useFormik} from "formik";
import {TopUpBonusSchema} from "../../schemas";
import {toast} from "react-toastify";
import './TopUpBonus.css';

const TopUpBonus = ({bin}) => {
    const [clicked, setClicked] = useState(false);

    const [topUpBalance] = useMutation(TOP_UP_BONUS, { context: { clientName: 'billing' }});

    const formik = useFormik({
        initialValues: {
            bin: bin,
            amount: 0,
            type: '',
            description: ''
        },
        validationSchema: TopUpBonusSchema,
        onSubmit: (values) => {
            setClicked(true);
            toast.promise(
                topUpBalance({
                    variables: {
                        bin: bin,
                        amount: parseInt(values.amount),
                        type: values.type,
                        description: values.description
                    }
                }), {
                    pending: 'В обработке',
                    success: 'Бонусы пополнены',
                    error: 'Ошибка'
                }
            )
                .then(result => {
                    // navigate('/billing/invoices');
                })
                .catch(err => {
                    setClicked(false);
                })
        }
    });
    
    return (
        <div>
            <Form onSubmit={formik.handleSubmit}>
                <div className={'create_invoice_wrapper'}>
                    <h3>Пополнение баланса</h3>
                    <Row className={'create_invoice_row'}>
                        <Col><Label htmlFor={'bin'}>БИН</Label></Col>
                        <Col>
                            <Input
                                type={'text'}
                                id={'bin'}
                                name={'bin'}
                                value={formik.values.bin}
                                disabled
                                invalid={formik.touched.bin && Boolean(formik.errors.bin)}
                            />
                            <FormFeedback>
                                {formik.touched.bin && formik.errors.bin}
                            </FormFeedback>
                        </Col>
                    </Row>
                    <Row className={'create_invoice_row'}>
                        <Col><Label htmlFor={'type'}>Тип</Label></Col>
                        <Col>
                            <Input 
                                type={'select'}
                                id={'type'}
                                name={'type'}
                                value={formik.values.type}
                                invalid={formik.touched.type && Boolean(formik.errors.type)}
                                onChange={formik.handleChange}
                            >
                                <option value={''}>Выберите тип оплаты</option>
                                <option>Bonus</option>
                                <option>NonKaspi</option>
                            </Input>
                            <FormFeedback>
                                {formik.touched.type && formik.errors.type}
                            </FormFeedback>
                        </Col>
                    </Row>
                    <Row className={'create_invoice_row'}>
                        <Col><Label htmlFor={'amount'}>Сумма</Label></Col>
                        <Col>
                            <Input
                                type={'number'}
                                id={'amount'}
                                name={'amount'}
                                value={formik.values.amount}
                                onChange={formik.handleChange}
                                invalid={formik.touched.amount && Boolean(formik.errors.amount)}
                            />
                            <FormFeedback>
                                {formik.touched.amount && formik.errors.amount}
                            </FormFeedback>
                        </Col>
                    </Row>
                    <Row className={'create_invoice_row'}>
                        <Col><Label htmlFor={'description'}>Комментарии</Label></Col>
                        <Col>
                            <Input
                                type={'textarea'}
                                id={'description'}
                                name={'description'}
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                invalid={formik.touched.description && Boolean(formik.errors.description)}
                            />
                            <FormFeedback>
                                {formik.touched.description && formik.errors.description}
                            </FormFeedback>
                        </Col>
                    </Row>
                    <Button color={'primary'} type={'submit'} className="filter_button" disabled={clicked}>Пополнить</Button>
                </div>
            </Form>
        </div>
    );
};

export default TopUpBonus;