import React, {useState} from 'react';
import {Button, Col, Form, FormFeedback, Input, Label, Row} from "reactstrap";
import {useMutation} from "@apollo/client";
import {CREATE_INVOICE} from "../../GraphQL/Mutations";
import {GET_INVOICES} from "../../GraphQL/Queries";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {CreateInvoiceSchema} from "../../schemas";
import {toast} from "react-toastify";
import './CreateInvoice.css';

const CreateInvoice = ({bin, invoiceTypesData}) => {
    let date = new Date().toISOString();
    const [clicked, setClicked] = useState(false);

    const [createInvoice] = useMutation(CREATE_INVOICE, {
        awaitRefetchQueries: true,
        refetchQueries: [{
            query: GET_INVOICES, variables: {
                filter: {
                    dateFrom: `${date.slice(0, 11)}00:00`,
                    dateTo: `${date.slice(0, 11)}23:59`,
                }, page: 0, size: 25, sortBy: 'id', sortOrder: 'desc'
            }, context: {clientName: 'billing'}
        }],
        context: {clientName: 'billing'}
    });

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            bin: bin,
            comment: '',
            amount: 0,
            type: ''
        },
        validationSchema: CreateInvoiceSchema,
        onSubmit: (values) => {
            setClicked(true);
            toast.promise(
                createInvoice({
                    variables: {
                        bin: bin,
                        comment: values.comment,
                        amount: parseInt(values.amount),
                        type: values.type
                    }
                }), {
                    pending: 'В обработке',
                    success: 'Инвойс успешно создан',
                    error: 'Ошибка'
                }
            )
                .then(result => {
                    navigate('/billing/invoices');
                })
                .catch(err => {
                    setClicked(false);
                })
        }
    });

    return (
        <div>
            <Form onSubmit={formik.handleSubmit}>
                <div className={'create_invoice_wrapper'}>
                    <h3>Создание инвойса</h3>
                    <Row className={'create_invoice_row'}>
                        <Col><Label htmlFor={'bin'}>БИН</Label></Col>
                        <Col>
                            <Input
                                type={'text'}
                                id={'bin'}
                                name={'bin'}
                                value={formik.values.bin}
                                disabled
                                invalid={formik.touched.bin && Boolean(formik.errors.bin)}
                            />
                            <FormFeedback>
                                {formik.touched.bin && formik.errors.bin}
                            </FormFeedback>
                        </Col>
                    </Row>
                    <Row className={'create_invoice_row'}>
                        <Col><Label htmlFor={'amount'}>Сумма</Label></Col>
                        <Col>
                            <Input
                                type={'number'}
                                id={'amount'}
                                name={'amount'}
                                value={formik.values.amount}
                                onChange={formik.handleChange}
                                invalid={formik.touched.amount && Boolean(formik.errors.amount)}
                            />
                            <FormFeedback>
                                {formik.touched.amount && formik.errors.amount}
                            </FormFeedback>
                        </Col>
                    </Row>
                    <Row className={'create_invoice_row'}>
                        <Col><Label htmlFor={'comment'}>Комментарии</Label></Col>
                        <Col>
                            <Input
                                type={'textarea'}
                                id={'comment'}
                                name={'comment'}
                                value={formik.values.comment}
                                onChange={formik.handleChange}
                            />
                        </Col>
                    </Row>
                    <Row className={'create_invoice_row'}>
                        <Col><Label htmlFor={'types'}>Тип</Label></Col>
                        <Col>

                            <Input
                                type={'select'}
                                id={'type'}
                                name={'type'}
                                value={formik.values.type}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                style={{display: "block"}}
                            >
                                <option value={''}>Выберите тип</option>
                                {invoiceTypesData.invoiceTypes.map(item => {
                                    if (item.type !== 'subscription') {
                                        return (
                                            <option
                                                value={item.type}
                                                label={item.russian}
                                                key={item.type}
                                            >{item.type}</option>
                                        )
                                    }
                                })}
                            </Input>
                        </Col>
                    </Row>
                    <Button color={'primary'} type={'submit'} className="filter_button"
                            disabled={clicked}>Создать</Button>
                </div>
            </Form>
        </div>
    );
};

export default CreateInvoice;