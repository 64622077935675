import {gql} from "@apollo/client";

export const CREATE_MERCHANT = gql`
    mutation createMerchant($name: String!, $bin: String!, $phone: String!, $helpDeskPhone: String) {
        createMerchant(name: $name, bin: $bin, phone: $phone, helpDeskPhone: $helpDeskPhone) {
            id,
            name,
            bin,
            phone,
            helpDeskPhone
        }
    }
`;

export const CREATE_SERVICE = gql`
    mutation createService($nameRus: String!, $nameKaz: String, $amount: Float!, $descriptionRus: String!, $descriptionKaz: String) {
        createService(nameRus: $nameRus, nameKaz: $nameKaz, amount: $amount, descriptionRus: $descriptionRus, descriptionKaz: $descriptionKaz){
            nameRus,
            nameKaz,
            amount,
            descriptionRus,
            descriptionKaz
        }
    }
`;

export const CREATE_SERVICE_PROFILE = gql`
    mutation createServicesProfile($name: String!) {
        createServicesProfile(name: $name, services: []){
            name
        }
    }
`;

export const UPDATE_SERVICE_PROFILE = gql`
    mutation updateServicesProfile($servicesProfile: ServicesProfileInput) {
        updateServicesProfile(servicesProfile: $servicesProfile){
            id
            name
            services {
                id
            }
        }
    }
`;

export const UPDATE_SERVICE = gql`
    mutation updateService($service: ServiceInput) {
        updateService(service: $service){
            id
            nameRus
            nameKaz
            amount
            descriptionRus
            descriptionKaz
        }
    }
`;

export const UPDATE_MERCHANT = gql`
    mutation updateMerchant($merchant: MerchantInput) {
        updateMerchant(merchant: $merchant){
            id
            name
            phone
            bin
            helpDeskPhone
        }
    }
`;

export const CREATE_TERMINAL = gql`
    mutation createTerminal($guid: String!, $name: String!, $typeId: ID!, $address: String!, $step: Int, $merchantId: Int!, $servicesProfileId: Int, $paymentType: PaymentType!, $useCredit: Boolean, $useAMQP: Boolean, $creditAmount: Float, $active: Boolean, $modemPhoneNumber: String!, $imei: String!){
        createTerminal(guid: $guid, name: $name, typeId: $typeId, address: $address, step: $step, merchantId: $merchantId, servicesProfileId: $servicesProfileId, paymentType: $paymentType, useCredit: $useCredit, useAMQP: $useAMQP, creditAmount: $creditAmount, active: $active, modemPhoneNumber: $modemPhoneNumber, imei: $imei){
            id
            guid
            name
            typeId
            address
            step
            merchantId
            servicesProfileId
            paymentType
            active
            modemPhoneNumber
            imei
            useCredit
            creditAmount
            useAMQP
        }
    }
`;

export const UPDATE_TERMINAL = gql`
    mutation updateTerminal($terminal: TerminalInput) {
        updateTerminal(terminal: $terminal) {
            id
            guid
            name
            typeId
            address
            step
            merchantId
            servicesProfileId
            paymentType
            active
            modemPhoneNumber
            imei
            useCredit
            creditAmount
            useAMQP
        }
    }
`;

export const CREATE_TERMINAL_TYPE = gql`
    mutation createTerminalType($name: String!, $kaspiQrURL: String!) {
        createTerminalType(name: $name, kaspiQrURL: $kaspiQrURL) {
            id
            name
            kaspiQrURL
        }
    }
`;

export const UPDATE_TERMINAL_TYPE = gql`
    mutation updateTerminalType($id: ID!, $name: String!, $kaspiQrURL: String!) {
        updateTerminalType(id: $id, name: $name, kaspiQrURL: $kaspiQrURL) {
            id
            name
            kaspiQrURL
        }
    }
`;

export const CREATE_TARIFF = gql`
    mutation createTariff($name: String!, $productType: ProductType!, $amount: Float!) {
        createTariff(name: $name, productType: $productType, amount: $amount) {
            id
            name
            productType
            amount
        }
    }
`;

export const UPDATE_TARIFF = gql`
    mutation updateTariff($tariff: TariffInput) {
        updateTariff(tariff: $tariff) {
            id
            name
            productType
            amount
        }
    }
`;

export const CREATE_INVOICE = gql`
    mutation createInvoice($bin: String!, $comment: String, $amount: Float!, $type: InvoiceType) {
        createInvoice(bin: $bin, comment: $comment, amount: $amount, type: $type) {
            id
            createdDate
            changedDate
            amount
            merchantId
            status
        }
    }
`;

export const CANCEL_INVOICE = gql`
    mutation cancelInvoice($invoiceId: ID!, $comment: String!){
        cancelInvoice(invoiceId: $invoiceId, comment: $comment){
            id
            status
            createdDate
            changedDate
            amount
            merchantId
        }
    }
`;

// export const CREATE_PRODUCT = gql`
//     mutation createProduct($guid: String!, $tariffId: Int!, $bin: String!, $trialDateTo: String){
//         createOrUpdateProduct(guid: $guid, tariffId: $tariffId, bin: $bin, trialDateTo: $trialDateTo){
//             guid
//             createdDate
//             activatedDate
//             active
//             tariffId
//             bin
//             trialDateTo
//         }
//     }
// `;

export const CREATE_PRODUCT = gql`
    mutation createProduct($productDTO: ProductDTO){
        createOrUpdateProduct(productDTO: $productDTO){
            guid
            createdDate
            activatedDate
            active
            tariffId
            bin
            trialDateTo
        }
    }
`;

export const TOP_UP_BONUS = gql`
    mutation topUpBalanceManually($bin: String!, $amount: Float!, $type: TransactionType!, $description: String){
        topUpBalanceManually(bin: $bin, amount: $amount, type: $type, description: $description){
            id
            txnId
            txnDate
            amount
            createdDate
            type
            accountId
            invoiceId
            description
        }
    }
`;

export const KASPI_BANK_PUSH_NOTIFY = gql`
    mutation kaspiBankPushNotify($invoiceId: ID!) {
        kaspiBankPushNotify(invoiceId: $invoiceId) {
            success
            message
        }
    }
`;

export const UPDATE_PUSH_PARAMETERS = gql`
    mutation updatePushParameters($merchantModel: MerchantModel){
        updatePushParameters(merchantModel: $merchantModel)
    }
`;

export const CHANGE_PRODUCT_MERCHANT = gql`
    mutation changeProductsMerchant($guid: String!, $bin:String!){
        changeProductsMerchant(guid: $guid, bin: $bin) {
            guid
            createdDate
            activatedDate
            active
            tariffId
            bin
        }
    }
`;

export const KASPI_BANK_PUSH_NOTIFY_FILTERED = gql`
    mutation getKaspiBankPushNotifyFiltered($filter: InvoiceFilter) {
        kaspiBankPushNotifyFiltered(filter: $filter) {
            success
            message
        }
    }
`;

export const CREATE_KASPI_REFUND = gql`
    mutation createKaspiRefund($cancellationDTO: CancellationDTO!) {
        createKaspiRefund(cancellationDTO: $cancellationDTO){
            id
            createdDate
            changedDate
            amount
            status
            transactionId
            txn_id
            comment
        }
    }
`;

export const CREATE_CHARGE_OFF_REFUND = gql`
    mutation chargeOffRefund($cancellationDTO: CancellationDTO!) {
        createChargeOffRefund(cancellationDTO: $cancellationDTO){
            id
            createdDate
            changedDate
            amount
            status
            transactionId
            txn_id
            comment
        }
    }
`;

export const COMPLETE_REFUND = gql`
    mutation completeRefund($refundId: String!) {
        completeRefund(refundId: $refundId) {
            id
            createdDate
            changedDate
            amount
            status
            transactionId
            txn_id
            comment
        }
    }
`;

export const CREATE_BONUS_REFUND = gql`
    mutation createBonusRefund($cancellationDTO: CancellationDTO!) {
        createBonusRefund(cancellationDTO: $cancellationDTO) {
            id
            createdDate
            changedDate
            amount
            status
            transactionId
            txn_id
            comment
        }
    }
`;

export const ACTIVATE_PRODUCT =gql`
    mutation activateProduct($guid:String!){
        activateProduct(guid: $guid) {
            success
            message
        }
    }
`;

export const DEACTIVATE_TERMINAL = gql`
    mutation productActivationRefund($model: DeActivationModel){
        productActivationRefund(model: $model){
            success
            message
        }
    }
`;

export const CREATE_ADVANCE_INVOICE = gql`
    mutation createAdvanceInvoice($model: AdvanceInvoice){
          createAdvanceInvoice(model: $model) {
            id
            createdDate
            changedDate
            amount
            merchantId
            status
          }
    }
`;