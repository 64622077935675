import React, { useState, useEffect } from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {CREATE_TERMINAL} from "../../GraphQL/Mutations";
import {
    GET_MERCHANTS,
    GET_SERVICE_PROFILES,
    GET_TERMINAL,
    GET_TERMINAL_TYPES,
    GET_TERMINALS
} from "../../GraphQL/Queries";
import {toast} from "react-toastify";
import Loader from "../../components/UI/Loader/Loader";
import {Breadcrumb, BreadcrumbItem, Form, Row, Col, Button, Label, Input, FormFeedback} from "reactstrap";
import CustomAutocomplete from "../../components/CustomAutocomplete/CustomAutocomplete";
import InputMask from "react-input-mask";
import {useFormik} from "formik";
import {CreateTerminalSchema} from "../../schemas";

const TerminalDuplicate = () => {
    const [clicked, setClicked] = useState(false);

    const params = useParams();

    const merchants = useQuery(GET_MERCHANTS, { variables: {
            sortBy: "id",
            sortOrder: 'asc'
        }}
    );

    const serviceProfile = useQuery(GET_SERVICE_PROFILES, { variables: {
            sortBy: "id"
        }}
    );

    const terminalTypes = useQuery(GET_TERMINAL_TYPES, { variables: {
            sortBy: 'id',
            sortOrder: 'asc'
        }}
    );

    const [createTerminal] = useMutation(CREATE_TERMINAL, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_TERMINALS, variables: { sortBy: 'id'}}],
    });
    
    const navigate = useNavigate();

    const [terminal, setTerminal] = useState({
        id: '',
        guid: '',
        name: '',
        address: '',
        step: '',
        useCredit: '',
        creditAmount: '',
        useAMQP: false,
        merchantId: '',
        servicesProfileId: '',
        paymentType: '',
        active: true,
        modemPhoneNumber: '',
        imei: ''
    });

    const handleChange = (e) => {
        setTerminal({
            ...terminal,
            [e.target.name]: e.target.value
        })
    };

    const getMerchantBinById = (id) => {
        console.log(id)
        let merch = merchants.data?.merchants.find(item => parseInt(item.id) === id);
        // let merch = merchants?.find(item => parseInt(item.id) === id);
        console.log(merch)
        return merch.bin;
    };

    const { loading, error, data } = useQuery(GET_TERMINAL, { variables: {
            id: params.id
        }});

    useEffect(() => {
        if (data && data.terminal) {
            setTerminal({
                ...terminal,
                name: data.terminal.name,
                address: data.terminal.address,
                step: data.terminal.step,
                typeId: data.terminal.typeId,
                merchantId: data.terminal.merchantId,
                servicesProfileId: data.terminal.servicesProfileId,
                paymentType: data.terminal.paymentType,
                active: data.terminal.active,
                useCredit: data.terminal.useCredit,
                creditAmount: data.terminal.creditAmount,
                useAMQP: data.terminal.useAMQP,
            })
        }
    },[data]);

    const formik = useFormik({
        initialValues: terminal,
        enableReinitialize: true,
        validationSchema: CreateTerminalSchema,
        onSubmit: (values) => {
            setClicked(true);

            let variables;
            console.log(values.paymentType)
            if (values.paymentType === "SERVICE"){
                variables = {
                    guid: values.guid,
                    name: values.name,
                    typeId: values.typeId,
                    useCredit: terminal.useCredit,
                    creditAmount: terminal.useCredit ? values.creditAmount : 0,
                    useAMQP: terminal.useAMQP,
                    address: values.address,
                    step: values.step,
                    merchantId: values.merchantId,
                    servicesProfileId: values.servicesProfileId,
                    paymentType: values.paymentType,
                    modemPhoneNumber: values.modemPhoneNumber,
                    imei: values.imei,
                    active: terminal.active,
                }
            } else {
                variables = {
                    guid: values.guid,
                    name: values.name,
                    typeId: terminal.typeId,
                    useCredit: terminal.useCredit,
                    creditAmount: terminal.useCredit ? values.creditAmount : 0,
                    useAMQP: terminal.useAMQP,
                    address: values.address,
                    merchantId: terminal.merchantId,
                    paymentType: values.paymentType,
                    modemPhoneNumber: values.modemPhoneNumber,
                    imei: values.imei,
                    active: terminal.active,
                }
            }
            toast.promise(
                createTerminal({variables: variables}), {
                    pending: 'В обработке',
                    success: 'Терминал успешно создан',
                    error: 'Ошибка'
                }
            )
                .then(result => {
                    console.log(result)
                    navigate(`/billing/terminal?guid=${result.data.createTerminal.guid}&bin=${getMerchantBinById(result.data.createTerminal.merchantId)}`)
                })
                .catch(err => {
                    setClicked(false);
                })
        }
    });

    const autocompleteChangeHandler = (name, id) => {
        console.log(id);
        setTerminal({
            ...terminal,
            [name]: id
        })
    };

    const handleActiveChange = (e) => {
        setTerminal({
            ...terminal,
            [e.target.name]: !terminal[e.target.name]
        })
    }

    if (loading || merchants.loading || serviceProfile.loading || terminalTypes.loading) return <Loader/>

    return (
        <div>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to='/terminals' className="breadcrumb_link">Терминалы</Link></BreadcrumbItem>
                <BreadcrumbItem active>{data.terminal.name}</BreadcrumbItem>
            </Breadcrumb>

            <h3 className="main_title">Дублирование терминала</h3>

            <Form onSubmit={formik.handleSubmit}>
                <Row className='row_form'>
                    <Col xs={4}><Label for="guid">GUID</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="guid"
                            id="guid"
                            value={formik.values.guid}
                            invalid={formik.touched.guid && Boolean(formik.errors.guid)}
                            onChange={formik.handleChange}
                        />
                        <FormFeedback>
                            {formik.touched.guid && formik.errors.guid}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="name">Наименование</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            invalid={formik.touched.name && Boolean(formik.errors.name)}
                        />
                        <FormFeedback>
                            {formik.touched.name && formik.errors.name}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="merchantId">Мерчант</Label></Col>
                    <Col xs={8}>
                        <CustomAutocomplete
                            list={merchants.data?.merchants}
                            placeholder="Наименование мерчанта"
                            name={'merchantId'}
                            setValue={(name, item) => autocompleteChangeHandler(name, item.id)}
                            searchBy={['name']}
                            resultStringKeyName='name'
                            getValueBy={'id'}
                            edit={true}
                            value={formik.values.merchantId || null}
                            invalid={formik.touched.merchantId && Boolean(formik.errors.merchantId)}
                            formFeedback={formik.touched.merchantId && formik.errors.merchantId}
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="paymentType">Тип оплаты</Label></Col>
                    <Col xs={8}>
                        <Input
                                type="select"
                                name="paymentType"
                                id="paymentType"
                                required
                                onChange={handleChange}
                                value={formik.values.paymentType}
                                invalid={formik.touched.paymentType && Boolean(formik.errors.paymentType)}
                        >
                                <option value="default" disabled>Выберите тип оплаты</option>
                                <option>
                                    INVOICE
                                </option>
                                <option>
                                    SERVICE
                                </option>
                        </Input>
                        <FormFeedback>
                            {formik.touched.paymentType && formik.errors.paymentType}
                        </FormFeedback>
                    </Col>
                </Row>
                {formik.values.paymentType === "INVOICE"
                    ? null
                    : <Row className='row_form'>
                        <Col xs={4}><Label for="servicesProfileId">Профиль услуги</Label></Col>
                        <Col xs={8}>
                            <CustomAutocomplete
                                list={serviceProfile.data?.servicesProfiles}
                                placeholder="Наименование профиля услуги"
                                name={'servicesProfileId'}
                                setValue={(name, item) => autocompleteChangeHandler(name, item.id)}
                                searchBy={['name']}
                                getValueBy={'id'}
                                resultStringKeyName='name'
                                edit={true}
                                value={formik.values.servicesProfileId || null}
                                invalid={formik.touched.servicesProfileId && Boolean(formik.errors.servicesProfileId)}
                                formFeedback={formik.touched.servicesProfileId && formik.errors.servicesProfileId}
                            />
                        </Col>
                    </Row>
                }
                {formik.values.paymentType === "INVOICE"
                    ? null
                    : <Row className='row_form'>
                        <Col xs={4}><Label for="step">Шаг</Label></Col>
                        <Col xs={8}>
                            <Input
                                type="text"
                                name="step"
                                id="step"
                                value={formik.values.step}
                                onChange={formik.handleChange}
                                invalid={formik.touched.step && Boolean(formik.errors.step)}
                            />
                        </Col>
                        <FormFeedback>
                            {formik.touched.step && formik.errors.step}
                        </FormFeedback>
                    </Row>
                }

                <Row className='row_form'>
                    <Col xs={4}><Label for="typeId">Тип</Label></Col>
                    <Col xs={8}>
                        <CustomAutocomplete
                            list={terminalTypes.data?.terminalTypes}
                            placeholder="Тип"
                            name={'typeId'}
                            setValue={(name, item) => autocompleteChangeHandler(name, item.id)}
                            searchBy={['name']}
                            resultStringKeyName='name'
                            getValueBy={'id'}
                            edit={true}
                            value={formik.values.typeId || null}
                            invalid={formik.touched.typeId && Boolean(formik.errors.typeId)}
                            formFeedback={formik.touched.typeId && formik.errors.typeId}
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={12}>
                        <Label for="useCredit" className="activeTerminalLabel">Использовать кредит</Label>
                        <Input type="checkbox" name="useCredit" id="useCredit" checked={terminal.useCredit} onChange={handleActiveChange}/>
                    </Col>
                </Row>
                {terminal.useCredit
                    ? <Row className='row_form'>
                        <Col xs={4}><Label for="creditAmount">Сумма кредита</Label></Col>
                        <Col xs={8}>
                            <Input
                                type="number"
                                name="creditAmount"
                                id="creditAmount"
                                invalid={formik.touched.creditAmount && Boolean(formik.errors.creditAmount)}
                                onChange={formik.handleChange}
                                value={formik.values.creditAmount}
                            />
                            <FormFeedback>
                                {formik.touched.creditAmount && formik.errors.creditAmount}
                            </FormFeedback>
                        </Col>
                    </Row>
                    : null
                }
                <Row className='row_form'>
                    <Col xs={12}>
                        <Label for="useAMQP" className="activeTerminalLabel">Новый протокол</Label>
                        <Input type="checkbox" name="useAMQP" id="useAMQP" checked={terminal.useAMQP} onChange={handleActiveChange}/>
                    </Col>
                </Row>               
                <Row className='row_form'>
                    <Col xs={4}><Label for="address">Адрес</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="address"
                            id="address"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            invalid={formik.touched.address && Boolean(formik.errors.address)}
                        />
                        <FormFeedback>
                            {formik.touched.address && formik.errors.address}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="modemPhoneNumber">Номер сим-карты</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="tel"
                            mask="+7(999)999-99-99"
                            maskChar="_"
                            name="modemPhoneNumber"
                            id="modemPhoneNumber"
                            pattern={'^(\\+\\d{1})\\(\\d{3}\\)\\d{3}[\\s.-]\\d{2}[\\s.-]\\d{2}$'}
                            title={'+7(XXX)XXX-XX-XX'}
                            value={formik.values.modemPhoneNumber}
                            invalid={formik.touched.modemPhoneNumber && Boolean(formik.errors.modemPhoneNumber)}
                            placeholder={'+7(XXX)XXX-XX-XX'}
                            onChange={formik.handleChange}
                            tag={InputMask}
                        />
                        <FormFeedback>
                            {formik.touched.modemPhoneNumber && formik.errors.modemPhoneNumber}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="imei">IMEI</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="imei"
                            id="imei"
                            value={formik.values.imei}
                            invalid={formik.touched.imei && Boolean(formik.errors.imei)}
                            onChange={formik.handleChange}
                        />
                        <FormFeedback>
                            {formik.touched.imei && formik.errors.imei}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={12}>
                        <Label for="active" className="activeTerminalLabel">Активный</Label>
                        <Input type="checkbox" name="active" id="active" checked={terminal.active} onChange={handleActiveChange}/>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Button type="button" color="dark" onClick={() => navigate(-1)} className={'crud_button'}>Назад</Button></Col>
                    <Col xs={4}><Button type="submit" color="primary" className="crud_button mr" disabled={clicked}>
                        Сохранить
                    </Button></Col>
                </Row>
            </Form>
        </div>
    );
};

export default TerminalDuplicate;