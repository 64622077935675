import React, {useState} from 'react';
import {useNavigate, Link} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem, Button, Col, Form, FormFeedback, Input, Label, Row} from 'reactstrap';
import {useMutation, useQuery} from "@apollo/client";
import {CREATE_TERMINAL} from "../../GraphQL/Mutations";
import {GET_MERCHANTS, GET_SERVICE_PROFILES, GET_TERMINAL_TYPES, GET_TERMINALS} from "../../GraphQL/Queries";
import {useFormik} from "formik";
import {CreateTerminalSchema} from "../../schemas";
import {toast} from "react-toastify";
import CustomAutocomplete from '../../components/CustomAutocomplete/CustomAutocomplete';
import InputMask from 'react-input-mask';
import Loader from "../../components/UI/Loader/Loader";

const TerminalNew = () => {
    const [clicked, setClicked] = useState(false);

    const { loading, error, data } = useQuery(GET_MERCHANTS, { variables: {
            sortBy: "id",
            sortOrder: "asc"
        }}
    );
    
    const serviceProfile = useQuery(GET_SERVICE_PROFILES, { variables: {
            sortBy: "id"
        }}
    );
    
    const terminalTypes = useQuery(GET_TERMINAL_TYPES, { variables: {
            sortBy: 'id',
            sortOrder: 'asc'
        }}
    );
    
    const getMerchantBinById = (id) => {
        console.log(id)
        let merch = data.merchants?.find(item => parseInt(item.id) === id);
        console.log(merch)
        return merch.bin;
    };
    
    const [terminal, setTerminal] = useState({
        merchantId: '',
        typeId: '',
        servicesProfileId: '',
        active: true,
        useCredit: false,
        useAMQP: false
    });

    const autocompleteChangeHandler = (name, id) => {
        console.log(id);
        console.log(name)
        setTerminal({
            ...terminal,
            [name]: id
        });
        formik.values[name] = id;
    };
    
    const activeChangeHandler = (e) => {
        setTerminal({
            ...terminal,
            [e.target.name]: !terminal[e.target.name]
        })
    }

    const [createTerminal] = useMutation(CREATE_TERMINAL, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_TERMINALS, variables: { sortBy: 'id'}}],
    });
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            guid: '',
            name: '',
            typeId: '',
            creditAmount: 0,
            address: '',
            step: '',
            merchantId: '',
            servicesProfileId: '',
            paymentType: '',
            modemPhoneNumber: '',
            imei: ''
        },
        validationSchema: CreateTerminalSchema,
        onSubmit: (values) => {
            setClicked(true);
            
            let variables;
            console.log(values.paymentType)
            if (values.paymentType === "SERVICE"){
                variables = {
                    guid: values.guid,
                    name: values.name,
                    typeId: values.typeId,
                    useCredit: terminal.useCredit,
                    useAMQP: terminal.useAMQP,
                    creditAmount: values.creditAmount,
                    address: values.address,
                    step: values.step,
                    merchantId: values.merchantId,
                    servicesProfileId: values.servicesProfileId,
                    paymentType: values.paymentType,
                    modemPhoneNumber: values.modemPhoneNumber,
                    imei: values.imei,
                    active: terminal.active,
                }
            } else {
                variables = {
                    guid: values.guid,
                    name: values.name,
                    typeId: terminal.typeId,
                    useCredit: terminal.useCredit,
                    useAMQP: terminal.useAMQP,
                    creditAmount: values.creditAmount,
                    address: values.address,
                    merchantId: terminal.merchantId,
                    paymentType: values.paymentType,
                    modemPhoneNumber: values.modemPhoneNumber,
                    imei: values.imei,
                    active: terminal.active,
                }
            }
            toast.promise(
                createTerminal({variables: variables}), {
                    pending: 'В обработке',
                    success: 'Терминал успешно создан',
                    error: 'Ошибка'
                }
            )
                .then(result => {
                    console.log(result.data.createTerminal)
                    navigate(`/billing/terminal?guid=${result.data.createTerminal.guid}&bin=${getMerchantBinById(result.data.createTerminal.merchantId)}`)
                })
                .catch(err => {
                    console.log(err)
                    setClicked(false);
                })
        }
    });

    if (loading || serviceProfile.loading || terminalTypes.loading) return <Loader/>
   
    return (
        <div>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to='/terminals' className="breadcrumb_link">Терминалы</Link></BreadcrumbItem>
                <BreadcrumbItem active>Добавить</BreadcrumbItem>
            </Breadcrumb>
            
            <h3 className="main_title">Создание терминала</h3>
            <Form onSubmit={formik.handleSubmit}>
                <Row className='row_form'>
                    <Col xs={4}><Label for="guid">GUID</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="guid"
                            id="guid"
                            invalid={formik.touched.guid && Boolean(formik.errors.guid)}
                            onChange={formik.handleChange}
                        />
                        <FormFeedback>
                            {formik.touched.guid && formik.errors.guid}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="name">Наименование</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            invalid={formik.touched.name && Boolean(formik.errors.name)}
                            onChange={formik.handleChange}
                        />
                        <FormFeedback>
                            {formik.touched.name && formik.errors.name}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="typeId">Тип</Label></Col>
                    <Col xs={8}>
                        <CustomAutocomplete
                            list={terminalTypes.data?.terminalTypes}
                            placeholder="Тип терминала"
                            name="typeId"
                            id="typeId"
                            setValue={(name, item) => autocompleteChangeHandler(name, item.id)}
                            // setValue={(e) => formik.handleChange(e)}
                            searchBy={['name']}
                            resultStringKeyName='name'
                            invalid={formik.touched.typeId && Boolean(formik.errors.typeId)}
                            formFeedback={formik.touched.typeId && formik.errors.typeId}
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="paymentType">Тип оплаты</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="select"
                            name="paymentType"
                            id="paymentType"
                            required
                            invalid={formik.touched.paymentType && Boolean(formik.errors.paymentType)}
                            onChange={formik.handleChange}
                            defaultValue="default"
                        >
                            <option value="default" disabled>Выберите тип оплаты</option>
                            <option>
                                INVOICE
                            </option>
                            <option>
                                SERVICE
                            </option>
                        </Input>
                        <FormFeedback>
                            {formik.touched.paymentType && formik.errors.paymentType}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={12}>
                        <Label for="useCredit" className="activeTerminalLabel">Использовать кредит</Label>
                        <Input type="checkbox" name="useCredit" id="useCredit" checked={terminal.useCredit} onChange={activeChangeHandler}/>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={12}>
                        <Label for="useAMQP" className="activeTerminalLabel">Новый протокол</Label>
                        <Input type="checkbox" name="useAMQP" id="useAMQP" checked={terminal.useAMQP} onChange={activeChangeHandler}/>
                    </Col>
                </Row>
                {terminal.useCredit
                    ? <Row className='row_form'>
                        <Col xs={4}><Label for="creditAmount">Сумма кредита</Label></Col>
                        <Col xs={8}>
                            <Input
                                type="number"
                                name="creditAmount"
                                id="creditAmount"
                                invalid={formik.touched.creditAmount && Boolean(formik.errors.creditAmount)}
                                onChange={formik.handleChange}
                            />
                            <FormFeedback>
                                {formik.touched.creditAmount && formik.errors.creditAmount}
                            </FormFeedback>
                        </Col>
                    </Row>
                    : null
                }
                <Row className='row_form'>
                    <Col xs={4}><Label for="address">Адрес</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="address"
                            id="address"
                            invalid={formik.touched.address && Boolean(formik.errors.address)}
                            onChange={formik.handleChange}
                        />
                        <FormFeedback>
                            {formik.touched.address && formik.errors.address}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="merchantId">Мерчант</Label></Col>
                    <Col xs={8}>
                        <CustomAutocomplete
                            list={data?.merchants}
                            placeholder="Наименование мерчанта"
                            name="merchantId"
                            id="merchantId"
                            setValue={(name, item) => autocompleteChangeHandler(name, item.id)}
                            // setValue={formik.handleChange}
                            searchBy={['name', 'bin']}
                            resultStringKeyName='name'
                            invalid={formik.touched.merchantId && Boolean(formik.errors.merchantId)}
                            formFeedback={formik.touched.merchantId && formik.errors.merchantId}
                        />
                    </Col>
                </Row>
                
                {formik.values.paymentType === "SERVICE" 
                    ? <Row className='row_form'>
                        <Col xs={4}><Label for="servicesProfileId">Профиль услуги</Label></Col>
                        <Col xs={8}>
                            <CustomAutocomplete
                                list={serviceProfile.data?.servicesProfiles}
                                name={'servicesProfileId'}
                                setValue={(name, item) => autocompleteChangeHandler(name, item.id)}
                                placeholder="Наименование профиля услуги"
                                searchBy={['name']}
                                resultStringKeyName='name'
                                invalid={formik.touched.servicesProfileId && Boolean(formik.errors.servicesProfileId)}
                                formFeedback={formik.touched.servicesProfileId && formik.errors.servicesProfileId}
                            />
                        </Col>
                    </Row>
                    : null
                }
                {formik.values.paymentType === "SERVICE"
                    ? <Row className='row_form'>
                        <Col xs={4}><Label for="step">Шаг</Label></Col>
                        <Col xs={8}>
                            <Input
                                type="number"
                                name="step"
                                id="step"
                                invalid={formik.touched.step && Boolean(formik.errors.step)}
                                onChange={formik.handleChange}
                            />
                            <FormFeedback>
                                {formik.touched.step && formik.errors.step}
                            </FormFeedback>
                        </Col>
                    </Row>
                    : null
                }
                
                <Row className='row_form'>
                    <Col xs={4}><Label for="modemPhoneNumber">Номер сим-карты</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="tel"
                            mask="+7(999)999-99-99"
                            maskChar="_"
                            name="modemPhoneNumber"
                            id="modemPhoneNumber"
                            invalid={formik.touched.modemPhoneNumber && Boolean(formik.errors.modemPhoneNumber)}
                            placeholder={'+7(XXX)XXX-XX-XX'}
                            onChange={formik.handleChange}
                            tag={InputMask}
                        />
                        <FormFeedback>
                            {formik.touched.modemPhoneNumber && formik.errors.modemPhoneNumber}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="imei">IMEI</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="imei"
                            id="imei"
                            invalid={formik.touched.imei && Boolean(formik.errors.imei)}
                            onChange={formik.handleChange}
                        />
                        <FormFeedback>
                            {formik.touched.imei && formik.errors.imei}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={12}>
                        <Label for="active" className="activeTerminalLabel">Активный</Label>
                        <Input type="checkbox" name="active" id="active" checked={terminal.active === true} onChange={activeChangeHandler}/>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={8}>
                        <Button type="submit" color="primary" className="crud_button mr" disabled={clicked}>
                            Сохранить
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default TerminalNew;