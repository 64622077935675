import {gql} from "@apollo/client";

export const FILTER_MERCHANTS = gql`
    query filterMerchants($filter: MerchantFilter, $page: Int!, $size: Int!, $sortBy: String!, $sortOrder: String!) {
        merchantsPage(filter: $filter, page: $page, size: $size, sortBy: $sortBy, sortOrder: $sortOrder) {
            total
            size
            page
            data {
                id
                name
                phone
                bin
                helpDeskPhone
            }
        }
    }
`;

export const GET_MERCHANTS = gql`
    query merchants($sortBy: String!, $sortOrder: String!) {
        merchants(sortBy: $sortBy, sortOrder: $sortOrder) {
            id
            name
            bin
        }
    }
`;

export const GET_MERCHANT = gql`
    query getMerchant($id: ID!) {
        merchant(id: $id) {
            id
            creationDate
            changeDate
            name
            phone
            bin
            helpDeskPhone
        }
    }
`;

export const FILTER_SERVICES = gql`
    query filterServices($filter: ServiceFilter, $page: Int!, $size: Int!, $sortBy: String!, $sortOrder: String!) {
        servicesPage(filter: $filter, page: $page, size: $size, sortBy: $sortBy, sortOrder: $sortOrder) {
            total
            size
            page
            data {
                id
                creationDate
                changeDate
                nameRus
                nameKaz
                amount
                descriptionRus
                descriptionKaz
            }
        }
    }
`;

export const GET_SERVICES = gql`
    query getServices($sortBy: String!) {
        services(sortBy: $sortBy, sortOrder: "asc") {
            id
            nameRus
        }
    }
`;

export const GET_SERVICE = gql`
    query getService($id: ID!) {
        service(id: $id) {
            id
            creationDate
            changeDate
            nameRus
            nameKaz
            amount
            descriptionRus
            descriptionKaz
        }
    }
`;

export const GET_NOT_LINKED_SERVICES = gql`
    query servicesNotLinked($sortBy: String!, $sortOrder: String!) {
        servicesNotLinked(sortBy: $sortBy, sortOrder: $sortOrder) {
            id
            nameRus
            creationDate
            amount
        }
    }
`;

export const GET_SERVICE_PROFILES = gql`
    query getServiceProfiles($sortBy: String!) {
        servicesProfiles(sortBy: $sortBy, sortOrder: "asc") {
            id
            creationDate
            changeDate
            name
        }
    }
`;

export const GET_SERVICE_PROFILE = gql`
    query getServiceProfile($id: ID!) {
        servicesProfile(id: $id) {
            id
            creationDate
            changeDate
            name
            services {
                id
                nameRus
                amount
            }
        }
    }
`;


export const FILTER_TERMINALS = gql`
    query filterTerminals($filter: TerminalFilter, $page: Int!, $size: Int!, $sortBy: String!, $sortOrder: String!) {
        terminalsPage(filter: $filter, page: $page, size: $size, sortBy: $sortBy, sortOrder: $sortOrder) {
            total
            size
            page
            data {
                id
                guid
                creationDate
                changeDate
                name
                typeId
                address
                step
                merchantId
                servicesProfileId
                paymentType
                active
                modemPhoneNumber
                imei
                useCredit
                creditAmount
                useAMQP
            }
        }
    }
`;

export const GET_TERMINALS = gql`
    query getTerminals($sortBy: String!) {
        terminals(sortBy: $sortBy, sortOrder: "asc") {
            id
            guid
            name
        }
    }
`;

export const GET_TERMINAL = gql`
    query terminal($id: ID!) {
        terminal(id: $id) {
            id
            guid
            creationDate
            changeDate
            name
            typeId
            address
            step
            merchantId
            servicesProfileId
            paymentType
            active
            modemPhoneNumber
            imei
            useCredit
            creditAmount
            useAMQP
        }
    }
`;

export const FILTER_PAYMENTS = gql`
    query filterPayments($filter: PaymentFilter, $page: Int!, $size: Int!, $sortBy: String!, $sortOrder: String!) {
        paymentsPage(filter: $filter, page: $page, size: $size, sortBy: $sortBy, sortOrder: $sortOrder) {
            total
            size
            page
            data {
                id
                amount
                externalId
                status
                clientId
                clientDate
                serviceId
                merchantId
                terminalId
                creationDate
                changeDate
                type
                invoiceId
                canceledAmount
                creditAmount
                paymentSystemId
            }
            totalAmount
            totalCreditAmount
            totalCanceledAmount
        }
    }
`;

export const FILTER_INVOICES = gql`
    query filterInvoices($filter: InvoiceFilter, $page: Int!, $size: Int!, $sortBy: String!, $sortOrder: String!) {
        invoicesPage(filter: $filter, page: $page, size: $size, sortBy: $sortBy, sortOrder: $sortOrder) {
            total
            size
            page
            data {
                id
                creationDate
                changeDate
                externalId
                status
                amount
                terminalId
                nameRus
                nameKaz
                descriptionRus
                descriptionKaz
                paymentId
            }
            totalAmount
        }
    }
`;

export const FILTER_REFUNDS = gql`
    query refundsPage($filter: RefundFilter, $page: Int!, $size: Int!, $sortBy: String!, $sortOrder: String!) {
        refundsPage(filter: $filter, page: $page, size: $size, sortBy: $sortBy, sortOrder: $sortOrder) {
            total
            size
            page
            data {
                id
                requestId
                creationDate
                changeDate
                externalId
                amount
                status
                paymentId
                errorCode
                errorMessage
            }
        }
    }
`;

export const GET_PAYMENT = gql`
    query getPayment($id: ID!) {
        payment(id: $id) {
            id
            externalId
            creationDate
            changeDate
            clientDate
            type
            status
            amount
            merchantId
            terminalId
            clientId
            serviceId
            invoiceId
            canceledAmount
            creditAmount
            paymentSystemId
        }
    }
`;

export const GET_INVOICE = gql`
    query getInvoice($id: ID!) {
        invoice(id: $id) {
            id
            creationDate
            changeDate
            externalId
            status
            amount
            terminalId
            nameRus
            nameKaz
            descriptionRus
            descriptionKaz
            paymentId
        }
    }
`;

export const GET_TERMINAL_TYPES = gql`
    query getTerminalTypes($sortBy: String!, $sortOrder: String!) {
        terminalTypes(sortBy: $sortBy, sortOrder: $sortOrder) {
            id
            name
            kaspiQrURL
        }
    }
`;

export const GET_TERMINAL_TYPE = gql`
    query getTerminalType($id: ID!){
        terminalType(id: $id){
            id
            name
            kaspiQrURL
        }
    }
`;

export const GET_TARIFFS = gql`
    query getAllTariffs($productType: ProductType){
        tariffsByProduct(productType: $productType){
            id
            productType
            name
            amount
        }
    }
`;

export const GET_TARIFF = gql`
    query tariffById($id: ID!) {
        tariffById(id: $id) {
            id
            productType
            name
            amount
        }
    }
`;

export const GET_INVOICES = gql`
    query getInvoices($filter: InvoiceFilter, $page: Int!, $size: Int!, $sortBy: String!, $sortOrder: String!){
        invoicesPageable(filter: $filter, page: $page, size: $size, sortBy: $sortBy, sortOrder: $sortOrder){
            total,
            page,
            size,
            data {
                id,
                createdDate,
                changedDate,
                amount,
                bin,
                status,
                phone,
                utilizedAmount
                type
                guids
                lastSentDate
                comment
            },
            totalAmount
        }
    }
`;

export const GET_TRANSACTIONS = gql`
    query getTransactions($filter: TransactionFilter, $page: Int!, $size: Int!, $sortBy: String!, $sortOrder: String!){
        transactionsPageable(filter: $filter, page: $page, size: $size, sortBy: $sortBy, sortOrder: $sortOrder){
            total,
            page,
            size,
            data {
                id
                bin
                createdDate
                amount
                type
                billingInvoiceId
                accountId
                guid
                description
                txn_id
                refundedAmount
                refunds{
                    id
                    createdDate
                    changedDate
                    amount
                    status
                    transactionId
                    txn_id
                    comment
                }
            },
            totalAmount
        }
    }
`;

export const GET_PRODUCT = gql`
    query productByGuid($guid: String!) {
        productByGuid(guid: $guid) {
            guid
            createdDate
            activatedDate
            tariffId
            bin
            trialDateTo
            trialDateComment
            description 
            active
        }
    }
`;

export const PAYMENT_SYSTEMS = gql`
    query paymentSystems($sortBy: String!, $sortOrder: String!){
        paymentSystems(sortBy: $sortBy, sortOrder: $sortOrder){
            id
            name
            protocol
            paymentEnabled
            refundEnabled
            properties {
                name
                value
            }
        }
    }
`;

export const GET_MERCHANT_BY_BIN = gql`
    query merchantFindByBin($bin: String!){
        merchantFindByBin(bin: $bin){
            id
            bin
            createdDate
            kaspiNotification
            kaspiPhone
        }
    }
`;

export const GET_INVOICE_STATUSES = gql`
    query invoiceStatuses{
        InvoiceStatuses{
            status
            russian
            color
        }
    }
`;

export const GET_ACCOUNTS = gql`
    query getAccounts($accountFilter: AccountFilter, $page: Int!, $size: Int!, $sortBy: String!, $sortOrder: String!){
        accountsPageable(accountFilter: $accountFilter, page: $page, size: $size, sortBy: $sortBy, sortOrder: $sortOrder){
            total,
            page,
            size,
            data {
                bin
                balance
            },
            totalAmount
        }
    }
`;

export const GET_INVOICE_PRODUCTS = gql`
    query getInvoiceProducts($invoiceId: String!){
        invoiceProducts(invoiceId: $invoiceId) {
            guid
            bin
        }
    }
`;

export const GET_INVOICE_TYPES = gql`
    query invoiceTypes{
        invoiceTypes {
            type
            russian
        }
    }
`;

export const NOT_COMPLETED_REFUNDS = gql`
    query notCompleted($transactionId: ID!) {
        notCompleted(transactionId: $transactionId) {
            id
            status
            transactionId
            createdDate
            txn_id
            amount
        }
    }
`;

export const GET_BILLING_TERMINALS = gql`
    query productsPageable($filter: ProductFilter, $page: Int!, $size: Int!, $sortBy: String!, $sortOrder: String!) {
        productsPageable(filter: $filter, page: $page, size: $size, sortBy: $sortBy, sortOrder: $sortOrder) {
            total
            page
            size
            data {
                guid
                createdDate
                changedDate
                activatedDate
                active
                tariffName
                bin
                trialDateTo
                trialDateComment
                description
                invoiceCreationDate
            }
        }
    }
`;

export const ACTIVATIONS_PAGEABLE = gql`
    query activationsPageable($filter: ActivationFilter, $page: Int!, $size: Int!,
        $sortBy: String!, $sortOrder: String!) {
        activationsPageable(filter: $filter, page: $page, size: $size, sortBy: $sortBy,
            sortOrder: $sortOrder) {
            data {
                id
                guid
                createdDate
                amount
                status
                transactions{
                    id
                    createdDate
                    amount
                    type
                    billingInvoiceId
                    description
                }
                bin
            }
            total
            page
            size
        }
    }
`;

export const GET_PRODUCTS_BY_BIN = gql`
    query productsByBin($bin : String){
        productsByBin(bin: $bin) {
            guid
            createdDate
            activatedDate
            active
            tariffName
            trialDateTo
            trialDateComment
            invoiceCreationDate
        }
    }
`;