import React, {useEffect, useState} from 'react';
import './CustomAutocomplete.css';
import Backdrop from "../UI/Backdrop/Backdrop";
import { Input, FormFeedback } from 'reactstrap';

const CustomAutocomplete = ({getValueBy, list, value, searchBy, resultStringKeyName, placeholder, setValue, name, id, width, edit, disabled, invalid, formFeedback}) => {

  const [data, setData] = useState({
    activeSuggestion: 0,
    filteredSuggestions: [],
    showSuggestions: false,
    showFullList: false,
    currentItem: {},
    userInput: ""
  })
  
    useEffect(() => {
      if (value !== null && value !== undefined && value !== "") {
        if (edit) {
            setData({
                ...data,
                userInput: list.find(item => (item[getValueBy]).toString() === (value).toString())[resultStringKeyName]
            })
        }
      } else {
        setData({
          ...data, 
          filteredSuggestions: list,
          userInput: ''
      })
      }
    }, [value, disabled, list]);
    
    const onChange = e => {
        const userInput = e.currentTarget.value;
      
        let suggestions = [];
        
        for (let i = 0; i < searchBy.length; i++) {
            for (let j = 0; j < list.length; j++) {
                if (list[j][searchBy[i]].toLowerCase().indexOf(userInput.toLowerCase()) > -1) {
                    suggestions.push(list[j]);
                }
            }    
        }

        const uniqueIds = [];
        
        const filteredSuggestions = suggestions.filter(element => {
            const isDuplicate = uniqueIds.includes(element.id);

            if (!isDuplicate) {
                uniqueIds.push(element.id);

                return true;
            } 
        });
      
        setData({
          ...data,
          activeSuggestion: 0, 
          showFullList: false,
          filteredSuggestions,
          showSuggestions: true,
          userInput: e.currentTarget.value
        });
        
        if (filteredSuggestions.length === 1){
            let item = list.find(item => parseInt(item.id) === parseInt(filteredSuggestions[0].id));
            setValue(name, item)
        }
        
        if (e.currentTarget.value.length === 0) {
            setData({...data, showFullList: true, userInput: ''})
        }
    };

    const onClick = e => {
        setData({
            ...data,
          activeSuggestion: 0,
          filteredSuggestions: [],
          showSuggestions: false,
          showFullList: false,
          userInput: e.currentTarget.innerText
        });
        let item = list.find(item => parseInt(item.id) === parseInt(e.currentTarget.id));
        setValue(name, item)
    };
    
    const closeSuggestions = () => {
        let val;
        if (value) {
            val = list.find(item => (item[getValueBy]).toString() === (value).toString())
        }
        setData({
                activeSuggestion: 0,
                filteredSuggestions: [],
                userInput: '',
                showSuggestions: false,
                showFullList: false
        })
        setValue(name, '')
    };

    const onKeyDown = e => {
        const { activeSuggestion, filteredSuggestions } = data;
      
        if (e.keyCode === 13) {
            e.preventDefault();
          setData({
            activeSuggestion: 0,
            showSuggestions: false,
            showFullList: false,
            userInput: filteredSuggestions[activeSuggestion][resultStringKeyName]
          });
            let item = list.find(item => parseInt(item.id) === parseInt(filteredSuggestions[activeSuggestion].id));
            setValue(name, item);
        } else if (e.keyCode === 38) {
          if (activeSuggestion === 0) {
            return;
          }
          setData({ ...data, activeSuggestion: activeSuggestion - 1 });
        }
        else if (e.keyCode === 40) {
          if (activeSuggestion - 1 === filteredSuggestions.length) {
            return;
          }
          setData({ ...data, activeSuggestion: activeSuggestion + 1 });
        }
    };
    
    const cleanInput = () => {
        setData({
            ...data,
            activeSuggestion: 0,
            filteredSuggestions: [],
            userInput: '',
            showSuggestions: false,
            showFullList: false
        });
        setValue(name, '');
    };

    let suggestionsListComponent;

      if (data.showSuggestions && data.userInput) {
        if (data.filteredSuggestions.length) {
          suggestionsListComponent = (
            <ul className="suggestions" style={{width: width}}>
              {data.filteredSuggestions.map((suggestion, index) => {
                let className;
      
                if (index === data.activeSuggestion) {
                  className = "suggestion-active";
                }
                return (
                  <li className={className} key={suggestion.id} id={suggestion.id} onClick={onClick} onMouseEnter={(e) => {
                      setData({...data, activeSuggestion: index})
                  }}>
                    {suggestion[resultStringKeyName]}
                  </li>
                );
              })}
            </ul>
          );
        } else {
          suggestionsListComponent = (
            <div className="no-suggestions">
              <em>Ничего не найдено</em>
            </div>
          );
        }
      }
      
      let fullList;
      
      fullList = (
              <ul className="suggestions full-list" style={{width: width}}>
                  {list?.map((suggestion, index) => {
                      let className;

                      if (index === data.activeSuggestion) {
                          className = "suggestion-active";
                      }
                      return (
                          <li className={className} key={suggestion.id} id={suggestion.id} onClick={onClick} onMouseEnter={(e) =>  setData({ ...data, activeSuggestion: index})}>
                              {suggestion[resultStringKeyName]}
                          </li>
                      );
                  })}
              </ul>
          );
      
    return (
        <>
            <div
                className='Custom_autocomplete'
            >
                <Input
                    type="text"
                    name={name}
                    id={id}
                    className='autocomplete_input'
                    style={{width: width}}
                    onChange={onChange}
                    onFocus={() => setData({...data, showFullList: true})}
                    onKeyDown={onKeyDown}
                    value={data.userInput}
                    placeholder={placeholder}
                    disabled={disabled}
                    invalid={invalid}
                    autoComplete="off"
                />
                {(data.userInput.length > 0) && !disabled 
                    ? <div onClick={cleanInput} className={'closeIcon'}>+</div>
                    : null
                }
                {data.userInput.length > 0 || data.showFullList
                    ? null
                    : <FormFeedback>
                            {formFeedback }
                      </FormFeedback>
                }
                
                {suggestionsListComponent}
                {data.showFullList
                    ? fullList
                    : null
                }
            </div>
            <Backdrop 
                show={data.showSuggestions || data.showFullList}
                clicked={() => closeSuggestions()}
            />
        </>
    )
};

export default CustomAutocomplete;