import React from 'react';
import {Button} from "reactstrap";
import './ServiceProfileChangeServices.css';
import {Link} from "react-router-dom";

const ServiceProfileChangeServices = ({available_services, profile_services, addService, removeService}) => {
    console.log(available_services)
    return (
        <div className="Service_profile_change_services">
            <div className="available_services_wrap">
                <h4 className="service_profile_change_title">Достпуные сервисы:</h4>
                <div className="services_wrap">
                    {
                        available_services.map(service => {
                            return <div key={service.id} className='service_item'>
                                <Link className="breadcrumb_link" to={`/services/${service.id}`}>{service.nameRus} {service.amount}</Link>
                                <Button id={service.id} color="success" onClick={(e) => addService(e)}><img id={service.id} src={'/plus.png'} alt="plus" width={20}/></Button>
                            </div>
                        })
                    }
                </div>
            </div>
            <div className="available_services_wrap profile_services">
                <h4 className="service_profile_change_title">Назначенные сервисы</h4>
                <div className="services_wrap">
                    {
                        profile_services.map(service => {
                            return <div key={service.id} className='service_item own'>
                                <Button id={service.id} color="danger" className="delete_icon" onClick={(e) => removeService(e)}><img id={service.id} src={'/minus.png'} alt="minus" width={20}/></Button>
                                <Link className="breadcrumb_link" to={`/services/${service.id}`}>{service.nameRus} {service.amount}</Link>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default ServiceProfileChangeServices;
