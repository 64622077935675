import React from "react";
import {NavLink as RouterLink} from "react-router-dom";
import {Navbar, NavbarBrand, Nav} from "reactstrap";
import UserMenu from "./Menus/UserMenu";
import "./Toolbar.css";
import packageJs from '../../../../package.json';
import HamburgerMenu from "./Menus/HamburgerMenu";

const Toolbar = ({username, logout}) => {
    return (
        <>
            <HamburgerMenu username={username} logout={() => logout()}/>
            <Navbar 
                color="light"
                expand="md"
                light
                className="Toolbar"
            >
                <div className="custom_container Toolbar_items">
                    <NavbarBrand tag={RouterLink} to="/">
                        Kaspi admin {packageJs.version}
                    </NavbarBrand>
                    <Nav className="mr-auto" navbar>
                        {username
                            ? <>
                                <UserMenu username={username} logout={() => logout()}/>
    
                              </>
                            : null
                        }
                    </Nav>
                </div>   
            </Navbar>
        </>
    );
};

export default Toolbar;
