export const getStatusColor = (status) => {
    switch (status){
        case 'NEW':
            return '#dee2e6';
        case 'PROCESSING':
        case 'PROCESS':
        case 'IN_PROCESS':
            return '#f8ff9c';
        case 'PAID':
            return '#a7c8d580';
        case 'COMPLETED':
        case 'FINISH':
        case 'SUCCESS':
            return '#c1ffd2';
        case 'CANCEL':
            return '#ff00002b';
        case 'CANCELED':
        case 'ERROR':
            return '#ef121285';
        case 'CANCELLING':
            return '#ff000052';
        case 'PAST_DUE':
            return '#ffd34f';
        default:
            return;
    }
}
