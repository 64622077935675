import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {GET_BILLING_TERMINALS, GET_MERCHANTS, GET_TARIFFS, GET_TERMINALS} from "../../../GraphQL/Queries";
import {Breadcrumb, BreadcrumbItem, Button, Form, Input} from "reactstrap";
import {DataTable} from "../../../components/DataTable/DataTable";
import Loader from "../../../components/UI/Loader/Loader";
import BillingFilter from "../../../store/BillingFilter";
import CustomAutocomplete from "../../../components/CustomAutocomplete/CustomAutocomplete";

const BillingTerminals = () => {
    const navigate = useNavigate();

    const [filter, setFilter] = useState({
        page: 1,
        size: 25,
        sortBy: 'id',
        sortOrder: 'asc'
    });
    const [totalItems, setTotalItems] = useState();
    const [ pageLoading, setPageLoading ] = useState(false);

    const { data, error, loading, refetch } = useQuery(GET_BILLING_TERMINALS, {
        variables: {
            page: 0,
            size: filter.size,
            sortBy: 'id',
            sortOrder: 'asc'
        }, context: { clientName: 'billing'}
    });
    
    const tariffs = useQuery(GET_TARIFFS, { context: { clientName: 'billing' }});
    const merchants = useQuery(GET_MERCHANTS, { variables: {
            sortBy: "id",
            sortOrder: 'asc'
        }}
    );
    const allTerminals = useQuery(GET_TERMINALS, { variables: {
            sortBy: "id"
        }}
    );
    
    let [searchParams, setSearchParams] = useSearchParams();
    
    const [terminals, setTerminals] = useState([]);
    
    useEffect(() => {
        if (data) {
            setTerminals(data.productsPageable.data);
            setTotalItems(data.productsPageable.total)
        }
    }, [data, filter]);

    const handlePageChange = (pageNumber) => {
        setPageLoading(true);

        setFilter({
            ...filter,
            page: pageNumber
        });
        
        refetch({
            filter: {
                activatedDateFrom: BillingFilter.terminalsFilter.activatedDateFrom || null,
                activatedDateTo: BillingFilter.terminalsFilter.activatedDateTo || null,
                active: BillingFilter.terminalsFilter.active === 'Да' ? true : BillingFilter.terminalsFilter.active === 'Нет' ? false : null,
                tariffId: BillingFilter.terminalsFilter.tariffId || null,
                bin: BillingFilter.terminalsFilter.bin || null,
                guid: BillingFilter.terminalsFilter.guid || null,
                trialDateTo: BillingFilter.terminalsFilter.trialDateTo || null,
                trialDateFrom: BillingFilter.terminalsFilter.trialDateFrom || null,
            },
            page: pageNumber - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const sortByHandler = (e) => {
        setPageLoading(true);

        if (filter.sortBy === e.target.id) {
            setFilter({
                ...filter,
                sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
            });
        } else {
            setFilter({
                ...filter,
                sortBy: e.target.id
            });
        }

        refetch({
            filter: {
                activatedDateFrom: BillingFilter.terminalsFilter.activatedDateFrom || null,
                activatedDateTo: BillingFilter.terminalsFilter.activatedDateTo || null,
                active: BillingFilter.terminalsFilter.active === 'Да' ? true : BillingFilter.terminalsFilter.active === 'Нет' ? false : null,
                tariffId: BillingFilter.terminalsFilter.tariffId || null,
                bin: BillingFilter.terminalsFilter.bin || null,
                guid: BillingFilter.terminalsFilter.guid || null,
                trialDateTo: BillingFilter.terminalsFilter.trialDateTo || null,
                trialDateFrom: BillingFilter.terminalsFilter.trialDateFrom || null,
            },
            page: filter.page - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const pageSizeHandler = (e) => {
        setPageLoading(true);

        setFilter({
            ...filter,
            size: parseInt(e.target.value),
            page: 1
        });

        BillingFilter.setTerminalsFilter('size', parseInt(e.target.value));

        setSearchParams({...BillingFilter.terminalsFilter, size: parseInt(e.target.value)});

        refetch({
            filter: {
                activatedDateFrom: BillingFilter.terminalsFilter.activatedDateFrom || null,
                activatedDateTo: BillingFilter.terminalsFilter.activatedDateTo || null,
                active: BillingFilter.terminalsFilter.active === 'Да' ? true : BillingFilter.terminalsFilter.active === 'Нет' ? false : null,
                tariffId: BillingFilter.terminalsFilter.tariffId || null,
                bin: BillingFilter.terminalsFilter.bin || null,
                guid: BillingFilter.terminalsFilter.guid || null,
                trialDateTo: BillingFilter.terminalsFilter.trialDateTo || null,
                trialDateFrom: BillingFilter.terminalsFilter.trialDateFrom || null,
            },
            page: 0,
            size: parseInt(e.target.value),
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    let activatedDateFrom = searchParams.get("activatedDateFrom");
    let activatedDateTo = searchParams.get("activatedDateTo");
    let active = searchParams.get("active");
    let tariffId = searchParams.get("tariffId");
    let bin = searchParams.get("bin");
    let guid = searchParams.get("guid");
    let trialDateTo = searchParams.get("trialDateTo");
    let trialDateFrom = searchParams.get("trialDateFrom");
    let size = searchParams.get("size");
    
    useEffect(() => {
        if (activatedDateFrom || guid || activatedDateTo || bin || tariffId || active || trialDateTo || trialDateFrom || size) {
            if (activatedDateFrom) BillingFilter.setTerminalsFilter('activatedDateFrom', activatedDateFrom);
            if (activatedDateTo) BillingFilter.setTerminalsFilter('activatedDateTo', activatedDateTo);
            if (active) BillingFilter.setTerminalsFilter('active', active);
            if (tariffId) BillingFilter.setTerminalsFilter('tariffId', tariffId);
            if (bin) BillingFilter.setTerminalsFilter('bin', bin);
            if (guid) BillingFilter.setTerminalsFilter('guid', guid);
            if (trialDateTo) BillingFilter.setTerminalsFilter('trialDateTo', trialDateTo);
            if (trialDateFrom) BillingFilter.setTerminalsFilter('trialDateFrom', trialDateFrom);
            if (size) {
                BillingFilter.setTerminalsFilter('size', size);
                setFilter({...filter, size});
            }

            refetch({
                filter: {
                    activatedDateFrom: activatedDateFrom ? activatedDateFrom : null,
                    activatedDateTo: activatedDateTo ? activatedDateTo : null,
                    active: active ? active === 'Да' : null,
                    tariffId: tariffId ? tariffId : null,
                    bin: bin ? bin : null,
                    guid: guid ? guid : null,
                    trialDateTo: trialDateTo ? trialDateTo : null,
                    trialDateFrom: trialDateFrom ? trialDateFrom : null,
                },
                page: 0,
                size: filter.size,
                sortBy: filter.sortBy,
                sortOrder: filter.sortOrder
            })
                .then(() => {
                    setPageLoading(false);
                })
        } else {
            BillingFilter.setTerminalsFilter('activatedDateFrom', '');
            BillingFilter.setTerminalsFilter('activatedDateTo', '');
            BillingFilter.setTerminalsFilter('active', '');
            BillingFilter.setTerminalsFilter('tariffId', '');
            BillingFilter.setTerminalsFilter('bin', '');
            BillingFilter.setTerminalsFilter('guid', '');
            BillingFilter.setTerminalsFilter('trialDateTo', '');
            BillingFilter.setTerminalsFilter('trialDateFrom', '');
            BillingFilter.setTerminalsFilter('size', '');
        }    
    }, [filter.size, BillingFilter.terminalsFilter.size]);
    
    const columns = [
        { title: '#', id: '#' },
        { title: 'Guid', id: 'guid' },
        { title: 'Дата создания', id: 'createdDate'},
        { title: 'Дата изменения', id: 'changedDate'},
        { title: 'Дата активации', id: 'activatedDate'},
        { title: 'Активный', id: 'active'},
        { title: 'Наим. тарифа', id: 'tariffName'},
        { title: 'БИН', id: 'bin' },
        { title: 'Тест. период', id: 'trialDateTo'},
        { title: 'Причина тест. периода', id : 'trialDateComment'},
        { title: 'Описание', id: 'description'},
        { title: 'Дата создания инвойса', id: 'invoiceCreationDate'},
        { title: 'Действия', id: 'actions'},
    ];

    const rowsItem = [
        { title: '#' },
        { title: 'guid' },
        { title: 'createdDate' },
        { title: 'changedDate' },
        { title: 'activatedDate' },
        { title: 'active' },
        { title: 'tariffName' },
        { title: 'bin'},
        { title: 'trialDateTo'},
        { title: 'trialDateComment'},
        { title: 'description'},
        { title: 'invoiceCreationDate'}
    ];
    
    if (loading || tariffs.loading || merchants.loading || allTerminals.loading) return <Loader/>
    
    return (
        <div>
            <div className="breadcrumb_wrapper">
                <Breadcrumb>
                    <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                    <BreadcrumbItem active> Отчет по терминалам (биллинг)</BreadcrumbItem>
                </Breadcrumb>
                {/*<Button*/}
                {/*    color="dark"*/}
                {/*    className="add_btn"*/}
                {/*    onClick={() => navigate('/merchants/new')}*/}
                {/*>*/}
                {/*    + Добавить*/}
                {/*</Button>*/}
            </div>
            <hr/>
            <Form onSubmit={(e) => {
                e.preventDefault();
                setPageLoading(true);
                setFilter({
                    ...filter,
                    page: 1
                })
                refetch({
                    filter: {
                        activatedDateFrom: BillingFilter.terminalsFilter.activatedDateFrom || null,
                        activatedDateTo: BillingFilter.terminalsFilter.activatedDateTo || null,
                        active: BillingFilter.terminalsFilter.active === 'Да' ? true : BillingFilter.terminalsFilter.active === 'Нет' ? false : null,
                        tariffId: BillingFilter.terminalsFilter.tariffId || null,
                        bin: BillingFilter.terminalsFilter.bin || null,
                        guid: BillingFilter.terminalsFilter.guid || null,
                        trialDateTo: BillingFilter.terminalsFilter.trialDateTo || null,
                        trialDateFrom: BillingFilter.terminalsFilter.trialDateFrom || null,
                    },
                    page: 0,
                    size: filter.size,
                    sortBy: filter.sortBy,
                    sortOrder: filter.sortOrder
                })
                    .then(() => {
                        setPageLoading(false);
                    })
                
            }}>
                <div className={'filter_wrapper'}>
                    <div className="date_wrap">
                        <span className={'date_wrap_label'}>Даты активации</span>
                        <Input
                            className={'filter_item'}
                            type="datetime-local"
                            id="activatedDateFrom"
                            name="activatedDateFrom"
                            value={BillingFilter.terminalsFilter.activatedDateFrom}
                            onChange={(e) => {
                                setSearchParams({
                                    ...BillingFilter.terminalsFilter,
                                    bin: BillingFilter.terminalsFilter.bin || '',
                                    tariffId: BillingFilter.terminalsFilter.tariffId || '',
                                    guid: BillingFilter.terminalsFilter.guid || ''
                                })
                                BillingFilter.terminalsFilter.activatedDateFrom = e.target.value
                            }}
                        />
                        <Input
                            className={'filter_item'}
                            type="datetime-local"
                            id="activatedDateTo"
                            name="activatedDateTo"
                            value={BillingFilter.terminalsFilter.activatedDateTo}
                            onChange={(e) => {
                                setSearchParams({
                                    ...BillingFilter.terminalsFilter,
                                    bin: BillingFilter.terminalsFilter.bin || '',
                                    tariffId: BillingFilter.terminalsFilter.tariffId || '',
                                    guid: BillingFilter.terminalsFilter.guid || ''
                                })
                                BillingFilter.terminalsFilter.activatedDateTo = e.target.value
                            }}
                        />
                    </div>
                    <Input
                        className={'filter_item'}
                        type='select'
                        id="active"
                        value={BillingFilter.terminalsFilter.active || ''}
                        name='active'
                        onChange={(e) => {
                            setSearchParams({
                                ...BillingFilter.terminalsFilter,
                                bin: BillingFilter.terminalsFilter.bin || '',
                                tariffId: BillingFilter.terminalsFilter.tariffId || '',
                                guid: BillingFilter.terminalsFilter.guid || '',
                                active: e.target.value
                            })
                            BillingFilter.terminalsFilter.active = e.target.value
                        }}
                        style={{color: '#747678'}}
                    >
                        <option value={''}>Активный</option>
                        <option>Да</option>
                        <option>Нет</option>
                    </Input>
                    <Input
                        className={'filter_item'}
                        type='select'
                        id="tariffId"
                        value={BillingFilter.terminalsFilter.tariffId || ''}
                        name='tariffId'
                        onChange={(e) => {
                            setSearchParams({
                                ...BillingFilter.terminalsFilter,
                                bin: BillingFilter.terminalsFilter.bin || '',
                                guid: BillingFilter.terminalsFilter.guid || '',
                                tariffId: e.target.value,
                            })
                            BillingFilter.terminalsFilter.tariffId = e.target.value
                        }}
                        style={{color: '#747678'}}
                    >
                        <option value={''}>Тариф</option>
                        {tariffs.data?.tariffsByProduct.map(item => {
                            return <option key={item.id} value={item.id}>{item.amount} тг. - {item.name} </option>
                        })}
                    </Input>
                    <div className={'filter_item'}>
                        <CustomAutocomplete
                            list={allTerminals.data?.terminals}
                            name={'guid'}
                            setValue={(name, item) => {
                                if (item){
                                    setSearchParams({
                                        ...BillingFilter.terminalsFilter,
                                        bin: BillingFilter.terminalsFilter.bin || '',
                                        tariffId: BillingFilter.terminalsFilter.tariffId || '',
                                        guid: item.guid
                                    })
                                    BillingFilter.terminalsFilter.guid = item.guid
                                } else {
                                    setSearchParams({
                                        ...BillingFilter.terminalsFilter,
                                        bin: BillingFilter.terminalsFilter.bin || '',
                                        tariffId: BillingFilter.terminalsFilter.tariffId || '',
                                        guid: ''
                                    })
                                    BillingFilter.terminalsFilter.guid = null
                                }
                            }}
                            placeholder="GUID терминала"
                            searchBy={["guid"]}
                            resultStringKeyName='guid'
                            getValueBy={'guid'}
                            edit={true}
                            value={BillingFilter.terminalsFilter.guid ? BillingFilter.terminalsFilter.guid : null}
                        />
                    </div>
                    <div className={'filter_item'}>
                        <CustomAutocomplete
                            list={merchants.data?.merchants}
                            name={'bin'}
                            setValue={(name, item) => {
                                if (item){
                                    setSearchParams({
                                        ...BillingFilter.terminalsFilter,
                                        guid: BillingFilter.terminalsFilter.guid || '',
                                        tariffId: BillingFilter.terminalsFilter.tariffId || '',
                                        bin: item.bin
                                    })
                                    BillingFilter.terminalsFilter.bin = item.bin
                                } else {
                                    setSearchParams({
                                        ...BillingFilter.terminalsFilter,
                                        guid: BillingFilter.terminalsFilter.guid || '',
                                        tariffId: BillingFilter.terminalsFilter.tariffId || '',
                                        bin: ''
                                    })
                                    BillingFilter.terminalsFilter.bin = null
                                }
                            }}
                            placeholder="Наименование / Бин мерчанта"
                            searchBy={["name", "bin"]}
                            resultStringKeyName='name'
                            getValueBy={'bin'}
                            edit={true}
                            value={BillingFilter.terminalsFilter.bin ? BillingFilter.terminalsFilter.bin : null}
                        />
                    </div>
                    <div className="date_wrap">
                        <span className={'date_wrap_label'}>Даты тест. периода</span>
                        <Input
                            className={'filter_item'}
                            type="datetime-local"
                            id="trialDateFrom"
                            name="trialDateFrom"
                            value={BillingFilter.terminalsFilter.trialDateFrom}
                            onChange={(e) => {
                                setSearchParams({
                                    ...BillingFilter.terminalsFilter,
                                    bin: BillingFilter.terminalsFilter.bin || '',
                                    tariffId: BillingFilter.terminalsFilter.tariffId || '',
                                    guid: BillingFilter.terminalsFilter.guid || ''
                                })
                                BillingFilter.terminalsFilter.trialDateFrom = e.target.value
                            }}
                        />
                        <Input
                            className={'filter_item'}
                            type="datetime-local"
                            id="trialDateTo"
                            name="trialDateTo"
                            value={BillingFilter.terminalsFilter.trialDateTo}
                            onChange={(e) => {
                                setSearchParams({
                                    ...BillingFilter.terminalsFilter,
                                    bin: BillingFilter.terminalsFilter.bin || '',
                                    tariffId: BillingFilter.terminalsFilter.tariffId || '',
                                    guid: BillingFilter.terminalsFilter.guid || ''
                                })
                                BillingFilter.terminalsFilter.trialDateTo = e.target.value
                            }}
                        />
                    </div>
                    <div className={'buttons_wrapper'}>
                        <Button color={'primary'} type={'submit'} className="filter_button" style={{width: 100, marginRight: 20}} >Поиск</Button>
                        <Button color={'danger'} style={{width: 100}} className="filter_button" onClick={() => {
                            setPageLoading(true);
                            setSearchParams('');
                            BillingFilter.cleanTerminalsFilter();
                            setFilter({
                                ...filter,
                                size: 25
                            })
                            refetch({
                                filter: {
                                    activatedDateFrom: null,
                                    activatedDateTo: null,
                                    active: null,
                                    tariffId: null,
                                    bin: null,
                                    guid: null,
                                    trialDateTo: null,
                                    trialDateFrom: null,
                                },
                                page: filter.page - 1,
                                size: filter.size,
                                sortBy: filter.sortBy
                            })
                                .then(() => {
                                    setPageLoading(false);
                                })
                        }}>Сбросить</Button>
                    </div>
                </div>
            </Form>
            <hr/>

            <DataTable
                hideColumns={[]}
                hideIcons={false}
                columns={columns}
                rows={terminals}
                rowsItem={rowsItem}
                billing={true}
                merchants={merchants.data?.merchants}
                itemsCountPerPage={filter.size}
                sortBy={filter.sortBy}
                sortOrder={filter.sortOrder}
                sortByHandler={(e) => sortByHandler(e)}
                pageSizeHandler={(e) => pageSizeHandler(e)}
                totalItemsCount={totalItems}
                activePage={filter.page}
                handlePageChange={(pageNumber) => handlePageChange(pageNumber)}
                isPaginated={true}
                showEditPhone={false}
                biilingTerminal={true}
            />
            {pageLoading
                ? <Loader/>
                : null
            }
        </div>
    )
};

export default BillingTerminals;