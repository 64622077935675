import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem, Input, Button} from "reactstrap";
import {DataTable} from "../../components/DataTable/DataTable";
import RowCount from "../../components/UI/Pagination/RowCount/RowCount";
import Loader from '../../components/UI/Loader/Loader';
import DeviceLogStore from "../../store/DeviceLogStore";
import './DeviceLogs.css';

const DeviceLogs = () => {
    const params = useParams();
    let date = new Date().toISOString();
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState({
        startdate: `${date.slice(0, 11)}00:00`,
        enddate: `${date.slice(0, 11)}23:59`,
        limit: 100,
        contains: null
    });

    const changeFilter = (e) => {
        if (e.target.name === 'rowCount'){
            setFilter({
                ...filter,
                limit: e.target.value
            })
        } else {
            setFilter({
                ...filter,
                [e.target.name]: e.target.value
            })
        }
    };

    const getLogs = () => {
        setLoading(true);
        DeviceLogStore.getLogs(params.guid, filter.startdate, filter.enddate, filter.limit, filter.contains)
            .then(result => {
                console.log(result)
                setLogs(result);
                setLoading(false);
            })
            .catch(err => {
                console.log(err)
                setLoading(false);
                setError(err);
            })
    };

    useEffect(() => {
        getLogs();
    }, [filter.limit]);

    const columns = [
        { title: 'Id', id: 'id' },
        { title: 'Value', id: 'data'},
        { title: 'Date', id: 'createdate'},
    ];

    const rowsItem = [
        { title: 'id' },
        { title: 'data' },
        { title: 'createdate' },
    ];

    if (error) return <div>Ошибка</div>

    return (
        <div className={'Device_logs'}>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to='/terminals' className="breadcrumb_link">Терминалы</Link></BreadcrumbItem>
                <BreadcrumbItem active>Логи устройства - {params.guid}</BreadcrumbItem>
            </Breadcrumb>
            <hr/>
            <div className={'filter_wrapper'}>
                <div className="date_wrap">
                    <Input
                        type="datetime-local"
                        id="startdate"
                        name="startdate"
                        value={filter.startdate}
                        onChange={changeFilter}
                    />
                    <Input
                        type="datetime-local"
                        id="enddate"
                        name="enddate"
                        value={filter.enddate}
                        onChange={changeFilter}
                    />
                </div>
                <Input
                    className={'filter_item'}
                    type="text"
                    id="contains"
                    name="contains"
                    placeholder='Поиск логов по тексту'
                    value={filter.contains ? filter.contains : ''}
                    onChange={changeFilter}
                />
                <div className={'buttons_wrapper'}>
                    <Button color={'primary'} style={{width: '100%'}} className="crud_button" onClick={() => getLogs()}>Поиск</Button>
                    <Button
                        color={'danger'}
                        className="crud_button"
                        onClick={() => {
                            setFilter({
                                startdate: `${date.slice(0, 11)}00:00`,
                                enddate: `${date.slice(0, 11)}23:59`,
                                limit: 100,
                                contains: null
                            })
                            getLogs(`${date.slice(0, 11)}00:00`, `${date.slice(0, 11)}23:59`, 100, null)
                        }}
                    >
                        Сбросить
                    </Button>
                </div>
            </div>
           <hr/>
            <DataTable
                hideColumns={[]}
                hideIcons={true}
                columns={columns}
                rows={logs}
                rowsItem={rowsItem}
                link={'service_profiles'}
                isPaginated={false}
                sortByHandler={(e) => console.log(e)}
            />
            <RowCount
                values={[100, 500, 1000, 10000, 50000]}
                itemsCountPerPage={filter.limit}
                pageSizeHandler={(e) => changeFilter(e)}
            />
            {loading
                ? <Loader/>
                : null
            }
        </div>
    );
};

export default DeviceLogs;
