import React, {useEffect, useState} from 'react';
import {Link, useSearchParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {GET_MERCHANTS, GET_TERMINALS, GET_TRANSACTIONS} from "../../../GraphQL/Queries";
import BillingFilter from "../../../store/BillingFilter";
import Loader from "../../../components/UI/Loader/Loader";
import {Breadcrumb, BreadcrumbItem, Button, Input, Form} from "reactstrap";
import ReportDatesFilter from "../../../components/ReportDatesFilter/ReportDatesFilter";
import CustomAutocomplete from "../../../components/CustomAutocomplete/CustomAutocomplete";
import {DataTable} from "../../../components/DataTable/DataTable";
import TotalInvoicesTable from "../../../components/TotalsTables/TotalInvoicesTable";
import axios from "axios";
import config from "../../../config";
import { CSVLink } from "react-csv";
const Transactions = () => {
    const [pageLoading, setPageLoading] = useState(false);

    let date = new Date().toISOString();

    let [searchParams, setSearchParams] = useSearchParams();

    const [filter, setFilter] = useState({
        page: 1,
        size: 25,
        sortBy: 'id',
        sortOrder: 'desc'
    });

    const {loading, error, data, refetch} = useQuery(GET_TRANSACTIONS, {
            variables: {
                filter: {
                    dateFrom: `${date.slice(0, 11)}00:00`,
                    dateTo: `${date.slice(0, 11)}23:59`,
                },
                page: 0,
                size: filter.size,
                sortBy: 'id',
                sortOrder: 'desc'
            }, context: {clientName: 'billing'}
        }
    );

    const merchants = useQuery(GET_MERCHANTS, {
            variables: {
                sortBy: "id",
                sortOrder: "asc"
            }
        }
    );

    const allTerminals = useQuery(GET_TERMINALS, { variables: {
            sortBy: "id"
        }}
    );
    
    const [reportData, setReportData] = useState('');
    
    const downloadReport = () => {
        axios.post(config.billingURL + '/api/transactions/report', {
            filter: {
                dateFrom: BillingFilter.transactionsFilter.dateFrom,
                dateTo: BillingFilter.transactionsFilter.dateTo,
                amountFrom: BillingFilter.transactionsFilter.amountFrom || null,
                amountTo: BillingFilter.transactionsFilter.amountTo || null,
                bin: BillingFilter.transactionsFilter.bin || null,
                type: BillingFilter.transactionsFilter.type || null,
                billingInvoiceId: BillingFilter.transactionsFilter.billingInvoiceId || null,
                guid: BillingFilter.transactionsFilter.guid || null
            },
            sortBy: "id",
            sortOrder: "asc"
        })
            .then(data => {
                setReportData(data.data);
            })
    }

    const sortByHandler = (e) => {
        setPageLoading(true);

        if (filter.sortBy === e.target.id) {
            setFilter({
                ...filter,
                sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
            });
        } else {
            setFilter({
                ...filter,
                sortBy: e.target.id
            });
        }

        refetch({
            filter: {
                dateFrom: BillingFilter.transactionsFilter.dateFrom,
                dateTo: BillingFilter.transactionsFilter.dateTo,
                // accountId: BillingFilter.transactionsFilter.accountId,
                amountFrom: BillingFilter.transactionsFilter.amountFrom || null,
                amountTo: BillingFilter.transactionsFilter.amountTo || null,
                bin: BillingFilter.transactionsFilter.bin || null,
                type: BillingFilter.transactionsFilter.type || null,
                billingInvoiceId: BillingFilter.transactionsFilter.billingInvoiceId || null,
                guid: BillingFilter.transactionsFilter.guid || null
            },
            page: filter.page - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const pageSizeHandler = (e) => {
        setPageLoading(true);

        setFilter({
            ...filter,
            size: parseInt(e.target.value),
            page: 1
        });
        BillingFilter.setTransactionsFilter('size', parseInt(e.target.value));
        setSearchParams({...BillingFilter.transactionsFilter, size: parseInt(e.target.value)});
        refetch({
            filter: {
                dateFrom: BillingFilter.transactionsFilter.dateFrom,
                dateTo: BillingFilter.transactionsFilter.dateTo,
                // accountId: BillingFilter.transactionsFilter.accountId || null,
                amountFrom: BillingFilter.transactionsFilter.amountFrom || null,
                amountTo: BillingFilter.transactionsFilter.amountTo || null,
                bin: BillingFilter.transactionsFilter.bin || null,
                type: BillingFilter.transactionsFilter.type || null,
                billingInvoiceId: BillingFilter.transactionsFilter.billingInvoiceId || null,
                guid: BillingFilter.transactionsFilter.guid || null
            },
            page: 0,
            size: parseInt(e.target.value),
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const handlePageChange = (pageNumber) => {
        setPageLoading(true);
        setFilter({
            ...filter,
            page: pageNumber
        });

        refetch({
            filter: {
                dateFrom: BillingFilter.transactionsFilter.dateFrom,
                dateTo: BillingFilter.transactionsFilter.dateTo,
                // accountId: BillingFilter.transactionsFilter.accountId || null,
                amountFrom: BillingFilter.transactionsFilter.amountFrom || null,
                amountTo: BillingFilter.transactionsFilter.amountTo || null,
                bin: BillingFilter.transactionsFilter.bin || null,
                type: BillingFilter.transactionsFilter.type || null,
                billingInvoiceId: BillingFilter.transactionsFilter.billingInvoiceId || null,
                guid: BillingFilter.transactionsFilter.guid || null
            },
            page: pageNumber - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const [transactions, setTransaction] = useState([]);

    useEffect(() => {
        if (data) {
            setTransaction(data.transactionsPageable.data)
        }
    }, [data, filter]);

    let dateFrom = searchParams.get("dateFrom");
    let dateTo = searchParams.get("dateTo");
    // let accountId = searchParams.get("accountId");
    let amountFrom = searchParams.get("amountFrom");
    let amountTo = searchParams.get("amountTo");
    let bin = searchParams.get("bin");
    let type = searchParams.get("type");
    let size = searchParams.get("size");
    let billingInvoiceId = searchParams.get("billingInvoiceId");
    let guid = searchParams.get("guid");

    useEffect(() => {
        BillingFilter.cleanTransactionsFilter();

        if (dateFrom || dateTo || amountFrom || amountTo || bin || type || size || billingInvoiceId || guid) {
            if (dateFrom) BillingFilter.setTransactionsFilter('dateFrom', dateFrom)
            if (dateTo) BillingFilter.setTransactionsFilter('dateTo', dateTo);
            // if (accountId) BillingFilter.setTransactionsFilter('accountId', accountId);
            //     else BillingFilter.setTransactionsFilter('accountId', '');
            if (amountFrom) BillingFilter.setTransactionsFilter('amountFrom', amountFrom);
            if (amountTo) BillingFilter.setTransactionsFilter('amountTo', amountTo);
            if (bin) BillingFilter.setTransactionsFilter('bin', bin);
            if (type) BillingFilter.setTransactionsFilter('type', type);
            if (size) {
                BillingFilter.setTransactionsFilter('size', size);
                setFilter({...filter, size});
            }
            if (billingInvoiceId) {
                BillingFilter.setTransactionsFilter('billingInvoiceId', billingInvoiceId);
            }
            if (guid) {
                BillingFilter.setTransactionsFilter('guid', guid);
            }

            refetch({
                filter: {
                    dateFrom: BillingFilter.transactionsFilter.dateFrom,
                    dateTo: BillingFilter.transactionsFilter.dateTo,
                    // accountId: BillingFilter.transactionsFilter.accountId || null,
                    amountFrom: BillingFilter.transactionsFilter.amountFrom || null,
                    amountTo: BillingFilter.transactionsFilter.amountTo || null,
                    bin: BillingFilter.transactionsFilter.bin || null,
                    type: BillingFilter.transactionsFilter.type || null,
                    billingInvoiceId: BillingFilter.transactionsFilter.billingInvoiceId || null,
                    guid: BillingFilter.transactionsFilter.guid || null
                },
                page: 0,
                size: filter.size,
                sortBy: filter.sortBy
            })
                .then(() => {
                    setPageLoading(false);
                })
        } else {
            BillingFilter.setTransactionsFilter('dateFrom', `${date.slice(0, 11)}00:00`);
            BillingFilter.setTransactionsFilter('dateTo', `${date.slice(0, 11)}23:59`);
            BillingFilter.setTransactionsFilter('amountFrom', '');
            BillingFilter.setTransactionsFilter('amountTo', '');
            BillingFilter.setTransactionsFilter('bin', '');
            BillingFilter.setTransactionsFilter('type', '');
            BillingFilter.setTransactionsFilter('size', 25);
            BillingFilter.setTransactionsFilter('guid', '');
        }
    }, [filter.size, BillingFilter.transactionsFilter.size]);

    const columns = [
        {title: '#', id: '#'},
        {title: 'Id', id: 'id'},
        {title: 'Бин', id: 'bin'},
        {title: 'Мерчант', id: 'merchantName'},
        {title: 'Дата создания', id: 'createdDate'},
        {title: 'Сумма', id: 'amount'},
        {title: 'Тип', id: 'type'},
        {title: 'Id инвойса', id: 'billingInvoiceId'},
        {title: 'Guid', id: 'guid'},
        {title: 'Описание', id: 'description'},
        {title: 'Txn_id', id: 'txn_id'},
        {title: 'Сумма возврата', id: 'refundedAmount'},
        {title: 'Список возвратов', id: 'refunds'},
        {title: 'Действие', id: 'actions'}
    ];

    const rowsItem = [
        {title: '#',},
        {title: 'id'},
        {title: 'bin'},
        {title: 'merchantName'},
        {title: 'createdDate'},
        {title: 'amount'},
        {title: 'type'},
        {title: 'billingInvoiceId'},
        // { title: 'accountId' },
        {title: 'guid'},
        {title: 'description'},
        {title: 'txn_id'},
        {title: 'refundedAmount'},
        {title: 'refunds'},
        // {title: 'actions'}
    ];

    if (loading || merchants.loading || pageLoading) return <Loader/>


    return (
        <div>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem active>Отчет по транзакциям (биллинг)</BreadcrumbItem>
            </Breadcrumb>
            <hr/>
            <Form onSubmit={e => {
                e.preventDefault();
                setPageLoading(true);
                setSearchParams({
                    dateFrom: BillingFilter.transactionsFilter.dateFrom,
                    dateTo: BillingFilter.transactionsFilter.dateTo,
                    bin: BillingFilter.transactionsFilter.bin ? BillingFilter.transactionsFilter.bin : '',
                    status: BillingFilter.invoicesFilter.status ? BillingFilter.invoicesFilter.status : '',
                    // accountId: BillingFilter.transactionsFilter.accountId ? BillingFilter.transactionsFilter.accountId : '',
                    amountFrom: BillingFilter.transactionsFilter.amountFrom ? BillingFilter.transactionsFilter.amountFrom : '',
                    amountTo: BillingFilter.transactionsFilter.amountTo ? BillingFilter.transactionsFilter.amountTo : '',
                    type: BillingFilter.transactionsFilter.type ? BillingFilter.transactionsFilter.type : '',
                    size: BillingFilter.transactionsFilter.size ? BillingFilter.transactionsFilter.size : 25,
                    billingInvoiceId: BillingFilter.transactionsFilter.billingInvoiceId ? BillingFilter.transactionsFilter.billingInvoiceId : '',
                    guid: BillingFilter.transactionsFilter.guid ? BillingFilter.transactionsFilter.guid : ''
                });
                setFilter({
                    ...filter,
                    page: 1
                });
                downloadReport();
                refetch({
                    filter: {
                        dateFrom: BillingFilter.transactionsFilter.dateFrom,
                        dateTo: BillingFilter.transactionsFilter.dateTo,
                        // accountId: BillingFilter.transactionsFilter.accountId || null,
                        amountFrom: BillingFilter.transactionsFilter.amountFrom || null,
                        amountTo: BillingFilter.transactionsFilter.amountTo || null,
                        bin: BillingFilter.transactionsFilter.bin || null,
                        type: BillingFilter.transactionsFilter.type || null,
                        billingInvoiceId: BillingFilter.transactionsFilter.billingInvoiceId || null,
                        guid: BillingFilter.transactionsFilter.guid || null
                    },
                    page: 0,
                    size: filter.size,
                    sortBy: filter.sortBy
                })
                    .then(() => {
                        setPageLoading(false);
                    })
            }}>
                <div className={'filter_wrapper'}>
                    <ReportDatesFilter
                        dateFrom={BillingFilter.transactionsFilter.dateFrom}
                        dateTo={BillingFilter.transactionsFilter.dateTo}
                        dateFromChange={(e) => {
                            setSearchParams({...BillingFilter.transactionsFilter, dateFrom: e.target.value})
                            BillingFilter.transactionsFilter.dateFrom = e.target.value
                        }}
                        dateToChange={(e) => {
                            setSearchParams({...BillingFilter.transactionsFilter, dateTo: e.target.value})
                            BillingFilter.transactionsFilter.dateTo = e.target.value
                        }}
                    />
                    <div className={'filter_item'}>
                        <CustomAutocomplete
                            list={merchants.data?.merchants}
                            name={'bin'}
                            setValue={(name, item) => {
                                if (item) {
                                    setSearchParams({...BillingFilter.transactionsFilter, bin: item.bin})
                                    BillingFilter.transactionsFilter.bin = item.bin
                                } else {
                                    setSearchParams({...BillingFilter.transactionsFilter, bin: ''})
                                    BillingFilter.transactionsFilter.bin = null
                                }
                            }}
                            placeholder="Наименование / Бин мерчанта"
                            searchBy={["name", "bin"]}
                            resultStringKeyName='name'
                            getValueBy={'bin'}
                            edit={true}
                            value={BillingFilter.transactionsFilter.bin ? BillingFilter.transactionsFilter.bin : null}
                        />
                    </div>
                    <div className={'filter_item'}>
                        <CustomAutocomplete
                            list={allTerminals.data?.terminals}
                            name={'guid'}
                            setValue={(name, item) => {
                                if (item) {
                                    setSearchParams({...BillingFilter.transactionsFilter, guid: item.guid})
                                    BillingFilter.transactionsFilter.guid = item.guid
                                } else {
                                    setSearchParams({...BillingFilter.transactionsFilter, guid: ''})
                                    BillingFilter.transactionsFilter.guid = null
                                }
                            }}
                            placeholder="Guid терминала"
                            searchBy={["guid"]}
                            resultStringKeyName='guid'
                            getValueBy={'guid'}
                            edit={true}
                            value={BillingFilter.transactionsFilter.guid ? BillingFilter.transactionsFilter.guid : null}
                        />
                    </div>
                    {/* <Input
                        className={'filter_item'}
                        type='text'
                        placeholder="Введите Id аккаунта"
                        value={BillingFilter.transactionsFilter.accountId}
                        name='accountId'
                        onChange={(e) => {
                            setSearchParams({ ...BillingFilter.transactionsFilter, bin: BillingFilter.transactionsFilter.bin ? BillingFilter.transactionsFilter.bin : '', accountId: e.target.value})
                            BillingFilter.transactionsFilter.accountId = e.target.value
                        }}
                    /> */}
                    <Input
                        className={'filter_item'}
                        type='number'
                        placeholder="Суммы с "
                        value={BillingFilter.transactionsFilter.amountFrom}
                        name='amountFrom'
                        onChange={(e) => {
                            setSearchParams({
                                ...BillingFilter.transactionsFilter,
                                bin: BillingFilter.transactionsFilter.bin ? BillingFilter.transactionsFilter.bin : '',
                                guid: BillingFilter.transactionsFilter.guid ? BillingFilter.transactionsFilter.guid : '',
                                amountFrom: e.target.value
                            })
                            BillingFilter.transactionsFilter.amountFrom = e.target.value
                        }}
                    />
                    <Input
                        className={'filter_item'}
                        type='number'
                        placeholder="Суммы по "
                        value={BillingFilter.transactionsFilter.amountTo}
                        name='amountTo'
                        onChange={(e) => {
                            setSearchParams({
                                ...BillingFilter.transactionsFilter,
                                bin: BillingFilter.transactionsFilter.bin ? BillingFilter.transactionsFilter.bin : '',
                                guid: BillingFilter.transactionsFilter.guid ? BillingFilter.transactionsFilter.guid : '',
                                amountTo: e.target.value
                            })
                            BillingFilter.transactionsFilter.amountTo = e.target.value
                        }}
                    />
                    <Input
                        className={'filter_item'}
                        type='select'
                        id="type"
                        value={BillingFilter.transactionsFilter.type}
                        name='type'
                        onChange={(e) => {
                            setSearchParams({...BillingFilter.transactionsFilter, type: e.target.value})
                            BillingFilter.transactionsFilter.type = e.target.value
                        }}
                        style={{color: '#747678'}}
                    >
                        <option value={''}>Выберите тип</option>
                        <option>Kaspi</option>
                        <option>Bonus</option>
                        <option>ChargeOff</option>
                        <option>NonKaspi</option>
                    </Input>
                    <Input
                        className={'filter_item'}
                        type='text'
                        placeholder="Id инвойса"
                        value={BillingFilter.transactionsFilter.billingInvoiceId}
                        name='billingInvoiceId'
                        onChange={(e) => {
                            setSearchParams({
                                ...BillingFilter.transactionsFilter,
                                bin: BillingFilter.transactionsFilter.bin ? BillingFilter.transactionsFilter.bin : '',
                                guid: BillingFilter.transactionsFilter.guid ? BillingFilter.transactionsFilter.guid : '',
                                billingInvoiceId: e.target.value
                            })
                            BillingFilter.transactionsFilter.billingInvoiceId = e.target.value
                        }}
                    />
                    
                </div>
                <div className={'buttons_wrapper'}>
                    <Button color={'primary'} type={'submit'} className="filter_button"
                            style={{width: 100, marginRight: 20}}>Поиск</Button>
                    <Button color={'danger'} style={{width: 100}} className="filter_button" onClick={() => {
                        setPageLoading(true);
                        setSearchParams({
                            dateFrom: `${date.slice(0, 11)}00:00`,
                            dateTo: `${date.slice(0, 11)}23:59`
                        });
                        BillingFilter.cleanTransactionsFilter();
                        setFilter({
                            ...filter,
                            size: 25
                        });
                        refetch({
                            filter: {
                                dateFrom: `${date.slice(0, 11)}00:00`,
                                dateTo: `${date.slice(0, 11)}23:59`,
                                // accountId: null,
                                amountFrom: null,
                                amountTo: null,
                                bin: null,
                                type: null,
                                guid: null
                            },
                            page: filter.page - 1,
                            size: filter.size,
                            sortBy: filter.sortBy
                        })
                            .then(() => {
                                setPageLoading(false);
                                setReportData('');
                            })
                    }}>Сбросить</Button>
                    {reportData
                        ? <CSVLink
                            data={reportData}
                            className={'filter_button excell_btn'}
                            style={{width: 99}}
                            target={"_blank"}
                            filename={`report_${BillingFilter.transactionsFilter.dateFrom}-${BillingFilter.transactionsFilter.dateTo}`}
                        >
                            Скачать
                        </CSVLink>
                        : null
                    }
                    
                </div>
            </Form>
            <br/>
            <hr/>
            <DataTable
                hideColumns={[]}
                columns={columns}
                rows={transactions}
                rowsItem={rowsItem}
                merchants={merchants.data ? merchants.data.merchants : []}
                sortBy={filter.sortBy}
                sortByHandler={(e) => sortByHandler(e)}
                itemsCountPerPage={filter.size}
                sortOrder={filter.sortOrder}
                pageSizeHandler={(e) => pageSizeHandler(e)}
                totalItemsCount={data?.transactionsPageable?.total}
                activePage={filter.page}
                handlePageChange={(pageNumber) => handlePageChange(pageNumber)}
                isPaginated={true}
                hideIcons={false}

            />
            <TotalInvoicesTable
                totalAmount={data?.transactionsPageable?.totalAmount}
            />
        </div>
    )
}

export default Transactions;