import React, {useState} from 'react';
import {useNavigate, Link} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem, Form, Input, Label, FormFeedback, Col, Button, Row} from 'reactstrap';
import { useFormik } from 'formik';
import {useMutation} from "@apollo/client";
import {CREATE_MERCHANT} from "../../GraphQL/Mutations";
import {CreateMerchantSchema} from "../../schemas";
import {FILTER_MERCHANTS, GET_MERCHANTS} from "../../GraphQL/Queries";
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import Loader from "../../components/UI/Loader/Loader";

const MerchantNew = () => {
    const [clicked, setClicked] = useState(false);
    
    const [createMerchant, { data, loading, error }] = useMutation(CREATE_MERCHANT, {
        awaitRefetchQueries: true,
        refetchQueries: [
            { query: FILTER_MERCHANTS, variables: { page: 0, size: 10, sortBy: "id", sortOrder: "asc"}},
            { query: GET_MERCHANTS, variables: {sortBy: "id", sortOrder: "asc"}}
        ],
    });
    const navigate = useNavigate();
    
    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',
            bin: '',
            helpDeskPhone: ''
          },
          validationSchema: CreateMerchantSchema,
          onSubmit: (values) => {
            setClicked(true);
            
            toast.promise(
                createMerchant({variables: {
                        name: values.name,
                        phone: values.phone,
                        bin: values.bin,
                        helpDeskPhone: values.helpDeskPhone
                    }}),
                {
                    pending: 'В обработке',
                    success: 'Мерчант успешно создан',
                    error: 'Ошибка'
                }
            )
                .then(result => {
                    console.log(result);
                    navigate('/merchants');
                })
          }
    });

    if (loading) return <Loader/>

    return (
        <div>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to='/merchants' className="breadcrumb_link">Мерчанты</Link></BreadcrumbItem>
                <BreadcrumbItem active>Добавить</BreadcrumbItem>
            </Breadcrumb>

            <h3 className="main_title">Создание мерчанта</h3>
            <Form onSubmit={formik.handleSubmit}>
                <Row className='row_form'>
                    <Col xs={4}><Label for="name">Наименование</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            invalid={formik.touched.name && Boolean(formik.errors.name)}
                            onChange={formik.handleChange}
                        />
                        <FormFeedback>
                            {formik.touched.name && formik.errors.name}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="phone">Номер</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="tel"
                            mask="+7(999)999-99-99"
                            maskChar="_"
                            name="phone"
                            id="phone"
                            invalid={formik.touched.phone && Boolean(formik.errors.phone)}
                            placeholder={'+7(XXX)XXX-XX-XX'}
                            onChange={formik.handleChange}
                            tag={InputMask}
                        />
                        <FormFeedback>
                            {formik.touched.phone && formik.errors.phone}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="bin">БИН</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="bin"
                            id="bin"
                            invalid={formik.touched.bin && Boolean(formik.errors.bin)}
                            onChange={formik.handleChange}
                        />
                        <FormFeedback>
                            {formik.touched.bin && formik.errors.bin}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="helpDeskPhone">Номер тех. поддержки</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="tel"
                            mask="+7(999)999-99-99"
                            maskChar="_"
                            name="helpDeskPhone"
                            id="helpDeskPhone"
                            invalid={formik.touched.helpDeskPhone && Boolean(formik.errors.helpDeskPhone)}
                            placeholder={'+7(XXX)XXX-XX-XX'}
                            onChange={formik.handleChange}
                            tag={InputMask}
                        />
                        <FormFeedback>
                            {formik.touched.helpDeskPhone && formik.errors.helpDeskPhone}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}>
                        <Button type="submit" color="primary" className="crud_button mr" disabled={clicked}>
                            Сохранить
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default MerchantNew;