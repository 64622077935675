import React, {useEffect, useState} from 'react';
import {Breadcrumb, BreadcrumbItem, Button, Col, Input, Label, Modal, ModalHeader, Row} from "reactstrap";
import {Link} from "react-router-dom";
import ControllerSettingsStore from "../../store/ControllerSettingsStore";
import Loader from "../../components/UI/Loader/Loader";
import ButtonSwitcher from "../../components/UI/ButtonSwitcher/ButtonSwitcher";
import {DataTable} from "../../components/DataTable/DataTable";

const MassUpdate = () => {
    const [type, setType] = useState(null);
    const [terminals, setTerminals] = useState([]);
    const [filter, setFilter] = useState({
        page: 1,
        size: 25
    });
    
    const [totalCount, setTotalCount] = useState(0);
    const [files, setFiles] = useState([]);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [settings, setSettings] = useState({
        fw: null,
        dbgLevel: null,
        log: null
    });
    const [showModal, setShowModal] = useState(false);

    const [guids, setGuids] = useState([]);
    
    const [toggleAllActive, setToggleAllActive] = useState(false);

    const toggleGuid = (guid) => {
        let newguids = [...guids];
        let index = newguids.indexOf(guid);
        if (index === -1) newguids.push(guid);
        else newguids.splice(index, 1);
        setGuids(newguids)
    };
    
    const toggleAll = () => {
        setToggleAllActive(!toggleAllActive);
        console.log(toggleAllActive)
        if (!toggleAllActive) {
            console.log(toggleAllActive)

            setGuids(terminals.map(terminal => terminal.guid))
        } else {
            setGuids([]);
        }
    }
    
    const changeHandler = (e) => {
        setType(e.target.value);
    };
    
    const getTerminals = (fw, page, size) => {
        setLoadingFiles(true);

        ControllerSettingsStore.getTerminalsByFw({
            fw: fw,
            page: page - 1,
            size: size
        })
            .then(data => {
                setTerminals(data.hosts);
                setTotalCount(data.total);
                setGuids([]);
                setToggleAllActive(false);
                setLoadingFiles(false);
            })
    };

    const pageSizeHandler = (e) => {

        setFilter({
            ...filter,
            size: parseInt(e.target.value),
            page: 1
        });

        getTerminals(type, 1, parseInt(e.target.value));
    };

    const handlePageChange = (pageNumber) => {
        setFilter({
            ...filter,
            page: pageNumber
        });

        getTerminals(type, pageNumber, filter.size)
    };

    const getFiles = () => {
        setLoadingFiles(true);
        ControllerSettingsStore.getFiles()
            .then(data => {
                setFiles(data);
                setLoadingFiles(false);
            })
            .catch(err => {
                setLoadingFiles(false);
            })
    };
    
    const massUpdate = () => {
        ControllerSettingsStore.massUpdate({
            hostGuidsList: guids,
            params: {
                fw: settings.fw,
                dbgLevel: settings.dbgLevel,
                log: settings.log
            }
        })
    };
    
    useEffect(() => {
        getFiles();
    }, []);

    const columns = [
        { title: '#', id: '#' },
        { title: 'Guid', id: 'guid'},
        { title: 'Fw', id: 'fw'},
        { title: 'Действия', id: 'actions'}
    ];

    const rowsItem = [
        { title: '#' },
        { title: 'guid' },
        { title: 'fw'}
    ];
    
    if (loadingFiles) return <Loader/>
    
    return (
        <div className={'Mass_update'}>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to='/terminals' className="breadcrumb_link">Терминалы</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to='/files' className="breadcrumb_link">Файлы для обновлении</Link></BreadcrumbItem>
                <BreadcrumbItem active>Обновление устройств</BreadcrumbItem>
            </Breadcrumb>
            <hr/>
            <Row className='row_form'>
                <Col xs={3}>
                    <Input type={'select'} onChange={changeHandler} placeholder={'Тип устройства'} value={type}>
                        <option defaultValue={''}>Тип устройства</option>
                        <option value={'2.1'}>ESP - версии с 2.1</option>
                        <option value={'2.0'}>STM - версии с 2.0</option>
                        <option value={'2.3'}>GL - версии с 2.3</option>
                    </Input>
                </Col>
                <Col xs={3}>
                    <Button color={'primary'} onClick={() => {

                        setFilter({
                            ...filter, page: 1
                        })
                        getTerminals(type, 1, filter.size)
                    }} className="add_btn">Выбрать</Button>
                </Col>
            </Row>
            {terminals.length 
                ? <>
                    <DataTable
                        updateAction={true}
                        updateCheckboxHandler={(guid) => toggleGuid(guid)}
                        updateAllCheckboxHandler={() => toggleAll()}
                        toggleAllActive={toggleAllActive}
                        updateGuids={guids}
                        updateHandler={() => setShowModal(!showModal)}
                        hideColumns={[]}
                        columns={columns}
                        rows={terminals}
                        rowsItem={rowsItem}
                        itemsCountPerPage={filter.size}
                        totalItemsCount={totalCount}
                        activePage={filter.page}
                        link={''}
                        isPaginated={true}
                        sortByHandler={(e) => console.log(e)}
                        hideIcons={true}
                        showCheckBoxes={true}
                        pageSizeHandler={(e) => pageSizeHandler(e)}
                        handlePageChange={(pageNumber) => handlePageChange(pageNumber)}
                    />
                  </>
                : null
            }
            
            <Modal isOpen={showModal}
                   centered={true}
                   fullscreen="xl"
                   size="lg"
                   toggle={() => {
                       setShowModal(false);
                       setSettings({dbgLevel: null, log: null, fw: null})
                   }}
            >
                <ModalHeader toggle={() => {
                    setShowModal(false);
                    setSettings({dbgLevel: null, log: null, fw: null})
                }}>Настройки обновления</ModalHeader>
                <Row className='row_form'>
                    <Col xs={{size: 3, offset: 1}}><Label htmlFor={'fw'}>fw</Label></Col>
                    <Col xs={3}><Input type={'select'} onChange={(e) => setSettings({...settings, fw: e.target.value})} placeholder={'Файл'} id={'fw'}>
                        <option defaultValue={''}>Файл</option>
                        {files.map(file => {
                            if ((file.fw).slice(0, 3) === type) return <option key={file.id}>{file.fw}</option>
                            }
                        )}
                    </Input></Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={{size: 3, offset: 1}}><Label htmlFor={'dbgLevel'}>dbgLevel</Label></Col>
                    <Col xs={6}>
                        <Input
                            type={'select'}
                            id={'dbgLevel'}
                            name={'dbgLevel'}
                            value={settings.dbgLevel}
                            onChange={(e) => setSettings({...settings, dbgLevel: e.target.value})}
                        >
                            <option value={''}>Выберите dbgLevel</option>
                            <option value={0}>System</option>
                            <option value={1}>Error</option>
                            <option value={2}>Warning</option>
                            <option value={3}>Notice</option>
                            <option value={4}>Debug</option>
                            <option value={5}>Debug2</option>
                        </Input>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={{size: 3, offset: 1}}><Label htmlFor={'log'}>log</Label></Col>
                    <Col xs={3}>
                        <ButtonSwitcher
                            id={'log'}
                            checked={settings.log}
                            changeHandler={() => setSettings({...settings, log: !settings.log})}
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={{size: 3, offset: 1}}><Label htmlFor={'devices'}>Выбрано устройств</Label></Col>
                    <Col xs={3}>
                        <Input
                            type={'text'}
                            id={'devices'}
                            name={'devices'}
                            defaultValue={guids.length}
                            disabled
                        />
                    </Col>
                </Row>
                <Row className={'row_form'} style={{margin: '30px 0 20px 0'}}>
                    <Col xs={{size: 4, offset: 1}}><Button color={'success'} onClick={massUpdate}>Запустить обновление</Button></Col>
                </Row>
            </Modal>
        </div>
    )
};

export default MassUpdate;