import * as Yup from "yup";

const phoneRegex = '^(\\+\\d{1})\\(\\d{3}\\)\\d{3}[\\s.-]\\d{2}[\\s.-]\\d{2}$';
const billingPhoneRegex = '^\\d{3}\\d{3}\\d{2}\\d{2}$';
const guidRegex = /^[A-Za-z0-9_$:-]+$/;

export const CreateMerchantSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Поле должно состоять минимум из 2х символов')
        .max(150, 'Поле должно состоять не более чем из 150 символов')
        .required('Поле не может быть пустым'),
    phone: Yup.string().matches(phoneRegex, 'Некорректное значение поля')
        .required('Поле не может быть пустым'),
    bin: Yup.string()
        .required("Поле не может быть пустым")
        .min(12, "Поле должно состоять из 12 символов")
        .max(12, 'Поле должно состоять не более чем из 12 символов'),
    helpDeskPhone: Yup.string().matches(phoneRegex, 'Некорректное значение поля')
        .required('Поле не может быть пустым'),
});

export const CreateServiceSchema = Yup.object().shape({
    nameRus: Yup.string()
        .required('Поле не может быть пустым')
        .min(2, 'Поле должно состоять минимум из 2х символов')
        .max(80, 'Поле должно состоять не более чем из 80 символов'),
    nameKaz: Yup.string()
        .required('Поле не может быть пустым')
        .min(2, 'Поле должно состоять минимум из 2х символов')
        .max(80, 'Поле должно состоять не более чем из 80 символов'),
    amount: Yup.number()
        .required('Поле не может быть пустым'),
    descriptionRus: Yup.string()
        .required('Поле не может быть пустым')
        .min(2, 'Поле должно состоять минимум из 2х символов')
        .max(150, 'Поле должно состоять не более чем из 150 символов'),
    descriptionKaz: Yup.string()
        .required('Поле не может быть пустым')
        .min(2, 'Поле должно состоять минимум из 2х символов')
        .max(150, 'Поле должно состоять не более чем из 150 символов'),
});

export const CreateServiceProfileSchema = Yup.object().shape({
    name: Yup.string()
        .required('Поле не может быть пустым')
        .min(2, 'Поле должно состоять минимум из 2х символов')
        .max(150, 'Поле должно состоять не более чем из 150 символов'),
});

export const CreateTerminalSchema = Yup.object().shape({
    guid: Yup.string().matches(guidRegex, "Только латинские буквы и/или цифры")
        .required('Поле не может быть пустым')
        .min(2, 'Поле должно состоять минимум из 2х символов')
        .max(36, 'Поле должно состоять не более чем из 36 символов'),
    name: Yup.string()
        .required('Поле не может быть пустым')
        .min(2, 'Поле должно состоять минимум из 2х символов')
        .max(150, 'Поле должно состоять не более чем из 150 символов'),
    typeId: Yup.number()
        .required('Поле не может быть пустым'),
    paymentType: Yup.string()
        .required('Поле не может быть пустым'),
    servicesProfileId: Yup.number()
        .nullable(true)
        .when('paymentType', {
            is: 'SERVICE',
            then: Yup.number().required('Поле не может быть пустым')
        }),
    useCredit: Yup.bool().required().default(false),
    useAMQP: Yup.bool().required().default(false),
    creditAmount: Yup.number().required().default(0),
    merchantId: Yup.number()
        .required('Поле не может быть пустым'),
    address: Yup.string()
        .required('Поле не может быть пустым')
        .min(2, 'Поле должно состоять минимум из 2х символов')
        .max(250, 'Поле должно состоять не более чем из 250 символов'),
    step: Yup.number()
        .nullable(true)
        .when('paymentType', {
            is: (val) => val === 'SERVICE',
            then: Yup.number().required('Поле не может быть пустым')
        }),
    modemPhoneNumber: Yup.string().matches(phoneRegex, 'Некорректное значение поля')
        .required('Поле не может быть пустым'),
    imei: Yup.string()
        .required('Поле не может быть пустым')
        .min(15, 'Поле должно состоять минимум из 15 символов')
        .max(16, 'Поле должно состоять не более чем из 16 символов'),
});

export const CreateTerminalTypeSchema = Yup.object().shape({
    name: Yup.string()
        .required('Поле не может быть пустым')
        .min(2, 'Поле должно состоять минимум из 2х символов')
        .max(150, 'Поле должно состоять не более чем из 150 символов'),
    kaspiQrURL: Yup.string()
        .required('Поле не может быть пустым')
        .min(2, 'Поле должно состоять минимум из 2х символов')
        .max(250, 'Поле должно состоять не более чем из 250 символов'),
});

export const CreateTariffSchema = Yup.object().shape({
    name: Yup.string()
        .required('Поле не может быть пустым')
        .min(2, 'Поле должно состоять минимум из 2х символов')
        .max(150, 'Поле должно состоять не более чем из 150 символов'),
    productType: Yup.string()
        .oneOf(['Kaspi', 'POS', 'KKM'])
        .required('Поле не может быть пустым'),
    amount: Yup.number()
        .required('Поле не может быть пустым')
});

export const CreateInvoiceSchema = Yup.object().shape({
    bin: Yup.string()
        .required('Поле не может быть пустым')
        .min(12, 'Поле должно состоять минимум из 12 символов')
        .max(12, 'Поле должно состоять не более чем из 12 символов'),
    amount: Yup.number()
        .required('Поле не может быть пустым')
        .min(1, 'Сумма должна быть больше 1')
});

export const CreateProductSchema = Yup.object().shape({
    bin: Yup.string()
        .required('Поле не может быть пустым')
        .min(12, 'Поле должно состоять минимум из 12 символов')
        .max(12, 'Поле должно состоять не более чем из 12 символов'),
    tariffId: Yup.number()
        .required('Поле не может быть пустым'),
    guid: Yup.string().matches(guidRegex, "Только латинские буквы и/или цифры")
        .required('Поле не может быть пустым')
        .min(2, 'Поле должно состоять минимум из 2х символов')
        .max(36, 'Поле должно состоять не более чем из 36 символов'),
    trialDateTo: Yup.string(),
    trialDateComment: Yup.string()
        .nullable(true)
        .when('trialDateTo', {
            is: value => value && value.length > 0,
            then: Yup.string().required('Поле не может быть пустым')
        }),
});

export const TopUpBonusSchema = Yup.object().shape({
    bin: Yup.string()
        .required('Поле не может быть пустым')
        .min(12, 'Поле должно состоять минимум из 12 символов')
        .max(12, 'Поле должно состоять не более чем из 12 символов'),
    amount: Yup.number()
        .required('Поле не может быть пустым')
        .min(1, 'Сумма должна быть больше 1'),
    type: Yup.string()
        .required('Выберите тип')
        .oneOf(['Bonus', 'NonKaspi'])
});

export const UpdatePushNotify = Yup.object().shape({
    bin: Yup.string()
        .required('Поле не может быть пустым')
        .min(12, 'Поле должно состоять минимум из 12 символов')
        .max(12, 'Поле должно состоять не более чем из 12 символов'),
    kaspiNotification: Yup.bool().required().default(false),
    kaspiPhone: Yup.string()
        .nullable(true)
        .when('kaspiNotification', {
            is: (val) => val === true,
            then: Yup.string().matches(billingPhoneRegex, 'Некорректное значение поля')
                .required('Поле не может быть пустым')
        }),
});

export const RefundKaspiSchema = Yup.object().shape({
    sum: Yup.number()
        .typeError('Введите число')
        .required('Поле не может быть пустым')
        .min(1, 'Поле должно состоять минимум из 1 цифры')
        .positive('Число не может быть отрицательным'),
    comment: Yup.string()
        .nullable(false)
        .required('Поле не может быть пустым')
});

export const RefundChargeOffSchema = Yup.object().shape({
    sum: Yup.number()
        .typeError('Введите число')
        .required('Поле не может быть пустым')
        .min(1, 'Поле должно состоять минимум из 1 цифры')
        .positive('Число не может быть отрицательным'),
    comment: Yup.string()

        .required('Поле не может быть пустым')
        .nullable(false)
});

export const CompletionRefundSchema = Yup.object().shape({
    option: Yup.string()
        .required('Выберите возврат')
});

export const RefundBonusSchema = Yup.object().shape({
    sum: Yup.number()
        .typeError('Введите число')
        .required('Поле не может быть пустым')
        .min(1, 'Поле должно состоять минимум из 1 цифры')
        .positive('Число не может быть отрицательным'),
    comment: Yup.string()
        .nullable(false)
        .required('Поле не может быть пустым')
});

export const ActivateTerminal =
    // Yup.boolean();
    Yup.object().shape({
    isActivated: Yup.boolean()
});

export const DeactivateTerminal = Yup.object().shape({
    comment: Yup.string()
        .nullable(false)
        .required('Поле не может быть пустым'),
    activationId: Yup.number()
        .required('Поле не может быть пустым')
});