import React, { useState } from 'react';
import { Col, Row, Input, Label } from 'reactstrap';

const BillsCoinsSettings = ({title, scale, itemsList, disabled, changeHandler}) => {
    console.log(itemsList ? '1' : '2');
    const [items, setItems] = useState(itemsList ? Object.assign({}, itemsList.split(',')) : []);

    const handlerChange = (e) => {
        setItems({
            ...items,
            [e.target.name]: e.target.value > 255 ? 255 : e.target.value < 1 ? 1 :e.target.value
        })
    }

    return (
        <Row style={{marginTop: 20}}>
            <Col xs={4}><Label for={`${title}`}>{title}</Label></Col>
            <Col xs={8}>
                {itemsList ?
                    Object.keys(items).map(key => {
                        let item = items[key];
                        return <Row key={key}>
                            <Col xs={4}>
                                <Input
                                    type='number'
                                    value={item}
                                    name={key}
                                    id={key}
                                    disabled={disabled}
                                    onChange={(e) => handlerChange(e)}
                                    onBlur={() => changeHandler(title, Object.values(items).toString())}
                                />
                            </Col>
                            <Col xs={4}>
                                <Input
                                    value={item * scale}
                                    disabled
                                />
                            </Col>
                        </Row>
                    })
                    : null 
                }
            </Col>
        </Row>
    )
}

export default BillsCoinsSettings;