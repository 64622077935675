import React from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    DropdownToggle,
    DropdownMenu, DropdownItem, UncontrolledDropdown
} from 'reactstrap';
import {NavLink as RouterLink} from "react-router-dom";
import packageJs from "../../../../../package.json";

export default class Example extends React.Component {
    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    render() {
        return (
            <div className="Hamburger">
                <Navbar color="light" light>
                    <NavbarBrand tag={RouterLink} to="/">
                        Kaspi admin {packageJs.version}
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" style={{marginRight: 10}}/>
                    <Collapse isOpen={!this.state.collapsed} navbar>
                        <Nav navbar className={'mobile_links_wrapper'}>
                            <UncontrolledDropdown nav inNavbar className="menu_link_title">
                                <DropdownToggle nav caret>
                                    Отчеты
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <DropdownItem>
                                        <NavLink style={{color: "black", padding: 0, fontSize: 16}} tag={RouterLink} to="/invoices" onClick={this.toggleNavbar}>
                                            Отчет по инвойсам
                                        </NavLink>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavLink style={{color: "black", padding: 0, fontSize: 16}} tag={RouterLink} to="/payments" onClick={this.toggleNavbar}>
                                            Отчет по платежам
                                        </NavLink>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavLink style={{color: "black", padding: 0, fontSize: 16}} tag={RouterLink} to="/refunds" onClick={this.toggleNavbar}>
                                            Отчет по заявкам на отмену
                                        </NavLink>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <NavItem>
                                <NavLink tag={RouterLink} to="/merchants" onClick={this.toggleNavbar}>
                                    Мерчанты
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={RouterLink} to="/terminals" onClick={this.toggleNavbar}>
                                    Терминалы
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={RouterLink} to="/terminal_types" onClick={this.toggleNavbar}>
                                    Типы терминалов
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={RouterLink} to="/services" onClick={this.toggleNavbar}>
                                    Услуги
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={RouterLink} to="/service_profiles" onClick={this.toggleNavbar}>
                                    Профили услуг
                                </NavLink>
                            </NavItem>
                            <UncontrolledDropdown
                                inNavbar
                                nav
                            >
                                <DropdownToggle
                                    caret
                                    nav
                                >
                                    {this.props.username}
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <DropdownItem onClick={() => {
                                        this.toggleNavbar()
                                        this.props.logout()
                                    }}>
                                        Выход
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}