import React, {useState} from 'react';
import {useNavigate, Link} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem, Button, Col, Form, FormFeedback, Input, Label, Row} from 'reactstrap';
import {useMutation} from "@apollo/client";
import {CREATE_SERVICE} from "../../GraphQL/Mutations";
import {GET_NOT_LINKED_SERVICES, GET_SERVICES} from "../../GraphQL/Queries";
import {useFormik} from "formik";
import {CreateServiceSchema} from "../../schemas";
import { toast } from 'react-toastify';

const ServiceNew = () => {
    const [clicked, setClicked] = useState(false);

    const [createService] = useMutation(CREATE_SERVICE, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_SERVICES, variables: { sortBy: 'id'}}, { query: GET_NOT_LINKED_SERVICES, variables: { sortBy: "creation_date", sortOrder: "desc" }}],
    });
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            nameRus: '',
            nameKaz: '',
            amount: '',
            descriptionRus: '',
            descriptionKaz: ''
        },
        validationSchema: CreateServiceSchema,
        onSubmit: (values) => {
            setClicked(true);

            toast.promise(
                createService({variables: {
                        nameRus: values.nameRus,
                        nameKaz: values.nameKaz,
                        amount: values.amount,
                        descriptionRus: values.descriptionRus,
                        descriptionKaz: values.descriptionKaz
                    }}), {
                    pending: 'В обработке',
                    success: 'Сервис успешно создан',
                    error: 'Ошибка'
                }
            )
                .then(result => {

                    navigate(-1)
                })
        }
    });

    return (
        <div>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to='/services' className="breadcrumb_link">Услуги</Link></BreadcrumbItem>
                <BreadcrumbItem active>Добавить</BreadcrumbItem>
            </Breadcrumb>
            <h3 className="main_title">Создание услуги</h3>
            <Form onSubmit={formik.handleSubmit}>
                <Row className='row_form'>
                    <Col xs={4}><Label for="nameRus">Наименование (рус)</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="nameRus"
                            id="nameRus"
                            invalid={formik.touched.nameRus && Boolean(formik.errors.nameRus)}
                            onChange={formik.handleChange}
                        />
                        <FormFeedback>
                            {formik.touched.nameRus && formik.errors.nameRus}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="nameKaz">Наименование (каз)</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="nameKaz"
                            id="nameKaz"
                            invalid={formik.touched.nameKaz && Boolean(formik.errors.nameKaz)}
                            onChange={formik.handleChange}
                        />
                        <FormFeedback>
                            {formik.touched.nameKaz && formik.errors.nameKaz}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="amount">Сумма</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="number"
                            name="amount"
                            id="amount"
                            invalid={formik.touched.amount && Boolean(formik.errors.amount)}
                            onChange={formik.handleChange}
                        />
                        <FormFeedback>
                            {formik.touched.amount && formik.errors.amount}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="descriptionRus">Описание (рус)</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="descriptionRus"
                            id="descriptionRus"
                            invalid={formik.touched.descriptionRus && Boolean(formik.errors.descriptionRus)}
                            onChange={formik.handleChange}
                        />
                        <FormFeedback>
                            {formik.touched.descriptionRus && formik.errors.descriptionRus}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="descriptionKaz">Описание (каз)</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="descriptionKaz"
                            id="descriptionKaz"
                            invalid={formik.touched.descriptionKaz && Boolean(formik.errors.descriptionKaz)}
                            onChange={formik.handleChange}
                        />
                        <FormFeedback>
                            {formik.touched.descriptionKaz && formik.errors.descriptionKaz}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={12}>
                        <Button type="submit" color="primary" className="crud_button mr" disabled={clicked}>
                            Сохранить
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default ServiceNew;
