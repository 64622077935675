import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './containers/App';
import User from "./store/User";
import {ApolloProvider, ApolloClient, createHttpLink, from, InMemoryCache, ApolloLink} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {onError} from "@apollo/client/link/error";
import {Auth} from "aws-amplify";
import config from './config';

const httpLink = createHttpLink({
    uri: config.apiURL + '/graphql',
    // uri: 'http://localhost:8080/graphql',
});

const httpBillingLink = createHttpLink({
    uri: config.billingURL + '/graphql',
    // uri: 'http://localhost:8080/graphql',
});

const errorLink = onError(({operation, networkError, graphQLErrors, response, forward}) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
        );
    if (networkError) {
        Auth.currentSession()
            .then(data => {
                console.log(data.getIdToken().getJwtToken())
                let user = JSON.parse(localStorage.getItem('user')) || {};
                user.signInUserSession.idToken.jwtToken = data.getIdToken().getJwtToken();
                user.signInUserSession.accessToken.jwtToken = data.getAccessToken().getJwtToken();
                user.signInUserSession.refreshToken.jwtToken = data.getRefreshToken().getToken();
                localStorage.setItem('user', JSON.stringify(user));
            })
            .catch(err => {
                User.logout()
            })
        console.log(`[Network error]: ${networkError}`);
        let user = JSON.parse(localStorage.getItem('user')) || {};  
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }));
        console.log(operation.getContext().headers)
        
        return forward(operation);
    }
});


const authLink = setContext((_, { headers }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    return {
        headers: {
            ...headers,
            authorization: user.signInUserSession ? `Bearer ${user.signInUserSession.idToken.jwtToken}` : "",
        }
    }
});

const client = new ApolloClient({
    link: from([errorLink, new (ApolloLink).split(
        operation => operation.getContext().clientName === 'billing',
        authLink.concat(httpBillingLink),
        authLink.concat(httpLink)
    )]),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore'
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
        }
    }
});

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
);