import React, {useEffect, useState} from 'react';
import {Link, useSearchParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {FILTER_REFUNDS} from "../../GraphQL/Queries";
import Loader from "../../components/UI/Loader/Loader";
import {Breadcrumb, BreadcrumbItem, Button, Form, Input} from "reactstrap";
import {DataTable} from "../../components/DataTable/DataTable";
import Filter from '../../store/Filter';
import ReportDatesFilter from "../../components/ReportDatesFilter/ReportDatesFilter";
import './Reports.css';
import Legenda from "../../components/UI/Legenda/Legenda";

const Refunds = () => {
    
    let [searchParams, setSearchParams] = useSearchParams();

    const [filter, setFilter] = useState({
        page: 1,
        size: 25,
        sortBy: 'id',
        sortOrder: 'desc'
    });

    const [ pageLoading, setPageLoading ] = useState(false);
    
    let date = new Date().toISOString();

    const { loading, error, data, refetch } = useQuery(FILTER_REFUNDS, { variables: {
            filter: {
                dateFrom: `${date.slice(0, 11)}00:00+06:00`,
                dateTo: `${date.slice(0, 11)}23:59+06:00`,
            },
            page: 0,
            size: filter.size,
            sortBy: 'id',
            sortOrder: 'desc'
        }}
    );


    const handlePageChange = (pageNumber) => {
    
        setPageLoading(true);
        
        console.log(`active page is ${pageNumber}`);
        setFilter({
            ...filter,
            page: pageNumber
        });

        refetch({
            filter: {
                dateFrom: Filter.refundsFilter.dateFrom  + '+06:00',
                dateTo: Filter.refundsFilter.dateTo  + '+06:00',
                externalId: Filter.refundsFilter.externalId ? Filter.refundsFilter.externalId : null,
                status: Filter.refundsFilter.status ? Filter.refundsFilter.status : null,
                paymentId: Filter.refundsFilter.paymentId ? Filter.refundsFilter.paymentId : null,
                requestId: Filter.refundsFilter.requestId ? Filter.refundsFilter.requestId : null,
            },
            page: pageNumber - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const sortByHandler = (e) => {
        setPageLoading(true);
        
        if (filter.sortBy === e.target.id) {
            setFilter({
                ...filter,
                sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
            });
        } else {
            setFilter({
                ...filter,
                sortBy: e.target.id
            });
        }

        refetch({
            filter: {
                dateFrom: Filter.refundsFilter.dateFrom + '+06:00',
                dateTo: Filter.refundsFilter.dateTo + '+06:00',
                externalId: Filter.refundsFilter.externalId ? Filter.refundsFilter.externalId : null,
                status: Filter.refundsFilter.status ? Filter.refundsFilter.status : null,
                paymentId: Filter.refundsFilter.paymentId ? Filter.refundsFilter.paymentId : null,
                requestId: Filter.refundsFilter.requestId ? Filter.refundsFilter.requestId : null,
            },
            page: filter.page - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const pageSizeHandler = (e) => {
        setPageLoading(true);
        
        setFilter({
            ...filter,
            size: parseInt(e.target.value),
            page: 1
        });
        Filter.setRefundsFilter('size', parseInt(e.target.value));
        setSearchParams({...Filter.refundsFilter, size: parseInt(e.target.value)});
        refetch({
            filter: {
                dateFrom: Filter.refundsFilter.dateFrom + '+06:00',
                dateTo: Filter.refundsFilter.dateTo + '+06:00',
                externalId: Filter.refundsFilter.externalId ? Filter.refundsFilter.externalId : null,
                status: Filter.refundsFilter.status ? Filter.refundsFilter.status : null,
                paymentId: Filter.refundsFilter.paymentId ? Filter.refundsFilter.paymentId : null,
                requestId: Filter.refundsFilter.requestId ? Filter.refundsFilter.requestId : null,
            },
            page: 0,
            size: parseInt(e.target.value),
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const [refunds, setRefunds] = useState([]);
    const [totalItems, setTotalItems] = useState();
    
    useEffect(() => {
        if (data) {
            setRefunds(data.refundsPage.data)
            setTotalItems(data.refundsPage?.total)
        }
    }, [data, filter]);

    let dateFrom = searchParams.get("dateFrom");
    let dateTo = searchParams.get("dateTo");
    let externalId = searchParams.get("externalId");
    let status = searchParams.get("status");
    let paymentId = searchParams.get("paymentId");
    let requestId = searchParams.get("requestId");
    let size = searchParams.get("size");

    useEffect(() => {
        if (dateFrom || dateTo || externalId || status || paymentId || requestId || size){
            if (dateFrom) Filter.setRefundsFilter('dateFrom', dateFrom)
            if (dateTo) Filter.setRefundsFilter('dateTo', dateTo);
            if (externalId) Filter.setRefundsFilter('externalId', externalId);
            if (status) Filter.setRefundsFilter('status', status);
            if (paymentId) Filter.setRefundsFilter('paymentId', paymentId);
            if (requestId) Filter.setRefundsFilter('requestId', requestId);
            if (size) {
                Filter.setRefundsFilter('size', size);
                setFilter({...filter, size});
            }

                refetch({
                    filter: {
                        dateFrom: Filter.refundsFilter.dateFrom + '+06:00',
                        dateTo: Filter.refundsFilter.dateTo + '+06:00',
                        externalId: Filter.refundsFilter.externalId || null,
                        status: Filter.refundsFilter.status || null,
                        paymentId: Filter.refundsFilter.paymentId || null,
                        requestId: Filter.refundsFilter.requestId || null,
                    },
                    page: filter.page - 1,
                    size: filter.size,
                    sortBy: filter.sortBy,
                    sortOrder: filter.sortOrder
                })
                    .then(() => {
                        setPageLoading(false);
                    })
        } else {
            Filter.setRefundsFilter('dateFrom', `${date.slice(0, 11)}00:00`);
            Filter.setRefundsFilter('dateTo', `${date.slice(0, 11)}23:59`);
            Filter.setRefundsFilter('externalId', '');
            Filter.setRefundsFilter('status', '');
            Filter.setRefundsFilter('paymentId', '');
            Filter.setRefundsFilter('requestId', '');
            Filter.setRefundsFilter('size', 25);
        }
    }, [filter.size, Filter.refundsFilter.size]);

    const columns = [
        { title: '#', id: '#' },
        { title: 'Id', id: 'id' },
        { title: 'ID запроса', id: 'requestId' },
        { title: 'Дата создания', id: 'creationDate'},
        { title: 'Дата изменения', id: 'changeDate'},
        { title: 'Внешний ID', id: 'externalId'},
        { title: 'Сумма', id: 'amount' },
        { title: 'Статус', id: 'status' },
        { title: 'ID платежа', id: 'paymentId' },
        { title: 'Код ошибки', id: 'errorCode' },
        { title: 'Текст ошибки', id: 'errorMessage' }
    ];
    const rowsItem = [
        { title: '#'},
        { title: 'id' },
        { title: 'requestId' },
        { title: 'creationDate' },
        { title: 'changeDate' },
        { title: 'externalId' },
        { title: 'amount' },
        { title: 'status' },
        { title: 'paymentId'},
        { title: 'errorCode' },
        { title: 'errorMessage' },
    ];

    if (loading) return <Loader/>
    
    return (
        <div>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem active>Отчет по заявкам на отмену</BreadcrumbItem>
            </Breadcrumb>
            <hr/>
            <Form onSubmit={(e) => {
                e.preventDefault();
                setPageLoading(true);
                    
                    setFilter({
                        ...filter,
                        page: 1
                    });
                    refetch({
                        filter: {
                            dateFrom: Filter.refundsFilter.dateFrom + '+06:00',
                            dateTo: Filter.refundsFilter.dateTo + '+06:00',
                            externalId: Filter.refundsFilter.externalId ? Filter.refundsFilter.externalId : null,
                            status: Filter.refundsFilter.status ? Filter.refundsFilter.status : null,
                            paymentId: Filter.refundsFilter.paymentId ? Filter.refundsFilter.paymentId : null,
                            requestId: Filter.refundsFilter.requestId ? Filter.refundsFilter.requestId : null,
                        },
                        page: 0,
                        size: filter.size,
                        sortBy: filter.sortBy
                    })
                        .then(() => {
                            setPageLoading(false);
                        })
            }}>
                <div className={'filter_wrapper'}>
                    <ReportDatesFilter 
                        dateFrom={Filter.refundsFilter.dateFrom}
                        dateTo={Filter.refundsFilter.dateTo}
                        dateFromChange={(e) => {
                            setSearchParams({ ...Filter.refundsFilter, dateFrom: e.target.value})
                            Filter.refundsFilter.dateFrom = e.target.value
                        }}
                        dateToChange={(e) => {
                            setSearchParams({ ...Filter.refundsFilter, dateTo: e.target.value})
                            Filter.refundsFilter.dateTo = e.target.value
                        }}
                    />
                    <Input
                        className={'filter_item'}
                        type="text"
                        id="paymentId"
                        name="paymentId"
                        placeholder='ID платежа'
                        value={Filter.refundsFilter.paymentId}
                        onChange={(e) => {
                            setSearchParams({ ...Filter.refundsFilter, paymentId: e.target.value})
                                Filter.refundsFilter.paymentId = e.target.value
                        }}
                    />
                    <Input
                        className={'filter_item'}
                        type="text"
                        id="externalId"
                        name="externalId"
                        placeholder='Внешний ID'
                        value={Filter.refundsFilter.externalId}
                        onChange={(e) => {
                            setSearchParams({ ...Filter.refundsFilter, externalId: e.target.value})
                                Filter.refundsFilter.externalId = e.target.value
                        }}
                    />
                    <Input
                        className={'filter_item'}
                        type="text"
                        id="requestId"
                        name="requestId"
                        placeholder='ID запроса'
                        value={Filter.refundsFilter.requestId}
                        onChange={(e) => {
                            setSearchParams({ ...Filter.refundsFilter, requestId: e.target.value})
                                Filter.refundsFilter.requestId = e.target.value
                        }}
                    />
                    <Input
                        className={'filter_item'}
                        type='select'
                        id="status"
                        name='status'
                        value={Filter.refundsFilter.status}
                        onChange={(e) => {
                            setSearchParams({ ...Filter.refundsFilter, status: e.target.value})
                                Filter.refundsFilter.status = e.target.value
                        }}
                        style={{color: '#747678'}}
                    >
                        <option value="">Выберите статус оплаты</option>
                        <option>NEW</option>
                        <option>IN_PROCESS</option>
                        <option>SUCCESS</option>
                        <option>ERROR</option>
                    </Input>
                    <div className={'filter_item'} >
                        <Button color={'primary'} type={'submit'} className="filter_button" style={{width: 100, marginRight: 20}} >Поиск</Button>
                        <Button color={'danger'} style={{width: 100}} className="filter_button" onClick={() => {
                           setPageLoading(true);

                           setFilter({
                               ...filter,
                               size: 25
                           });
                           Filter.cleanRefundsFilter();
                            setSearchParams({
                                dateFrom: `${date.slice(0, 11)}00:00`,
                                dateTo: `${date.slice(0, 11)}23:59`
                            });
                           refetch({
                               filter: {
                                   // dateFrom: `${date.slice(0, 11)}00:00+06:00`,
                                   // dateTo: `${date.slice(0, 11)}23:59+06:00`,
                                   dateFrom: `${(new Date().toISOString()).slice(0, 11)}00:00+06:00`,
                                   dateTo: `${(new Date().toISOString()).slice(0, 11)}23:59+06:00`,
                                   externalId: null,
                                   status: null,
                                   paymentId: null,
                                   requestId: null,
                               },
                               page: 0,
                               size: filter.size,
                               sortBy: filter.sortBy
                           })
                               .then(() => {
                                   setPageLoading(false);
                               })
                        }}>Сбросить</Button>
                    </div>
                </div>
            </Form>
            <br/><hr/>
            <Legenda
                data={[{ status: 'NEW', color: '#dee2e6'}, { status: 'IN_PROCESS', color: '#f8ff9c' }, { status: 'SUCCESS', color: '#c1ffd2'}, { status: 'ERROR', color: '#ef121285' }]}
                title={'Статусы платежей'}
            />
            <DataTable
                notStripped={true}
                hideColumns={['errorCode', 'errorMessage', 'status', 'changeDate']}
                columns={columns}
                rows={refunds}
                rowsItem={rowsItem}
                link={'refunds'}
                sortBy={filter.sortBy}
                sortByHandler={(e) => sortByHandler(e)}
                itemsCountPerPage={filter.size}
                sortOrder={filter.sortOrder}
                pageSizeHandler={(e) => pageSizeHandler(e)}
                totalItemsCount={totalItems}
                activePage={filter.page}
                handlePageChange={(pageNumber) => handlePageChange(pageNumber)}
                isPaginated={true}
                hideIcons={true}
            />
            {pageLoading
                ? <Loader/>
                : null
            }
        </div>
    );
};

export default Refunds;