import React from 'react';
import Backdrop from "../Backdrop/Backdrop";
import "./Loader.css";

const Loader = () => {
    return (
        <div>
            <Backdrop show={true}/>
            <div className="Spinner"></div>
        </div>
    );
};

export default Loader;