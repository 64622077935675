import React from 'react';

const RowCount = ({pageSizeHandler, itemsCountPerPage, values}) => {
    return(
        <div style={{fontSize: 14}}>
            <label htmlFor="rowCount" style={{marginRight: 10, marginBottom: 15}}>Строк: </label>
            <select id="rowCount" name="rowCount" onChange={(e) => pageSizeHandler(e)} value={parseInt(itemsCountPerPage)}>
                {
                    values.map(value => {
                        return <option key={value}>{value}</option>
                    })
                }
            </select>
        </div>
    )
};

export default RowCount;