import React, {useState} from 'react';
import {useMutation} from "@apollo/client";
import {CREATE_TARIFF} from "../../../GraphQL/Mutations";
import {GET_TARIFFS} from "../../../GraphQL/Queries";
import {Link, useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {CreateTariffSchema} from "../../../schemas";
import {toast} from "react-toastify";
import {Breadcrumb, BreadcrumbItem, Button, Col, Form, FormFeedback, Input, Label, Row} from "reactstrap";

const TariffNew = () => {
    const [clicked, setClicked] = useState(false);
    
    const [createTariff] = useMutation(CREATE_TARIFF, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_TARIFFS, variables: { sortBy: 'id', sortOrder : 'asc'}, context: { clientName: 'billing' }}],
        context: { clientName: 'billing' }
    });
    
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: '',
            productType: '',
            amount: 0
        },
        validationSchema: CreateTariffSchema,
        onSubmit: (values) => {
            setClicked(true);
            toast.promise(
                createTariff({
                    variables: {
                        name: values.name,
                        productType: values.productType,
                        amount: parseInt(values.amount)
                    }
                }), {
                    pending: 'В обработке',
                    success: 'Тариф успешно создан',
                    error: 'Ошибка'
                }
            )
                .then(result => {
                    navigate(-1);
                })
        }
    });
    
    return (
        <div>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to='/service_profiles' className="breadcrumb_link">Тарифы</Link></BreadcrumbItem>
                <BreadcrumbItem active>Добавить</BreadcrumbItem>
            </Breadcrumb>
            <h3 className="main_title">Создание тарифа</h3>
            <Form onSubmit={formik.handleSubmit}>
                <Row className='row_form'>
                    <Col xs={4}><Label for="name">Наименование</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            invalid={formik.touched.name && Boolean(formik.errors.name)}
                            onChange={formik.handleChange}
                        />
                        <FormFeedback>
                            {formik.touched.name && formik.errors.name}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="productType">Тип продукта</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="select"
                            name="productType"
                            id="productType"
                            invalid={formik.touched.productType && Boolean(formik.errors.productType)}
                            onChange={formik.handleChange}
                            defaultValue="default"
                        >
                            <option value="default" disabled>Выберите тип продукта</option>
                            <option>
                                Kaspi
                            </option>
                            <option>
                                POS
                            </option>
                            <option>
                                KKM
                            </option>
                        </Input>
                        <FormFeedback>
                            {formik.touched.productType && formik.errors.productType}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="amount">Сумма</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="amount"
                            id="amount"
                            invalid={formik.touched.amount && Boolean(formik.errors.amount)}
                            onChange={formik.handleChange}
                        />
                        <FormFeedback>
                            {formik.touched.amount && formik.errors.amount}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={12}>
                        <Button type="submit" color="primary" className="crud_button mr" disabled={clicked}>
                            Сохранить
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default TariffNew;