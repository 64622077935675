import React from 'react';
import './TransactionRefund.css'

const TransactionRefund = ({allFormik, title, clicked, transactionType, transactionAmount}) => {
    if (transactionType) allFormik.values.sum = transactionAmount;

    return (
        <form className="refund_transaction_form" onSubmit={allFormik.handleSubmit}>
            <h3 className="refund_transaction_title">{title}</h3>
            {transactionType === "ChargeOff"
                ? null
                : <div className={'refund_transaction_form_item'}>
                    <div className="refund_transaction_form_item_inner sum">
                        <label htmlFor={'sum'}>Сумма возврата</label>
                        <input
                            className="refund_transaction_form_input"
                            type={'text'}
                            id={'sum'}
                            name={'sum'}
                            value={allFormik.values.sum}
                            onChange={allFormik.handleChange}
                        />
                    </div>
                    <div
                        className="refund_transaction_form_item_warning"
                        style={allFormik.errors ? {visibility: 'visible'} : {visibility: 'hidden'}}
                    >{allFormik.errors.sum}</div>
                </div>
            }
            <div className={'refund_transaction_form_item'}>
                <div className="refund_transaction_form_item_inner comment">
                    <label className="refund_transaction_form_label" htmlFor={'comment'}>Комментарий</label>
                    <input
                        className="refund_transaction_form_input"
                        type={'text'}
                        id={'comment'}
                        name={'comment'}
                        value={allFormik.values.comment}
                        onChange={allFormik.handleChange}
                    />
                </div>
                <div
                    className="refund_transaction_form_item_warning"
                    style={allFormik.errors ? {visibility: 'visible'} : {visibility: 'hidden'}}
                >{allFormik.errors.comment}</div>
            </div>
            <button type={'submit'} className="transaction_refund_button" disabled={clicked}>Отправить</button>
        </form>
    );
};

export default TransactionRefund;