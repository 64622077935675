import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {FILTER_INVOICES, GET_TERMINALS} from "../../GraphQL/Queries";
import Loader from "../../components/UI/Loader/Loader";
import {Breadcrumb, BreadcrumbItem, Input, Button, Form} from "reactstrap";
import {DataTable} from "../../components/DataTable/DataTable";
import CustomAutocomplete from '../../components/CustomAutocomplete/CustomAutocomplete';
import Legenda from "../../components/UI/Legenda/Legenda";
import { useSearchParams } from "react-router-dom";
import Filter from '../../store/Filter';
import TotalInvoicesTable from '../../components/TotalsTables/TotalInvoicesTable';
import ReportDatesFilter from "../../components/ReportDatesFilter/ReportDatesFilter";
import './Reports.css';

const Invoices = () => {

    const [ pageLoading, setPageLoading ] = useState(false);

    let [searchParams, setSearchParams] = useSearchParams();

    const [filter, setFilter] = useState({
        page: 1,
        size: 25,
        sortBy: 'id',
        sortOrder: 'desc'
    });

    let date = new Date().toISOString();

    const { loading, error, data, refetch } = useQuery(FILTER_INVOICES, { variables: {
            filter: {
                dateFrom: `${date.slice(0, 11)}00:00+06:00`,
                dateTo: `${date.slice(0, 11)}23:59+06:00`,
            },
            page: 0,
            size: filter.size,
            sortBy: 'id',
            sortOrder: 'desc'
        }}
    );

    const allTerminals = useQuery(GET_TERMINALS, { variables: {
            sortBy: "id"
        }}
    );

    const handlePageChange = (pageNumber) => {
        setPageLoading(true);

        console.log(`active page is ${pageNumber}`);
        setFilter({
            ...filter,
            page: pageNumber
        });

        refetch({
            filter: {
                dateFrom: Filter.invoicesFilter.dateFrom + '+06:00',
                dateTo: Filter.invoicesFilter.dateTo + '+06:00',
                externalId: Filter.invoicesFilter.externalId || null,
                status: Filter.invoicesFilter.status || null,
                terminalId: parseInt(Filter.invoicesFilter.terminalId) || null,
            },
            page: pageNumber - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const sortByHandler = (e) => {
        setPageLoading(true);

        if (filter.sortBy === e.target.id) {
            setFilter({
                ...filter,
                sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
            });
        } else {
            setFilter({
                ...filter,
                sortBy: e.target.id
            });
        }

        refetch({
            filter: {
                dateFrom: Filter.invoicesFilter.dateFrom + '+06:00',
                dateTo: Filter.invoicesFilter.dateTo + '+06:00',
                externalId: Filter.invoicesFilter.externalId || null,
                status: Filter.invoicesFilter.status || null,
                terminalId: parseInt(Filter.invoicesFilter.terminalId) || null,
            },
            page: filter.page - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const pageSizeHandler = (e) => {
        setPageLoading(true);

        setFilter({
            ...filter,
            size: parseInt(e.target.value),
            page: 1
        });
        Filter.setInvoicesFilter('size', parseInt(e.target.value));
        setSearchParams({...Filter.invoicesFilter, size: parseInt(e.target.value)});
        refetch({
            filter: {
                dateFrom: Filter.invoicesFilter.dateFrom + '+06:00',
                dateTo: Filter.invoicesFilter.dateTo + '+06:00',
                externalId: Filter.invoicesFilter.externalId || null,
                status: Filter.invoicesFilter.status || null,
                terminalId: parseInt(Filter.invoicesFilter.terminalId) || null,
            },
            page: 0,
            size: parseInt(e.target.value),
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => {
                setPageLoading(false);
            })
    };

    const [invoices, setInvoices] = useState([]);
    const [totalItems, setTotalItems] = useState();
    
    useEffect(() => {
        if (data) {
            setInvoices(data.invoicesPage.data)
            setTotalItems(data.invoicesPage.total)
        }
    }, [data, filter]);

    let dateFrom = searchParams.get("dateFrom");
    let dateTo = searchParams.get("dateTo");
    let externalId = searchParams.get("externalId");
    let status = searchParams.get("status");
    let terminalId = searchParams.get("terminalId");
    let size = searchParams.get("size");
    
    useEffect(() => {
        Filter.cleanInvoicesFilter();
        if (dateFrom || dateTo || externalId || status || terminalId || size){
            if (dateFrom) Filter.setInvoicesFilter('dateFrom', dateFrom)
            if (dateTo) Filter.setInvoicesFilter('dateTo', dateTo);
            if (externalId) Filter.setInvoicesFilter('externalId', externalId);
            if (status) Filter.setInvoicesFilter('status', status);
            if (terminalId) Filter.setInvoicesFilter('terminalId', terminalId);
            if (size) {
                Filter.setInvoicesFilter('size', size);
                setFilter({...filter, size});
            }
            
            refetch({
                filter: {
                    dateFrom: Filter.invoicesFilter.dateFrom + '+06:00',
                    dateTo: Filter.invoicesFilter.dateTo + '+06:00',
                    externalId: Filter.invoicesFilter.externalId || null,
                    status: Filter.invoicesFilter.status || null,
                    terminalId: parseInt(Filter.invoicesFilter.terminalId) || null,
                },
                page: 0,
                size: filter.size,
                sortBy: filter.sortBy
            })
                .then(() => {
                    setPageLoading(false);
                })
        }else {
            Filter.setInvoicesFilter('dateFrom', `${date.slice(0, 11)}00:00`);
            Filter.setInvoicesFilter('dateTo', `${date.slice(0, 11)}23:59`);
            Filter.setInvoicesFilter('externalId', '');
            Filter.setInvoicesFilter('status', '');
            Filter.setInvoicesFilter('terminalId', '');
            Filter.setInvoicesFilter('size', 25);
        }
    },[filter.size, Filter.invoicesFilter.size]);

    const columns = [
        { title: '#', id: '#' },
        { title: 'Id', id: 'id' },
        { title: 'Дата создания', id: 'creationDate'},
        { title: 'Дата изменения', id: 'changeDate'},
        { title: 'Внешний ID', id: 'externalId'},
        { title: 'Сумма', id: 'amount' },
        { title: 'Терминал', id: 'terminalId' },
        { title: 'Наим-е (рус)', id: 'nameRus'},
        { title: 'Наим-е (каз)', id: 'nameKaz'},
        { title: 'Описание (рус)', id: 'descriptionRus' },
        { title: 'Описание (каз)', id: 'descriptionKaz' },
        { title: 'ID платежа', id: 'paymentId' },
    ];

    const rowsItem = [
        { title: '#', },
        { title: 'id' },
        { title: 'creationDate' },
        { title: 'changeDate' },
        { title: 'externalId' },
        { title: 'amount' },
        { title: 'terminalId' },
        { title: 'nameRus' },
        { title: 'nameKaz' },
        { title: 'descriptionRus' },
        { title: 'descriptionKaz' },
        { title: 'paymentId' },

    ];

    const [notSelectedTerminalId, setNotSelectedTerminalId] = useState(null);

    if (loading || allTerminals.loading) return <Loader/>
    if (error) return <div>Произошла ошибка</div>

    return (
        <div>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem active>Отчет по инвойсам</BreadcrumbItem>
            </Breadcrumb>
            <hr/>
            <Form onSubmit={(e) => {
                e.preventDefault();
                setPageLoading(true);
                setSearchParams({
                    dateFrom: Filter.invoicesFilter.dateFrom,
                    dateTo: Filter.invoicesFilter.dateTo,
                    externalId: Filter.invoicesFilter.externalId ? Filter.invoicesFilter.externalId : '',
                    status: Filter.invoicesFilter.status ? Filter.invoicesFilter.status : '',
                    terminalId: Filter.invoicesFilter.terminalId ? Filter.invoicesFilter.terminalId : '',
                    size: Filter.invoicesFilter.size ? Filter.invoicesFilter.size : 25
                });
                setFilter({
                    ...filter,
                    page: 1,
                });
                refetch({
                    filter: {
                        dateFrom: Filter.invoicesFilter.dateFrom + '+06:00',
                        dateTo: Filter.invoicesFilter.dateTo + '+06:00',
                        externalId: Filter.invoicesFilter.externalId ? Filter.invoicesFilter.externalId : null,
                        status: Filter.invoicesFilter.status ? Filter.invoicesFilter.status : null,
                        terminalId: Filter.invoicesFilter.terminalId ? parseInt(Filter.invoicesFilter.terminalId) : notSelectedTerminalId,
                    },
                    page: 0,
                    size: filter.size,
                    sortBy: filter.sortBy
                })
                    .then(() => {
                        setPageLoading(false);
                    })
            }}>
            <div className={'filter_wrapper'}>
                <ReportDatesFilter 
                    dateFrom={Filter.invoicesFilter.dateFrom}
                    dateTo={Filter.invoicesFilter.dateTo}
                    dateFromChange={(e) => {
                        setSearchParams({ ...Filter.invoicesFilter, terminalId: Filter.invoicesFilter.terminalId ? Filter.invoicesFilter.terminalId : '', dateFrom: e.target.value})
                        Filter.invoicesFilter.dateFrom = e.target.value
                    }}
                    dateToChange={(e) => {
                        setSearchParams({ ...Filter.invoicesFilter, terminalId: Filter.invoicesFilter.terminalId ? Filter.invoicesFilter.terminalId : '', dateTo: e.target.value})
                        Filter.invoicesFilter.dateTo = e.target.value
                    }}
                />
                <Input
                    className={'filter_item'}
                    type="text"
                    id="externalId"
                    name="externalId"
                    placeholder='Внешний ID'
                    value={Filter.invoicesFilter.externalId}
                    onChange={(e) => {
                        setSearchParams({ ...Filter.invoicesFilter, terminalId: Filter.invoicesFilter.terminalId ? Filter.invoicesFilter.terminalId : '', externalId: e.target.value})
                        Filter.invoicesFilter.externalId = e.target.value
                    }}
                />
                <div className={'filter_item'}>
                    <CustomAutocomplete
                        list={allTerminals.data?.terminals}
                        name={'terminalId'}
                        setValue={(name, item) => {
                            if (item) {
                                setSearchParams({ ...Filter.invoicesFilter, terminalId: item.id})
                                Filter.invoicesFilter.terminalId = item.id
                            } else {
                                setSearchParams({ ...Filter.invoicesFilter, terminalId: ''})
                                Filter.invoicesFilter.terminalId = null
                            }
                        }}
                        placeholder="Наименование / GUID терминала"
                        searchBy={["name", "guid"]}
                        resultStringKeyName='guid'
                        getValueBy={'id'}
                        edit={true}
                        value={Filter.invoicesFilter.terminalId ? Filter.invoicesFilter.terminalId : null}
                    />
                </div>
                <Input
                    className={'filter_item'}
                    type='select'
                    id="status"
                    value={Filter.invoicesFilter.status }
                    name='status'
                    onChange={(e) => {
                        setSearchParams({ ...Filter.invoicesFilter, terminalId: Filter.invoicesFilter.terminalId ? Filter.invoicesFilter.terminalId : '', status:  e.target.value})
                        Filter.invoicesFilter.status =  e.target.value
                    }}
                    style={{color: '#747678'}}
                >
                    <option value={''}>Выберите статус</option>
                    <option>NEW</option>
                    <option>PROCESSING</option>
                    <option>PAID</option>
                    <option>COMPLETED</option>
                    <option>CANCELED</option>
                </Input>
                <div className={'buttons_wrapper'}>
                    <Button color={'primary'} type={'submit'} className="filter_button" style={{width: 100, marginRight: 20}} >Поиск</Button>
                    <Button color={'danger'} style={{width: 100}} className="filter_button" onClick={() => {
                        setPageLoading(true);
                        setSearchParams({
                            dateFrom: `${date.slice(0, 11)}00:00`,
                            dateTo: `${date.slice(0, 11)}23:59`
                        });
                        Filter.cleanInvoicesFilter();
                        setFilter({
                            ...filter,
                            size: 25
                        })
                        setNotSelectedTerminalId(null);
                        refetch({
                            filter: {
                                // dateFrom: `${date.slice(0, 11)}00:00+06:00`,
                                // dateTo: `${date.slice(0, 11)}23:59+06:00`,
                                dateFrom: `${(new Date().toISOString()).slice(0, 11)}00:00+06:00`,
                                dateTo: `${(new Date().toISOString()).slice(0, 11)}23:59+06:00`,
                                externalId: null,
                                status: null,
                                terminalId: null,
                            },
                            page: filter.page - 1,
                            size: filter.size,
                            sortBy: filter.sortBy
                        })
                            .then(() => {
                                setPageLoading(false);
                            })
                    }}>Сбросить</Button>
                </div>
            </div>
            </Form>
            <br/><hr/>
            <Legenda 
                data={[{ status: 'NEW', color: '#dee2e6'}, { status: 'PROCESSING', color: '#f8ff9c' }, { status: 'PAID', color: '#a7c8d580'}, { status: 'COMPLETED', color: '#c1ffd2'}, { status: 'CANCELED', color: '#ef121285' }]}
                title={'Статусы платежей'}
            /> 
            <DataTable
                notStripped={true}
                hideColumns={['nameKaz', 'descriptionKaz', 'descriptionRus', 'changeDate']}
                columns={columns}
                rows={invoices}
                rowsItem={rowsItem}
                terminals={allTerminals.data ? allTerminals.data.terminals : []}
                sortBy={filter.sortBy}
                sortByHandler={(e) => sortByHandler(e)}
                itemsCountPerPage={filter.size}
                sortOrder={filter.sortOrder}
                pageSizeHandler={(e) => pageSizeHandler(e)}
                totalItemsCount={totalItems}
                activePage={filter.page}
                handlePageChange={(pageNumber) => handlePageChange(pageNumber)}
                isPaginated={true}
                hideIcons={true}
            />
            <TotalInvoicesTable
                totalAmount={data?.invoicesPage?.totalAmount}
            />
            {pageLoading
                ? <Loader/>
                : null
            }
        </div>
    );
};

export default Invoices;