import React, {useState} from "react";
import {useMutation} from "@apollo/client";
import {DEACTIVATE_TERMINAL} from "../../GraphQL/Mutations";
import {useFormik} from "formik";
import {DeactivateTerminal} from "../../schemas";
import {toast} from "react-toastify";
import {Form, Button, Col, Input, Label, Row} from "reactstrap";

const DeactivateModule = ({activationId, handler}) => {
    
    const [clicked, setClicked] = useState(false);
    const [productActivationRefund] = useMutation(DEACTIVATE_TERMINAL, {context: {clientName: 'billing'},});

    const formik = useFormik({
        initialValues: {
            activationId: activationId,
            comment: ''
        },
        validationSchema: DeactivateTerminal,
        onSubmit: (values) => {
            setClicked(true);
            toast.promise(
                productActivationRefund({
                    variables: {
                        model: {
                            activationId: values.activationId,
                            comment: values.comment,
                        }
                    }
                }), {
                    pending: 'В обработке',
                    success: {
                        render(data){
                            return data.data.data.productActivationRefund.message
                        }
                    },
                    error: 'Ошибка'
                }
            )
                .then(result => {
                    handler()
                })
                .catch(err => {
                    setClicked(false);
                })
        }
    });
    return(
        <Form onSubmit={formik.handleSubmit}>
            <div className={'create_invoice_wrapper'}>
                <h3>Деактивация терминала</h3>
                <Row className={'create_invoice_row'}>
                    <Col><Label htmlFor={'comment'}>Комментарии</Label></Col>
                    <Col>
                        <Input
                            type={'textarea'}
                            id={'comment'}
                            name={'comment'}
                            value={formik.values.comment}
                            onChange={formik.handleChange}
                        />
                    </Col>
                </Row>
                <Button color={'primary'} type={'submit'} className="filter_button"
                        disabled={clicked}>Деакти-ать</Button>
            </div>
        </Form>
    );
};

export default DeactivateModule;