import React from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavLink, NavItem } from "reactstrap";
import {NavLink as RouterLink} from "react-router-dom";

const UserMenu = ({username, logout}) => {
    return (
            <div className="links_wrapper">
                <UncontrolledDropdown nav inNavbar className="menu_link_title">
                    <DropdownToggle nav caret>
                        Биллинг
                    </DropdownToggle>
                    <DropdownMenu end>
                        <DropdownItem>
                            <NavLink style={{color: "black", padding: 0, fontSize: 16}} tag={RouterLink} to="/billing/tariffs">
                                Тарифы
                            </NavLink>
                        </DropdownItem>
                        <DropdownItem>
                            <NavLink style={{color: "black", padding: 0, fontSize: 16}} tag={RouterLink} to="/billing/invoices">
                                Отчет по инвойсам
                            </NavLink>
                        </DropdownItem>
                        <DropdownItem>
                            <NavLink style={{color: "black", padding: 0, fontSize: 16}} tag={RouterLink} to="/billing/invoices/create">
                                Создание инвойса
                            </NavLink>
                        </DropdownItem>
                        <DropdownItem>
                            <NavLink style={{color: "black", padding: 0, fontSize: 16}} tag={RouterLink} to="/billing/transactions">
                                Отчет по транзакциям
                            </NavLink>
                        </DropdownItem>
                        <DropdownItem>
                            <NavLink style={{color: "black", padding: 0, fontSize: 16}} tag={RouterLink} to="/billing/accounts">
                                Отчет по балансам
                            </NavLink>
                        </DropdownItem>
                        <DropdownItem>
                            <NavLink style={{color: "black", padding: 0, fontSize: 16}} tag={RouterLink} to="/billing/terminals">
                                Отчет по терминалам
                            </NavLink>
                        </DropdownItem>
                        <DropdownItem>
                            <NavLink style={{color: "black", padding: 0, fontSize: 16}} tag={RouterLink} to="/billing/terminalActivation">
                                Отчет по активации терминалов
                            </NavLink>
                        </DropdownItem>
                        
                        {/*<DropdownItem>*/}
                        {/*    <NavLink style={{color: "black", padding: 0, fontSize: 16}} tag={RouterLink} to="/">*/}
                        {/*        Отчет по задолженностям*/}
                        {/*    </NavLink>*/}
                        {/*</DropdownItem>*/}
                    </DropdownMenu>
                </UncontrolledDropdown>
                
                <UncontrolledDropdown nav inNavbar className="menu_link_title">
                    <DropdownToggle nav caret>
                        Отчеты
                    </DropdownToggle>
                    <DropdownMenu end>
                        <DropdownItem>
                            <NavLink style={{color: "black", padding: 0, fontSize: 16}} tag={RouterLink} to="/invoices">
                                Отчет по инвойсам
                            </NavLink>
                        </DropdownItem>
                        <DropdownItem>
                            <NavLink style={{color: "black", padding: 0, fontSize: 16}} tag={RouterLink} to="/payments">
                                Отчет по платежам
                            </NavLink>
                        </DropdownItem>
                        <DropdownItem>
                            <NavLink style={{color: "black", padding: 0, fontSize: 16}} tag={RouterLink} to="/refunds">
                                Отчет по заявкам на отмену
                            </NavLink>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                
                <NavItem>
                    <NavLink tag={RouterLink} to="/merchants">
                        Мерчанты
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={RouterLink} to="/terminals">
                        Терминалы
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={RouterLink} to="/terminal_types">
                        Типы терминалов
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={RouterLink} to="/services">
                        Услуги
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={RouterLink} to="/service_profiles">
                        Профили услуг
                    </NavLink>
                </NavItem>
                <UncontrolledDropdown
                    inNavbar
                    nav
                >
                    <DropdownToggle
                        caret
                        nav
                    >
                        {username}
                    </DropdownToggle>
                    <DropdownMenu end>
                        <DropdownItem onClick={() => logout()}>
                            Выход
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
    );
};

export default UserMenu;
