import React, { useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_INVOICE, GET_TERMINALS } from '../../GraphQL/Queries';
import { Modal, ModalHeader, Table } from 'reactstrap';
import { resolveOperationsTitle } from '../../services/utils/resolveOperationsTitle';
import { resolvePaymentValue } from '../../services/utils/resolvePaymentValue';
import Loader from "../UI/Loader/Loader";

const InvoiceDetails = ({id}) => {
    const [showModal, setShowModal] = useState(false);

    const allTerminals = useQuery(GET_TERMINALS, { variables: {
            sortBy: "id"
        }}
    );

    const [getInvoice, { loading, error, data }] = useLazyQuery(GET_INVOICE);

    const getInfo = () => {
        setShowModal(!showModal);
        getInvoice({variables: { id: id}})
    };
    
    return (
        <>
            <span onClick={getInfo} className='paymentValue'>{id}</span>
            <Modal
                isOpen={showModal}
                centered
                fullscreen="xl"
                size="lg"
                toggle={() => setShowModal(!showModal)}
            >
                <ModalHeader toggle={() => setShowModal(!showModal)}>
                    Детали инвойса
                </ModalHeader>
                {loading
                    ? <Loader />
                    : <Table striped>
                        <tbody>
                        { data
                            ? Object.keys(data.invoice).map(field => {
                                const item = data.invoice[field];
                                if (field !== '__typename') {
                                    return <tr key={field}>
                                        <td>
                                            {resolveOperationsTitle(field)}:
                                        </td>
                                        <td>
                                            {resolvePaymentValue(field, item, allTerminals.data?.terminals)}
                                        </td>
                                    </tr>
                                }
                            })
                            : null
                        }
                        </tbody>
                    </Table>
                }
                
            </Modal>
        </>
    )
};

export default InvoiceDetails;