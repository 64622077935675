import React, {useState} from "react";
import {Modal, ModalHeader, Table} from "reactstrap";

const BillingTransactionsForActivation = ({transactions}) => {
    const [showModal, setShowModal] = useState(false);
    const dateFormatFunc = (value) => {
        let date = new Date(value);
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, 0);
        let day = (date.getDate()).toString().padStart(2, 0);
        let hours = (date.getHours()).toString().padStart(2, 0);
        let minutes = (date.getMinutes()).toString().padStart(2, 0);
        let seconds = (date.getSeconds()).toString().padStart(2, 0);
        return value === null ? "N/A" : `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    }
    
    return(
        <>
            <span onClick={() => setShowModal(true)} className='paymentValue'>Транзакции</span>
            <Modal
                isOpen={showModal}
                centered
                fullscreen="xl"
                size="lg"
                toggle={() => setShowModal(!showModal)}
            >
                <ModalHeader toggle={() => setShowModal(!showModal)}>
                    Список транзакции
                </ModalHeader>
                <Table striped style={{textAlign: 'center'}}>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>CreatedDate</th>
                        <th>Amount</th>
                        <th>Type</th>
                        <th>BillingInvoiceId</th>
                        <th>description</th>
                    </tr>
                    </thead>
                    <tbody>

                    </tbody>
                    {transactions.map((item, i) => {
                        return <tr key={item.id}>
                            <td>{i + 1}</td>
                            <td>{dateFormatFunc(item.createdDate)}</td>
                            <td>{item.amount} </td>
                            <td>{item.type}</td>
                            <td>{item.billingInvoiceId}</td>
                            <td>{item.description}</td>
                        </tr>
                    })}
                </Table>
            </Modal>
        </>
    )
};

export default BillingTransactionsForActivation;