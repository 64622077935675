let resourcesURL = 'http://kaspi.multibankonline.net:8080';
let billingURL = 'https://billing.multibankonline.net:8080';
let controllerURL = 'http://cms.multibankonline.net:8080';
let logsURL = 'http://cms.multibankonline.net:8080';

console.log(process.env.REACT_APP_NODE_ENV)

if (process.env.REACT_APP_NODE_ENV === "prod"){
  resourcesURL = "http://kaspi.multibank.kz";
  billingURL = 'http://billing.multibank.kz';
  controllerURL = 'http://cms.multibank.kz';
  logsURL = 'http://cms.multibank.kz';
}

// if (process.env.REACT_APP_NODE_ENV === "dev"){
//   resourcesURL = "http://kaspi.multibankonline.net:8080/graphql";
// };

const config = {
  apiURL: resourcesURL,
  billingURL: billingURL,
  controllerURL: controllerURL,
  logsURL: logsURL
};

export default config;