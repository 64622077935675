import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {GET_TARIFFS} from "../../../GraphQL/Queries";
import {Breadcrumb, BreadcrumbItem, Button, Form, Input} from "reactstrap";
import {DataTable} from "../../../components/DataTable/DataTable";
import Loader from "../../../components/UI/Loader/Loader";
import BillingFilter from "../../../store/BillingFilter";

const Tariffs = () => {
    
    const navigate = useNavigate();
    
    const [filter, setFilter] = useState({
        sortBy: 'id',
        sortOrder: 'asc'
    });

    const [ pageLoading, setPageLoading ] = useState(false);

    const { loading, error, data, refetch } = useQuery(GET_TARIFFS, { context: { clientName: 'billing' }});

    let [searchParams, setSearchParams] = useSearchParams();
    
    const [tariffs, setTariffs] = useState([]);
    
    useEffect(() => {
        if (data) {
            setTariffs(data.tariffsByProduct);
        }
    }, [data, filter]);

    let productType = searchParams.get("productType");
    
    useEffect(() => {
        BillingFilter.cleanTariffsFilter();

        if (productType) {
            if (productType) BillingFilter.setTariffsFilter('productType', productType)
                else BillingFilter.setTariffsFilter('productType', '');
            refetch({
                productType: BillingFilter.tariffsFilter.productType
            })
        }
    }, []);
    

    const columns = [
        { title: 'Id', id: 'id' },
        { title: 'Наим-е', id: 'name'},
        { title: 'Продукт', id: 'productType' },
        { title: 'Сумма', id: 'amount' },
    ];

    const rowsItem = [
        { title: 'id' },
        { title: 'name' },
        { title: 'productType' },
        { title: 'amount' },
    ];

    if (loading || pageLoading) return <Loader/>
    
    return (
        <div>
            <div className="breadcrumb_wrapper">
                <Breadcrumb>
                    <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Тарифы</BreadcrumbItem>
                </Breadcrumb>
                <div>
                    <Button
                        color="dark"
                        className="add_btn"
                        onClick={() => navigate('/billing/tariffs/new')}
                    >
                        + Добавить
                    </Button>
                </div>
            </div>
            <hr/>
            <Form>
                <div className={'filter_wrapper'}>
                    <Input
                        className={'filter_item'}
                        type='select'
                        id="productType"
                        value={BillingFilter.tariffsFilter.productType }
                        name='productType'
                        onChange={(e) => {
                            setSearchParams({ ...BillingFilter.tariffsFilter, productType:  e.target.value})
                            BillingFilter.tariffsFilter.productType =  e.target.value
                        }}
                        style={{color: '#747678'}}
                    >
                        <option value={''}>Выберите тип продукта</option>
                        <option>Kaspi</option>
                        <option>POS</option>
                        <option>KKM</option>
                    </Input>
                    <div className={'buttons_wrapper'}>
                        <Button color={'primary'} type={'submit'} className="filter_button" style={{width: 100, marginRight: 20}} >Поиск</Button>
                        <Button color={'danger'} style={{width: 100}} className="filter_button" onClick={() => {
                            setPageLoading(true);
                            setSearchParams({productType: ''});
                            BillingFilter.cleanTariffsFilter();
                            refetch({productType: null })
                                .then(() => {
                                    setPageLoading(false);
                                })
                        }}>Сбросить</Button>
                    </div>
                </div>    
            </Form>
            <br/><hr/>
            <DataTable
                hideColumns={[]}
                columns={columns}
                rows={tariffs}
                rowsItem={rowsItem}
                sortBy={filter.sortBy}
                isPaginated={false}
                sortByHandler={(e) => console.log(e)}
                hideIcons={true}
            />
        </div>
    );
};

export default Tariffs;