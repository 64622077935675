import * as React from 'react';
import InputMask from 'react-input-mask';

import './LoginForm.css';


const LoginForm = ({login, blurHandler, submitHandler, changeHandler}) => (
    <form onSubmit={(e) => submitHandler(e)}>
        <div className='login_wrapper'>
            <div className={'login_item_wrapper'}>
               

                {/*<input*/}
                {/*    id="login"*/}
                {/*    name="login"*/}
                {/*    type="text"*/}
                {/*    autoComplete="login"*/}
                {/*    required*/}
                {/*    className="login_item"*/}
                {/*    placeholder="Логин"*/}
                {/*    onChange={(e) => changeHandler(e)}  */}
                {/*/>*/}
                <InputMask
                    mask='+7 999 999 99 99'
                    required
                    value={login}
                    placeholder="Логин"
                    id={'login'}
                    name={'login'}
                    onChange={changeHandler}
                    onBlur={() => blurHandler()}
                    className={`login_item`}
                />
            </div>
            <div className={'login_item_wrapper'}>
                <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="login_item"
                    placeholder="Пароль"
                    onChange={(e) => changeHandler(e)}
                />
            </div>
            
            <button
                type="submit"
                className="login_button"
            >
                Вход
            </button>
        </div>
    </form>
)

export default LoginForm;
