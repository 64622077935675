import React from 'react';
import {useMutation} from "@apollo/client";
import {KASPI_BANK_PUSH_NOTIFY} from "../../GraphQL/Mutations";
import {Button} from "reactstrap";
import {toast} from "react-toastify";

const PushNotification = ({invoiceId, closeModal}) => {
    const [kaspiBankPushNotify, {loading, data}] = useMutation(KASPI_BANK_PUSH_NOTIFY, { context: { clientName: 'billing' }});
    
    const onSubmit = () => {
        toast.promise(
            kaspiBankPushNotify({
                variables: { invoiceId } 
            }), {
                pending: 'В обработке',
                success: {
                    render({data}) {
                        console.log(data)
                        return  data.data.kaspiBankPushNotify.success ? 'Успех' : data.data.kaspiBankPushNotify.message
                    }
                },
                // success: (data) => data,
                error: 'Ошибка'
            },
        )
            .then((data) => {
                if (data.data.kaspiBankPushNotify.success){
                    closeModal();
                }
            })
    }
    
    return (
        <div className={'create_invoice_wrapper'}>
            <h4>Отправить push уведомление?</h4>
            <div className={'invoice_cancel_wrapper'}>
                <Button color={'primary'} className="invoice_cancel_btn" onClick={() => {
                    onSubmit()
                }}>Да</Button>
                <Button className="invoice_cancel_btn" onClick={() => {
                    closeModal()
                }}>Нет</Button>
            </div>
        </div>
    );
};

export default PushNotification;