import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {UPDATE_TERMINAL_TYPE} from "../../GraphQL/Mutations";
import {GET_TERMINAL_TYPES, GET_TERMINAL_TYPE} from "../../GraphQL/Queries";
import {toast} from "react-toastify";
import Loader from "../../components/UI/Loader/Loader";
import {Breadcrumb, BreadcrumbItem, Button, Col, Form, FormFeedback, Input, Label, Row} from "reactstrap";
import {useFormik} from "formik";
import {CreateTerminalTypeSchema} from "../../schemas";

const TerminalTypeEdit = () => {

    const params = useParams();

    const { loading, error, data } = useQuery(GET_TERMINAL_TYPE, { variables: {
            id: params.id
        }}
    );
    
    const [updateTerminalType] = useMutation(UPDATE_TERMINAL_TYPE, {
        awaitRefetchQueries: true,
        refetchQueries: [
            { query: GET_TERMINAL_TYPES, variables: { sortBy: 'id', sortOrder: 'asc'}}
        ],
    });
    const navigate = useNavigate();
    const [terminalType, setTerminalType] = useState({});
    const [isBlocked, setIsBlocked] = useState(true);
    const [clicked, setClicked] = useState(false);

    const formik = useFormik({
        initialValues: terminalType,
        enableReinitialize: true,
        validationSchema: CreateTerminalTypeSchema,
        onSubmit: (values) => {
            setClicked(true);
            toast.promise(
                updateTerminalType({
                    variables: {
                        id: terminalType.id,
                        kaspiQrURL: values.kaspiQrURL,
                        name: values.name
                    }
                }), {
                    pending: 'В обработке',
                    success: 'Тип терминала успешно обновлен',
                    error: 'Ошибка'
                }
            )
                .then(result => {
                    navigate('/terminal_types');
                })
                .catch(err => {
                    setClicked(false);
                })
        }
    });
    
    useEffect(() => {

        if (data && data.terminalType) {
            setTerminalType(data.terminalType);
        }

    },[data]);

    if (loading) return <Loader/>

    if (error) return <div>Ошибка
        {error.message}
    </div>
    
    return (
        <div>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to='/terminal_types' className="breadcrumb_link">Типы терминалов</Link></BreadcrumbItem>
                <BreadcrumbItem active>{data.terminalType.name}</BreadcrumbItem>
            </Breadcrumb>

            <h3 className="main_title">Редактирование типа терминала</h3>

            <Form onSubmit={formik.handleSubmit}>
                <Row className='row_form'>
                    <Col xs={4}><Label for="name">ID</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            value={terminalType.id}
                            disabled
                        />
                    </Col>
                </Row>
                
                <Row className='row_form'>
                    <Col xs={4}><Label for="name">Наименование</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            disabled={isBlocked}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            invalid={formik.touched.name && Boolean(formik.errors.name)}
                        />
                        <FormFeedback>
                            {formik.touched.name && formik.errors.name}
                        </FormFeedback>
                    </Col>
                </Row>

                <Row className='row_form'>
                    <Col xs={4}><Label for="kaspiQrURL">Qr</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="kaspiQrURL"
                            id="kaspiQrURL"
                            disabled={isBlocked}
                            value={formik.values.kaspiQrURL}
                            onChange={formik.handleChange}
                            invalid={formik.touched.kaspiQrURL && Boolean(formik.errors.kaspiQrURL)}
                        />
                        <FormFeedback>
                            {formik.touched.kaspiQrURL && formik.errors.kaspiQrURL}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}>
                        <Button color='dark' onClick={() => navigate(-1)}>Назад</Button>
                    </Col>    
                        {isBlocked
                            ? <Col xs={4}><Button
                                color="success"
                                className="add_btn"
                                onClick={() => setIsBlocked(false)}>
                                Редактировать
                            </Button></Col>
                            : <>
                                <Col xs={4}><Button
                                    type="button"
                                    color="danger"
                                    className="add_btn"
                                    onClick={() => {
                                        setIsBlocked(true);
                                        setTerminalType(data.terminalType);
                                    }}
                                >
                                    Отмена
                                </Button></Col>
                                <Col xs={4}><Button type="submit" color="primary" className="add_btn mr" disabled={clicked}>
                            Сохранить
                        </Button></Col>
                            </>
                        }
                </Row>
            </Form>
        </div>
    );
};

export default TerminalTypeEdit;