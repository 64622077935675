import {makeAutoObservable} from "mobx";

let date = new Date().toISOString();

class Filter {
    merchantsFilter = {
        name: '',
        bin: '',
        size: 25
    };
    terminalsFilter = {
        name: '',
        guid: '',
        merchantId: '',
        servicesProfileId: '',
        paymentType: '',
        active: '',
        typeId: '',
        useCredit: '',
        useAMQP: '',
        size: 25
    };
    servicesFilter = {
        name: '',
        size: 25
    };
    invoicesFilter = {
        dateFrom: `${date.slice(0, 11)}00:00`,
        dateTo: `${date.slice(0, 11)}23:59`,
        externalId: '',
        terminalId: '',
        status: '',
        size: 25
    };
    paymentesFilter = {
        dateFrom: `${date.slice(0, 11)}00:00`,
        dateTo: `${date.slice(0, 11)}23:59`,
        externalId: '',
        type: '',
        status: '',
        terminalId: '',
        merchantId: '',
        clientId: '',
        serviceId: '',
        paymentSystemId: '',
        size: 25
    };
    refundsFilter = {
        dateFrom: `${date.slice(0, 11)}00:00`,
        dateTo: `${date.slice(0, 11)}23:59`,
        externalId: '',
        status: '',
        paymentId: '',
        requestId: '',
        size: 25
    };

    constructor() {
        makeAutoObservable(this);
    };

    setRefundsFilter(name, value){
        this.refundsFilter = {
            ...this.refundsFilter,
            [name]: value
        }
    };

    cleanRefundsFilter = () => {
        this.refundsFilter = {
            dateFrom: `${date.slice(0, 11)}00:00`,
            dateTo: `${date.slice(0, 11)}23:59`,
            externalId: '',
            status: '',
            paymentId: '',
            requestId: '',
            size: 25
        }
    };
    
    setInvoicesFilter(name, value){
        this.invoicesFilter = {
            ...this.invoicesFilter,
            [name]: value
        }
    };

    cleanInvoicesFilter = () => {
        this.invoicesFilter = {
            dateFrom: `${date.slice(0, 11)}00:00`,
            dateTo: `${date.slice(0, 11)}23:59`,
            externalId: '',
            terminalId: '',
            status: '',
            size: 25
        }
    };

    setPaymentsFilter(name, value){
        this.paymentesFilter = {
            ...this.paymentesFilter,
            [name]: value
        }
    };

    cleanPaymentsFilter = () => {
        this.paymentesFilter = {
            dateFrom: `${date.slice(0, 11)}00:00`,
            dateTo: `${date.slice(0, 11)}23:59`,
            externalId: '',
            type: '',
            status: '',
            terminalId: '',
            merchantId: '',
            clientId: '',
            serviceId: '',
            paymentSystemId: '',
            size: 25
        }
    }

    setMerchantsFilter(name, value) {
        this.merchantsFilter = {
            ...this.merchantsFilter,
            [name]: value
        }
    };
    
    cleanMerchantsFilter = () => {
        this.merchantsFilter = {
            name: '',
            bin: '',
            size: 25
        }
    }
    
    setTerminalsFilter(name, value) {
        this.terminalsFilter = {
            ...this.terminalsFilter,
            [name]: value
        }
    };

    cleanTerminalsFilter = () => {
        this.terminalsFilter = {
            name: '',
            guid: '',
            merchantId: '',
            servicesProfileId: '',
            paymentType: '',
            active: '',
            useCredit: '',
            size: 25
        }
    };

    setServicesFilter(name, value) {
        this.servicesFilter = {
            ...this.servicesFilter,
            [name]: value
        }
    };

    cleanServicesFilter = () => {
        this.servicesFilter = {
            name: '',
            size: 25
        }
    }
}

export default new Filter();