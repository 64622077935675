import React, {useState} from 'react';
import {useMutation} from "@apollo/client";
import {CREATE_TERMINAL_TYPE} from "../../GraphQL/Mutations";
import {GET_TERMINAL_TYPES} from "../../GraphQL/Queries";
import {Link, useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {CreateTerminalTypeSchema} from "../../schemas";
import {toast} from "react-toastify";
import {Breadcrumb, BreadcrumbItem, Button, Col, Form, FormFeedback, Input, Label, Row} from "reactstrap";

const TerminalTypeNew = () => {

    const [createTerminalType] = useMutation(CREATE_TERMINAL_TYPE, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_TERMINAL_TYPES, variables: { sortBy: 'id', sortOrder: 'asc'}}],
    });

    const navigate = useNavigate();
    const [clicked, setClicked] = useState(false);
    
    const formik = useFormik({
        initialValues: {
            kaspiQrURL: '',
            name: ''
        },
        validationSchema: CreateTerminalTypeSchema,
        onSubmit: (values) => {
            setClicked(true);
            toast.promise(
                createTerminalType({
                    variables: {
                        kaspiQrURL: values.kaspiQrURL,
                        name: values.name
                    }
                }), {
                    pending: 'В обработке',
                    success: 'Тип терминала успешно создан',
                    error: 'Ошибка'
                }
            )
                .then(result => {
                    navigate('/terminal_types');
                })
                .catch(err => {
                    setClicked(false);
                })
        }
    });
    
    return (
        <div>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to='/terminal_types' className="breadcrumb_link">Типы терминалов</Link></BreadcrumbItem>
                <BreadcrumbItem active>Добавить</BreadcrumbItem>
            </Breadcrumb>
            <h3 className="main_title">Создание типа терминала</h3>
            <Form onSubmit={formik.handleSubmit}>
                <Row className='row_form'>
                    <Col xs={4}><Label for="name">Наименование</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            invalid={formik.touched.name && Boolean(formik.errors.name)}
                            onChange={formik.handleChange}
                        />
                        <FormFeedback>
                            {formik.touched.name && formik.errors.name}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="kaspiQrURL">Qr</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="kaspiQrURL"
                            id="kaspiQrURL"
                            invalid={formik.touched.kaspiQrURL && Boolean(formik.errors.kaspiQrURL)}
                            onChange={formik.handleChange}
                        />
                        <FormFeedback>
                            {formik.touched.kaspiQrURL && formik.errors.kaspiQrURL}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={12}>
                        <Button type="submit" color="primary" className="crud_button mr" disabled={clicked}>
                            Сохранить
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default TerminalTypeNew;