import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {CHANGE_PRODUCT_MERCHANT, UPDATE_TERMINAL} from "../../GraphQL/Mutations";
import {
    GET_SERVICE_PROFILES,
    GET_TERMINAL,
    GET_TERMINAL_TYPES,
    GET_TERMINALS
} from "../../GraphQL/Queries";
import {toast} from "react-toastify";
import Loader from "../../components/UI/Loader/Loader";
import {Form, Row, Col, Button, Label, Input, FormFeedback} from "reactstrap";
import CustomAutocomplete from "../../components/CustomAutocomplete/CustomAutocomplete";
import InputMask from "react-input-mask";
import {useFormik} from "formik";
import {CreateTerminalSchema} from "../../schemas";
import Filter from "../../store/Filter";

const TerminalEdit = ({merchants}) => {
    const [clicked, setClicked] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams();
    const [updateTerminal] = useMutation(UPDATE_TERMINAL, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_TERMINALS, variables: { sortBy: 'id' }}],
    });
    
    const [changeProductsMerchant] = useMutation(CHANGE_PRODUCT_MERCHANT, {
        context: { context: { clientName: 'billing' }}
    });

    const serviceProfile = useQuery(GET_SERVICE_PROFILES, { variables: {
            sortBy: "id"
        }}
    );
    
    const terminalTypes = useQuery(GET_TERMINAL_TYPES, { variables: {
            sortBy: 'id',
            sortOrder: 'asc'
        }}
    );
    
    const navigate = useNavigate();

    const [terminal, setTerminal] = useState({
        id: '',
        guid: '',
        name: '',
        type: '',
        useCredit: '',
        useAMQP: '',
        creditAmount: '',
        address: '',
        step: '',
        merchantId: '',
        servicesProfileId: '',
        paymentType: '',
        active: true
    });
    
    const handleChange = (e) => {
        setTerminal({
            ...terminal,
            [e.target.name]: e.target.value
        })
    }

    const { loading, error, data } = useQuery(GET_TERMINAL, { variables: {
            id: params.id
        }});
    
    useEffect(() => {
        if (data && data.terminal) {
            setTerminal(data.terminal);
            setSearchParams({
                guid: data.terminal.guid,
                bin: merchants.find(item => parseInt(item.id) === parseInt(data.terminal.merchantId)).bin
            })
        }
    },[data]);
    
    const formik = useFormik({
        initialValues: terminal,
        enableReinitialize: true,
        validationSchema: CreateTerminalSchema,
        onSubmit: (values) => {
            setClicked(true);

            let variables;
            if (values.paymentType === "SERVICE"){
                variables = {
                    id: terminal.id,
                    guid: values.guid,
                    name: values.name,
                    typeId: terminal.typeId,
                    useCredit: values.useCredit,
                    useAMQP: values.useAMQP,
                    creditAmount: values.useCredit ? values.creditAmount : 0,
                    address: values.address,
                    step: values.step,
                    merchantId: values.merchantId,
                    servicesProfileId: terminal.servicesProfileId,
                    paymentType: terminal.paymentType,
                    modemPhoneNumber: values.modemPhoneNumber,
                    imei: values.imei,
                    active: values.active,
                }
            } else {
                variables = {
                    id: terminal.id,
                    guid: values.guid,
                    name: values.name,
                    typeId: terminal.typeId,
                    useCredit: values.useCredit,
                    useAMQP: values.useAMQP,
                    creditAmount: values.useCredit ? values.creditAmount : 0,
                    address: values.address,
                    merchantId: values.merchantId,
                    paymentType: terminal.paymentType,
                    modemPhoneNumber: values.modemPhoneNumber,
                    imei: values.imei,
                    active: values.active,
                }
            }
            // toast.promise(
            //     updateTerminal({
            //         variables: {
            //             terminal: variables
            //         }
            //     }), {
            //         pending: 'В обработке',
            //         success: 'Терминал успешно обнавлен',
            //         error: 'Ошибка'
            //     }
            // )
            //     .then(result => {
            //         navigate(-1);
            //     })
            //     .catch(err => {
            //         setClicked(false);
            //     })
            
            updateTerminal({
                variables: { terminal: variables }
            })
                .then(data => {
                    toast.promise(
                        changeProductsMerchant({ variables: {
                            guid: variables.guid,
                            bin: merchants.find(item => parseInt(item.id) === parseInt(variables.merchantId)).bin
                        }, context: { clientName: 'billing' }}),
                        {
                            pending: 'В обработке',
                            success: 'Терминал успешно обновлен',
                            error: 'Ошибка'
                        })
                        .then(result => {
                            navigate(`/terminals?name=${Filter.terminalsFilter.name}&guid=${Filter.terminalsFilter.guid}&merchantId=${Filter.terminalsFilter.merchantId}&servicesProfileId=${Filter.terminalsFilter.servicesProfileId}&paymentType=${Filter.terminalsFilter.paymentType}&active=${Filter.terminalsFilter.active}&typeId=${Filter.terminalsFilter.typeId}&useCredit=${Filter.terminalsFilter.useCredit}&size=${Filter.terminalsFilter.size}`);
                            setClicked(false);

                        })
                        .catch(err => {
                            setClicked(false);
                        })
                })
                .catch(err => {
                    setClicked(false);
                })
        }
    });
    
    const [isBlocked, setIsBlocked] = useState(true);
    
    const autocompleteChangeHandler = (name, id) => {
        setTerminal({
            ...terminal,
            [name]: id
        })
    };
    
    const handleActiveChange = (e) => {
        setTerminal({
            ...terminal,
            [e.target.name]: !terminal[e.target.name]
        })
    }

    if (loading || serviceProfile.loading || terminalTypes.loading) return <Loader/>

    return (
        <div>
            <Form onSubmit={formik.handleSubmit}>
                <Row className='row_form'>
                    <Col xs={4}><Label for="id">ID</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="id"
                            id="id"
                            value={terminal.id}
                            disabled
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="creationDate">Дата создания</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="creationDate"
                            id="creationDate"
                            value={terminal.creationDate ? (terminal.creationDate).slice(0, 19) : new Date(terminal.creationDate).toLocaleString()}
                            disabled
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="changeDate">Дата изменения</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="changeDate"
                            id="changeDate"
                            value={terminal.changeDate ? (terminal.changeDate).slice(0, 19) : new Date(terminal.changeDate).toLocaleString()}
                            disabled
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="guid">GUID</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="guid"
                            id="guid"
                            value={formik.values.guid}
                            disabled
                        />
                        <FormFeedback>
                            {formik.touched.guid && formik.errors.guid}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="name">Наименование</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            value={formik.values.name}
                            disabled={isBlocked}
                            onChange={(e) => {
                                formik.handleChange(e);
                                handleChange(e)
                            }}
                            invalid={formik.touched.name && Boolean(formik.errors.name)}
                        />
                        <FormFeedback>
                            {formik.touched.name && formik.errors.name}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="merchantId">Мерчант</Label></Col>
                    <Col xs={8}>
                        <CustomAutocomplete
                            list={merchants}
                            placeholder="Наименование мерчанта"
                            name={'merchantId'}
                            setValue={(name, item) => autocompleteChangeHandler(name, item.id)}
                            searchBy={['name']}
                            resultStringKeyName='name'
                            getValueBy={'id'}
                            disabled={isBlocked}
                            edit={true}
                            value={formik.values.merchantId || null}
                            invalid={formik.touched.merchantId && Boolean(formik.errors.merchantId)}
                            formFeedback={formik.touched.merchantId && formik.errors.merchantId}
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="paymentType">Тип оплаты</Label></Col>
                    <Col xs={8}>
                        {isBlocked
                            ? <Input
                                type="text"
                                name="paymentType"
                                id="paymentType"
                                value={formik.values.paymentType}
                                disabled={isBlocked}
                                onChange={formik.handleChange}
                            />
                            : <Input
                                type="select"
                                name="paymentType"
                                id="paymentType"
                                required
                                onChange={handleChange}
                                value={formik.values.paymentType}
                                invalid={formik.touched.paymentType && Boolean(formik.errors.paymentType)}
                            >
                                <option value="default" disabled>Выберите тип оплаты</option>
                                <option>
                                    INVOICE
                                </option>
                                <option>
                                    SERVICE
                                </option>
                            </Input>
                        }
                        <FormFeedback>
                            {formik.touched.paymentType && formik.errors.paymentType}
                        </FormFeedback>
                    </Col>
                </Row>
                {formik.values.paymentType === "INVOICE"
                    ? null
                    : <Row className='row_form'>
                        <Col xs={4}><Label for="servicesProfileId">Профиль услуги</Label></Col>
                        <Col xs={8}>
                            <CustomAutocomplete
                                list={serviceProfile.data?.servicesProfiles}
                                placeholder="Наименование профиля услуги"
                                name={'servicesProfileId'}
                                setValue={(name, item) => autocompleteChangeHandler(name, item.id)}
                                searchBy={['name']}
                                getValueBy={'id'}
                                resultStringKeyName='name'
                                disabled={isBlocked}
                                edit={true}
                                value={formik.values.servicesProfileId || null}
                                invalid={formik.touched.servicesProfileId && Boolean(formik.errors.servicesProfileId)}
                                formFeedback={formik.touched.servicesProfileId && formik.errors.servicesProfileId}
                            />
                        </Col>
                    </Row>
                }
                {formik.values.paymentType === "INVOICE"
                    ? null
                    : <Row className='row_form'>
                        <Col xs={4}><Label for="step">Шаг</Label></Col>
                        <Col xs={8}>
                            <Input
                                type="text"
                                name="step"
                                id="step"
                                value={formik.values.step}
                                disabled={isBlocked}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    handleChange(e)
                                }}
                                invalid={formik.touched.step && Boolean(formik.errors.step)}
                            />
                        </Col>
                        <FormFeedback>
                            {formik.touched.step && formik.errors.step}
                        </FormFeedback>
                    </Row>
                }
                
                <Row className='row_form'>
                    <Col xs={4}><Label for="typeId">Тип</Label></Col>
                    <Col xs={8}>
                        <CustomAutocomplete
                            list={terminalTypes.data?.terminalTypes}
                            placeholder="Тип"
                            name={'typeId'}
                            setValue={(name, item) => autocompleteChangeHandler(name, item.id)}
                            searchBy={['name']}
                            resultStringKeyName='name'
                            getValueBy={'id'}
                            disabled={isBlocked}
                            edit={true}
                            value={formik.values.typeId || null}
                            invalid={formik.touched.typeId && Boolean(formik.errors.typeId)}
                            formFeedback={formik.touched.typeId && formik.errors.typeId}
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={12}>
                        <Label for="useCredit" className="activeTerminalLabel">Использовать кредит</Label>
                        <Input type="checkbox" name="useCredit" id="useCredit" checked={formik.values.useCredit} disabled={isBlocked} onChange={() => formik.setFieldValue("useCredit", !formik.values.useCredit)}/>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={12}>
                        <Label for="useAMQP" className="activeTerminalLabel">Новый протокол</Label>
                        <Input type="checkbox" name="useAMQP" id="useAMQP" checked={formik.values.useAMQP} disabled={isBlocked} onChange={() => formik.setFieldValue("useAMQP", !formik.values.useAMQP)}/>
                    </Col>
                </Row>
                {formik.values.useCredit
                    ? <Row className='row_form'>
                        <Col xs={4}><Label for="creditAmount">Сумма кредита</Label></Col>
                        <Col xs={8}>
                            <Input
                                type="number"
                                name="creditAmount"
                                id="creditAmount"
                                invalid={formik.touched.creditAmount && Boolean(formik.errors.creditAmount)}
                                onChange={formik.handleChange}
                                value={formik.values.creditAmount}
                                disabled={isBlocked}
                            />
                            <FormFeedback>
                                {formik.touched.creditAmount && formik.errors.creditAmount}
                            </FormFeedback>
                        </Col>
                    </Row>
                    : null
                }
                <Row className='row_form'>
                    <Col xs={4}><Label for="address">Адрес</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="address"
                            id="address"
                            value={formik.values.address}
                            disabled={isBlocked}
                            onChange={formik.handleChange}
                            invalid={formik.touched.address && Boolean(formik.errors.address)}
                        />
                        <FormFeedback>
                            {formik.touched.address && formik.errors.address}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="modemPhoneNumber">Номер сим-карты</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="tel"
                            mask="+7(999)999-99-99"
                            maskChar="_"
                            name="modemPhoneNumber"
                            id="modemPhoneNumber"
                            pattern={'^(\\+\\d{1})\\(\\d{3}\\)\\d{3}[\\s.-]\\d{2}[\\s.-]\\d{2}$'}
                            title={'+7(XXX)XXX-XX-XX'}
                            value={formik.values.modemPhoneNumber || ''}
                            disabled={isBlocked}
                            invalid={formik.touched.modemPhoneNumber && Boolean(formik.errors.modemPhoneNumber)}
                            placeholder={'+7(XXX)XXX-XX-XX'}
                            onChange={formik.handleChange}
                            tag={InputMask}
                        />
                        <FormFeedback>
                            {formik.touched.modemPhoneNumber && formik.errors.modemPhoneNumber}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="imei">IMEI</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="imei"
                            id="imei"
                            value={formik.values.imei || ''}
                            disabled={isBlocked}
                            invalid={formik.touched.imei && Boolean(formik.errors.imei)}
                            onChange={formik.handleChange}
                        />
                        <FormFeedback>
                            {formik.touched.imei && formik.errors.imei}
                        </FormFeedback>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={12}>
                        <Label for="active" className="activeTerminalLabel">Активный</Label>
                        <Input type="checkbox" name="active" id="active" checked={formik.values.active} disabled={isBlocked} onChange={() => formik.setFieldValue("active", !formik.values.active)}/>
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Button type="button" color="dark" onClick={() => navigate(-2)} className={'crud_button'}>Назад</Button></Col>
                        {isBlocked
                            ? <Col xs={4}><Button
                                color="success"
                                className="crud_button"
                                onClick={() => setIsBlocked(false)}
                            >
                                Редактировать
                            </Button></Col>
                            : <>
                                <Col xs={4}><Button type="button" color="danger" className="crud_button" onClick={() => {
                                    setIsBlocked(true);
                                    setTerminal(data.terminal);
                                }}>
                                    Отмена
                                </Button></Col>
                                <Col xs={4}><Button type="submit" color="primary" className="crud_button mr" disabled={clicked}>
                                    Сохранить
                                </Button></Col>
                            </>
                        }
                </Row>
            </Form>
        </div>
    );
};

export default TerminalEdit;