import React, {useState} from 'react';
import {Modal, ModalHeader, Table} from "reactstrap";

const RefundsBillingTransactions = ({refunds}) => {
    const [showModal, setShowModal] = useState(false);
    
    const dateFormatFunc = (value) => {
        let date = new Date(value);
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, 0);
        let day = (date.getDate()).toString().padStart(2, 0);
        let hours = (date.getHours()).toString().padStart(2, 0);
        let minutes = (date.getMinutes()).toString().padStart(2, 0);
        let seconds = (date.getSeconds()).toString().padStart(2, 0);
        return value === null ? "N/A" : `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    }
    return(
        <>
            <span onClick={() => setShowModal(true)} className='paymentValue'>Возвраты</span>
            <Modal
                isOpen={showModal}
                centered
                fullscreen="xl"
                size="lg"
                toggle={() => setShowModal(!showModal)}
            >
                <ModalHeader toggle={() => setShowModal(!showModal)}>
                    Список возвратов
                </ModalHeader>
                <Table striped style={{textAlign: 'center'}}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>createdDate</th>
                            <th>changedDate</th>
                            <th>amount</th>
                            <th>status</th>
                            <th>comment</th>
                            <th>txn_id</th>
                        </tr>
                    </thead>
                    <tbody>
                    
                    </tbody>
                {refunds.map((item, i) => {
                    return <tr key={item.id}>
                            <td>{i + 1}</td>
                            <td>{dateFormatFunc(item.createdDate)}</td>
                            <td>{dateFormatFunc(item.changedDate)}</td>
                            <td>{item.amount} </td>
                            <td>{item.status}</td>
                            <td>{item.comment}</td>
                            <td>{item.txn_id}</td>
                        </tr>
                })}
                </Table>
            </Modal>
        </>
    )
};

export default RefundsBillingTransactions;