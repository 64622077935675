import { Link } from "react-router-dom";
import InvoiceDetails from "../../components/InvoiceDetails/InvoiceDetails";
import PaymentDetails from "../../components/PaymentDetails/PaymentDetails";
import InvoiceTerminals from "../../components/InvoiceTerminals/InvoiceTerminals";
import RefundsBillingTransactions from "../../components/RefundsBillingTransactions/RefundsBillingTransactions";
import BillingTransactionsForActivation
    from "../../components/BillingTransactionsForActivation/BillingTransactionsForActivation";

export const resolveRowValue = (row, i, item, terminals, services, link, merchants, serviceProfiles, terminalTypes, paymentSystems, billingInvoiceStatuses, billing) => {

    switch (item.title){
        case '#':
            return i + 1;
        case 'status':
            if (billingInvoiceStatuses){
                let stat = billingInvoiceStatuses.find((statuses) => (statuses.status) === row[item.title]);
                return stat.russian
            } else return row[item.title];
        // case 'createdate': 
        //     return new Date(row[item.title]).toLocaleString(); 
        case 'creationDate':
        case 'changeDate':
        case 'clientDate':
            return row[item.title] ? row[item.title].slice(0, 19) : row[item.title];
            // return new Date(row[item.title]).toISOString();
        // case 'datecreate':
        //     let date = new Date(row[item.title]);
        //     console.log(date)
        //     let year = date.getFullYear();
        //     let month = (date.getMonth() + 1).toString().padStart(2, 0);
        //     let day = (date.getDate()).toString().padStart(2, 0);
        //     let hours = (date.getHours()).toString().padStart(2, 0);
        //     let minutes = (date.getMinutes()).toString().padStart(2, 0);
        //     let seconds = (date.getSeconds()).toString().padStart(2, 0);
        //     return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
        case 'createdDate':
        case 'changedDate':
        case 'lastSentDate':
        case 'activatedDate':
        case 'trialDateTo':
        case 'invoiceCreationDate':
            let date = new Date(row[item.title]);
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, 0);
            let day = (date.getDate()).toString().padStart(2, 0);
            let hours = (date.getHours()).toString().padStart(2, 0);
            let minutes = (date.getMinutes()).toString().padStart(2, 0);
            let seconds = (date.getSeconds()).toString().padStart(2, 0);
            return row[item.title] === null ? "N/A" : `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
        case 'name':
            if (link) return <Link to={`/${link}/${row.id}`} className="table_links">{row[item.title]}</Link>;
            return row.name
        case 'nameRus': 
            if (link) return <Link to={`/${link}/${row.id}`} className="table_links">{row[item.title]}</Link>;
            return row.nameRus
        case 'merchantName':
            let currentMerch = merchants.find((merch) => {
                return parseInt(merch.bin) === parseInt(row.bin)
            });
            if (currentMerch) {
                return <Link to={`/merchants/${currentMerch.id}`} className="table_links">{currentMerch.name}</Link>;
            } else {
                return row[item.title];
            }
        case 'bin':
            if (billing){
                let currentMerch = merchants.find((merch) => {
                    return parseInt(merch.bin) === parseInt(row.bin)
                });
                if (currentMerch) {
                    return <Link to={`/merchants/${currentMerch.id}`} className="table_links">{currentMerch.name}</Link>;
                } else {
                    return row[item.title];
                }
            } else {
                return row[item.title];
            }
        case 'merchantId':
            let current = merchants.find((merch) => parseInt(merch.id) === parseInt(row[item.title]));
            if (current) {
                return <Link to={`/merchants/${row.merchantId}`} className="table_links">{current.name}</Link>;
            } else {
                return <Link to={`/merchants/${row.merchantId}`} className="table_links">{row[item.title]}</Link>;
            }
        case 'terminalId':
            let currentTerminal = terminals.find((terminal) => parseInt(terminal.id) === parseInt(row[item.title]));
            if (currentTerminal) {
                return <Link to={`/terminals/${row.terminalId}`} className="table_links">{currentTerminal.guid}</Link>;
            } else {
                return <Link to={`/terminals/${row.terminalId}`} className="table_links">{row[item.title]}</Link>;
            }
        case 'typeId':
            let currentType = terminalTypes.find(type => parseInt(type.id) === parseInt(row[item.title]));
            if (currentType) {
                return <Link to={`/terminal_types/${row.typeId}`} className="table_links">{currentType.name}</Link>;
            } else {
                return <Link to={`/terminal_types/${row.typeId}`} className="table_links">{row[item.title]}</Link>;
            }
        case 'serviceId':
            let currentService = services.find((terminal) => parseInt(terminal.id) === parseInt(row[item.title]));
            if (currentService) {
                return <Link to={`/services/${row.serviceId}`} className="table_links">{currentService.nameRus}</Link>;
            } else {
                return <Link to={`/services/${row.serviceId}`} className="table_links">{row[item.title]}</Link>;
            }
        case 'servicesProfileId':
            let currentProf = serviceProfiles.find((prof) => parseInt(prof.id) === row[item.title]);
            if (currentProf) {
                return <Link to={`/service_profiles/${row.servicesProfileId}`} className="table_links">{currentProf.name}</Link>;
            } else {
                return <Link to={`/service_profiles/${row.servicesProfileId}`} className="table_links">{row[item.title]}</Link>;
            }
        case 'paymentSystemId':
            console.log(paymentSystems);
            let currentPaymentSys = paymentSystems.find((sys) => sys.id === row[item.title]);
            return currentPaymentSys.name || row[item.title];  
        case 'guids':
            return (row[item.title]).length > 0 ? <InvoiceTerminals terminals={terminals} invoiceId={row.id}/> : "N/A";
        case 'active':
            return row[item.title] ? 'Да' : (row[item.title] === false ? 'Нет' : "N/A");
        case 'invoiceId':
            return row[item.title] ?  <InvoiceDetails id={row[item.title]}/> : "N/A";
        case 'paymentId':
            return row[item.title] ?  <PaymentDetails id={row[item.title]}/> : "N/A";
        case 'useCredit': 
        case 'useAMQP':
            return row[item.title] ? 'Да' : (row[item.title] === false ? 'Нет' : "N/A");
        case 'creditAmount':
        case 'billingInvoiceId':
        case 'accountId':
        case 'productId':
        case 'phone':
            return row[item.title] === null ? "N/A" :  row[item.title];
        case 'data':
            return (row[item.title].length > 315) ? row[item.title].substr(0, 315) + '...' : row[item.title]
        case 'refunds': 
            return <RefundsBillingTransactions refunds={row.refunds}/>
        case 'transactions':
            return <BillingTransactionsForActivation transactions={row.transactions}/>
        default:
            return row[item.title];
    }
}