import React from 'react';
import './ProgressBar.css';

const ProgressBar = ({completed, bgColor}) => {
    return (
        <div className='progress_container'>
            <div className='progress_filler' style={{width: `${completed}%`, backgroundColor: bgColor}}>
                <span className='progress_label'>{`${completed}%`}</span>
            </div>
        </div>
    )
}

export default ProgressBar;