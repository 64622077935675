import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {GET_NOT_LINKED_SERVICES, GET_SERVICE_PROFILE, GET_SERVICE_PROFILES} from "../../GraphQL/Queries";
import Loader from "../../components/UI/Loader/Loader";
import {Breadcrumb, BreadcrumbItem, Button, Col, Form, Input, Label, Row} from "reactstrap";
import ServiceProfileServices from "../../components/ServiceProfileServices/ServiceProfileServices";
import ServiceProfileChangeServices from "../../components/ServiceProfileChangeServices/ServiceProfileChangeServices";
import {UPDATE_SERVICE_PROFILE} from "../../GraphQL/Mutations";
import { toast } from 'react-toastify';

const ServiceProfileEdit = () => {
    const [clicked, setClicked] = useState(false);

    const params = useParams();
    const [updateServicesProfile] = useMutation(UPDATE_SERVICE_PROFILE, {
        awaitRefetchQueries: true,
        refetchQueries: [
            { query: GET_SERVICE_PROFILES, variables: { sortBy: 'id'}},
            { query: GET_NOT_LINKED_SERVICES, variables: { sortBy: "creation_date", sortOrder: "desc"}}
        ],
    });
    const navigate = useNavigate();
    const [serviceProfile, setServiceProfile] = useState({});
    const [isBlocked, setIsBlocked] = useState(true);

    const services = useQuery(GET_NOT_LINKED_SERVICES, { variables: {
            sortBy: "creation_date",
            sortOrder: "desc"
        }});

    const { loading, error, data } = useQuery(GET_SERVICE_PROFILE, { variables: {
            id: params.id
        }}
    );

    const [availableServices, setAvailableServices] = useState([]);

    const handleSubmit = (e) => {
        setClicked(true);

        e.preventDefault();
        let services = [];
        for (let i = 0; i < serviceProfile.services.length; i++){
            services.push({id: serviceProfile.services[i].id});
        }

        toast.promise(
            updateServicesProfile({
                variables: {
                    servicesProfile: {
                        id: serviceProfile.id,
                        name: serviceProfile.name,
                        services: services
                    }
                }
            }),{
                pending: 'В обработке',
                success: 'Профиль успешно изменен',
                error: 'Ошибка'
            }
        )
            .then(result => {
                console.log(result)
                navigate('/service_profiles');
            })
            .catch(err => {
                setClicked(false);
            })
    };

    const handleChange = (e) => {
        setServiceProfile({
            ...serviceProfile,
            [e.target.name]: e.target.value
        })
    };

    const addService = (e) => {
        let profile_services = [...serviceProfile.services];
        let available_services = [...availableServices];

        let service = available_services.find(item => (item.id).toString() === e.target.id);

        let availableServicesNew = available_services.filter(item => item.id !== service.id);

        profile_services.push(service);

        setAvailableServices(availableServicesNew);

        setServiceProfile({
            ...serviceProfile,
            services: profile_services
        })
    };

    const removeService = (e) => {
        let profile_services = [...serviceProfile.services];
        let available_services = [...availableServices];

        let service = profile_services.find(item => (item.id).toString() === e.target.id);

        let profileServicesNew = profile_services.filter(item => item.id !== service.id);

        available_services.push(service);

        setServiceProfile({
            ...serviceProfile,
            services: profileServicesNew
        });

        setAvailableServices(available_services);
    };


    useEffect(() => {

        if (data && data.servicesProfile) {
            setServiceProfile(data.servicesProfile);
        }

        if (services.data) {
            setAvailableServices(services.data.servicesNotLinked);
        }
    },[data, services.data]);

    if (loading || services.loading) return <Loader/>

    if (error) return <div>Ошибка
        {error.message}
    </div>

    return (
        <div>
            <Breadcrumb>
                <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to='/service_profiles' className="breadcrumb_link">Профили услуг</Link></BreadcrumbItem>
                <BreadcrumbItem active>{data.servicesProfile.name}</BreadcrumbItem>
            </Breadcrumb>

            <h3 className="main_title">Редактирование профиля услуг</h3>

            <Form onSubmit={handleSubmit}>
                <Row className='row_form'>
                    <Col xs={4}><Label for="id">ID</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="name"
                            id="id"
                            value={serviceProfile.id}
                            disabled
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="name">Дата создания</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            value={serviceProfile.creationDate ? (serviceProfile.creationDate).slice(0, 19) : new Date(serviceProfile.creationDate).toLocaleString()}
                            disabled
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="name">Дата изменения</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            value={serviceProfile.changeDate ? (serviceProfile.changeDate).slice(0, 19) : new Date(serviceProfile.changeDate).toLocaleString()}
                            disabled
                        />
                    </Col>
                </Row>
                <Row className='row_form'>
                    <Col xs={4}><Label for="name">Наименование</Label></Col>
                    <Col xs={8}>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            disabled={isBlocked}
                            value={serviceProfile.name}
                            onChange={handleChange}
                            required
                        />
                    </Col>
                </Row>

                {isBlocked
                    ? <ServiceProfileServices
                        services={serviceProfile.services}
                    />
                    : <ServiceProfileChangeServices
                        available_services={availableServices}
                        profile_services={serviceProfile.services}
                        addService={(e) => addService(e)}
                        removeService={(e) => removeService(e)}
                    />
                }
                <Row className='row_form'>
                    <Col xs={4}>
                        <Button color='dark' className={'crud_button'} onClick={() => navigate(-1)}>Назад</Button>
                    </Col>
                    
                        {isBlocked
                            ? <Col xs={4}><Button
                                color="success"
                                className="crud_button"
                                onClick={() => setIsBlocked(false)}>
                                Редактировать
                            </Button></Col>
                            : <>
                                <Col xs={4}><Button
                                    type="button"
                                    color="danger"
                                    className="crud_button"
                                    onClick={() => {
                                        setIsBlocked(true);
                                        setServiceProfile(data.servicesProfile);
                                    }}
                                >
                                    Отмена
                                </Button></Col>
                                <Col xs={4}><Button type="submit" color="primary" className="crud_button mr" disabled={clicked}>
                                    Сохранить
                                </Button></Col>
                            </>
                        }
                </Row>
            </Form>
        </div>
    );
};

export default ServiceProfileEdit;
