import React from 'react';
import './Legenda.css';

const Legenda = ({data, title}) => {
    return (
        <div>
            <p>{title}</p>
            <div className={'Legenda wrapper'}>
                {
                    data.map(item => {
                        return <div key={item.status} className={'wrapper'}>
                            <div style={{backgroundColor: item.color ? item.color : ''}} className={'color_box'}></div> -  {item.russian ? item.russian : item.status}
                        </div>
                    })
                }
            </div>
        </div>
    );
};

export default Legenda;