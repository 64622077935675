import axios from "axios";
import config from "./config";

const instance = axios.create({
  baseURL: config.apiURL,
  headers: {
    Authorization: 'Basic YWRtaW46YWRtaW4='
  }
});

export default instance;
