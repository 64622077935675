import React from 'react';
import {Table} from "reactstrap";

const TotalPaymentsTable = ({totalAmount, totalCanceledAmount, totalCreditAmount}) => {
    return (
        <Table borderless style={{width: 300}}>
            <tbody>
                <tr>
                    <td>
                        Общая сумма
                    </td>
                    <td>
                        {totalAmount ? totalAmount.toLocaleString('ru') : ''}
                    </td>
                </tr>
                <tr>
                    <td style={{padding: '0 8px'}}>
                        Общая сумма кредита
                    </td>
                    <td style={{padding: '0 8px'}}>
                        {totalCreditAmount ? totalCreditAmount.toLocaleString('ru') : ''}
                    </td>
                </tr>
                <tr>
                    <td>
                        Общая сумма отмены
                    </td>
                    <td>
                        {totalCanceledAmount ? totalCanceledAmount.toLocaleString('ru') : ''}
                    </td>
                </tr>
            </tbody>
        </Table>
    );
};

export default TotalPaymentsTable;