import { Link } from "react-router-dom";

export const resolvePaymentValue = (field, value, terminals, merchants, services) => {
    switch (field) {
        case 'creationDate':
        case 'changeDate':
        case 'clientDate':
            return value ? value.slice(0, 19) : value;
        case 'invoiceId':
            return value ?  value : "N/A";
        case 'paymentId':
            return value ?  value : "N/A";
        case 'merchantId':
                let current = merchants.find((merch) => parseInt(merch.id) === parseInt(value));
                if (current) {
                    return <Link to={`/merchants/${value}`} className="breadcrumb_link">{current.name}</Link>;
                } else {
                    return <Link to={`/merchants/${value}`} className="breadcrumb_link">{value}</Link>;
                };
        case 'terminalId':
            let currentTerminal = terminals.find((terminal) => parseInt(terminal.id) === parseInt(value));
            if (currentTerminal) {
                return <Link to={`/terminals/${value}`} className="breadcrumb_link">{currentTerminal.guid}</Link>;
            } else {
                return <Link to={`/terminals/${value}`} className="breadcrumb_link">{value}</Link>;
            };
        case 'serviceId':
            if (!value) return "N/A";

            let currentService = services.find((terminal) => parseInt(terminal.id) === parseInt(value));
            if (currentService) {
                return <Link to={`/services/${value}`} className="breadcrumb_link">{currentService.nameRus}</Link>;
            } else {
                return <Link to={`/services/${value}`} className="breadcrumb_link">{value}</Link>;
            };
        default:
            return value;
    }
}