import {makeAutoObservable} from "mobx";
import { Amplify, Auth } from 'aws-amplify';
import awsExports from '../aws-config';

Amplify.configure(awsExports);

class User {
    user = JSON.parse(localStorage.getItem('user')) || {};
    challengeName = '';
    loading = false;
    error = null;
    username = '';
    password = '';

    constructor() {
        makeAutoObservable(this)
    };
    
    login(userName, password) {
        this.loading = true;
        this.error = null;
        this.username = userName;
        this.password = password;
        
        Auth.signIn({
            username: userName,
            password: password
        })
            .then(user => {
                console.log(user);                
                this.challengeName = user.challengeName;

                if (user.challengeName !== 'NEW_PASSWORD_REQUIRED') {
                    this.user = user;
                    localStorage.setItem('user', JSON.stringify(user));
                }
                
                this.loading = false;
                
            })
            .catch(err => {
                console.log(err.message)
                this.error = err.message;
                this.loading = false;
            })
    };

    completeNewPassword(newPassword){
        this.loading = true;

        console.log(this.user);
        Auth.signIn({
            username: this.username,
            password: this.password
        })
            .then(user => {
                console.log(user);
                Auth.completeNewPassword(user, newPassword)
                    .then(response => {
                        console.log(response);
                        localStorage.setItem('user', JSON.stringify(response));
                        this.user = response;
                        this.challengeName = '';
                        this.loading = false;
                    })
                    .catch(err => {
                        console.log(err.message)
                        this.error = err.message;
                        this.loading = false;
                    })
            })
    };
    
    changePassword(oldPass, newPass){
        this.loading = true;

        return Auth.signIn(this.user.username, oldPass)
            .then(user => {
                return Auth.changePassword(user, oldPass, newPass)
                    .then(response => {
                        console.log(response)
                        this.loading = false;

                        return response;
                    })
            })
            .catch(err => {
                this.loading = false;
                return err.message;
            })
    }
    
    logout() {
        Auth.signOut();
        this.user = {};
        localStorage.removeItem('user');
    };
}

export default new User();