import React, {useEffect, useState} from 'react';
import {Button, Form, Input} from "reactstrap";
import {useNavigate, Link, useSearchParams} from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { DataTable } from '../../components/DataTable/DataTable';
import {useQuery} from "@apollo/client";
import Loader from "../../components/UI/Loader/Loader";
import {FILTER_MERCHANTS, GET_INVOICE_TYPES, GET_MERCHANTS} from "../../GraphQL/Queries";
import CustomAutocomplete from "../../components/CustomAutocomplete/CustomAutocomplete";
import Filter from "../../store/Filter";

const Merchants = () => {
    const [filter, setFilter] = useState({
        page: 1,
        size: 25,
        sortBy: 'id',
        sortOrder: 'asc'
    });

    const [searchParams, setSearchParams] = useSearchParams();

    const [ pageLoading, setPageLoading ] = useState(false);
    
    const { data, error, loading, refetch } = useQuery(FILTER_MERCHANTS, {
        variables: {
            page: 0,
            size: filter.size,
            sortBy: 'id',
            sortOrder: 'asc'
        }
    });

    const allMerchants = useQuery(GET_MERCHANTS, { variables: {
            sortBy: "id",
            sortOrder: "asc"
        }}
    );

    const {loading: invoiceTypesLoading, data: invoiceTypesData} = useQuery(GET_INVOICE_TYPES, {
        context: {clientName: 'billing'}
    });
    
    const [merchants, setMerchants] = useState([]);
    const [totalItems, setTotalItems] = useState();
    
    const navigate = useNavigate();

    const handlePageChange = (pageNumber) => {
        setPageLoading(true);
        
        console.log(`active page is ${pageNumber}`);
        setFilter({
            ...filter,
            page: pageNumber
        });
        
        refetch({
            filter: {
                name: Filter.merchantsFilter.name ? Filter.merchantsFilter.name : null,
                bin: Filter.merchantsFilter.bin ? Filter.merchantsFilter.bin : null
            },
            page: pageNumber - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => {
                setPageLoading(false);
            })
    };
    
    const sortByHandler = (e) => {
        setPageLoading(true);
       
        if (filter.sortBy === e.target.id) {
            setFilter({
                ...filter,
                sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
            });
        } else {
            setFilter({
                ...filter,
                sortBy: e.target.id
            });
        }
        
        refetch({
            filter: {
                name: Filter.merchantsFilter.name ? Filter.merchantsFilter.name : null,
                bin: Filter.merchantsFilter.bin ? Filter.merchantsFilter.bin : null
            },
            page: filter.page - 1,
            size: filter.size,
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder === "asc" ? "desc" : "asc"
        })
            .then(() => {
                setPageLoading(false);
            })
    };
    
    const pageSizeHandler = (e) => {
        setPageLoading(true);
        
        setFilter({
            ...filter,
            size: parseInt(e.target.value), 
            page: 1
        });
        Filter.setMerchantsFilter('size', parseInt(e.target.value));
        setSearchParams({...Filter.merchantsFilter, size: parseInt(e.target.value)});
        refetch({
            filter: {
                name: Filter.merchantsFilter.name ? Filter.merchantsFilter.name : null,
                bin: Filter.merchantsFilter.bin ? Filter.merchantsFilter.bin : null
            },
            page: 0,
            size: parseInt(e.target.value),
            sortBy: filter.sortBy,
            sortOrder: filter.sortOrder
        })
            .then(() => {
                setPageLoading(false);
            })
    };
    
    useEffect(() => {
        if (data) {
            setMerchants(data.merchantsPage.data);
            setTotalItems(data.merchantsPage.total)
        }
    }, [data, filter]);

    let name = searchParams.get("name");
    let bin = searchParams.get("bin");
    let size = searchParams.get('size');
    
    useEffect(() => {
        if (name || bin || size){
            if (name) Filter.setMerchantsFilter('name', name);
            if (bin) Filter.setMerchantsFilter('bin', bin);
            if (size) {
                Filter.setMerchantsFilter('size', size);
                setFilter({...filter, size});
            }
            refetch({
                filter: {
                    name: name || null,
                    bin: bin || null
                },
                page: 0,
                size: parseInt(filter.size),
                sortBy: filter.sortBy,
                sortOrder: filter.sortOrder
            })
        } else {
            Filter.setMerchantsFilter('size', 25);
            Filter.setMerchantsFilter('name', '');
            Filter.setMerchantsFilter('bin', '');
        }
    }, [filter.size, Filter.merchantsFilter.size]);

    const columns = [
        { title: '#', id: '#' },
        { title: 'Id', id: 'id' },
        { title: 'Наименование', id: 'name'},
        { title: 'Номер', id: 'phone'},
        { title: 'БИН', id: 'bin' },
        { title: 'Номер тех. поддержки', id: 'helpDeskPhone'},
        { title: 'Действия', id: 'actions'}
    ];
    
    const rowsItem = [
        { title: '#' },
        { title: 'id' }, 
        { title: 'name' },
        { title: 'phone' },
        { title: 'bin' },
        { title: 'helpDeskPhone' }
    ];
    
    if (loading || allMerchants.loading || invoiceTypesLoading) return <Loader/>
    
    return (
        <div>
            <div className="breadcrumb_wrapper">
                <Breadcrumb>
                    <BreadcrumbItem><Link to='/' className="breadcrumb_link">Главная</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Мерчанты</BreadcrumbItem>
                </Breadcrumb>
                <Button 
                    color="dark"
                    className="add_btn"
                    onClick={() => navigate('/merchants/new')}
                >
                    + Добавить 
                </Button>
            </div>
            <hr/>
            <Form onSubmit={(e) => {
                e.preventDefault();
                setPageLoading(true);
                        
                setSearchParams({
                    name: Filter.merchantsFilter.name,
                    bin: Filter.merchantsFilter.bin,
                    size: Filter.merchantsFilter.size
                });
                setFilter({
                    ...filter,
                    page: 1
                });

                refetch({
                    filter: {
                        name: Filter.merchantsFilter.name ? Filter.merchantsFilter.name : null,
                        bin: Filter.merchantsFilter.bin ? Filter.merchantsFilter.bin : null
                    },
                    page: 0,
                    size: filter.size,
                    sortBy: filter.sortBy
                })
                    .then(() => {
                        setPageLoading(false);
                    })
            }}>
                <div className={'filter_wrapper'}>
                    <div className={'filter_item'}>
                        <CustomAutocomplete
                            list={allMerchants.data ? allMerchants.data.merchants : []}
                            name={'merchantId'}
                            setValue={(name, item) => {
                                if (item) {
                                    setSearchParams({ ...Filter.merchantsFilter, name: item.name})
                                    Filter.merchantsFilter.name = item.name
                                } else {
                                    setSearchParams({ ...Filter.merchantsFilter, name: ''})
                                    Filter.merchantsFilter.name = null
                                }
                            }}
                            placeholder="Наименование / Бин мерчанта"
                            searchBy={["name", "bin"]}
                            resultStringKeyName='name'
                            getValueBy={'name'}
                            edit={true}
                            value={Filter.merchantsFilter.name || null}
                        />
                    </div>
                    <Input
                        className={'filter_item'}
                        type='text'
                        placeholder="Введите БИН"
                        value={Filter.merchantsFilter.bin}
                        name='bin'
                        onChange={(e) => {
                            setSearchParams({ ...Filter.merchantsFilter, name: Filter.merchantsFilter.name ? Filter.merchantsFilter.name : '', bin: e.target.value})
                            Filter.merchantsFilter.bin = e.target.value
                        }}
                    />
                    <div className={'buttons_wrapper'}>
                        <Button color={'primary'} type={'submit'} className="filter_button" style={{width: 100, marginRight: 20}} >Поиск</Button>
                        <Button color={'danger'} style={{width: 100}} className="filter_button" onClick={() => {
                            setPageLoading(false);
                            setSearchParams('');
                            Filter.cleanMerchantsFilter();
                            setFilter({
                                ...filter,
                                size: 25
                            })
                            refetch({
                                filter: {
                                    name: null,
                                    bin: null
                                },
                                page: filter.page - 1,
                                size: filter.size,
                                sortBy: filter.sortBy
                            })
                                .then(() => {
                                    setPageLoading(false);
                                })
                        }}>Сбросить</Button>
                    </div>
                </div>
            </Form>
            <hr/>
            <DataTable 
                hideColumns={['helpDeskPhone']}
                showInvoiceCreater={true}
                columns={columns}
                rows={merchants}
                rowsItem={rowsItem}
                itemsCountPerPage={filter.size}
                sortBy={filter.sortBy}
                sortOrder={filter.sortOrder}
                link={'merchants'}
                sortByHandler={(e) => sortByHandler(e)}
                pageSizeHandler={(e) => pageSizeHandler(e)}
                totalItemsCount={totalItems}
                activePage={filter.page}
                handlePageChange={(pageNumber) => handlePageChange(pageNumber)}
                isPaginated={true}
                showEditPhone={true}
                // hideIcons={true}
                invoiceTypesData={invoiceTypesData}
            />
            {pageLoading
                ? <Loader/>
                : null
            }
        </div>
    );
};

export default Merchants;